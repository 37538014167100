import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import useDebugInformation from '../../../../../hooks/useDebugInformation';
import Box from '@mui/material/Box';
import { state, ui } from '../../../../../interfaces/interfaces';
import TicketIncidentForm from './TicketForm';
import { api } from '../../../../../interfaces/interfaces';
import Skeleton from '@mui/material/Skeleton';
import ForwardEmailDialog from '../../../../shared/ForwardEmailDialog';
import MemoizedButton from './../../../../shared/Button';
import ReassignDialog from '../../../../shared/ReassignDialog';
import { User } from '../../../../../utilities/basicTypes';
import { useAppSelector } from '../../../../../store/hooks';
import { style } from '../../../../../utilities/style';
import { CacheTypes } from '../../../../../utilities/cache/interfaces';
import { cacheUtils } from '../../../../..';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const name = "TicketPanel"
const steps = ['Ticket Management'];

const getTicketType = async () => {
    if (ui.TicketTypeId === 0) {
        const ticket = (await api.fetchTicketsById(ui.TicketId))
        if (ticket && ticket.subCategoryId) {
            const subCategory = cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories").find(subCategory => subCategory.id === ticket.subCategoryId)
            ui.TicketTypeId = subCategory?.ticketTypeId || 0
            await getUsers(ticket.reporteeId, ticket.assigneeId);
            state.LoggedUser && !ui.ViewOnly && (ui.ViewOnly = (ticket.statusId > 3 || !state.Credentials?.manage))
        }
    }
}

const getUsers = async (reporteeId: number, assigneeId: number) => {
    const users = cacheUtils.getCache<CacheTypes.Users>("Users")
    if (users && users.length > 0) {
        ui.ItMembers.length === 0 && (ui.ItMembers = users.filter(user => user.roleId < 4))
        const reportee = (users.filter(user => user.id === reporteeId)[0])
        const assignee = (users.filter(user => user.id === assigneeId)[0])
        reportee && reportee?.id > 0 && reportee?.id !== state.Reportee.id && (state.Reportee = reportee as User)
        assignee && assignee?.id > 0 && assignee?.id !== state.Assignee.id && (state.Assignee = assignee as User)
    }
}

const requestInformationTemplate = () =>
    `Dear ${state.Reportee.firstName},<br><br>

I am reporting that ${state.LoggedUser?.profile.first_name} has requested additional information regarding the IT Support Ticket ID#: ${state.ActiveTicket?.id}.<br>
Please review the request and provide additional information as necessary.<br><br>

Ticket ID: ${state.ActiveTicket?.id}<br>
Reported by: ${state.Reportee.firstName} ${state.Reportee.lastName}<br>
Assigned to: ${state.Assignee.firstName} ${state.Assignee.lastName}<br>
Category: ${state.ActiveTicket?.category.name}<br>
Subcategory: ${state.ActiveTicket?.subCategory.name}<br>
Status: ${state.ActiveTicket?.status.name}<br>
Priority: ${state.ActiveTicket?.priority.name}<br><br>

Current Progress:<br>
${state.ActiveTicket?.progress.value.replaceAll("\n", "<br>")}<br><br>

Requested by: ${state.LoggedUser?.profile.first_name} ${state.LoggedUser?.profile.last_name}:<br><br>
Please provide additional information regarding...
<br><br><br><br>
${state.LoggedUser?.profile.first_name}`.replaceAll("<br>", "%0D%0A")

const sendUpdateTemplate = () =>
    `Dear ${state.Reportee.firstName},<br><br>

I am reporting that ${state.LoggedUser?.profile.first_name} has updated the IT Support Ticket ID#: ${state.ActiveTicket?.id}.<br>
Please review the update as necessary.<br><br>

Ticket ID: ${state.ActiveTicket?.id}<br>
Reported by: ${state.Reportee.firstName} ${state.Reportee.lastName}<br>
Assigned to: ${state.Assignee.firstName} ${state.Assignee.lastName}<br>
Category: ${state.ActiveTicket?.category.name}<br>
Subcategory: ${state.ActiveTicket?.subCategory.name}<br>
Status: ${state.ActiveTicket?.status.name}<br>
Priority: ${state.ActiveTicket?.priority.name}<br><br>

Current Progress:<br>
${state.ActiveTicket?.progress.value.replaceAll("\n", "<br>")}<br><br>

Updated by: ${state.LoggedUser?.profile.first_name} ${state.LoggedUser?.profile.last_name}:<br><br>
Update:<br>
<br><br><br><br>
${state.LoggedUser?.profile.first_name}`.replaceAll("<br>", "%0D%0A")

const showReAssignForwarding = () => ui.ReassignDialog = true

const sendUpdate = () => window.location.href = `mailto:${state.Reportee.eMail}?subject=RE: Update to IT Support Ticket ID#: ${state.ActiveTicket?.id}&body=${sendUpdateTemplate()}`

const requestInformation = () => window.location.href = `mailto:${state.Reportee.eMail}?subject=RE: Additional information required for IT Support Ticket ID#: ${state.ActiveTicket?.id}&body=${requestInformationTemplate()}`

const sendEmail = () => window.location.href = `mailto:${state.Assignee.eMail}?subject=RE: IT Support Ticket ID#: ${state.ActiveTicket?.id}&body=Dear ${state.Assignee.firstName},`;

const reopen = (ticketId: number, navigate: NavigateFunction) => api.fetchTicketsPatch(ticketId, { statusId: 1 }).then(() => {
    state.ActiveTicket = null;
    navigate(`/manage-ticket/${ticketId}`)
})

const updateState = () => state.RequestUpdateActive = true

type Props = {
    parent: string
}

const TicketPanel: React.FC<Props> = ({ parent }) => {
    const ticketTypeId = useAppSelector(({ ui }) => ui.TicketTypeId)
    const activeStep = useAppSelector(({ ui }) => ui.ActiveStep)
    const requestChanged = useAppSelector(({ ui }) => ui.RequestChanged)
    const ticketId = useAppSelector(({ ui }) => ui.TicketId)
    const viewOnly = useAppSelector(({ ui }) => ui.ViewOnly)
    const loggedUser = useAppSelector(({ state }) => state.LoggedUser)
    const activeTicket = useAppSelector(({ state }) => state.ActiveTicket)
    const canReopen = useAppSelector(({ state }) => state?.Credentials?.reopen)

    const navigate = useNavigate()

    const debugName = `${parent}▷[${name}]`

    React.useEffect(() => {
        ticketId > 0 && getTicketType()
    }, [ticketId])

    useDebugInformation(debugName, { activeStep, ticketTypeId, ticketId, requestChanged, parent })
    return (
        <Box id="form-panel" sx={{ width: 1060 }}>
            <ForwardEmailDialog />
            <ReassignDialog />
            <Paper sx={(theme) => style(theme).paperTicketFormWrapper}>
                <Typography component="h6" variant="h6" align="center">
                    {steps[activeStep]}{ticketTypeId === 1 ? " - Incident" : " - Request"}
                </Typography>
                {[1, 2, 3].includes(ticketTypeId)
                    ? <TicketIncidentForm />
                    : <Skeleton variant="rectangular" width={"auto"} height={450} />}
                <Box sx={(theme) => style(theme).buttonsTickets}>
                    {requestChanged && !viewOnly && <Button
                        variant="contained"
                        color="error"
                        disabled
                        sx={[
                            {
                                '&.Mui-disabled': {
                                    backgroundColor: "#d32f2f",
                                    color: "white"
                                },
                            },
                            (theme) =>
                                style(theme).button
                        ]}
                    >Unsaved Changes Click UPDATE to preserve changes
                    </Button>}
                    {loggedUser && canReopen && activeTicket?.statusId === 4 && <MemoizedButton label='Reopen Ticket' onClick={() => reopen(ticketId, navigate)} sx={(theme) => ({ ...style(theme).button, backgroundColor: "red" })} />}
                    {viewOnly && <MemoizedButton label='Send Email' onClick={sendEmail} sx={(theme) => style(theme).button} />}
                    {!viewOnly && <MemoizedButton label='Re-assign' onClick={showReAssignForwarding} sx={(theme) => style(theme).button} />}
                    {!viewOnly && <MemoizedButton label='Send Update' onClick={sendUpdate} sx={(theme) => style(theme).button} />}
                    {!viewOnly && <MemoizedButton label='Request information' onClick={requestInformation} sx={(theme) => style(theme).button} />}
                    {!viewOnly && <Button
                        variant="contained"
                        disabled={!requestChanged}
                        color="primary"
                        onClick={updateState}
                        sx={(theme) => style(theme).button}>Update</Button>}
                </Box>
            </Paper >
            {/* <Copyright /> */}
        </Box >
    )
}
export default React.memo(TicketPanel)