import React from "react";
import Grid from '@mui/material/Grid';
import { api, ui } from "../../../../../interfaces/interfaces";
import { Tables } from '../../../../../services/databaseModels/tables/index';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import ChartByCategory from "./Charts/ByCategory"
import ChartByRate from "./Charts/ByRate"
// import ChartByClosureTimeBins from "./Charts/ByClosureBins"
import TablesBSMini from "../../../../shared/TablesBSMini";
import { sortTable } from "../../../../../assets/js/main";
import useDebugInformation from "../../../../../hooks/useDebugInformation";

const name = "Requests"

type Props = {
    parent: string

}

const columns = [
    {
        title: "Category:",
        data: "name",
        // render: (date: Date) => date.toLocaleDateString("en-GB"),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "Total:",
        data: "total",
        render: (data: number) => (data || 0),
        sortingActive: true,
        reversed: true,
        filter: "",
    },
    {
        title: "Open:",
        data: "open",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "On Hold:",
        data: "onHold",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "In Progress:",
        data: "inProgress",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "Closed:",
        data: "closed",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "Unset Risk:",
        data: "unsetRisk",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "Low Risk:",
        data: "lowRisk",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "Medium Risk:",
        data: "mediumRisk",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "High Risk:",
        data: "highRisk",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
    {
        title: "Critical Risk:",
        data: "criticalRisk",
        render: (data: number) => (data || 0),
        sortingActive: false,
        reversed: false,
        filter: "",
    },
]

const processData = (data: Tables.TicketsOverallOutput[]) => {
    const total = data.reduce((a, b) => a + (b.total || 0), 0)
    const open = data.reduce((a, b) => a + (b.open || 0), 0)
    const onHold = data.reduce((a, b) => a + (b.onHold || 0), 0)
    const inProgress = data.reduce((a, b) => a + (b.inProgress || 0), 0)
    const closed = data.reduce((a, b) => a + (b.closed || 0), 0)
    const unsetRisk = data.reduce((a, b) => a + (b.unsetRisk || 0), 0)
    const lowRisk = data.reduce((a, b) => a + (b.lowRisk || 0), 0)
    const mediumRisk = data.reduce((a, b) => a + (b.mediumRisk || 0), 0)
    const highRisk = data.reduce((a, b) => a + (b.highRisk || 0), 0)
    const criticalRisk = data.reduce((a, b) => a + (b.criticalRisk || 0), 0)

    ui.RequestsFooters = [
        "Totals:",
        total.toString(),
        `${open} (${(100 / total * open).toFixed(2)}%)`,
        `${onHold} (${(100 / total * onHold).toFixed(2)}%)`,
        `${inProgress} (${(100 / total * inProgress).toFixed(2)}%)`,
        `${closed} (${(100 / total * closed).toFixed(2)}%)`,
        `${unsetRisk} (${(100 / total * unsetRisk).toFixed(2)}%)`,
        `${lowRisk} (${(100 / total * lowRisk).toFixed(2)}%)`,
        `${mediumRisk} (${(100 / total * mediumRisk).toFixed(2)}%)`,
        `${highRisk} (${(100 / total * highRisk).toFixed(2)}%)`,
        `${criticalRisk} (${(100 / total * criticalRisk).toFixed(2)}%)`,
    ]
    ui.RequestsTableData = makeRawData(data)
}

const fetchRequestsData = async () => {
    const response = await api.fetchTicketsRequests()
    if (response) processData(response)
}

const makeRawData = (data: any) => {
    const newRowData: any[][] = []
    const sortCol = 1
    for (const row of data) {
        const newCellData: any[] = []
        const data = Object.values(row).slice(1)
        for (const cell of data) {
            newCellData.push(cell)
        }
        newRowData.push(newCellData)
    }
    return sortTable(newRowData, sortCol, true)
}

const RenderByCategoryTable = React.memo<{ parent: "Requests" | "Incidents" | "Overall" | "Manage" }>(({ parent }) => {
    return (
        <Paper elevation={4} sx={{ ml: 1 }}>
            <TablesBSMini tableName='tickets-Requests' parent={parent} columns={columns} />
        </Paper>
    )
});

const Requests: React.FC<Props> = ({ parent }) => {

    React.useEffect(() => { fetchRequestsData() }, [])
    const debugName = `${parent}▷[${name}]`
    useDebugInformation(debugName, { parent })
    return (
        <Paper elevation={4}>
            <Grid container gap={2} direction={"column"}>
                <Grid item sm={12} xs={12} sx={{ p: 1 }}>
                    <Paper elevation={4}>
                        <Grid container gap={2} sx={{ justifyContent: "space-between" }}>
                            <Grid item sm={7} xs={7} >
                                <Typography variant="h5" sx={{ p: 1 }} >Tickets by Category</Typography>
                                <RenderByCategoryTable parent={name} />
                            </Grid>
                            <Grid item sm={4} xs={4} sx={{ p: 1 }} >
                                <Paper elevation={4}>
                                    <ChartByCategory />
                                </Paper>
                            </Grid>
                        </Grid >
                    </Paper>
                </Grid>
                <Grid item sm={12} xs={12} sx={{ p: 1 }}>
                    <Paper elevation={4}>
                        <Paper elevation={4}>
                            <ChartByRate />
                        </Paper>
                    </Paper>
                </Grid>
                {/* <Grid item sm={12} xs={12} sx={{ p: 1 }}>
                    <Paper elevation={4}>
                        <Paper elevation={4}>
                            <ChartByClosureTimeBins />
                        </Paper>
                    </Paper>
                </Grid> */}
            </Grid>
        </Paper>
    )
}


export default React.memo(Requests)