import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import React from "react"
import { Tables } from "../../../services/databaseModels/tables"
import useDebugInformation from "../../../hooks/useDebugInformation"
import { ui } from "../../../interfaces/interfaces"

type BooleanRadioProps = {
    question: Tables.QuestionsOutput
    index: number
    id: number
    value: string
}

const MemoizedRadioQuestions = React.memo<BooleanRadioProps>(({ question, index, id, value }) => {
    const options = question?.options ? question.options.split("|") : [""]
        // if (!ui.Answer[id][`answer${index}`] && options)
    //     ui.Answer = { [id]: { ...ui.Answer[id], [`answer${index}`]: options[0] } }
    const debugName = "MemoizedRadioQuestions"
    useDebugInformation(debugName, { question, index, id, value })
    return (
        <FormControl component="fieldset" error={value === ""}>
            <FormLabel component="legend">{question.question}</FormLabel>
            <RadioGroup
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { ui.Answer = { [id]: { ...ui.Answer[id], [`answer${index}`]: (event.target as HTMLInputElement).value } } }}>
                {options.map((option, idx) => < FormControlLabel key={idx} value={option} control={<Radio />} label={option} />)}
            </RadioGroup>
            <FormHelperText>{value===""?"Please select option!":""}</FormHelperText>
        </FormControl >
    )
}
)

export default MemoizedRadioQuestions