import React from 'react'
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { ApplicationPaths } from '../../../../api-authorization/ApiAuthorizationConstants';

const AuthenticatedView: React.FC = () => {
    const profilePath = `${ApplicationPaths.Profile}`;
    const logoutPath = React.useMemo(() => ({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } }), []);
    return (
        <>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>
                    Account
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>
                    Logout
                </NavLink>
            </NavItem>
        </>
    )
}

export default React.memo(AuthenticatedView)