import React from 'react'
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import config from '../../utilities/env';
import TemporaryMessage from './TemporaryMessage';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.

const login = async (returnUrl: string | URL, setMessage: ((arg0: string) => void)) => {
    const state = { returnUrl };
    const result = await authService.signIn(state);
    if (result) {
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                break;
            case AuthenticationResultStatus.Success:
                navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                setMessage(result.status);
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }
}

const processLoginCallback = async (setMessage: { (value: React.SetStateAction<string>): void; (arg0: any): void; }) => {
    const url = window.location.href;
    const result = await authService.completeSignIn(url);

    console.log(result, "result", url, "url");
    if (result) {
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                navigateToReturnUrl(getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                setMessage(result.state);
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }
}

const getReturnUrl = (returnUrl: string) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    console.log('fromQuery', fromQuery, params);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
    }
    return (returnUrl) || fromQuery || `${window.location.origin}/`;
}

const redirectToRegister = () => {
    redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
}

const redirectToProfile = () => {
    redirectToApiManagePath(ApplicationPaths.IdentityManagePath);
}

const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
}

const redirectToApiManagePath = (apiAuthorizationPath: string) => {
    const authority = config.authority_url;
    const redirectUrl = `${authority}/${apiAuthorizationPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
}

const navigateToReturnUrl = (returnUrl: string | URL) => {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
}

const processAction = async (action: string, setMessage: React.Dispatch<React.SetStateAction<string>>) => {
    console.log('processAction', action);
    switch (action) {
        case LoginActions.Login:
            await login(getReturnUrl(action), setMessage);
            break;
        case LoginActions.LoginCallback:
            await processLoginCallback(setMessage);
            break;
        case LoginActions.LoginFailed:
            const params = new URLSearchParams(window.location.search);
            const error = params.get(QueryParameterNames.Message);
            setMessage(error ? error : 'Unknown error in login.');
            break;
        case LoginActions.Profile:
            redirectToProfile();
            break;
        case LoginActions.Register:
            redirectToRegister();
            break;
        default:
            throw new Error(`Invalid action '${action}'`);
    }
}

interface LoginProps {
    action: string;
}

export const Login: React.FC<LoginProps> = ({ action }) => {
    const [message, setMessage] = React.useState('');

    console.log('Login', action);

    React.useEffect(() => {
        processAction(action, setMessage);
    }, [action]);


    if (!!message) {
        return <div>{message}</div>
    } else {
        switch (action) {
            case LoginActions.Login:
                return (<TemporaryMessage message="Processing login..." />);
            case LoginActions.LoginCallback:
                return (<TemporaryMessage message="Processing login callback..." />);
            case LoginActions.Profile:
            case LoginActions.Register:
                return (<div></div>);
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }
}

Login.displayName = 'Login';