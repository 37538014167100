import React from 'react';
import useDebugInformation from '../../hooks/useDebugInformation';
import NavMenu from './NavMenu';
import { Box, Container } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { ApiAuthorizationRoutes } from '../api-authorization/ApiAuthorizationRoutes';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import Editor from './Editor';
import Categories from './Editor/Categories';
import Home from './Home';
import TicketsManagement from './TicketsManagement';
import RequestForm from './TicketsCreation/RequestForm';
import IncidentForm from './TicketsCreation/IncidentForm';
import Dashboard from './Dashboard';
import TicketManagement from './TicketsManagement/TicketManagement';

const Layout: React.FC = () => {
    const authRoutes = React.useCallback(() => ApiAuthorizationRoutes(), [])

    useDebugInformation("Layout", { authRoutes })

    return (
        <Box>
            <NavMenu />
            <Container style={{ maxWidth: "max-content" }}>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/request-form' element={<AuthorizeRoute path='/request-form' component={<RequestForm parent='Layout' />} />} />
                    <Route path='/report-form' element={<AuthorizeRoute path='/report-form' component={<IncidentForm parent='Layout' />} />} />
                    <Route path='/manage-tickets' element={<AuthorizeRoute path='/manage-tickets' component={<TicketsManagement parent='Layout' />} />} />
                    <Route path='/my-tickets' element={<AuthorizeRoute path='/my-tickets' component={<TicketsManagement parent='Layout' />} />} />
                    <Route path='/editor' element={<AuthorizeRoute path='/editor' component={<Editor />} />} />
                    <Route path='/edit-categories' element={<AuthorizeRoute path='/edit-categories' component={<Categories />} />} />
                    <Route path='/dashboard' element={<AuthorizeRoute path='/dashboard' component={<Dashboard parent='Layout' />} />} />
                    <Route path='/manage-ticket/:id' element={<AuthorizeRoute path='/manage-ticket/:id' component={<TicketManagement parent='Layout' />} />} />
                    <Route path='/view-ticket/:id' element={<AuthorizeRoute path='/view-ticket/:id' component={<TicketManagement parent='Layout' />} />} />
                    {authRoutes()}
                </Routes>
            </Container>
        </Box>
    )
};

export default React.memo(Layout)