import {
  dispatchHydrateUI,
  dispatchResetUI,
  dispatchResetUIWithCache,
  dispatchSaveUI,
} from "..";
import { store } from "../../../store";
import { logger } from "../../../services/logger";
import { fixDate } from "../../../utilities/general";
import ObjectsOperations from "../../../utilities/objects/operations";
import StorageUtils from "../../../services/storage";
import { storeName, templateState } from "../slice";
import { TemplateUIObject } from "../../../services/storage/storeTemplate/UI";

const namespace = "UIReducer";

export const hydrateUIReducer = async () => {
  const newState = ObjectsOperations.deepCopyObjectsAll(templateState);
  for (const key in newState) {
    const value = fixDate(StorageUtils.getFromStorage({ type: "local", store: storeName, key }));
    if (value) {
      newState[key as keyof typeof newState] = value as never;
    }
  }
  logger.debug(namespace, "Data loaded", newState);
  dispatchHydrateUI(newState);
  dispatchSaveUI();
};

// resetUI to initial state
export const resetUIReducer = (exceptions?: string[]) => {
  const exceptionsObj: any = {};
  const ui = store.getState().ui;
  if (exceptions) {
    for (const key of exceptions) {
      exceptionsObj[key] = ObjectsOperations.deepCopyObjectsAll(ui[key as keyof typeof ui]);
    }
  }
  dispatchResetUI(exceptionsObj);
  logger.debug(namespace, "Resetting UI state");
  dispatchSaveUI();
};

// resetUI to initial state
export const resetUIReducerWithCache = (exceptions?: string[]) => {
  const exceptionsObj: any = {};
  const ui = store.getState().ui;
  if (exceptions) {
    for (const key of exceptions) {
      exceptionsObj[key] = ObjectsOperations.deepCopyObjectsAll(ui[key as keyof typeof ui]);
    }
  }
  dispatchResetUIWithCache(exceptionsObj);
  logger.debug(namespace, "Resetting UI state");
  dispatchSaveUI();
};

// importUI to state
export const importUIReducer = (ui: TemplateUIObject) => {
  // preserve CallMeBacksQueue
  logger.debug(namespace, "Importing UI state", ui);
  dispatchHydrateUI(ui);

  dispatchSaveUI();
};
