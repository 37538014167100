import React from 'react';
import Layout from './components/Layout';
import { withOneTabEnforcer } from "react-one-tab-enforcer"
import './custom.css'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ui } from './interfaces/interfaces';
import useMediaQuery from '@mui/material/useMediaQuery';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { isDev } from './assets/js/main/index';
import { SnackbarMessage, OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import useDebugInformation from './hooks/useDebugInformation';
import { useAppSelector } from './store/hooks';

const name = "Ticketing System";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#3838d0"
    },
    secondary: {
      main: "#de0e0e"
    },
  }
})

const App = () => {
  const xs = useMediaQuery(theme.breakpoints.up('xs')); //xs, extra-small: 0px
  const sm = useMediaQuery(theme.breakpoints.up('sm')); //sm, small: 600px
  const md = useMediaQuery(theme.breakpoints.up('md')); //md, medium: 900px
  const lg = useMediaQuery(theme.breakpoints.up('lg')); //lg, large: 1200px
  const xl = useMediaQuery(theme.breakpoints.up('xl')); //xl, extra-large: 1536px
  const problemShowMsg = useAppSelector(({ ui }) => ui.ProblemShowMsg)
  const problemMsg = useAppSelector(({ ui }) => ui.ProblemMsg)
  const problemMsgVariant = useAppSelector(({ ui }) => ui.ProblemMsgVariant)
  const loading = useAppSelector(({ ui }) => ui.Loading)
  const done = useAppSelector(({ ui }) => ui.Done)
  const ticketsPerPage = useAppSelector(({ state }) => state.TicketsPerPage)
  const cachingStarted = useAppSelector(({ state }) => state.CachingStarted)

  React.useEffect(() => {
    if (lg && !xl && (ticketsPerPage !== 15)) {
      ui.TicketsPerPage = 15
    }
    else if (xl && (ticketsPerPage !== 20)) {
      ui.TicketsPerPage = 20
    }
    else
      ui.TicketsPerPage = 10
  }, [lg, ticketsPerPage, xl])

  React.useEffect(() => {
    ui.xs !== xs && (ui.xs = !ui.xs)
    ui.sm !== sm && (ui.sm = !ui.sm)
    ui.md !== md && (ui.md = !ui.md)
    ui.lg !== lg && (ui.lg = !ui.lg)
    ui.xl !== xl && (ui.xl = !ui.xl)
  }, [xs, sm, md, lg, xl])

  const showSnackBar = React.useCallback((enqueueSnackbar: {
    (message: SnackbarMessage, options?: OptionsObject | undefined): SnackbarKey; (arg0: any, arg1: { variant: any; }): any;
  }) => {
    enqueueSnackbar(problemMsg, { variant: problemMsgVariant });
    ui.ProblemShowMsg = false;
  }, [problemMsg, problemMsgVariant])

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    problemShowMsg && showSnackBar(enqueueSnackbar)
  }, [enqueueSnackbar, problemShowMsg, showSnackBar])

  useDebugInformation(name, { xs, sm, md, lg, xl, problemShowMsg, problemMsg, problemMsgVariant, loading, done, cachingStarted })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{ zIndex: 1000 }}
        open={(loading || (cachingStarted && !done))}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {isDev() && <Alert variant="filled" severity="warning">
        RUNNING IN DEV MODE! All emails will be sent to YOU only!
      </Alert>}
      <Layout />
    </ThemeProvider >
  );
}

export default withOneTabEnforcer({ appName: "TicketingSystem" })(App);