import React from 'react'
import { Link } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap'
import { ApplicationPaths } from '../../../../api-authorization/ApiAuthorizationConstants'

const AnonymousView: React.FC = () => {
    return (
        <NavItem>
            <NavLink tag={Link} className="text-dark" to={ApplicationPaths.Login}>
                Login
            </NavLink>
        </NavItem>
    )
}

export default React.memo(AnonymousView)