import { dispatchHydrateState, dispatchResetState, dispatchResetStateWithCache, dispatchSaveState } from "..";
import { TemplateSTATEObject } from "../../../services/storage/storeTemplate/State";
import { store } from "../../../store";
import { fixDate } from "../../../utilities/general";
import ObjectsOperations from "../../../utilities/objects/operations";
import StorageUtils from "../../../services/storage";
import { storeName, templateState } from "../slice";
import { logger } from "../../../services/logger";

const namespace = "StateReducer";

export const hydrateStateReducer = async () => {
  const newState = ObjectsOperations.deepCopyObjectsAll(templateState);
  for (const key in newState) {
    const value = fixDate(StorageUtils.getFromStorage({ type: "local", store: storeName, key }));
    if (value) {
      newState[key as keyof typeof newState] = value as never;
    }
  }
  logger.debug(namespace, "Data loaded", newState);
  dispatchHydrateState(newState);
  dispatchSaveState();
};

// resetState to initial state
export const resetStateReducer = (exceptions?: string[]) => {
  const exceptionsObj: any = {};
  const state = store.getState().state;
  if (exceptions) {
    for (const key of exceptions) {
      exceptionsObj[key] = ObjectsOperations.deepCopyObjectsAll(state[key as keyof typeof state]);
    }
  }
  dispatchResetState(exceptionsObj);
  logger.debug(namespace, "Resetting State state");
  dispatchSaveState();
};

// resetState to initial state
export const resetStateReducerWithCache = (exceptions?: string[]) => {
  const exceptionsObj: any = {};
  const state = store.getState().state;
  if (exceptions) {
    for (const key of exceptions) {
      exceptionsObj[key] = ObjectsOperations.deepCopyObjectsAll(state[key as keyof typeof state]);
    }
  }
  dispatchResetStateWithCache(exceptionsObj);
  logger.debug(namespace, "Resetting State state");
  dispatchSaveState();
};

// importState to state
export const importStateReducer = (state: TemplateSTATEObject) => {
  // preserve CallMeBacksQueue
  logger.debug(namespace, "Importing State state", state);
  dispatchHydrateState(state);

  dispatchSaveState();
};
