import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { logger } from "../../../services/logger";
import StorageUtils from "../../../services/storage";
import { CacheKeys, cacheTemplate, TemplateCACHEObject } from "../../../services/storage/storeTemplate/Cache";
import { Tables } from "../../../services/storage/model";
import { importCacheReducer } from "../operations";
import { cacheUtils } from "../../..";

const namespace = "CacheReducer";
const database = "CACHE";

const tableNames: Tables[] = [
  "TicketCategories",
  "RequestCategories",
  "Files",
  "Categories",
  "SubCategories",
  "Priorities",
  "Statuses",
  "Roles",
  "TicketTypes",
  "Questions",
  "QuestionsTypes",
  "Users",
  "Locations",
];

export const prepCache = async () => {
  StorageUtils.saveToStorage({ type: "local", store: "state", key: "CacheLoaded", value: false });
  StorageUtils.createIndexedDB(database);
  await StorageUtils.createStoreInIndexedDB({
    database,
    tableNames,
  });
  logger.debug(namespace, "IndexedDB initialized");

  const tempCache: TemplateCACHEObject = cacheTemplate();

  for (const key of Object.keys(tempCache)) {
    if (!tableNames.includes(key as Tables)) {
      logger.debug(namespace, `${key} is not a table name`);
      delete tempCache[key as Tables];
    }
  }

  for (let i = 0; i < tableNames.length; i++) {
    if (tableNames[i] !== "Files") {
      const tableName = tableNames[i];
      const table = await StorageUtils.getAllValuesFromStoreInIndexedDB<any>({ database, tableName });
      if (table && table.length > 0) {
        logger.debug(namespace, `${tableName} is populated`);
        tempCache[tableName] = table as any;
        cacheUtils.setCache(tableName, table);
      } else {
        logger.debug(namespace, `${tableName} is empty`);
      }
    }
  }

  importCacheReducer(tempCache);
  return tempCache;
};

export const storeName = "cache";
export const templateState = cacheTemplate();
export const initialState = cacheTemplate();
export interface Action {
  name?: CacheKeys;
  value: any;
}
export const cacheSlice = createSlice({
  initialState,
  name: storeName,
  reducers: {
    hydrate: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Importing Cache state");
      return { ...initialState, ...action.payload.value };
    },
    cached: (state) => {
      logger.debug(namespace, "Finished caching");
      state.Cached = true;
    },
    caching: (state) => {
      logger.debug(namespace, "Started caching");
      state.Cached = false;
    },
    reset: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Resetting Cache state");
      return { ...initialState, ...action.payload.value, Done: true };
    },
    resetWithCache: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Resetting Cache state");
      return { ...initialState, ...action.payload.value, Done: false };
    },
    save: (state) => {
      for (const key in state) {
        if (Array.isArray(state[key as CacheKeys])) {
          StorageUtils.setValuesInStoreInIndexedDB({
            database: "CACHE",
            tableName: key as Tables,
            values: state[key as keyof typeof state] as any[],
          });
        }
      }
    },
    set: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Setting Cache state");

      if (action.payload.name) {
        state[action.payload.name] = action.payload.value as never;
        if (action.payload.name !== "Cached") {
          StorageUtils.setValuesInStoreInIndexedDB({
            database: "CACHE",
            tableName: action.payload.name as Tables,
            values: action.payload.value as any[],
          });
        }
      }
    },
  },
});
export const { actions, reducer } = cacheSlice;
export const { hydrate, reset, resetWithCache, save, set, cached, caching } = actions;
export const cacheSelector = (state: RootState) => state.cache;
