import React from 'react';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
// import { indexedDBCache } from '../../../../reducers/cacheReducer';
import { Tables } from '../../../../services/databaseModels/tables';
import TabPanel from './TabPanel';
import Overall from './Overall';
import Incidents from './Incidents';
import Requests from './Requests';
import SwipeableViews from "@arzymski/react-swipeable-views";

const getCategories = (setCategories: { (value: React.SetStateAction<Tables.CategoriesOutput[]>): void; (arg0: any): any; }) => setCategories([{ id: 0, name: "Overall", description: "Overall" }, { id: 1, name: "Incidents", description: "Incidents" }, { id: 2, name: "Requests", description: "Requests" }]);

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const FullWidthTabs: React.FC = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [categories, setCategories] = React.useState<Tables.CategoriesOutput[]>([]);

    React.useEffect(() => {
        getCategories(setCategories)
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="secondary"
                    // textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {categories.map((category, idx) => <Tab key={idx} label={category.name} {...a11yProps(idx)} />)}
                </Tabs>
            </Box>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {categories.map((category, idx) => <TabPanel key={category + "_" + idx} value={idx} index={idx} dir={theme.direction}>
                    {idx === 0 && <Overall parent='Tabs' />}
                    {idx === 1 && <Incidents parent='Tabs' />}
                    {idx === 2 && <Requests parent='Tabs' />}
                </TabPanel>)}
            </SwipeableViews>
        </Box>
    );
}

export default FullWidthTabs