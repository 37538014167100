import Paper from '@mui/material/Paper';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../../hooks/useDebugInformation';
import Grid from '@mui/material/Grid';
import { state, ui } from '../../../../../../interfaces/interfaces';
import { Tables } from '../../../../../../services/databaseModels/tables';
import MemoizedDropDownMini from '../../../../../shared/Inputs/DropDownQuestionMini';
import MemoizedRadioInputMini from '../../../../../shared/Inputs/RadioQuestionMini';
import MemoizedTextFieldQuestionsMini from '../../../../../shared/Inputs/TextFieldQuestionMini';
import Typography from '@mui/material/Typography';
import { questionsOptions } from '../../../../../../assets/js/main';
import Question from '../../../../../shared/Questions';
import { SubOption, Option } from '../../../../../../utilities/basicTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { style } from '../../../../../../utilities/style';
import { CacheTypes } from '../../../../../../utilities/cache/interfaces';
import { cacheUtils } from '../../../../../..';

const NAME = "FormPanel"
const STEP = 0

type Props = {
    parent: string
}

const categoryGetOptionLabel = (option: Option) => option?.name || ""
const categoryIsOptionEqualToValue = (option: Option, value: Option) => option?.id === value?.id
const categoryOptions = (opts: CacheTypes.Categories[]) => opts && opts?.length > 0 ? opts : []

const subCategoryGetOptionLabel = (option: SubOption) => option?.name || ""
const subCategoryIsOptionEqualToValue = (option: SubOption, value: SubOption) => option?.id === value?.id
const subCategoryOptions = (opts: CacheTypes.SubCategories[], categoryId: number) => {
    return opts && opts?.length > 0 && categoryId > 0 ? (opts.filter((option) => option.categoryId === categoryId && option.ticketTypeId === 1)) : []
}

const handleCategoryChange = (evt: { target: { value: any; name: any; }; }, value: any,) => {
    ui.TicketCategory = value
    ui.TicketSubcategory = {} as SubOption
}

const handleSubcategoryChange = (evt: { target: { value: any; name: any; }; }, value: any,) => {
    if (value) {
        ui.TicketSubcategory = value
        ui.AssigneeId = value?.ownerId
        if (value?.id > 0) {
            ui.Answer = {
                // ...ui.Answer,
                [value?.id]: {
                    answer1: "",
                    answer2: "",
                    answer3: "",
                    answer4: "",
                    answer5: "",
                    answer6: "",
                    answerOther1: "",
                    answerOther2: "",
                    answerOther3: "",
                    answerOther4: "",
                    answerOther5: "",
                    answerOther6: "",
                }
            }
        }
    }
    else {
        ui.TicketSubcategory = {} as SubOption
    }
}

const shouldQuestionRender = (index: number) => {
    if (index > ui.OptionalQuestions.length) return false
    if (index === 1) return true
    const optionalQuestion = ui.OptionalQuestions?.[index - 1]
    const subCategoryId = ui.TicketSubcategory?.id
    const prevQuestionTypeId = ui.OptionalQuestions[index - 2].questionTypeId
    const dependsId = optionalQuestion?.dependsId ? optionalQuestion.dependsId : null
    const dependsValueArr = String(optionalQuestion.dependsValue).split("|")
    if (subCategoryId) {
        const answer = ui.Answer[subCategoryId][`answer${index - 1}`];
        const result = (dependsId === null || (Number(dependsId) > 0 && (dependsValueArr.includes(answer))) || ([2, 3].includes(prevQuestionTypeId)));

        return result
    }
}

const needResetAnswer = (index: number) => {
    const subCategoryId = ui.TicketSubcategory?.id
    if (subCategoryId) {
        if (!ui.Answer[subCategoryId]) ui.Answer[subCategoryId] = { answer1: "", answer2: "", answer3: "", answer4: "", answer5: "", answer6: "", answerOther1: "", answerOther2: "", answerOther3: "", answerOther4: "", answerOther5: "", answerOther6: "" }
        const answer = ui.Answer[subCategoryId][`answer${index}`];
        const answerOther = ui.Answer[subCategoryId][`answerOther${index}`];
        const resetAnswers: { [key: string]: string } = {}
        if (answer !== "" || answerOther !== "") {
            for (let i = index; i < 7; i++) {
                resetAnswers[`answer${i}`] = ""
                resetAnswers[`answerOther${i}`] = ""
            }
            ui.Answer = {
                [subCategoryId]: { ...ui.Answer[subCategoryId], ...resetAnswers }
            }
        }
    }
}

type StandardTicketProps = {
    optionalQuestions: Tables.QuestionsOutput[]
    ticketSubcategoryId: number
    parent: string
    hasQuestions?: boolean[]
}

export const StandardTicket: React.FC<StandardTicketProps> = ({ optionalQuestions, ticketSubcategoryId, parent, hasQuestions }) => {
    if (hasQuestions && hasQuestions.length > 0) return (<>
        {hasQuestions[0] && <Typography variant='subtitle1' marginBottom={-2}>Screening Questions</Typography>}
        <Grid container margin={0} gap={1} width={"100%"} >
            {/* Screening Questions: */}
            {hasQuestions[0] && <Question question={optionalQuestions[0]} index={1} id={ticketSubcategoryId} />}
            {hasQuestions[1] && <Question question={optionalQuestions[1]} index={2} id={ticketSubcategoryId} />}
            {hasQuestions[2] && <Question question={optionalQuestions[2]} index={3} id={ticketSubcategoryId} />}
        </Grid>
        <Grid container margin={0} gap={1} width={"100%"} >
            {/* Screening Questions: */}
            {hasQuestions[3] && <Question question={optionalQuestions[3]} index={4} id={ticketSubcategoryId} />}
            {hasQuestions[4] && <Question question={optionalQuestions[4]} index={5} id={ticketSubcategoryId} />}
            {hasQuestions[5] && <Question question={optionalQuestions[5]} index={6} id={ticketSubcategoryId} />}
        </Grid>
    </>)
    return null
}

const areInputsValid = () => {
    if (ui.TicketSubcategory && ui.TicketSubcategory.ticketTypeId > 0) {
        const inputs = []
        inputs.push((ui.TicketSubcategory?.id > 0))
        if (ui.TicketSubcategory.ticketTypeId === 1) {
            if (ui.OptionalQuestions.length > 0) {
                if (ui.HasQuestion1) inputs.push(((ui.Answer[ui.TicketSubcategory.id].answer1 !== "" && ui.Answer[ui.TicketSubcategory.id].answer1 !== null) || (ui.Answer[ui.TicketSubcategory.id].answerOther1 !== "" && ui.Answer[ui.TicketSubcategory.id].answerOther1 !== null)))
                if (ui.HasQuestion2) inputs.push(((ui.Answer[ui.TicketSubcategory.id].answer2 !== "" && ui.Answer[ui.TicketSubcategory.id].answer2 !== null) || (ui.Answer[ui.TicketSubcategory.id].answerOther2 !== "" && ui.Answer[ui.TicketSubcategory.id].answerOther2 !== null)))
                if (ui.HasQuestion3) inputs.push(((ui.Answer[ui.TicketSubcategory.id].answer3 !== "" && ui.Answer[ui.TicketSubcategory.id].answer3 !== null) || (ui.Answer[ui.TicketSubcategory.id].answerOther3 !== "" && ui.Answer[ui.TicketSubcategory.id].answerOther3 !== null)))
                if (ui.HasQuestion4) inputs.push(((ui.Answer[ui.TicketSubcategory.id].answer4 !== "" && ui.Answer[ui.TicketSubcategory.id].answer4 !== null) || (ui.Answer[ui.TicketSubcategory.id].answerOther4 !== "" && ui.Answer[ui.TicketSubcategory.id].answerOther4 !== null)))
                if (ui.HasQuestion5) inputs.push(((ui.Answer[ui.TicketSubcategory.id].answer5 !== "" && ui.Answer[ui.TicketSubcategory.id].answer5 !== null) || (ui.Answer[ui.TicketSubcategory.id].answerOther5 !== "" && ui.Answer[ui.TicketSubcategory.id].answerOther5 !== null)))
                if (ui.HasQuestion6) inputs.push(((ui.Answer[ui.TicketSubcategory.id].answer6 !== "" && ui.Answer[ui.TicketSubcategory.id].answer6 !== null) || (ui.Answer[ui.TicketSubcategory.id].answerOther6 !== "" && ui.Answer[ui.TicketSubcategory.id].answerOther6 !== null)))
            }
            inputs.push((ui.TicketDate !== ""))
            inputs.push((ui.TicketTime !== ""))
        }
        state[`Step${STEP}Valid`] = inputs.every(x => x === true)
        return
    }
    state[`Step${STEP}Valid`] = false
    return
}

const CategoryPanel: React.FC<Props> = ({ parent }) => {
    const debugName = `${parent}▷[${NAME}]`
    const ticketCategory = useAppSelector(({ ui }) => ui["TicketCategory"])
    const ticketSubcategory = useAppSelector(({ ui }) => ui["TicketSubcategory"])

    const hasQuestion1 = useAppSelector(({ ui }) => ui["HasQuestion1"])
    const hasQuestion2 = useAppSelector(({ ui }) => ui["HasQuestion2"])
    const hasQuestion3 = useAppSelector(({ ui }) => ui["HasQuestion3"])
    const hasQuestion4 = useAppSelector(({ ui }) => ui["HasQuestion4"])
    const hasQuestion5 = useAppSelector(({ ui }) => ui["HasQuestion5"])
    const hasQuestion6 = useAppSelector(({ ui }) => ui["HasQuestion6"])
    const ticketDate = useAppSelector(({ ui }) => ui["TicketDate"])
    const ticketTime = useAppSelector(({ ui }) => ui["TicketTime"])

    const options = cacheUtils.getCache<CacheTypes.TicketCategories>("TicketCategories");
    const subOptions = cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories").filter(x => x.enabled)
    const questions = cacheUtils.getCache<CacheTypes.Questions>("Questions");

    const optionalQuestions = useAppSelector(({ ui }) => ui["OptionalQuestions"])
    const answer = useAppSelector(({ ui }) => ui["Answer"])

    React.useEffect(() => {
        if (questions.length > 0 && ticketSubcategory && ticketSubcategory?.id > 0) {
            ui.OptionalQuestions = questionsOptions(questions, ticketSubcategory.id);
        }
        else {
            if (ui.HasQuestion1) ui.HasQuestion1 = false
            if (ui.HasQuestion2) ui.HasQuestion2 = false
            if (ui.HasQuestion3) ui.HasQuestion3 = false
            if (ui.HasQuestion4) ui.HasQuestion4 = false
            if (ui.HasQuestion5) ui.HasQuestion5 = false
            if (ui.HasQuestion6) ui.HasQuestion6 = false
        }
        areInputsValid()
    }, [ticketSubcategory, questions])

    React.useEffect(() => { if (!hasQuestion2) { needResetAnswer(2) } }, [hasQuestion2])
    React.useEffect(() => { if (!hasQuestion3) { needResetAnswer(3) } }, [hasQuestion3])
    React.useEffect(() => { if (!hasQuestion4) { needResetAnswer(4) } }, [hasQuestion4])
    React.useEffect(() => { if (!hasQuestion5) { needResetAnswer(5) } }, [hasQuestion5])
    React.useEffect(() => { if (!hasQuestion6) { needResetAnswer(6) } }, [hasQuestion6])

    React.useEffect(() => {
        if (optionalQuestions && optionalQuestions.length > 0) {
            ui.HasQuestion1 = shouldQuestionRender(1) ? true : false
            ui.HasQuestion2 = shouldQuestionRender(2) ? true : false
            ui.HasQuestion3 = shouldQuestionRender(3) ? true : false
            ui.HasQuestion4 = shouldQuestionRender(4) ? true : false
            ui.HasQuestion5 = shouldQuestionRender(5) ? true : false
            ui.HasQuestion6 = shouldQuestionRender(6) ? true : false
        }
        areInputsValid()
    }, [answer, optionalQuestions])

    React.useEffect(() => {

        areInputsValid()
    }
        , [ticketDate, ticketTime])

    const memoizedHasQuestions = React.useMemo(() => [hasQuestion1, hasQuestion2, hasQuestion3, hasQuestion4, hasQuestion5, hasQuestion6], [hasQuestion1, hasQuestion2, hasQuestion3, hasQuestion4, hasQuestion5, hasQuestion6])

    useDebugInformation(debugName, { memoizedHasQuestions, ticketCategory, ticketSubcategory, optionalQuestions, answer })
    if (ticketSubcategory && ticketSubcategory?.id > 0)
        return (
            <Box id="form-panel" component="form"
                noValidate
                autoComplete="off" >
                <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperParentCol}>
                    <Grid container spacing={2} margin={0} gap={1} width={"100%"}>
                        <Paper variant="elevation" sx={(theme) => style(theme).paperItem()} elevation={4}>
                            <MemoizedDropDownMini options={categoryOptions(options ? options : [])} name='Ticket Category' handleChange={handleCategoryChange} value={ticketCategory} isOptionEqualToValue={categoryIsOptionEqualToValue} getOptionLabel={categoryGetOptionLabel} xs={12} sm={12} />
                        </Paper>
                        <Paper variant="elevation" sx={(theme) => style(theme).paperItem({ maxWidth: "50%" })} elevation={4}>
                            <MemoizedDropDownMini options={subCategoryOptions(subOptions ? subOptions : [], ticketCategory?.id ? ticketCategory.id : -1)} xs={12} sm={12} isOptionEqualToValue={subCategoryIsOptionEqualToValue} getOptionLabel={subCategoryGetOptionLabel} disabled={ticketCategory?.id ? false : true} name='Ticket Subcategory' value={ticketSubcategory} handleChange={handleSubcategoryChange} />
                        </Paper>
                    </Grid>
                    <Grid container spacing={2} margin={0} gap={1} width={"100%"}>
                        <Paper variant="elevation" sx={(theme) => style(theme).paperItem({ width: "25%" })} elevation={4} >
                            <Grid container spacing={2}>
                                <MemoizedTextFieldQuestionsMini inputName="TicketDate" parent={debugName} xs={6} sm={6} type="date" />
                                <MemoizedTextFieldQuestionsMini inputName="TicketTime" parent={debugName} xs={6} sm={6} type="time" step={60 * 15} />
                            </Grid>
                        </Paper>
                        <Paper variant="elevation" sx={(theme) => style(theme).paperItem({ width: "25%" })} elevation={4} >
                            <MemoizedRadioInputMini inputName="TicketLocation" parent={debugName} xs={12} sm={12} />
                        </Paper>
                    </Grid>
                    <StandardTicket optionalQuestions={optionalQuestions} parent={debugName} ticketSubcategoryId={ticketSubcategory.id} hasQuestions={memoizedHasQuestions} />
                </Paper >
            </Box >
        )
    return (
        <Box id="form-panel" component="form"
            noValidate
            autoComplete="off" >
            <Paper variant="elevation" sx={(theme) => style(theme).paperParentCol} elevation={4}>
                <Grid container spacing={2} margin={0} gap={1} width={"100%"}>
                    <Paper variant="elevation" sx={(theme) => style(theme).paperItem()} elevation={4}>
                        <MemoizedDropDownMini options={categoryOptions(options ? options : [])} name='Ticket Category' handleChange={handleCategoryChange} value={ticketCategory} isOptionEqualToValue={categoryIsOptionEqualToValue} getOptionLabel={categoryGetOptionLabel} xs={12} sm={12} />
                    </Paper>
                    <Paper variant="elevation" sx={(theme) => style(theme).paperItem({ maxWidth: "50%" })} elevation={4} >
                        <MemoizedDropDownMini options={subCategoryOptions(subOptions ? subOptions : [], ticketCategory?.id ? ticketCategory.id : -1)} xs={12} sm={12} isOptionEqualToValue={subCategoryIsOptionEqualToValue} getOptionLabel={subCategoryGetOptionLabel} disabled={ticketCategory?.id ? false : true} name='Ticket Subcategory' value={ticketSubcategory} handleChange={handleSubcategoryChange} />
                    </Paper>
                </Grid>
                <Grid container spacing={2} margin={0} gap={1} width={"100%"}>
                    <Paper variant="elevation" sx={(theme) => style(theme).paperItem({ width: "25%" })} elevation={4} >
                        <Grid container spacing={2}>
                            <MemoizedTextFieldQuestionsMini inputName="TicketDate" parent={debugName} xs={6} sm={6} type="date" />
                            <MemoizedTextFieldQuestionsMini inputName="TicketTime" parent={debugName} xs={6} sm={6} type="time" step={60 * 15} />
                        </Grid>
                    </Paper>
                    <Paper variant="elevation" sx={(theme) => style(theme).paperItem({ width: "25%" })} elevation={4} >
                        <MemoizedRadioInputMini inputName="TicketLocation" parent={debugName} xs={12} sm={12} />
                    </Paper>
                </Grid>
            </Paper>
        </Box>
    )
}
export default React.memo(CategoryPanel)