import React from "react";
import { Route } from "react-router-dom";
import { Logout } from "../Layout/NavMenu/Logout";
import { ApplicationPaths, LoginActions, LogoutActions } from "./ApiAuthorizationConstants";
import { Login } from "./Login";

const loginAction = (name: RouteLoginProps) => <Login action={name}></Login>;

const logoutAction = (name: RouteLogoutProps) => <Logout action={name}></Logout>;

type RouteLoginProps =
  | 'login'
  | 'login-callback'
  | 'login-failed'
  | 'profile'
  | 'register'

type RouteLogoutProps =
  | 'logout-callback'
  | 'logout'
  | 'logged-out'

export const ApiAuthorizationRoutes = () => {
  console.log("ApiAuthorizationRoutes.js", window.location.pathname);
  return (
    <>
      <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login as RouteLoginProps)} />
      <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed as RouteLoginProps)} />
      <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback as RouteLoginProps)} />
      <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile as RouteLoginProps)} />
      <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register as RouteLoginProps)} />
      <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout as RouteLogoutProps)} />
      <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback as RouteLogoutProps)} />
      <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut as RouteLogoutProps)} />
    </>
  );
};


