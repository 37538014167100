import { cacheUtils } from "../../..";
import { api, state, ui } from "../../../interfaces/interfaces";
import { APIObject } from "../../../reducers/actions/apiActions/interface";
import { Tables } from "../../../services/databaseModels/tables";
import { logger } from "../../../services/logger";
import StorageUtils from "../../../services/storage";
import { TemplateSTATEObject } from "../../../services/storage/storeTemplate/State";
import { TemplateUIObject } from "../../../services/storage/storeTemplate/UI";
import { CacheTypes } from "../../../utilities/cache/interfaces";
import questionsManager from "../questions";

const namespace = "MAIN SCRIPT";

export const camelToWords = (camelWord: string) => camelWord.replace(/([a-z])([A-Z])/g, "$1 $2");
export const wordsToCamel = (word: string) => {
  const words = word.split(" ");
  words[0] = words[0].toLowerCase();
  return words.join("");
};

// setter
export const saveToStorage = (store: string, data: any): void => {
  logger.debug(namespace, "Saving to Local Storage -" + store);
  localStorage.setItem(store, JSON.stringify(data));
};

export const saveToSessionStorage = (store: string, data: any): void => {
  logger.debug(namespace, "Saving to Session Storage -" + store);
  sessionStorage.setItem(store, JSON.stringify(data));
};
// getter
export const loadFromStorage = (store: string): Object | null => {
  logger.debug(namespace, "Load from Local Storage -" + store);
  const data = localStorage.getItem(store);
  if (data !== undefined && data) return JSON.parse(data);
  return null;
};
export const loadFromSessionStorage = (store: string): Object | null => {
  logger.debug(namespace, "Load from Session Storage -" + store);
  const data = sessionStorage.getItem(store);
  if (data) return JSON.parse(data);
  return null;
};

// remove
export const removeFromStorage = (store: string): void => localStorage.removeItem(store);

// remove all
export const clearStorage = (): void => localStorage.clear();
export const clearSessionStorage = (): void => sessionStorage.clear();
export const clearIndexedDBStorage = async () => StorageUtils.clearAllIndexedDBStoresInDB("CACHE");
export const clearUIStorage = (): void => {
  for (const item in localStorage) {
    if (item.startsWith("ui-")) localStorage.removeItem(item);
  }
};
export const clearCacheStorage = (): void => {
  for (const item in localStorage) {
    if (item.startsWith("cache-")) localStorage.removeItem(item);
  }
};
export const clearStateStorage = (): void => {
  for (const item in localStorage) {
    if (item.startsWith("state-")) localStorage.removeItem(item);
  }
};

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const b64toBlob = (base64: string, type: string) =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

export const downloadFile = async (base64: string, type: string, name: string) => {
  const a = document.createElement("a");
  a.download = name;
  a.href = URL.createObjectURL(await b64toBlob(base64, type));
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};

export const dynamicFileSize = (fSize: number) => {
  if (fSize < 1024) return (fSize / 1024).toFixed(2) + " kB";
  if (fSize > 1024 && fSize < 1024 * 1024) return (fSize / 1024).toFixed(2) + " kB";
  if (fSize > 1024 * 1024) return (fSize / 1024 / 1024).toFixed(2) + " MB";
};

export const insertTicket = async (
  immediateId: number,
  longTermId: number,
  progressId: number,
  ticketDetailsId: number,
  reporteeId: number,
  locationId: number,
  categoryId: number,
  assigneeId: number,
  subCategoryId: number,
  updatedById: number,
  ticketDate: Date | string,
  ticketTime: string
) => {
  return (
    await api.fetchTicketsInsert({
      reporteeId,
      assigneeId,
      priorityId: 1,
      statusId: 1,
      ticketDatetime: new Date(Date.parse(`${ticketDate} ${ticketTime}`)),
      locationId,
      categoryId,
      subCategoryId,
      updatedById,
      immediateId,
      longTermId,
      progressId,
      ticketDetailsId,
    })
  )?.id;
};

export const insertRequest = async (
  progressId: number,
  ticketDetailsId: number,
  reporteeId: number,
  categoryId: number,
  assigneeId: number,
  subCategoryId: number,
  updatedById: number
) => {
  return (
    await api.fetchRequestsInsert({
      reporteeId,
      assigneeId,
      priorityId: 1,
      statusId: 1,
      categoryId,
      subCategoryId,
      updatedById,
      progressId,
      ticketDetailsId,
    })
  )?.id;
};

export const generateLongTermActionId = async () => (await api.fetchLongTermActionsInsert({ value: "" }))?.id;
export const generateProgressLogId = async () => (await api.fetchProgressLogsInsert({ value: "" }))?.id;
export const generateImmediateActionId = async () => (await api.fetchImmediateActionsInsert({ value: "" }))?.id;
export const generateTicketDetailId = async (incidentDetails: string) =>
  (await api.fetchTicketDetailsInsert({ value: incidentDetails }))?.id;
export const insertAnswers = async (
  ticketId: number,
  answers: { value: string; questionId: number; order: number }[]
) => {
  const promiseArray = [];
  for (let index = 0; index < answers.length; index++) {
    const answer = answers[index];
    promiseArray.push(
      api.fetchAnswersInsert({
        ticketId,
        questionId: answer.questionId,
        value: answer.value,
        order: answer.order,
      })
    );
  }
  return Promise.all(promiseArray);
};

export const insertAttachments = async (
  ticketId: number,
  attachments: { fileName: string; fileType: string; data: string; dateAdded: Date; fileDate: Date; fileSize: number }[]
) => {
  // async upload multiple files
  const promiseArray: Promise<Tables.AttachmentsOutput>[] = [];

  attachments.forEach((attachment) => {
    promiseArray.push(
      api.fetchAttachmentsInsert({
        ticketId,
        fileName: attachment.fileName,
        fileType: attachment.fileType,
        data: attachment.data,
        dateAdded: attachment.dateAdded,
        fileDate: attachment.fileDate,
        fileSize: attachment.fileSize,
      })
    );
  });
  return Promise.all(promiseArray);
};

export const getLoggedUserId = async (loggedUser: { profile: { last_name: string; first_name: string } }) => {
  const users = cacheUtils.getCache<CacheTypes.Users>("Users");
  if (users && users.length > 0) {
    for (let index = 0; index < users.length; index++) {
      const user = users[index];
      if (user.firstName === loggedUser.profile.first_name && user.lastName === loggedUser.profile.last_name) {
        return user.id;
      }
    }
  }
  return -1;
};

export const compileFiles = async () => {
  const files = cacheUtils.getCache<CacheTypes.Files>("Files");
  const tempFiles = [];
  if (files && files.length > 0) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      tempFiles.push({
        fileName: file.name,
        data: file?.data,
        dateAdded: new Date(),
        fileDate: new Date(file.lastModified),
        fileType: file.type,
        fileSize: file.size,
      });
    }
  }
  return tempFiles;
};

const isObject = (value: unknown) => {
  return !!(value && typeof value === "object" && !Array.isArray(value));
};

export const convertData = (data: any) => {
  let newObj = {} as any;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      let element = data[key];
      if (key === "$id" || key === "$ref" || element === null || element === undefined) continue;
      if (isObject(element)) newObj[key] = convertData(element);
      else if (key === "$values") newObj = Object.values(convertData(element));
      else if (Array.isArray(element)) {
        const newArr = [];
        for (let c of element) {
          newArr.push(convertData(c));
        }
        newObj[key] = newArr;
      } else newObj[key] = element;
    }
  }
  return newObj;
};

export const compileAnswers = (setAnswers: {
  (value: React.SetStateAction<{ value: string; question: string; order: number }[]>): void;
  (arg0: { value: string; question: string; order: number }[]): void;
}) => {
  const questions = cacheUtils.getCache<CacheTypes.Questions>("Questions");
  const compiledAnswers = [];
  const subCategoryId = ui.TicketSubcategory?.id;
  if (
    (subCategoryId &&
      ui.ActiveStep === 1 &&
      (ui.TicketSubcategory?.ticketTypeId === 2 || ui.TicketSubcategory?.ticketTypeId === 3)) ||
    ui.ActiveStep === 2 ||
    (ui.TicketId > 0 && ui.ActiveStep === 0)
  ) {
    console.log("compiling answers", questions, ui.OptionalQuestions);
    if (ui.OptionalQuestions.length > 0) {
      if (ui.HasQuestion1 && subCategoryId)
        compiledAnswers.push({
          value:
            ui.Answer[subCategoryId][`answer1`] === "Other"
              ? ui.Answer[subCategoryId][`answerOther1`]
              : ui.Answer[subCategoryId][`answer1`],
          question: questions.find((question) => question.id === ui.OptionalQuestions[0].id)?.question ?? "",
          order: ui.OptionalQuestions[0].order,
        });
      if (ui.HasQuestion2 && subCategoryId)
        compiledAnswers.push({
          value:
            ui.Answer[subCategoryId][`answer2`] === "Other"
              ? ui.Answer[subCategoryId][`answerOther2`]
              : ui.Answer[subCategoryId][`answer2`],
          question: questions.find((question) => question.id === ui.OptionalQuestions[1].id)?.question ?? "",
          order: ui.OptionalQuestions[1].order,
        });
      if (ui.HasQuestion3 && subCategoryId)
        compiledAnswers.push({
          value:
            ui.Answer[subCategoryId][`answer3`] === "Other"
              ? ui.Answer[subCategoryId][`answerOther3`]
              : ui.Answer[subCategoryId][`answer3`],
          question: questions.find((question) => question.id === ui.OptionalQuestions[2].id)?.question ?? "",
          order: ui.OptionalQuestions[2].order,
        });
      if (ui.HasQuestion4 && subCategoryId)
        compiledAnswers.push({
          value:
            ui.Answer[subCategoryId][`answer4`] === "Other"
              ? ui.Answer[subCategoryId][`answerOther4`]
              : ui.Answer[subCategoryId][`answer4`],
          question: questions.find((question) => question.id === ui.OptionalQuestions[3].id)?.question ?? "",
          order: ui.OptionalQuestions[3].order,
        });
      if (ui.HasQuestion5 && subCategoryId)
        compiledAnswers.push({
          value:
            ui.Answer[subCategoryId][`answer5`] === "Other"
              ? ui.Answer[subCategoryId][`answerOther5`]
              : ui.Answer[subCategoryId][`answer5`],
          question: questions.find((question) => question.id === ui.OptionalQuestions[4].id)?.question ?? "",
          order: ui.OptionalQuestions[4].order,
        });
      if (ui.HasQuestion6 && subCategoryId)
        compiledAnswers.push({
          value:
            ui.Answer[subCategoryId][`answer6`] === "Other"
              ? ui.Answer[subCategoryId][`answerOther6`]
              : ui.Answer[subCategoryId][`answer6`],
          question: questions.find((question) => question.id === ui.OptionalQuestions[5].id)?.question ?? "",
          order: ui.OptionalQuestions[5].order,
        });
    }
  }
  setAnswers(compiledAnswers);
};

export const questionsOptions = (opts: Tables.QuestionsOutput[], subCategoryId: number) =>
  opts.filter((option) => option.subCategoryId === subCategoryId);

export const clearAnswers = async (ui: TemplateUIObject, state: TemplateSTATEObject) => {
  ui.HasQuestion1 = false;
  ui.HasQuestion2 = false;
  ui.HasQuestion3 = false;
  ui.HasQuestion4 = false;
  ui.HasQuestion5 = false;
  ui.HasQuestion6 = false;
  ui.TicketDate = "";
  ui.TicketTime = "";
  ui.TicketSubcategory = null as any;
  ui.TicketCategory = null as any;
  ui.TicketLocation = { id: 0, name: "", description: "" };
  ui.Files = undefined;
  ui.OtherCategory = "";
  ui.TicketDetails = "";
  ui.RequestDetails = "";
  ui.TicketTypeId = 0;
  ui.AssigneeId = -1;
  state.Reportee = {
    firstName: "",
    id: 0,
    lastName: "",
    eMail: "",
    initials: "",
    roleId: 0,
    identityId: "",
  };
  state.Assignee = {
    firstName: "",
    id: 0,
    lastName: "",
    eMail: "",
    initials: "",
    roleId: 0,
    identityId: "",
  };
  ui.UpdatedBy = {
    id: 0,
    firstName: "",
    lastName: "",
    eMail: "",
    initials: "",
    roleId: 0,
  };
  ui.AssignedTo = {
    id: 0,
    firstName: "",
    lastName: "",
    eMail: "",
    initials: "",
    roleId: 0,
  };
  ui.ImmediateAction = "";
  ui.LongTermAction = "";
  ui.TicketProgress = "";
  ui.DateReported = "";
  ui.TicketDate = "";
  ui.TicketTime = "";
  ui.TicketPriority = { name: "Unset", description: "", id: 1 };
  ui.TicketPriorities = [];
  ui.TicketStatuses = [];
  ui.TicketStatus = { name: "Open", description: "", id: 1 };
  ui.DateCreated = "";
  ui.DateUpdated = "";
  state.ActiveTicket = null;
  ui.RequestProgressChanged = false;
  ui.RequestPriorityChanged = false;
  ui.RequestStatusChanged = false;
  ui.RequestChanged = false;
  ui.OptionalQuestions = [];
  ui.Answer = {
    0: {
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      answer6: "",
      answerOther1: "",
      answerOther2: "",
      answerOther3: "",
      answerOther4: "",
      answerOther5: "",
      answerOther6: "",
    },
  };
  cacheUtils.setCache("Files", []);
  await StorageUtils.replaceValuesInStoreInIndexedDB({ database: "CACHE", tableName: "Files", values: [] });
};

export const setMsg = (msg: string, variant: "success" | "error" | "warning" | "info") => {
  ui.ProblemMsgVariant = variant;
  ui.ProblemMsg = msg;
  ui.ProblemShowMsg = true;
};

export const updateState = async () => {
  const progressId = state.ActiveTicket?.progressId || null;
  const longTermId = state.ActiveTicket?.longTermId || null;
  const immediateId = state.ActiveTicket?.immediateId || null;
  const ticketTypeId = state.ActiveTicket?.subCategory.ticketTypeId || null;
  const assigneeId = state.ActiveTicket?.assigneeId || undefined;
  const id = state.ActiveTicket?.id || null;
  const fetchPromises = [];
  if (progressId && ticketTypeId) {
    const data: Tables.ProgressLogsInput = {
      value: ui.TicketProgress,
      dateUpdated: new Date(),
    };
    fetchPromises.push(api.fetchProgressLogsPatch(progressId, data));
  }
  if (longTermId && ticketTypeId === 1) {
    const data: Tables.LongTermActionsInput = {
      value: ui.LongTermAction,
      dateUpdated: new Date(),
    };
    fetchPromises.push(api.fetchLongTermActionsPatch(longTermId, data));
  }
  if (immediateId && ticketTypeId === 1) {
    const data: Tables.ImmediateActionsInput = {
      value: ui.ImmediateAction,
      dateUpdated: new Date(),
    };
    fetchPromises.push(api.fetchImmediateActionsPatch(immediateId, data));
  }
  if (id && ticketTypeId) {
    const updatee = cacheUtils
      .getCache<CacheTypes.Users>("Users")
      ?.find((u) => u.eMail === state.LoggedUser?.profile?.email);
    const data: Tables.TicketsPatchInput = {
      statusId: ui.TicketStatus.id,
      priorityId: ui.TicketPriority.id,
      updatedById: updatee?.id,
      assigneeId,
      dateUpdated: new Date(),
    };
    fetchPromises.push(api.fetchTicketsPatch(id, data));
  }
  const responses = await Promise.all(fetchPromises);
  if (responses.length > 0 && responses.every((r) => r)) {
    logger.info(`${namespace}::updateState`, `Successfully updated ticket`);
    setMsg(`Successfully updated ticket`, "success");
  } else {
    logger.error(`${namespace}::updateState`, `Failed to update ticket`);
    setMsg(`Failed to update ticket`, "error");
  }
  ui.RequestChanged = false;
  state.RequestUpdateActive = false;
  const ticketId = state.ActiveTicket?.id || null;
  if (ticketId && ticketTypeId) {
    const fetchMethod: APIObject["fetchTicketsWithFK"] | APIObject["fetchRequestsWithFK"] =
      ticketTypeId === 1 ? api.fetchTicketsWithFK : api.fetchRequestsWithFK;
    const res = await fetchMethod(ticketId);
    if (res) {
      state.ActiveTicket = { ...res, attachments: [] };
      ui.DateUpdated = state.ActiveTicket?.dateUpdated || "";
      ui.UpdatedBy = state.ActiveTicket?.updatedBy || {
        id: 0,
        firstName: "",
        lastName: "",
        eMail: "",
        initials: "",
        roleId: 0,
      };
      ui.AssignedTo = state.ActiveTicket?.assignee || {
        id: 0,
        firstName: "",
        lastName: "",
        eMail: "",
        initials: "",
        roleId: 0,
      };
      logger.info(`${namespace}::updateState`, `Successfully updated ticket update date`);
      setMsg(`Successfully updated ticket update date`, "success");
    }
  }
};

export const hasTicketStateChanged = () => {
  const ticketTypeId = state.ActiveTicket?.subCategory.ticketTypeId || null;
  if (ticketTypeId) {
    let stateHasChanged: string | boolean | null = false;
    const statusHasValue = state.ActiveTicket?.statusId || null;
    const statusControlState = ui.TicketStatus?.id || null;
    const priorityHasValue = state.ActiveTicket?.priorityId || null;
    const priorityControlState = ui.TicketPriority?.id || null;
    const progressHasValue = state.ActiveTicket?.progress?.value || null;
    const progressControlState = ui.TicketProgress || null;

    const statusHasChanged = statusHasValue && statusControlState && statusControlState !== statusHasValue;
    const priorityHasChanged = priorityHasValue && priorityControlState && priorityControlState !== priorityHasValue;
    const progressHasChanged = progressControlState !== progressHasValue;

    // IF REQUEST
    if (ticketTypeId === 2 || ticketTypeId === 3) {
      stateHasChanged = statusHasChanged || priorityHasChanged || progressHasChanged;
    }

    // IF INCIDENT WE NEED ADDITIONAL CHECKS FOR IMMEDIATE AND LONG TERM ACTIONS
    if (ticketTypeId === 1) {
      const immediateHasValue = state.ActiveTicket?.immediate?.value || null;
      const immediateControlState = ui.ImmediateAction || null;
      const longTermHasValue = state.ActiveTicket?.longTerm?.value || null;
      const longTermControlState = ui.LongTermAction || null;

      const immediateHasChanged = immediateControlState !== immediateHasValue;
      const longTermHasChanged = longTermControlState !== longTermHasValue;
      stateHasChanged =
        statusHasChanged || priorityHasChanged || progressHasChanged || immediateHasChanged || longTermHasChanged;
    }

    // SHOW SNACKBAR MESSAGE IF CHANGES ARE DETECTED
    stateHasChanged && !ui.RequestChanged && setMsg(`Tickets state have changed. Please press update.`, "warning");
    logger.info(`${namespace}::hasTicketStateChanged`, `Ticket state has changed`);

    // SET STATE OF REQUEST CHANGE
    stateHasChanged && !ui.RequestChanged && (ui.RequestChanged = true);
    !stateHasChanged && ui.RequestChanged && (ui.RequestChanged = false);
  }
};

export const sortTable = (data: any[][], column: number, reversed: boolean) => {
  // if data match date in format 00/00/0000 then sort by date reversed or not
  if (String(data[0][column]).match(/^\d{2}\/\d{2}\/\d{4}$/))
    return reversed
      ? data.sort((a, b) => new Date(a[column]).getDate() - new Date(b[column]).getDate()).reverse()
      : data.sort((a, b) => new Date(a[column]).getDate() - new Date(b[column]).getDate());
  // just sort
  return reversed
    ? data.sort((a, b) => a[column] - b[column]).reverse()
    : data.sort((a, b) => compare(a[column], b[column]));
};

const compare = (aVal: any, bVal: any) => {
  if (!isNaN(aVal)) return aVal - bVal;
  if (aVal < bVal) {
    return -1;
  }
  if (aVal > bVal) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const sendEmail = async (
  toUserName: string,
  toEmail: string,
  subject: string,
  message: string,
  fromUserName?: string,
  fromEmail?: string
) => {
  return api.fetchEmailSend({
    fromUserName: fromUserName || "Rightio IT Support",
    fromEmail: fromEmail || "itsupport.rightio@adamr.space",
    toUserName,
    toEmail: isDev() && state.LoggedUser?.profile?.email ? state.LoggedUser.profile.email : toEmail,
    subject,
    message,
  });
};

export const seedData = async (ui: TemplateUIObject) => {
  const response = await Promise.all([questionsManager.insertSubCategories(), questionsManager.insertQuestions()]);
  if (response && response.length > 0 && response.every((r) => r === true)) {
    logger.info(`${namespace}::seedData`, `Successfully seeded data`);
    setMsg(`Successfully seeded data`, "success");
  } else {
    logger.error(`${namespace}::seedData`, `Failed to seed data`);
    setMsg(`Failed to seed data`, "error");
  }
};

export const isDev = (): boolean => !process.env.NODE_ENV || process.env.NODE_ENV === "development";
