import React from "react";
import authService from "../../../api-authorization/AuthorizeService";
import subscriptions from "../../../../services/subscriptions";
import AnonymousView from "./AnonymousView";
import AuthenticatedView from "./AuthenticatedView";
import useDebugInformation from './../../../../hooks/useDebugInformation';
import { state } from "../../../../interfaces/interfaces";
import { useAppSelector } from "../../../../store/hooks";
import { ExtUser } from "../../../api-authorization/AuthorizeServiceInterface";

const name = "LoginMenu";

const subscribe = () => {
  subscriptions.subscribe('IsAuthenticated', "LoginMenu", (isAuthenticated: boolean) => {
    console.log(isAuthenticated, "IsAuthenticated");
    state.IsAuthenticated = isAuthenticated;
  });

  subscriptions.subscribe('User', "LoginMenu", (user: ExtUser) => {
    if (!user) { state.LoggedUser = undefined; return; }
    state.LoggedUser = user;
    subscriptions.publish('AuthService', user);
  });
}

const unsubscribe = () => {
  subscriptions.unsubscribe('IsAuthenticated', "LoginMenu");
  subscriptions.unsubscribe('User', "LoginMenu");
}

const setIsAuthenticated = (isAuthenticated: boolean) => {
  subscriptions.publish('IsAuthenticated', isAuthenticated);
}

const setUser = (user: ExtUser) => {
  subscriptions.publish('User', user);
}

const authenticate = async () => {
  await authService.isAuthenticated()
}

const LoginMenu: React.FC = () => {
  const isAuthenticated = useAppSelector(({ state }) => state.IsAuthenticated);

  React.useEffect(() => {
    subscribe();
    const subscription = authService.subscribe((isAuthenticated, user) => {
      setIsAuthenticated(isAuthenticated);
      setUser(user);
    })

    !isAuthenticated && authenticate();
    // populateAuth();
    return () => {
      authService.unsubscribe(subscription);
      unsubscribe()
    }
  }, [isAuthenticated]);

  useDebugInformation(name, { isAuthenticated });

  return (
    !isAuthenticated
      ? <AnonymousView />
      : <AuthenticatedView />
  )
}

export default React.memo(LoginMenu);