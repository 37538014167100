import { Questions, SubCategories } from ".";

export const someQuestions: Questions = [];
export const someSubCategories: SubCategories = [];
someSubCategories.push({
  name: "Total outage",
  id: 1,
  categoryId: 1,
  description: "Total outage",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Application frozen",
  id: 2,
  categoryId: 1,
  description: "Application frozen",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Random screen pops",
  id: 3,
  categoryId: 1,
  description: "Random screen pops",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Application running slow",
  id: 4,
  categoryId: 1,
  description: "Application running slow",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Other",
  id: 5,
  categoryId: 1,
  description: "Other",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Auto-allocation",
  id: 6,
  categoryId: 1,
  description: "Auto-allocation",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Text messages",
  id: 7,
  categoryId: 1,
  description: "Text messages",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Permissions",
  id: 8,
  categoryId: 1,
  description: "Permissions",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Test WBS",
  id: 9,
  categoryId: 1,
  description: "Test Web Booking System",
  ticketTypeId: 1,
  ownerId: 1,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Purchase orders",
  id: 10,
  categoryId: 1,
  description: "Purchase orders",
  ticketTypeId: 1,
  ownerId: 6,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Customer payment",
  id: 11,
  categoryId: 2,
  description: "Customer payment",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Customer data",
  id: 12,
  categoryId: 2,
  description: "Customer data",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Care Plan creation error",
  id: 13,
  categoryId: 2,
  description: "Care Plan creation error",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Customer credit",
  id: 14,
  categoryId: 2,
  description: "Customer credit",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Text messaging",
  id: 15,
  categoryId: 2,
  description: "Text messaging",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Campaign codes",
  id: 16,
  categoryId: 2,
  description: "Campaign codes",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Engineer payment",
  id: 17,
  categoryId: 3,
  description: "Engineer payment",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Customer payment",
  id: 18,
  categoryId: 3,
  description: "Customer payment",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Sage import error",
  id: 19,
  categoryId: 3,
  description: "Sage import error",
  ticketTypeId: 1,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Application running slow",
  id: 20,
  categoryId: 4,
  description: "Application running slow",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Application frozen",
  id: 21,
  categoryId: 4,
  description: "Application frozen",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "In-app messages",
  id: 22,
  categoryId: 4,
  description: "In-app messages",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Payments",
  id: 23,
  categoryId: 4,
  description: "Payments",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Imagery",
  id: 24,
  categoryId: 4,
  description: "Imagery",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Job information",
  id: 25,
  categoryId: 4,
  description: "Job information",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "CP12 appliance entry",
  id: 26,
  categoryId: 4,
  description: "CP12 appliance entry",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Consequential loss",
  id: 27,
  categoryId: 4,
  description: "Consequential loss",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Job sheets",
  id: 28,
  categoryId: 4,
  description: "Job sheets",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Purchase orders",
  id: 29,
  categoryId: 4,
  description: "Purchase orders",
  ticketTypeId: 1,
  ownerId: 7,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Missing data",
  id: 30,
  categoryId: 5,
  description: "Missing data",
  ticketTypeId: 1,
  ownerId: 4,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Report didn't run",
  id: 31,
  categoryId: 5,
  description: "Report didn't run",
  ticketTypeId: 1,
  ownerId: 4,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Report crashing",
  id: 32,
  categoryId: 5,
  description: "Report crashing",
  ticketTypeId: 1,
  ownerId: 4,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Report not loading",
  id: 33,
  categoryId: 5,
  description: "Report not loading",
  ticketTypeId: 1,
  ownerId: 4,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Incorrect data",
  id: 34,
  categoryId: 5,
  description: "Incorrect data",
  ticketTypeId: 1,
  ownerId: 4,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Report not added to portal",
  id: 35,
  categoryId: 5,
  description: "Report not added to portal",
  ticketTypeId: 1,
  ownerId: 4,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Engineer portal",
  id: 36,
  categoryId: 6,
  description: "Engineer portal",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Rightio website",
  id: 37,
  categoryId: 6,
  description: "Rightio website",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Care Club website",
  id: 38,
  categoryId: 6,
  description: "Care Club website",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Engineer rota",
  id: 39,
  categoryId: 6,
  description: "Engineer rota",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Recruitment database",
  id: 40,
  categoryId: 6,
  description: "Recruitment database",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Care Club Hub",
  id: 41,
  categoryId: 6,
  description: "Care Club Hub",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Azure",
  id: 42,
  categoryId: 6,
  description: "Azure",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Telephony recordings portal",
  id: 43,
  categoryId: 6,
  description: "Telephony recordings portal",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Force 24 Sync",
  id: 44,
  categoryId: 6,
  description: "Force 24 Sync",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Emails sent from database",
  id: 45,
  categoryId: 6,
  description: "Emails sent from database",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Ad console error",
  id: 46,
  categoryId: 6,
  description: "Ad console error",
  ticketTypeId: 1,
  ownerId: 5,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Unable to log in",
  id: 47,
  categoryId: 7,
  description: "Unable to log in",
  ticketTypeId: 1,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "New category - answer below",
  id: 48,
  categoryId: 9,
  description: "Please provide category name",
  ticketTypeId: 1,
  ownerId: 2,
  version: 1,
  enabled: true,
});

someSubCategories.push({
  name: "CMS",
  id: 49,
  categoryId: 2,
  description: "CMS",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Database",
  id: 50,
  categoryId: 2,
  description: "Database",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "EMS",
  id: 51,
  categoryId: 2,
  description: "EMS",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Finance",
  id: 52,
  categoryId: 2,
  description: "Finance",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Portal",
  id: 53,
  categoryId: 2,
  description: "Portal",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Sage 200",
  id: 54,
  categoryId: 2,
  description: "Sage 200",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Website",
  id: 55,
  categoryId: 2,
  description: "Website",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "New starters",
  id: 56,
  categoryId: 2,
  description: "New starters",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Password reset",
  id: 57,
  categoryId: 2,
  description: "Password reset",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Remote connection",
  id: 58,
  categoryId: 2,
  description: "Remote connection",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "CMS",
  id: 59,
  categoryId: 8,
  description: "CMS",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Database",
  id: 60,
  categoryId: 8,
  description: "Database",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "EMS",
  id: 61,
  categoryId: 8,
  description: "EMS",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Finance",
  id: 62,
  categoryId: 8,
  description: "Finance",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Portal",
  id: 63,
  categoryId: 8,
  description: "Portal",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Sage 200",
  id: 64,
  categoryId: 8,
  description: "Sage 200",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Website",
  id: 65,
  categoryId: 8,
  description: "Website",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "New starters",
  id: 66,
  categoryId: 8,
  description: "New starters",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Password reset",
  id: 67,
  categoryId: 8,
  description: "Password reset",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Remote connection",
  id: 68,
  categoryId: 8,
  description: "Remote connection",
  ticketTypeId: 2,
  ownerId: 3,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "CMS",
  id: 69,
  categoryId: 10,
  description: "CMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Database",
  id: 70,
  categoryId: 10,
  description: "Database",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "EMS",
  id: 71,
  categoryId: 10,
  description: "EMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Finance",
  id: 72,
  categoryId: 10,
  description: "Finance",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Portal",
  id: 73,
  categoryId: 10,
  description: "Portal",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Sage 200",
  id: 74,
  categoryId: 10,
  description: "Sage 200",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Website",
  id: 75,
  categoryId: 10,
  description: "Website",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "New starters",
  id: 76,
  categoryId: 10,
  description: "New starters",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Password reset",
  id: 77,
  categoryId: 10,
  description: "Password reset",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Remote connection",
  id: 78,
  categoryId: 10,
  description: "Remote connection",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "CMS",
  id: 79,
  categoryId: 11,
  description: "CMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Database",
  id: 80,
  categoryId: 11,
  description: "Database",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "EMS",
  id: 81,
  categoryId: 11,
  description: "EMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Finance",
  id: 82,
  categoryId: 11,
  description: "Finance",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Portal",
  id: 83,
  categoryId: 11,
  description: "Portal",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Sage 200",
  id: 84,
  categoryId: 11,
  description: "Sage 200",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Website",
  id: 85,
  categoryId: 11,
  description: "Website",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "New starters",
  id: 86,
  categoryId: 11,
  description: "New starters",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Password reset",
  id: 87,
  categoryId: 11,
  description: "Password reset",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Remote connection",
  id: 88,
  categoryId: 11,
  description: "Remote connection",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "CMS",
  id: 89,
  categoryId: 12,
  description: "CMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Database",
  id: 90,
  categoryId: 12,
  description: "Database",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "EMS",
  id: 91,
  categoryId: 12,
  description: "EMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Finance",
  id: 92,
  categoryId: 12,
  description: "Finance",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Portal",
  id: 93,
  categoryId: 12,
  description: "Portal",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Sage 200",
  id: 94,
  categoryId: 12,
  description: "Sage 200",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Website",
  id: 95,
  categoryId: 12,
  description: "Website",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "New starters",
  id: 96,
  categoryId: 12,
  description: "New starters",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Password reset",
  id: 97,
  categoryId: 12,
  description: "Password reset",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Remote connection",
  id: 98,
  categoryId: 12,
  description: "Remote connection",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "CMS",
  id: 99,
  categoryId: 13,
  description: "CMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Database",
  id: 100,
  categoryId: 13,
  description: "Database",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "EMS",
  id: 101,
  categoryId: 13,
  description: "EMS",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Finance",
  id: 102,
  categoryId: 13,
  description: "Finance",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Portal",
  id: 103,
  categoryId: 13,
  description: "Portal",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Sage 200",
  id: 104,
  categoryId: 13,
  description: "Sage 200",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Website",
  id: 105,
  categoryId: 13,
  description: "Website",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "New starters",
  id: 106,
  categoryId: 13,
  description: "New starters",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Password reset",
  id: 107,
  categoryId: 13,
  description: "Password reset",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});
someSubCategories.push({
  name: "Remote connection",
  id: 108,
  categoryId: 13,
  description: "Remote connection",
  ticketTypeId: 2,
  ownerId: 2,
  version: 1,
  enabled: true,
});

someQuestions.push({
  questionTypeId: 1,
  order: 1,
  question: "Is it affecting an individual or multiple users?",
  options: "Individual|Multiple",
  subCategoryId: 1,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "Computer name of affected PC (e.g. RIGHTIOW383)|1",
  options: "",
  dependsId: 1,
  dependsValue: "Individual",
  subCategoryId: 1,
  version: 1,
});
someQuestions.push({
  questionTypeId: 1,
  order: 1,
  question: "Is it affecting an individual or multiple users?",
  options: "Individual|Multiple",
  subCategoryId: 2,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "Computer name of affected PC (e.g. RIGHTIOW383)|1",
  options: "",
  dependsId: 2,
  dependsValue: "Individual",
  subCategoryId: 2,
  version: 1,
});
someQuestions.push({
  questionTypeId: 1,
  order: 1,
  question: "Is it affecting an individual or multiple users?",
  options: "Individual|Multiple",
  subCategoryId: 3,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "Computer name of affected PC (e.g. RIGHTIOW383)|1",
  options: "",
  dependsId: 3,
  dependsValue: "Individual",
  subCategoryId: 3,
  version: 1,
});
someQuestions.push({
  questionTypeId: 1,
  order: 1,
  question: "Is it affecting an individual or multiple users?",
  options: "Individual|Multiple",
  subCategoryId: 4,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "Computer name of affected PC (e.g. RIGHTIOW383)|1",
  options: "",
  dependsId: 4,
  dependsValue: "Individual",
  subCategoryId: 4,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Please provide subcategory name",
  options: "",
  subCategoryId: 5,
  version: 1,
});
someQuestions.push({
  questionTypeId: 1,
  order: 1,
  question: "Is it affecting an individual or multiple users?",
  options: "Individual|Multiple",
  subCategoryId: 6,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "Job details (if applicable)?",
  options: "",
  dependsId: 6,
  dependsValue: "Individual",
  subCategoryId: 6,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 3,
  question: "Engineer details",
  options: "",
  dependsId: 16,
  subCategoryId: 6,
  version: 1,
});
someQuestions.push({
  questionTypeId: 3,
  order: 1,
  question: "Please provide the name or content of the text message and the related issue",
  options: "",
  subCategoryId: 7,
  version: 1,
});
someQuestions.push({ questionTypeId: 2, order: 1, question: "User Name", options: "", subCategoryId: 8, version: 1 });
someQuestions.push({ questionTypeId: 2, order: 2, question: "Department", options: "", subCategoryId: 8, version: 1 });
someQuestions.push({ questionTypeId: 2, order: 3, question: "Role", options: "", subCategoryId: 8, version: 1 });
someQuestions.push({ questionTypeId: 2, order: 1, question: "User Name", options: "", subCategoryId: 9, version: 1 });
someQuestions.push({ questionTypeId: 2, order: 2, question: "Department", options: "", subCategoryId: 9, version: 1 });
someQuestions.push({ questionTypeId: 2, order: 3, question: "Role", options: "", subCategoryId: 9, version: 1 });
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "RA number?/Job number? (if applicable)",
  options: "",
  subCategoryId: 11,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 11,
  version: 1,
});
someQuestions.push({
  questionTypeId: 3,
  order: 1,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 12,
  version: 1,
});
someQuestions.push({
  questionTypeId: 3,
  order: 1,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 13,
  version: 1,
});
someQuestions.push({
  questionTypeId: 3,
  order: 1,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 14,
  version: 1,
});
someQuestions.push({
  questionTypeId: 3,
  order: 1,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 15,
  version: 1,
});
someQuestions.push({
  questionTypeId: 3,
  order: 1,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 16,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Job number? (if applicable)",
  options: "",
  subCategoryId: 17,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "RA number?/Job number? (if applicable)",
  options: "",
  subCategoryId: 18,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 18,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "RA number?/Job number? (if applicable)",
  options: "",
  subCategoryId: 19,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "What action caused the error/issue (if applicable)?",
  options: "",
  subCategoryId: 19,
  version: 1,
});
someQuestions.push({
  questionTypeId: 1,
  order: 1,
  question: "Is it affecting an individual or multiple users?",
  options: "Individual|Multiple",
  subCategoryId: 20,
  version: 1,
});
someQuestions.push({
  questionTypeId: 1,
  order: 1,
  question: "Is it affecting an individual or multiple users?",
  options: "Individual|Multiple",
  subCategoryId: 21,
  version: 1,
});
someQuestions.push({
  questionTypeId: 6,
  order: 2,
  question: "Scope of screens affected?",
  options: "Particular Screen|Multiple Screens|EMS in General",
  dependsId: 20,
  dependsValue: "Individual|Multiple",
  subCategoryId: 21,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 3,
  question: "Please provide name of the screen affected",
  options: "",
  dependsId: 32,
  dependsValue: "Particular Screen",
  subCategoryId: 21,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Report name",
  options: "",
  subCategoryId: 30,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "What data is Missing?",
  options: "",
  subCategoryId: 30,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Report name",
  options: "",
  subCategoryId: 31,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Report name",
  options: "",
  subCategoryId: 32,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Report name",
  options: "",
  subCategoryId: 33,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Report name",
  options: "",
  subCategoryId: 34,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "What data is incorrect?",
  options: "",
  subCategoryId: 34,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Report name",
  options: "",
  subCategoryId: 35,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "What category do you want it added to?",
  options: "",
  subCategoryId: 35,
  version: 1,
});
someQuestions.push({
  questionTypeId: 6,
  order: 1,
  question: "Which part of the engineer portal is affected?",
  options: "Recruitment area|Complaints area|Rota area",
  subCategoryId: 36,
  version: 1,
});
someQuestions.push({
  questionTypeId: 6,
  order: 2,
  question: "What is affected?",
  options: "Complaint|Applicant|Engineer",
  subCategoryId: 36,
  version: 1,
});
someQuestions.push({
  questionTypeId: 6,
  order: 1,
  question: "Which rightio website?",
  options:
    "www.rightio.co.uk|plumber.rightio.co.uk|electrician.rightio.co.uk|locksmith.rightio.co.uk|boilers.rightio.co.uk",
  subCategoryId: 37,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 2,
  question: "What page is affected?",
  options: "",
  subCategoryId: 37,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "What page is affected?",
  options: "",
  subCategoryId: 38,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "The name/recruitment ID of the applicant affected",
  options: "",
  subCategoryId: 40,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "The customer ID/reference of the customer affected",
  options: "",
  subCategoryId: 41,
  version: 1,
});
someQuestions.push({
  questionTypeId: 6,
  order: 1,
  question: "What part of Azure?",
  options: "Storage container|SSL certificate|App Service|Resource Group",
  subCategoryId: 42,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Do you have a Job ID/phone number for the recording?",
  options: "",
  subCategoryId: 43,
  version: 1,
});
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Which email is affected?",
  options: "",
  subCategoryId: 45,
  version: 1,
});
someQuestions.push({ questionTypeId: 2, order: 1, question: "User Name", options: "", subCategoryId: 47, version: 1 });
someQuestions.push({ questionTypeId: 2, order: 2, question: "Department", options: "", subCategoryId: 47, version: 1 });
someQuestions.push({
  questionTypeId: 1,
  order: 3,
  question: "Is a password reset required?",
  options: "Yes|No",
  subCategoryId: 47,
  version: 1,
});

const cats = [2, 8, 10, 11, 12, 13];
const bussFuncCount = 6;
const subCatCount = 10;
const startingAt = 49;
const endingAt = startingAt + bussFuncCount * subCatCount;
for (let start = startingAt; start < endingAt; start += 10) {
  for (let i = 0; i < subCatCount - 2; i++) {
    if (i < 7) {
      someQuestions.push({
        questionTypeId: 2,
        order: 1,
        question: "Role",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 2,
        question: "Department/Function",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 3,
        order: 3,
        question: "Reason for change",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 3,
        order: 4,
        question: "Expected outcome/business impact",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      // loggerlog(cats[(start-49)/10],start,start-49,(start-49)/10)
      if (i === 0)
        someSubCategories.push({
          name: "CMS",
          id: start + i,
          categoryId: cats[(start - 49) / 10],
          description: "CMS",
          ticketTypeId: 2,
          ownerId: 2,
          version: 1,
          enabled: true,
        });
      if (i === 1)
        someSubCategories.push({
          name: "Database",
          id: start + i,
          categoryId: cats[(start - 49) / 10],
          description: "Database",
          ticketTypeId: 2,
          ownerId: 2,
          version: 1,
          enabled: true,
        });
      if (i === 2)
        someSubCategories.push({
          name: "EMS",
          id: start + i,
          categoryId: cats[(start - 49) / 10],
          description: "EMS",
          ticketTypeId: 2,
          ownerId: 2,
          version: 1,
          enabled: true,
        });
      if (i === 3)
        someSubCategories.push({
          name: "Finance",
          id: start + i,
          categoryId: cats[(start - 49) / 10],
          description: "Finance",
          ticketTypeId: 2,
          ownerId: 2,
          version: 1,
          enabled: true,
        });
      if (i === 4)
        someSubCategories.push({
          name: "Portal",
          id: start + i,
          categoryId: cats[(start - 49) / 10],
          description: "Portal",
          ticketTypeId: 2,
          ownerId: 2,
          version: 1,
          enabled: true,
        });
      if (i === 5)
        someSubCategories.push({
          name: "Sage 200",
          id: start + i,
          categoryId: cats[(start - 49) / 10],
          description: "Sage 200",
          ticketTypeId: 2,
          ownerId: 2,
          version: 1,
          enabled: true,
        });
      if (i === 6)
        someSubCategories.push({
          name: "Website",
          id: start + i,
          categoryId: cats[(start - 49) / 10],
          description: "Website",
          ticketTypeId: 2,
          ownerId: 2,
          version: 1,
          enabled: true,
        });
    } else {
      someQuestions.push({
        questionTypeId: 2,
        order: 1,
        question: "User Name",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 2,
        question: "Department",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 3,
        question: "Role",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 4,
        question: "Required Software/Hardware",
        options: "",
        subCategoryId: start + i,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 1,
        question: "User Name",
        options: "",
        subCategoryId: start + i + 1,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 1,
        question: "User Name",
        options: "",
        subCategoryId: start + i + 2,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 2,
        question: "Department",
        options: "",
        subCategoryId: start + i + 2,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 3,
        question: "E-mail address",
        options: "",
        subCategoryId: start + i + 2,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 3,
        order: 4,
        question: "Reason for access",
        options: "",
        subCategoryId: start + i + 2,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 1,
        order: 5,
        question: "Using Private Hardware or Provided",
        options: "Private|Provided",
        subCategoryId: start + i + 2,
        version: 1,
      });
      someQuestions.push({
        questionTypeId: 2,
        order: 6,
        question: "Computer name of PC (e.g. RIGHTIOW383)|1",
        options: "",
        subCategoryId: start + i + 2,
        version: 1,
      });
      someSubCategories.push({
        name: "New starters",
        id: start + i,
        categoryId: cats[(start - 49) / 10],
        description: "New starters",
        ticketTypeId: 3,
        ownerId: 2,
        secOwnerId: 1,
        version: 1,
        enabled: true,
      });
      someSubCategories.push({
        name: "Password reset",
        id: start + i + 1,
        categoryId: cats[(start - 49) / 10],
        description: "Password reset",
        ticketTypeId: 3,
        ownerId: 2,
        secOwnerId: 1,
        version: 1,
        enabled: true,
      });
      someSubCategories.push({
        name: "Remote connection",
        id: start + i + 2,
        categoryId: cats[(start - 49) / 10],
        description: "Remote connection",
        ticketTypeId: 3,
        ownerId: 2,
        secOwnerId: 1,
        version: 1,
        enabled: true,
      });
    }
  }
}
someQuestions.push({
  questionTypeId: 2,
  order: 1,
  question: "Please try to describe a category the issue belongs to.",
  options: "",
  subCategoryId: 48,
  version: 1,
});
