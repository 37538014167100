import { Theme } from "@mui/material";

export const style = (theme: Theme) => ({
  paper: {
    margin: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
    },
  },
  paperTicketFormWrapper: {
    margin: theme.spacing(0),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 1)]: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  dashboard: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "unset  !important",
    position: "absolute",
    left: 0,
  },
  paperParentCol: {
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      padding: theme.spacing(2),
    },
    // minHeight: 300
  },
  dropZoneBox: {
    margin: theme.spacing(0),
    // marginBottom: theme.spacing(0),
    padding: theme.spacing(0),
    width: "100%",
    [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
      margin: theme.spacing(1),
      padding: theme.spacing(0),
    },
    marginLeft: theme.spacing(2),
  },
  paperItem: (additional?: any) => ({
    flexGrow: 1,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(1),
    },
    ...additional,
    // minHeight: 300
  }),
  paperInnerDropZone: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(2),
    },
  },
  dashboardLayout: {
    // width: 'auto',
    margin: "auto",
    // maxwidth: "100%",
    width: "100%",
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      width: "100%",
      margin: "auto",
      // minWidth: 310
    },
    minWidth: 365,
  },
  btnHomeMenu: {
    display: "flex",
    justifyContent: "center",
    padding: 1,
    marginBottom: theme.spacing(1),
  },
  paperInner: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(2),
    },
    minHeight: 580,
  },
  paperInnerTicketForm: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + Number(theme.spacing(1)) * 1)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(1),
    },
    minHeight: 580,
  },
  paperAttachments: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(2),
    },
  },
  paperDetails: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1),
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(1),
    },
  },
  paperStatus: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      padding: theme.spacing(2),
    },
    // minHeight: 300
  },
  paperChart: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      padding: theme.spacing(2),
      maxWidth: 800,
    },
  },
  homeLogo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      padding: theme.spacing(2),
    },
    position: "relative",
    bottom: 185,
    left: 700,
    width: 85,
    height: 85,
    maxWidth: "fit-content",
  },
  homeMiniLogo: {
    width: 85,
    height: 85,
  },
  gridHome: {
    width: "100%",
    maxWidth: 1200,
    backgroundColor: theme.palette.background.paper,
  },
  stepper: {
    padding: theme.spacing(1, 0, 1),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonsTickets: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBlock: theme.spacing(1),
    gap: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  historyButton: {
    marginTop: theme.spacing(1),
    marginRight: "auto",
    marginLeft: 0,
  },
  ticketLayout: {
    margin: "auto",
    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
      width: 1060,
      margin: "auto",
    },
    minWidth: 365,
  },
});
