import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../../hooks/useDebugInformation';
import { ui } from '../../../../../../interfaces/interfaces';
import Grid from '@mui/material/Grid';
import TextInput from '../../../../../shared/TextInput';
import { RenderFilesTable } from '../../../../../shared/DropZone';
import { compileAnswers } from '../../../../../../assets/js/main';
import { InputNames } from '../../../../../../utilities/basicTypes';
import { style } from '../../../../../../utilities/style';
import { cacheUtils } from '../../../../../..';
import { CacheTypes } from '../../../../../../utilities/cache/interfaces';

const name = "FormPanel"
type StandardTicketProps = {
    answers: any[]
    parent: string
}

const StandardTicket: React.FC<StandardTicketProps> = ({ parent, answers }) => {
    return (<>
        <Grid item xs={12} sm={12}><Typography variant='subtitle1'>Screening Answers:</Typography></Grid>
        {/* Screening Questions: */}
        {answers[0] && <TextInput readOnly uncontrolledName={`${answers[0].question}`} inputName={`${answers[0].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[0].value} />}
        {answers[1] && <TextInput readOnly uncontrolledName={`${answers[1].question}`} inputName={`${answers[1].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[1].value} />}
        {answers[2] && <TextInput readOnly uncontrolledName={`${answers[2].question}`} inputName={`${answers[2].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[2].value} />}
        {/* Screening Questions: */}
        {answers[3] && <TextInput readOnly uncontrolledName={`${answers[3].question}`} inputName={`${answers[3].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[3].value} />}
        {answers[4] && <TextInput readOnly uncontrolledName={`${answers[4].question}`} inputName={`${answers[4].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[4].value} />}
        {answers[5] && <TextInput readOnly uncontrolledName={`${answers[5].question}`} inputName={`${answers[5].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[5].value} />}
    </>)
}

type Props = {
    parent: string
}

const SummaryPanel: React.FC<Props> = ({ parent }) => {
    const questions = cacheUtils.getCache<CacheTypes.Questions>("Questions")
    const [answers, setAnswers] = React.useState([] as { value: string; question: string; order: number; }[])

    const debugName = `${parent}▷[${name}]`

    React.useEffect(() => { if (questions.length > 0) compileAnswers(setAnswers) }, [questions])

    useDebugInformation(debugName, { questions })
    return (
        <Box id="form-panel" >
            <Paper sx={(theme) => style(theme).paper} variant="elevation" elevation={4}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    {ui.TicketCategory && <TextInput readOnly uncontrolledName='Ticket Category > SubCategory' inputName="Ticket Category > SubCategory" parent={debugName} xs={6} sm={6} defaultValue={`${ui.TicketCategory.name !== "Other" ? `${ui.TicketCategory.name}  > ` : `Custom Other: ${ui.OtherCategory}`}${ui.TicketCategory.name !== "Other" ? ui?.TicketSubcategory?.name : ""}`} />}
                    <TextInput readOnly uncontrolledName='Ticket Location, Date and Time' inputName="Ticket Location, Date and Time" parent={debugName} xs={6} sm={6} defaultValue={`${ui.TicketLocation.name} - ${ui.TicketDate} ${ui.TicketTime}`} />
                    {(answers.length > 0 || ui.OtherCategory !== "") && <StandardTicket answers={answers} parent={debugName} />}
                    <TextInput readOnly uncontrolledName={`TicketDetails`} inputName={`TicketDetails`} parent={parent} xs={12} sm={12} defaultValue={ui.TicketDetails} multiline={6} minRows={6} />
                    {ui.Files && ui.Files.length > 0 && (
                        <>
                            <Grid item xs={12} sm={12}>
                                <Typography align="center" variant="subtitle2">
                                    Attachments:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}><ul style={{ paddingLeft: 0 }}><RenderFilesTable files={ui.Files} /></ul></Grid>
                        </>)}
                </Grid>
            </Paper>
        </Box >
    )
}
export default React.memo(SummaryPanel)