import React from "react";
import { sortTable } from "../../../../assets/js/main";
import useDebugInformation from "../../../../hooks/useDebugInformation";
import { ui } from "../../../../interfaces/interfaces";

interface DtProps {
    parent: "Incidents" | "Requests" | "Overall" | "Manage"
    columns: any[];
}

const name = "Header"

const headerClick = (e: any, theadRowsRef: any, parent: DtProps["parent"]) => {
    if (e && e.target) {
        const currentCell = e.target as HTMLTableCellElement
        const isReversed = !currentCell.classList.contains("active-desc") && currentCell.classList.contains("active-asc")
        const rows: HTMLTableRowElement = theadRowsRef.current
        const cells = rows.cells
        for (const cell of cells) {
            cell.classList.remove("active-desc")
            cell.classList.remove("active-asc")
        }
        let i = 0
        let currentCol = 0
        currentCell.classList.add((isReversed ? "active-desc" : "active-asc"))
        for (const cell of cells) {
            if (cell.classList.contains("active-desc") || cell.classList.contains("active-asc")) currentCol = i
            i++
        }
        ui[`${parent}TableData`] = sortTable([...ui[`${parent}TableData`]], currentCol, isReversed)
    }
}

const TableBSHeader: React.FC<DtProps> = ({ parent, columns }) => {
    const theadRowsRef = React.useRef(null)
    const debugName = `${parent}▷[${name}]`
    useDebugInformation(debugName, { parent, columns })

    return (
        <thead>
            <tr ref={theadRowsRef}>
                {columns.map((col, idx) => <th scope="col" className={`pointer${col.sortingActive ? (!col.isReversed ? " active-desc" : " active-asc") : ""}`} onClick={(e: any) => headerClick(e, theadRowsRef, parent)} key={idx}>{col.title}</th>)}
            </tr>
        </thead>
    )
}

export default React.memo(TableBSHeader);