import React from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../hooks/useDebugInformation';
import FullWidthTabs from './Tabs';
import { style } from '../../../utilities/style';

const name = "TicketsDashboard"

type Props = {
    parent: string
}

const TicketsDashboard: React.FC<Props> = ({ parent }) => {
    const debugName = `${parent}▷[${name}]`

    useDebugInformation(debugName, {})
    return (
        <Container sx={(theme) => style(theme).dashboard}>
            <Box sx={(theme) => style(theme).dashboardLayout}>
                <Box id="main-container" >
                    {/* <TicketsPanel parent={debugName} /> */}
                    <FullWidthTabs />
                </Box>
            </Box >
        </Container >
    );
}

export default React.memo(TicketsDashboard)
