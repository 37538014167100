import { store } from "../../store";
import * as reducer from "./slice";

export const dispatchSetApi = (name: string, payload: any, body: any) => {
    store.dispatch(
        reducer.set({
            name,
            value: payload,
            body
        })
    );
};

export const dispatchErrorApi = () => {
    store.dispatch(
        reducer.error()
    );
};

export const dispatchStartApi = () => {
    store.dispatch(
        reducer.start()
    );
};

export const dispatchResetAPI = (value?: any) => {
    store.dispatch(
        reducer.reset(
            value
        )
    );
}

export default reducer;