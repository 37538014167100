import React from "react";
import Paper from '@mui/material/Paper';
import "./../TablesBS.css"
import Header from "./Header";
import Footer from "./Footer";
import Body from "./TBody";
import useDebugInformation from "../../../hooks/useDebugInformation";

const name = "Table"

interface DtProps {
    parent: "Incidents" | "Requests" | "Overall" | "Manage"
    tableName: string
    columns: any[];
}

const TableBSMini: React.FC<DtProps> = ({ parent, tableName, columns }) => {
    const tbodyRef = React.useRef(null)
    const debugName = `${parent}▷[${name}]`
    useDebugInformation(debugName, { parent, tbodyRef })

    return (
        <Paper elevation={4}>
            <table id={tableName} className="table table-sm table-striped table-hover table-bordered" style={{ fontSize: 12, width: "100%" }}>
                <Header parent={parent} columns={columns} />
                <Body parent={parent} />
                <Footer parent={parent} />
            </table>
        </Paper>
    )
}

export default React.memo(TableBSMini);