import Paper from "@mui/material/Paper"
import React from "react"
import { Tables } from "../../services/databaseModels/tables"
import { ui } from "../../interfaces/interfaces"
import MemoizedDropDownQuestion from "./Inputs/DropDownQuestion"
import MemoizedRadioQuestion from "./Inputs/RadioQuestion"
import MemoizedTextFieldQuestion from "./Inputs/TextFieldQuestion"
import { Tooltip, Typography } from "@mui/material"
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import QuestionEditor from "./QuestionsEditor"

type QuestionTemplateProps = {
    question: Tables.QuestionsOutput
    index: number
    id: number
    minRows?: number
    maxRows?: number
}

const QuestionTemplate: React.FC<QuestionTemplateProps> = ({ question, index, id, minRows, maxRows }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    switch (question?.questionTypeId) {
        case 1:
            return (
                <Tooltip title={question.id} placement="top">
                    <Paper sx={(theme) => ({
                        flexGrow: 1,
                        marginTop: theme.spacing(0),
                        marginBottom: theme.spacing(0),
                        padding: theme.spacing(1),
                        [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                            marginTop: theme.spacing(0),
                            marginBottom: theme.spacing(0),
                            padding: theme.spacing(1),
                        },
                        // minHeight: 300
                    })} variant="elevation" elevation={4}>
                        <QuestionEditor question={question} open={open} onClose={handleClose} />
                        {question.dependsId && <Typography>{`${question.dependsId} : ${question.dependsValue}`}</Typography>}
                        <MemoizedRadioQuestion index={index} id={id} question={question} value={""} />
                        <IconButton onClick={handleClickOpen}>
                            <EditIcon />
                        </IconButton>
                    </Paper >
                </Tooltip>
            )
        case 2:
            return (<Tooltip title={question.id} placement="top"><Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}>
                <QuestionEditor question={question} open={open} onClose={handleClose} />
                {question.dependsId && <Typography>{`${question.dependsId} : ${question.dependsValue}`}</Typography>}
                <MemoizedTextFieldQuestion index={index} id={id} question={question} value={""} />
                <IconButton onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </Paper>
            </Tooltip>)
        case 3:
            return (<Tooltip title={question.id} placement="top"><Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}>
                <QuestionEditor question={question} open={open} onClose={handleClose} />
                {question.dependsId && <Typography>{`${question.dependsId} : ${question.dependsValue}`}</Typography>}
                <MemoizedTextFieldQuestion index={index} id={id} question={question} value={""} maxRows={maxRows} minRows={minRows ? minRows : 1} />
                <IconButton onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </Paper>
            </Tooltip>)
        case 4:
            return (<Tooltip title={question.id} placement="top"><Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}>
                <QuestionEditor question={question} open={open} onClose={handleClose} />
                {question.dependsId && <Typography>{`${question.dependsId} : ${question.dependsValue}`}</Typography>}
                <MemoizedRadioQuestion index={index} id={id} question={question} value={""} />
                <IconButton onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </Paper>
            </Tooltip>)
        case 5:
            return (<Tooltip title={question.id} placement="top"><Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}>
                <QuestionEditor question={question} open={open} onClose={handleClose} />
                {question.dependsId && <Typography>{`${question.dependsId} : ${question.dependsValue}`}</Typography>}
                <MemoizedDropDownQuestion index={index} id={id} question={question} value={""} />
                <IconButton onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </Paper>
            </Tooltip>)
        case 6:
            return (<Tooltip title={question.id} placement="top"><Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}>
                <QuestionEditor question={question} open={open} onClose={handleClose} />
                {question.dependsId && <Typography>{`${question.dependsId} : ${question.dependsValue}`}</Typography>}
                <MemoizedDropDownQuestion index={index} id={id} question={question} value={""} other={true} otherValue={""} />
                <IconButton onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </Paper>
            </Tooltip>)
        case 7:
            return (<></>)
        default:
            return null
    }
}

export default QuestionTemplate