import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React from "react"
import { hasTicketStateChanged } from "../../../../../../../assets/js/main"
import { state, ui } from "../../../../../../../interfaces/interfaces"
import { InputNames } from "../../../../../../../utilities/basicTypes"
import TextInput from "../../../../../../shared/TextInput"
import { style } from './../../../../../../../utilities/style';

type SystemRequestProps = {
    answers: any[]
    parent: string
    viewOnly: boolean
}

const SystemRequest: React.FC<SystemRequestProps> = ({ parent, answers, viewOnly }) => {
    return (<>
        <Grid container spacing={2} sx={{ alignItems: "center" }} gap={1} width={"100%"} margin={0}>
            {/* Screening Questions: */}
            {answers.map((answer, index) => {
                return (<Paper key={`answer-${index}`} variant="elevation" elevation={4} sx={(theme) => ({ ...style(theme).paperDetails, flexGrow: 1 })}>
                    <TextInput readOnly={viewOnly} styledVariant='disabled' uncontrolledName={`${answer.question}`} inputName={(`${answer.question}`) as InputNames} parent={parent} xs={12} sm={12} defaultValue={answer.value} />
                </Paper>)
            })}
        </Grid>
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                <TextInput readOnly styledVariant='disabled' uncontrolledName={`RequestDetails`} inputName={`RequestDetails`} parent={parent} xs={12} sm={12} defaultValue={ui.TicketDetails} multiline={6} minRows={6} />
            </Grid>
        </Paper>
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                {viewOnly
                    ? <TextInput readOnly styledVariant='disabled' uncontrolledName="TicketProgress" defaultValue={ui.TicketProgress} inputName="TicketProgress" parent={parent} xs={12} sm={12} type="text" multiline={3} minRows={3} />
                    : <TextInput styledVariant='required' color="success" onChange={(e: { target: { value: string; }; }) => { ui[`TicketProgress`] = e.target.value; hasTicketStateChanged() }} onDoubleClick={() => { ui.ScrollableDialogControlName = "TicketProgress"; ui.ScrollableDialogOpen = true }} inputName="TicketProgress" parent={parent} xs={12} sm={12} type="text" multiline={3} minRows={3} />}
            </Grid>
        </Paper>
    </>)
}
export default React.memo(SystemRequest)