import * as CacheInterface from "./interface";
export * from "./interface";
export const cacheTemplate: CacheInterface.TemplateCACHE = () => ({
  Cached: false,
  Categories: [],
  Files: [],
  Locations: [],
  Priorities: [],
  Questions: [],
  QuestionsTypes: [],
  RequestCategories: [],
  Roles: [],
  Statuses: [],
  SubCategories: [],
  TicketCategories: [],
  TicketTypes: [],
  Users: []
});