import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import useDebugInformation from '../../../../hooks/useDebugInformation';
import Box from '@mui/material/Box';
import TicketsTable from './TicketsTable';
import { useAppSelector } from '../../../../store/hooks';

const name = "TicketsTableWrapper"

const steps = ['Tickets Management'];

type Props = {
    parent: string
}

const TicketsTableWrapper: React.FC<Props> = ({ parent }) => {
    const activeStep = useAppSelector(({ ui }) => ui.ActiveStep)

    const debugName = `${parent}▷[${name}]`

    useDebugInformation(debugName, { activeStep })
    return (
        <Box id="form-panel" sx={{
            width: {
                xs: 500, // theme.breakpoints.up('xs')
                sm: 500, // theme.breakpoints.up('sm')
                md: 850, // theme.breakpoints.up('md')
                lg: 1150, // theme.breakpoints.up('lg')
                xl: 1480, // theme.breakpoints.up('xl') 
            }
        }
        } >
            < Paper style={{
                paddingBottom: "8px"
            }}>
                <Typography component="h6" variant="h6" align="center" className='pt-2' style={{ marginBottom: -8 }}>
                    {steps[activeStep]}
                </Typography>
                <TicketsTable parent={name} />
            </Paper >
        </Box >
    )
}
export default React.memo(TicketsTableWrapper)