import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../../hooks/useDebugInformation';
import { ui } from '../../../../../../interfaces/interfaces';
import Grid from '@mui/material/Grid';
import { style } from '../../../../../../utilities/style';
import { useAppSelector } from '../../../../../../store/hooks';

const name = "CompletionPanel"
type Props = {
    parent: string
}

const CompletionPanel: React.FC<Props> = ({ parent }) => {
    const loggedUser = useAppSelector(({ state }) => state.LoggedUser)
    const debugName = `${parent}▷[${name}]`

    useDebugInformation(debugName, {})
    return (
        <Box id="form-panel" >
              <Paper sx={(theme) => style(theme).paperInner} variant="elevation" elevation={4}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h5" gutterBottom>
                            Thank you {loggedUser?.profile.first_name} for submitting a ticket.
                        </Typography>
                        <Typography variant="subtitle1">
                            Your ticket number is <b>{ui.TicketId}</b>. We have emailed your ticket confirmation to <b><i>{loggedUser?.profile.email}</i></b>, and will
                            contact you in due process with any updates if applicable.
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}
export default React.memo(CompletionPanel)