/**
 * Debouncing class for preventing the same event to be fired multiple times
 * @class Debouncing
 * @remarks This class is used to debounce the same event to be fired multiple times
 * @constructor
 * @author Adam Rzymski
 * @date 2022-03-29
 * @version 1.0.0
 * @param {number} [delay=0] - The delay in milliseconds
 * @param {context} [context=null] - The context to be used for the debounced function
 * @param {Function} [callback] - The function to be called
 * @example
 * import { Debouncing } from '@app/utilities/debouncing';
 * const debouncing = new Debouncing(1000, this, () => {
 *  console.log('debounced');
 * });
 * @returns {Debouncing}
 */

export class Debouncing {
  private delay: number;
  private timeout: any;
  private callback: any | Promise<any>;
  private context: any;
  private lastExecution: Date | null = null;
  constructor(delay: number, callback: any | Promise<any>, context?: any) {
    this.delay = delay;
    this.callback = callback;
    this.context = context || null;
  }

  /**
   * execute the debounced function
   * @method execute
   * @param {callback} [callback=null] - The function to be called
   * @param {delay} [delay=0] - The delay in milliseconds
   * @param {context} [context=null] - The context to be used for the debounced function
   * @example
   * debouncing.execute(() => {
   * console.log('debounced');
   * });
   * @returns {void}
   * @example
   * debouncing.execute(() => {
   * console.log('debounced');
   * }, 1000);
   * @returns {void}
   */

  public execute = (callback?: any, delay?: number, context?: any) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.context) {
      // replace the callback with the new one if it is passed
      if (callback) {
        this.callback = callback;
      }

      // replace the delay with the new one if it is passed
      if (delay) {
        this.delay = delay;
      }

      // replace the context with the new one if it is passed
      if (context) {
        this.context = context;
      }
      // create a new timeout
      this.timeout = setTimeout(() => {
        // execute the callback with the context
        this.callback.apply(this.context);
        this.lastExecution = new Date();
      }, this.delay);
    } else {
      // replace the callback with the new one if it is passed
      if (callback) {
        this.callback = callback;
      }

      // replace the delay with the new one if it is passed
      if (delay) {
        this.delay = delay;
      }

      // if no context is passed, execute the callback without context
      if (!context) {
        // create a new timeout
        this.timeout = setTimeout(() => {
          // execute the callback without the context
          this.callback();
          this.lastExecution = new Date();
        }, this.delay);
      }
      // otherwise execute the callback with the context
      else {
        // create a new timeout
        this.timeout = setTimeout(() => {
          // execute the callback with the context
          this.callback.apply(this.context);
          this.lastExecution = new Date();
        }, this.delay);
      }
    }
  };

  /**
   * execute the function asynchronously
   * @method executeAsync
   * @param {callback} [callback=null] - The function to be called
   * @param {number} [delay=0] - The delay in milliseconds
   * @param {context} [context=null] - The context to be used for the debounced function
   * @example
   * await debouncing.executeAsync(() => {
   * console.log('debounced');
   * });
   * @returns {Promise<void>}
   * @example
   * await debouncing.executeAsync(() => {
   * console.log('debounced');
   * }, 1000);
   * @returns {Promise<void>}
   */

  public executeAsync = async (callback?: Promise<any>, delay?: number, context?: any) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.context) {
      // replace the callback with the new one if it is passed
      if (callback) {
        this.callback = callback;
      }

      // replace the delay with the new one if it is passed
      if (delay) {
        this.delay = delay;
      }

      // replace the context with the new one if it is passed
      if (context) {
        this.context = context;
      }
      // create a new timeout
      this.timeout = setTimeout(async () => {
        // execute the callback with the context
        await this.callback.apply(this.context);
        this.lastExecution = new Date();
      }, this.delay);
    } else {
      // replace the callback with the new one if it is passed
      if (callback) {
        this.callback = callback;
      }

      // replace the delay with the new one if it is passed
      if (delay) {
        this.delay = delay;
      }

      // if no context is passed, execute the callback without context
      if (!context) {
        // create a new timeout
        this.timeout = setTimeout(async () => {
          // execute the callback without the context
          await this.callback();
          this.lastExecution = new Date();
        }, this.delay);
      }
      // otherwise execute the callback with the context
      else {
        // create a new timeout
        this.timeout = setTimeout(async () => {
          // execute the callback with the context
          await this.callback.apply(this.context);
          this.lastExecution = new Date();
        }, this.delay);
      }
    }
  };
}

/**
 * DebouncingFunctions class for storing debounced functions
 * @class DebouncingFunctions
 * @remarks This class is used to store debounced functions in an object
 * and execute them when needed.
 * @constructor
 * @author Adam Rzymski
 * @date 2022-03-29
 * @version 1.0.0
 * @example
 * import DebouncingFunctions from '@app/utilities/debouncing';
 * const debouncingFunctions = new DebouncingFunctions();
 * debouncingFunctions.add('debounced', 1000, () => {
 * console.log('debounced');
 * });
 * debouncingFunctions.execute('debounced');
 */
export class DebouncingFunctions {
  private _debouncedFunctions: { [key: string]: Debouncing } = {};

  constructor() {
    this._debouncedFunctions = {};
  }

  /**
   * getter for the debounced functions
   * @method debouncedFunctions
   * @example
   * debouncingFunctions.debouncedFunctions;
   * @returns {object}
   * @readonly
   * @memberof DebouncingFunctions
   * @returns {object}
   * @example
   * import DebouncingFunctions from '@app/utilities/debouncing';
   * const debouncingFunctions = new DebouncingFunctions();
   * debouncingFunctions.debouncedFunctions;
   */

  public get debouncedFunctions() {
    return this._debouncedFunctions;
  }

  /**
   * add a debounced function to the object
   * @method add
   * @param {string} name - The name of the debounced function
   * @param {number} delay - The delay in milliseconds
   * @param {Function} callback - The function to be called
   * @param {context} context - The context to be used for the debounced function
   * @example
   * debouncedFunctions.add('debounced', 1000, () => {
   *  console.log('debounced');
   * });
   * @returns {void}
   */
  public add = (name: string, delay: number, callback: any, context?: any) => {
    this.debouncedFunctions[name] = new Debouncing(delay, callback, context);
  };

  /**
   * execute a debounced function
   * @method execute
   * @param {string} name - The name of the debounced function
   * @param {callback} [callback=null] - The function to be called
   * @param {delay} [delay=0] - The delay in milliseconds
   * @param {context} [context=null] - The context to be used for the debounced function
   * @example
   * debouncedFunctions.execute('debounced', 1, 2, 3);
   * @returns {void}
   */
  public execute = (name: string, delay?: number, callback?: any, context?: any) => {
    this.debouncedFunctions[name].execute(callback, delay, context);
  };

  /**
   * execute debounced function asynchronously
   * @method executeAsync
   * @param {string} name - The name of the debounced function
   * @param {callback} [callback=null] - The function to be called
   * @param {delay} [delay=0] - The delay in milliseconds
   * @param {context} [context=null] - The context to be used for the debounced function
   * @async
   * @example
   * await debouncedFunctions.executeAsync('debounced', 1, 2, 3);
   * @returns {Promise<void>}
   *
   */
  public executeAsync = async (name: string, callback?: any, delay?: number, context?: any) => {
    await this.debouncedFunctions[name].executeAsync(callback, delay, context);
  };

  /**
   * remove a debounced function from the object
   * @method remove
   * @param {string} name - The name of the debounced function
   * @example
   * debouncedFunctions.remove('debounced');
   * @returns {void}
   */
  public remove = (name: string) => {
    delete this.debouncedFunctions[name];
  };

  /**
   * remove all debounced functions from the object
   * @method removeAll
   * @example
   * debouncedFunctions.removeAll();
   * @returns {void}
   */
  public removeAll = () => {
    this._debouncedFunctions = {};
  };

  /**
   * get a debounced function from the object
   * @method get
   * @param {string} name - The name of the debounced function
   * @example
   * const debounced = debouncedFunctions.get('debounced');
   * @returns {Debouncing}
   */
  public get = (name: string) => {
    return this.debouncedFunctions[name];
  };

  /**
   * get all debounced functions from the object
   * @method getAll
   * @example
   * const debouncedFunctions = debouncedFunctions.getAll();
   * @returns {any}
   */

  public getAll = () => {
    return this.debouncedFunctions;
  };

  /**
   * get all debounced functions names from the object
   * @method getAllNames
   * @example
   * const debouncedFunctionsNames = debouncedFunctions.getAllNames();
   * @returns {string[]}
   */
  public getAllNames = () => {
    return Object.keys(this.debouncedFunctions);
  };

  /**
   * check if a debounced function exists in the object
   * @method has
   * @param {string} name - The name of the debounced function
   * @example
   * const hasDebounced = debouncedFunctions.has('debounced');
   * @returns {boolean}
   */
  public has = (name: string) => {
    return this.debouncedFunctions.hasOwnProperty(name);
  };

  /**
   * addAndExecute a debounced function to the object and execute it
   * @method addAndExecute
   * @param {string} name - The name of the debounced function
   * @param {number} delay - The delay in milliseconds
   * @param {Function} callback - The function to be called
   * @param {context} context - The context to be used for the debounced function
   * @example
   * debouncedFunctions.addAndExecute('debounced', 1000, () => {
   *  console.log('debounced');
   * });
   * @returns {void}
   */
  public addAndExecute = (name: string, delay: number, callback: any, context?: any) => {
    this.add(name, delay, callback, context);
    this.execute(name);
  };

  /**
   * addOrExecute a debounced function to the object or execute it
   * @method addOrExecute
   * @param {string} name - The name of the debounced function
   * @param {number} delay - The delay in milliseconds
   * @param {Function} callback - The function to be called
   * @param {context} context - The context to be used for the debounced function
   * @example
   * debouncedFunctions.addOrExecute('debounced', 1000, () => {
   *  console.log('debounced');
   * });
   * @returns {void}
   */
  public addOrExecute = (name: string, delay: number, callback: any, context?: any) => {
    if (!this.has(name)) {
      this.add(name, delay, callback, context);
    }
    this.execute(name, delay, callback, context);
  };

  /**
   * addOrExecuteAsync a debounced function to the object or execute it
   * @method addOrExecuteAsync
   * async version of addOrExecute
   * @async
   * @param {string} name - The name of the debounced function
   * @param {number} delay - The delay in milliseconds
   * @param {Function} callback - The function to be called
   * @param {context} context - The context to be used for the debounced function
   * @example
   * debouncedFunctions.addOrExecuteAsync('debounced', 1000, () => {
   *  console.log('debounced');
   * });
   * @returns {Promise<any>}
   */
  public addOrExecuteAsync = async (name: string, delay: number, callback: any, context?: any) => {
    if (!this.has(name)) {
      this.add(name, callback, delay, context);
    }
    await this.executeAsync(name, callback, delay, context);
  };
}

const debouncingFunctions = new DebouncingFunctions();

export default debouncingFunctions;
