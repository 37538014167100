import * as React from 'react';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import ConstructionSharpIcon from '@mui/icons-material/ConstructionSharp';
import Box from '@mui/material/Box';
import { api, state } from '../../../interfaces/interfaces';
import { alpha, styled } from '@mui/material/styles';
import { Tables } from '../../../services/databaseModels/tables';
import { useAppSelector } from '../../../store/hooks';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { NavigateFunction, useNavigate } from "react-router-dom";
import Toolbar from './Toolbar';
import CustomPagination from './CustomPagination';
import { cacheUtils } from '../../..';
import { CacheTypes } from '../../../utilities/cache/interfaces';

const ODD_OPACITY = 0.2;
const EVEN_OPACITY = 0.2;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}));

const columns: (navigate: NavigateFunction, viewOnly: boolean) => GridColDef[] = (navigate, viewOnly) => [
    { field: "id", headerName: "Ticket#:", width: 70 },
    {
        field: "dateReported", headerName: "Date created:", width: 100, valueGetter: (params: GridValueGetterParams) =>
            `${new Date(params.row.dateReported).toLocaleDateString("en-GB") || ''}`,
    },
    {
        field: "categoryId", headerName: "Category:", flex: 200, valueGetter: (params: GridValueGetterParams) =>
            `${cacheUtils.getCache<CacheTypes.Categories>("Categories").find(cat => cat.id === params.row.categoryId)?.name || ''
            }`,
    },
    {
        field: "subCategoryId", headerName: "Subcategory:", flex: 200, valueGetter: (params: GridValueGetterParams) =>
            `${cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories").find(subCat => subCat.id === params.row.subCategoryId)?.name || ''}`,
    },
    {
        field: "statusId", headerName: "Status:", width: 90, valueGetter: (params: GridValueGetterParams) =>
            `${cacheUtils.getCache<CacheTypes.Statuses>("Statuses").find(status => status.id === params.row.statusId)?.name || ''}`,
    },
    {
        field: "priorityId", headerName: "Priority:", width: 70, valueGetter: (params: GridValueGetterParams) =>
            `${cacheUtils.getCache<CacheTypes.Priorities>("Priorities").find(priority => priority.id === params.row.priorityId)?.name || ''}`,
    },
    {
        field: "updatedById", headerName: "Updated by:", flex: 200, valueGetter: (params: GridValueGetterParams) => {
            const user = cacheUtils.getCache<CacheTypes.Users>("Users").find(user => user.id === params.row.updatedById);
            return `${user?.firstName} ${user?.lastName}` || '';
        },
    },
    {
        field: "assigneeId", headerName: "Assigned to:", flex: 200, valueGetter: (params: GridValueGetterParams) => {
            const user = cacheUtils.getCache<CacheTypes.Users>("Users").find(user => user.id === params.row.assigneeId);
            return `${user?.firstName} ${user?.lastName}` || '';
        },
    },
    {
        field: "reporteeId", headerName: "Reported by:", flex: 200, valueGetter: (params: GridValueGetterParams) => {
            const user = cacheUtils.getCache<CacheTypes.Users>("Users").find(user => user.id === params.row.reporteeId);
            return `${user?.firstName} ${user?.lastName}` || '';
        },
    },
    {
        field: "locationId", headerName: "Type:", width: 70, valueGetter: (params: GridValueGetterParams) =>
            `${params.row.locationId ? "Incident" : "Request"}`,
    },
    {
        field: "manage", headerName: "Manage:",
        renderCell: (params) => params.row.statusId < 4 && !viewOnly ? <LightTooltip placement='right-end' title={`Manage ticket ${params.row.id}`}><ConstructionSharpIcon sx={{ cursor: "pointer" }} color='primary' onClick={() => navigate(`../manage-ticket/${params.row.id}`)} /></LightTooltip> : <LightTooltip placement='right-end' title={`View ticket ${params.row.id}`}><VisibilitySharpIcon color='primary' sx={{ cursor: "pointer" }} onClick={() => navigate(`../view-ticket/${params.row.id}`)} /></LightTooltip>,
        align: 'center',
        hideSortIcons: true,
        disableColumnMenu: true,
        filterable: false,
        width: 70,
    },
];

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#4a4a4a2e' : '#303030'
            }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#4a4a4a2e' : '#303030'
            }`,
    },
    '& .MuiDataGrid-cell': {
        color:
            theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: "#fafafa",
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, EVEN_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                EVEN_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    EVEN_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        EVEN_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));

interface Props {
    viewOnly: boolean;
}

const getScreenHeight = () => {
    const { innerHeight } = window;
    console.log(innerHeight);
    return innerHeight
}

const DenseGridDataTable: React.FC<Props> = ({ viewOnly }) => {
    const [ticketData, setTicketData] = React.useState<Tables.TicketsOutput[] | null>(null)
    const [originalTicketData, setOriginalTicketData] = React.useState<Tables.TicketsOutput[] | null>(null)
    const myOnly = useAppSelector(({ ui }) => ui.MyOnly)
    const openOnly = useAppSelector(({ ui }) => ui.OpenOnly)
    const closedOnly = useAppSelector(({ ui }) => ui.ClosedOnly)
    const user = useAppSelector(({ state }) => state.User)
    const filterModel = useAppSelector(({ state }) => state.FilterModel)
    const sortModel = useAppSelector(({ state }) => state.SortModel)
    const columnVisibilityModel = useAppSelector(({ state }) => state.ColumnVisibilityModel)
    const page = useAppSelector(({ state }) => state.Page)

    const navigate = useNavigate();

    getScreenHeight()

    React.useEffect(() => {
        if (originalTicketData === null && !viewOnly) {
            api.fetchTickets().then(data => {
                setOriginalTicketData(data)
            })
        }
        if (originalTicketData === null && viewOnly) {
            api.fetchTickets().then(data => {
                setOriginalTicketData(data
                    .filter(ticket => ticket.reporteeId === user?.id)
                )
            })
        }
    }, [user?.id, originalTicketData, viewOnly])

    React.useEffect(() => {
        if (originalTicketData !== null && (myOnly || openOnly || closedOnly)) {
            if (myOnly && !openOnly && !closedOnly) {
                setTicketData(originalTicketData.filter(x => x.assigneeId === user?.id))
            }
            else if (openOnly && !myOnly) {
                setTicketData(originalTicketData.filter(x => x.statusId < 4))
            }
            else if (closedOnly && !myOnly) {
                setTicketData(originalTicketData.filter(x => x.statusId === 4))
            }
            else if (myOnly && openOnly) {
                setTicketData(originalTicketData.filter(x => x.assigneeId === user?.id && x.statusId < 4))
            }
            else if (myOnly && closedOnly) {
                setTicketData(originalTicketData.filter(x => x.assigneeId === user?.id && x.statusId === 4))
            }
            else {
                setTicketData(originalTicketData.filter(x => x.assigneeId === user?.id && x.statusId === 1))
            }
        }
        else if (originalTicketData !== null && !myOnly) {
            setTicketData(originalTicketData)
        }
    }, [myOnly, user?.id, originalTicketData, openOnly, closedOnly])

    return (
        <Box sx={{ height: (getScreenHeight() - 220) * .98 }}>
            {ticketData && <StripedDataGrid
                initialState={{ filter: { filterModel }, sorting: { sortModel }, columns: { columnVisibilityModel }, pagination: { page } }}
                rows={ticketData as any}
                columns={columns(navigate, viewOnly)}
                pagination
                autoPageSize={true}
                rowsPerPageOptions={[5]}
                components={{
                    Toolbar: Toolbar,
                    Pagination: CustomPagination,
                }}
                rowHeight={30}
                headerHeight={30}
                onSortModelChange={(model) => state.SortModel = model}
                onFilterModelChange={(model) => state.FilterModel = model}
                onColumnVisibilityModelChange={(model) => state.ColumnVisibilityModel = model}
                onPageChange={(page) => state.Page = page}
                sx={{
                    m: 0.5,
                    p: 0.5,
                }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
            />}
        </Box>
    );
}

export default DenseGridDataTable;