import { TemplateUIObject, UIKeys } from "../../services/storage/storeTemplate/UI";
import { store } from "../../store";
import * as reducer from "./slice";

export const dispatchHydrateUI = (state: TemplateUIObject) => {
  store.dispatch(
    reducer.hydrate({
      value: state,
    })
  );
};

export const dispatchSetUI = (name: UIKeys, payload: any) => {
  store.dispatch(
    reducer.set({
      name,
      value: payload,
    })
  );
};

export const dispatchResetUI = (payload?: Array<string>) => {
  store.dispatch(
    reducer.reset({
      value: payload,
    })
  );
};

export const dispatchResetUIWithCache = (payload?: Array<string>) => {
  store.dispatch(
    reducer.resetWithCache({
      value: payload,
    })
  );
};

export const dispatchSaveUI = () => {
  store.dispatch(reducer.save());
};

export const dispatchUILoading = () => {
  store.dispatch(reducer.loading());
};

export const dispatchUILoaded = () => {
  store.dispatch(reducer.loaded());
};

export default reducer;
