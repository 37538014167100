import Paper from "@mui/material/Paper"
import React from "react"
import { Tables } from "../../services/databaseModels/tables"
import { ui } from "../../interfaces/interfaces"
import MemoizedDropDownQuestion from "./Inputs/DropDownQuestion"
import MemoizedRadioQuestion from "./Inputs/RadioQuestion"
import MemoizedTextFieldQuestion from "./Inputs/TextFieldQuestion"
import { FormControlLabel, Checkbox, FormGroup } from "@mui/material"
import Typography from '@mui/material/Typography';

type QuestionProps = {
    question: Tables.QuestionsOutput
    index: number
    id: number
    minRows?: number
    maxRows?: number
}

const Question: React.FC<QuestionProps> = ({ question, index, id, minRows, maxRows }) => {
    const value = ui.Answer[id][`answer${index}`]
    const otherValue = ui.Answer[id][`answerOther${index}`]
    switch (question?.questionTypeId) {
        case 1:
            return (<Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}><MemoizedRadioQuestion index={index} id={id} question={question} value={value} /></Paper >)
        case 2:
            return (<Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}><MemoizedTextFieldQuestion index={index} id={id} question={question} value={value} /></Paper>)
        case 3:
            return (<Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}><MemoizedTextFieldQuestion index={index} id={id} question={question} value={value} maxRows={maxRows} minRows={minRows ? minRows : 1} /></Paper>)
        case 4:
            return (<Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}><MemoizedRadioQuestion index={index} id={id} question={question} value={value} /></Paper>)
        case 5:
            return (<Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}><MemoizedDropDownQuestion index={index} id={id} question={question} value={value} /></Paper>)
        case 6:
            return (<Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
                // minHeight: 300
            })} variant="elevation" elevation={4}><MemoizedDropDownQuestion index={index} id={id} question={question} value={value} other={true} otherValue={otherValue} /></Paper>)
        case 7:
            console.log(question)
            const options = question.options?.split("|")
            return (<Paper sx={(theme) => ({
                flexGrow: 1,
                marginTop: theme.spacing(0),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(1),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(0),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(1),
                },
            })} variant="elevation" elevation={4}>
                {options && options?.length > 0 && <Typography variant="h6" gutterBottom>{question.question}</Typography>}
                {options && options?.length > 0 && <FormGroup dir="row">
                    {options.map((option, index) =>
                        <FormControlLabel key={index} control={<Checkbox />} label={`${option}`} />
                    )}
                </FormGroup>}
            </Paper >)
        default:
            return null
    }
}

export default Question