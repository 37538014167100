import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { downloadFile, dynamicFileSize } from "../../../../../../../assets/js/main";
import CloudDownloadSharpIcon from '@mui/icons-material/CloudDownloadSharp';
import { ui } from "../../../../../../../interfaces/interfaces";
import { TemplateUIObject } from "../../../../../../../services/storage/storeTemplate/UI";
import { logger } from "../../../../../../../services/logger";

type File = {
    data: string;
    dateAdded: string;
    fileDate: string;
    fileName: string;
    fileSize: number;
    fileType: string;
    id: number
}

interface DownloadTypeIconProps {
    title: string
    state: boolean
    action: any
}
const DownloadIcon = React.memo<DownloadTypeIconProps>(({ title, state, action }) => {
    return (
        <IconButton data-toggle="tooltip" data-placement="right" sx={{ width: "0.6em", height: "0.6em" }} title={title} aria-label="manage-ticket" size="small" color="primary" onClick={() => action()} >
            <Badge color="primary">
                {state ? <CloudDownloadSharpIcon /> : <CloudDownloadSharpIcon />}
            </Badge>
        </IconButton>
    )
})

interface AttachmentsTableProps { files: Array<File>, setMsg: (msg: string, variant: "success" | "error" | "warning" | "info", ui: TemplateUIObject) => void }

const AttachmentsTable: React.FC<AttachmentsTableProps> = ({ files, setMsg }) => {

    React.useEffect(() => { files.length > 0 && setMsg(`${files.length} Attachment(s) Loaded.`, "info", ui) }, [files, setMsg])
    return (
        < div className='table-responsive-sm' >
            <table className="table table-sm table-striped table-hover table-bordered" style={{ fontSize: 12, width: "100%" }} aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {files?.map(file => {
                        const date = new Date(file.dateAdded)
                        return (< tr key={file.id} >
                            <td>{file.fileName}</td>
                            <td>{file.fileType}</td>
                            <td>{dynamicFileSize(file.fileSize)}</td>
                            <td>{`${date.toLocaleDateString("en-GB")} ${date.toLocaleTimeString("en-GB")}`}</td>
                            <td style={{ textAlign: "center" }}><DownloadIcon title={'Download ' + file.fileName} state={false} action={() => { downloadFile(file.data, file.fileType, file.fileName); setMsg("Downloading of file " + file.fileName + " started.", "info", ui); logger.info("FILE DOWNLOAD:", "Downloading of file " + file.fileName + " started.") }} /></td>
                        </tr>)
                    })}
                </tbody>
            </table>
        </div >
    )
}

export default React.memo(AttachmentsTable)