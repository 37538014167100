import Typography from "@mui/material/Typography";
import React from "react";

type HeadingProps = {
    children: any
}

const Heading: React.FC<HeadingProps> = ({ children }) => <Typography variant="h4" fontWeight={"bolder"}>{children}</Typography>


export default React.memo(Heading);