import { DelayedWritesStore } from "../../services/storage/model";
import ObjectsOperations from "../objects/operations";
import { store } from "../../store";
import { state, ui } from "../../interfaces/interfaces";
import { cacheUtils } from "../..";
import { CacheTypes } from "../cache/interfaces";
import { sendEmail, setMsg, updateState } from "../../assets/js/main";
import { logger } from "../../services/logger";

const namespace = "MAIN SCRIPT";

export const dateTimeNow = () =>
  new Date()
    .toISOString()
    .replace(/\.[0-9]{3}/, "")
    .replace("Z", "");
export const zeroPad = (num: number, places: number) => String(num).padStart(places, "0");

export const uppercaseFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const addDaysToDate = (days: number, date: Date) => {
  date.setDate(date.getDate() + days);
  return date;
};

const delayedActionsStore: DelayedWritesStore = {};

export const debouncedAction = (f: { (): Promise<void>; (): void }, name: string, time: number | undefined) => {
  if (delayedActionsStore[name]) {
    clearTimeout(delayedActionsStore[name]);
    delayedActionsStore[name] = setTimeout(() => {
      f();
      clearTimeout(delayedActionsStore[name]);
      delete delayedActionsStore[name];
    }, time) as unknown as number;
    return;
  }
  delayedActionsStore[name] = setTimeout(() => {
    f();
    clearTimeout(delayedActionsStore[name]);
    delete delayedActionsStore[name];
  }, time) as unknown as number;
};

// recursive function to go through object and check if value is a string with date format, replace with date object
export const fixDate = (obj: any) => {
  if (typeof obj === "string" && obj.match(/^\d{4}-\d{2}-\d{2}.*$/)) {
    return new Date(obj);
  }
  if (typeof obj === "object") {
    for (const key in obj) {
      if (typeof obj[key] === "string" && obj[key].match(/^\d{4}-\d{2}-\d{2}.*$/)) {
        obj[key] = new Date(obj[key]);
      } else if (typeof obj[key] === "object") {
        fixDate(obj[key]);
      }
    }
  }
};

// exportState from state
export const exportReducerState = (storeName: string) => {
  switch (storeName) {
    case "ui":
      return store.getState().ui;
    case "cache":
      return store.getState().cache;
    case "state":
      return store.getState().state;

    default:
      return store.getState().state;
  }
};

// recursive function to go through object values and assign them to new object only if key starts with _, if value is a object, call function again
export const exportObject = (obj: any, force?: boolean) => {
  const newObj: any = {};
  const oldObj = ObjectsOperations.deepCopyObjects(obj);
  //if obj is an object
  for (const [key, value] of Object.entries(oldObj)) {
    if (key.startsWith("_") || force) {
      // if function skip
      if (typeof value === "function") {
        continue;
      }
      if (typeof value === "object" && value !== null && !Array.isArray(value) && !(value instanceof Date)) {
        newObj[key.slice(1)] = exportObject(value, true);
      } else newObj[key.slice(1)] = value;
    }
  }
  return newObj; // return new object
};

export const isDev = (): boolean => !process.env.NODE_ENV || process.env.NODE_ENV === "development";

// ----------------------------------------------------------------------------
// Helper Functions
// ----------------------------------------------------------------------------

// capitalizeFirstLetter:
// ----------------------------------------------------------------------------
// Capitalizes the first letter of the given string.
// ----------------------------------------------------------------------------
export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// capitalizeFirstLetter:
// ----------------------------------------------------------------------------
// Capitalizes the first letter of the given string.
// ----------------------------------------------------------------------------
export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// deep compare two objects
export const deepCompare = (obj1: any, obj2: any) => {
  // if both are dates
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2;
  } else if (obj1 === null && obj2 === null) {
    return true;
  } else if (obj1 !== null && obj2 === null) {
    return false;
  } else if (obj1 === null && obj2 !== null) {
    return false;
  } else if (obj1 === undefined && obj2 === undefined) {
    return true;
  } else if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  } else if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true;
  }
  for (const key in obj1) {
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }
    if (typeof obj1[key] !== typeof obj2[key]) {
      return false;
    }
    if (typeof obj1[key] === "object") {
      if (!deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

// function to delete all object properties including nested objects and arrays and functions
export const deleteObjectProperties = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      deleteObjectProperties(obj[key]);
    }
    delete obj[key];
  }
};

export const sendEmails = async (newAssignee: any) => {
  const assignee = state.ActiveTicket?.assigneeId;
  if (assignee) {
    const currentAssignee = cacheUtils.getCache<CacheTypes.Users>("Users").find((user) => user.id === assignee);
    if (currentAssignee) {
      const response = await Promise.all([
        sendEmail(
          `${newAssignee.firstName} ${newAssignee.lastName}`,
          `${newAssignee.eMail}`,
          `Ticket# ${state.ActiveTicket?.id} was reassigned to you.`,
          `
Dear ${newAssignee.firstName},<br><br>
A ticket was reassigned to you.
Please log in to "<b>Ticketing Portal</b>" and check ticket number <b>${state.ActiveTicket?.id}</b> and comply with required actions.<br>
If there is anything else you would need from a person reporting an incident, please get in touch with them via e-Mail.<br>
Please always provide ticket ID <b>${state.ActiveTicket?.id}</b> as a reference in the e-Mail subject field.<br>
<br>
Thank you.
`
        ),
        sendEmail(
          `${currentAssignee.firstName} ${currentAssignee.lastName}`,
          `${currentAssignee.eMail}`,
          `Ticket# ${state.ActiveTicket?.id} was unassigned from you..`,
          `
Dear ${currentAssignee.firstName},<br><br>
A ticket with number <b>${state.ActiveTicket?.id}</b> was reassigned to another person and is no longer requiring your attention.
<br>
Thank you.
`
        ),
      ]);
      if (response) {
        logger.info(`${namespace}::sendRequest`, `Emails sent successfully`);
        setMsg("Emails sent successfully", "success");
        if (state.ActiveTicket) state.ActiveTicket = { ...state.ActiveTicket, assigneeId: newAssignee.id };
        await updateState();
        return true;
      } else {
        logger.error(`${namespace}::sendRequest`, `Emails failed to send`);
        setMsg("Emails failed to send", "error");
        return false;
      }
    }
  }
};
