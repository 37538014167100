import Card from "@mui/material/Card"
import React from "react"
import about from '../../assets/img/about-pc.png';
import cmd from '../../assets/img/cmd-line.png';
import Text from "../shared/Typography/Text";
import Heading from "../shared/Typography/Heading";

const ComputerName: React.FC = () => (<div className="computerName" style={{ margin: 8, backgroundColor: "InfoBackground" }}>
    <Heading>1. Use a Keyboard Shortcut</Heading>
    <Text>The quickest way to find out your computer name is to press <b>Windows key + Pause/break</b>. </Text>
    <Text>You can then look next to <b>Computer name or Device name</b></Text>
    <Card sx={{ width: "fit-content" }}> <img src={about} alt="about" /></Card>
    <br />
    <Text>Your keyboard may not have a keyboards won't. If not, use another of these simple techniques.</Text>
    <br />

    <Heading>2. Use the Start Button</Heading>
    <Text><b>Right click</b> the Start button. This will open a list of choices. Click <b>System</b>, which will open a</Text>
    <Text>Settings window. Look next to <b>Device name</b> as on first screenshot above.</Text>
    <br />

    <Heading>3. Use Command Prompt</Heading>
    <Text>Press <b>Windows key + R</b> to open Run. Input <b>cmd</b> and click <b>OK</b> (or press <b>Enter.</b>) This will open </Text>
    <Text>the Command Prompt. Type <b>hostname</b> and press <b>Enter.</b> This will output your computer name</Text>
    <Text>on the next line.</Text>
    <Card sx={{ width: "fit-content" }}> <img src={cmd} alt="cmd" /></Card>

    < br />
    <Heading>4. Use Settings</Heading>
    <Text>Press <b>Windows key + I</b> to open Settings. Click <b>{"System > About"}</b> and look next to <b>Device name.</b> as on first screenshot above</Text>
</div >
)

export default React.memo(ComputerName)