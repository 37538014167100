interface Config {
  DEBUG: boolean;
  api_url: string;
  authority_url: string;
  mode: modes;
}

type modes = "development" | "production" | "training";

const mode: modes = "production";

const authorityUrls = {
  development: "https://is-development.rightio.co.uk",
  production: "https://is.rightio.co.uk",
};

const apiUrls = {
  development: "https://ticketing-api-development.rightio.co.uk",
  production: "https://ticketing-api.rightio.co.uk",
};

const config: Config = {
  DEBUG: !mode.includes("prod") ? true : false,
  api_url: apiUrls[mode],
  authority_url: authorityUrls[mode],
  mode,
};

export default config;
