import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { store } from './store';
import { createRoot } from 'react-dom/client';
import CacheUtils from './utilities/cache';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SnackbarProvider } from 'notistack';
import { prepCache } from './reducers/cacheReducer/slice';
import { resetStateReducer } from './reducers/stateReducer/operations';
import { resetUIReducer } from './reducers/uiReducer/operations';
//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const container = document.getElementById('root');
const root = createRoot(container!);

export const cacheUtils = new CacheUtils();

// initReactApp will prepare async IndexedDB for read/write, then combine reducers, create store and finally render APP in DOM
const initReactApp = async () => {
  await prepCache();
  resetUIReducer();
  resetStateReducer();

  root.render(<Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <BrowserRouter basename={baseUrl}>
        <App />
      </BrowserRouter>
    </SnackbarProvider>
  </Provider>)
}

initReactApp()

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

