import TextField from "@mui/material/TextField"
import React from "react"
import { Tables } from "../../../services/databaseModels/tables"
import useDebugInformation from "../../../hooks/useDebugInformation"
import { ui } from "../../../interfaces/interfaces"
import ComboDropDown from "../ComboDropDown"

type DropDownQuestionsProps = {
    question: Tables.QuestionsOutput
    index: number
    id: number
    value: string
    other?: boolean
    otherValue?: string
    classes?: any
}

const MemoizedDropDownQuestion: React.FC<DropDownQuestionsProps> = (({ question, index, id, value, other, otherValue, classes }) => {
    const options = question?.options ? question.options.split("|") : [""]
    other && options.push("Other")
    const debugName = "MemoizedDropDownQuestions"
    useDebugInformation(debugName, { question, index, id, value, other, otherValue, classes })
    return (
        <>
            {/* <Typography sx={{ marginBottom: 2 }}>{question.question}</Typography> */}
            <ComboDropDown
                sm={12}
                xs={12}
                name={question.question}
                options={options}
                onChange={(e: { target: { value: any; name: any; }; }, value: any) =>
                    ui.Answer = { ...ui.Answer, [id]: { ...ui.Answer[id], [`answer${index}`]: value } }
                }
                value={value}
            />
            {value === "Other" && <TextField
                id="outlined-required"
                label="Enter other value"
                value={otherValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    ui.Answer = { ...ui.Answer, [id]: { ...ui.Answer[id], [`answerOther${index}`]: event.target.value } }
                }}
                sx={{ marginTop: 2 }} />}
        </>
    )
})

export default React.memo(MemoizedDropDownQuestion)