import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../hooks/useDebugInformation';
import TicketsTableWrapper from './TicketsTableWrapper';
import { ui } from '../../../interfaces/interfaces';

const name = "TicketsManagement"


type Props = {
    parent: string
}

const TicketsManagement: React.FC<Props> = ({ parent }) => {
    const debugName = `${parent}▷[${name}]`

    React.useEffect(() => {
        return () => { ui.ManageTableData = [] }
    })

    useDebugInformation(debugName, { parent })
    return (
        <Box >
            <TicketsTableWrapper parent={debugName} />
        </Box>
    );
}

export default React.memo(TicketsManagement)
