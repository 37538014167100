import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../../hooks/useDebugInformation';
import { ui } from '../../../../../../interfaces/interfaces';
import Grid from '@mui/material/Grid';
import TextInput from '../../../../../shared/TextInput';
import { compileAnswers } from '../../../../../../assets/js/main';
import { RenderFilesTable } from '../../../../../shared/DropZone';
import { InputNames } from '../../../../../../utilities/basicTypes';
import { style } from '../../../../../../utilities/style';
import { cacheUtils } from '../../../../../..';
import { CacheTypes } from '../../../../../../utilities/cache/interfaces';

const name = "FormPanel"

type StandardRequetsProps = {
    answers: any[]
    parent: string
}

const StandardRequest: React.FC<StandardRequetsProps> = ({ answers, parent }) => {
    return (
        <>
            <Typography variant='subtitle1' marginBottom={-2} marginLeft={2}>Screening Answers:</Typography>
            {/* Screening Questions: */}
            <TextInput readOnly uncontrolledName={`${answers[0].question}`} inputName={`${answers[0].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[0].value} />
            <TextInput readOnly uncontrolledName={`${answers[1].question}`} inputName={`${answers[1].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[1].value} />
            <TextInput readOnly uncontrolledName={`BriefDescriptionOfChangeRequired`} inputName={`BriefDescriptionOfChangeRequired`} parent={parent} xs={12} sm={12} defaultValue={ui.TicketDetails} multiline={6} minRows={6} />
            {/* Screening Questions: */}
            <TextInput readOnly uncontrolledName={`${answers[2].question}`} inputName={`${answers[2].question}` as InputNames} parent={parent} xs={6} sm={6} minRows={6} multiline={6} defaultValue={answers[2].value} />
            <TextInput readOnly uncontrolledName={`${answers[3].question}`} inputName={`${answers[3].question}` as InputNames} parent={parent} xs={6} sm={6} minRows={6} multiline={6} defaultValue={answers[3].value} />
        </>)
}

const SystemRequest: React.FC<StandardRequetsProps> = ({ parent, answers }) => {
    return (<>
        <Typography variant='subtitle1' marginBottom={-2} marginLeft={2}>Screening Answers:</Typography>
        {/* Screening Questions: */}
        {answers[0] && <TextInput readOnly uncontrolledName={`${answers[0].question}`} inputName={`${answers[0].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[0].value} />}
        {answers[1] && <TextInput readOnly uncontrolledName={`${answers[1].question}`} inputName={`${answers[1].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[1].value} />}
        {answers[2] && <TextInput readOnly uncontrolledName={`${answers[2].question}`} inputName={`${answers[2].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[2].value} />}
        {/* Screening Questions: */}
        {answers[3] && <TextInput readOnly uncontrolledName={`${answers[3].question}`} inputName={`${answers[3].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[3].value} />}
        {answers[4] && <TextInput readOnly uncontrolledName={`${answers[4].question}`} inputName={`${answers[4].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[4].value} />}
        {answers[5] && <TextInput readOnly uncontrolledName={`${answers[5].question}`} inputName={`${answers[5].question}` as InputNames} parent={parent} xs={6} sm={6} defaultValue={answers[5].value} />}
        <TextInput readOnly uncontrolledName={`RequestDetails`} inputName={`RequestDetails`} parent={parent} xs={12} sm={12} defaultValue={ui.TicketDetails} multiline={6} minRows={6} />
    </>)
}

type Props = {
    parent: string
}

const SummaryPanel: React.FC<Props> = ({ parent }) => {
    const questions = cacheUtils.getCache<CacheTypes.Questions>("Questions");
    const [answers, setAnswers] = React.useState([] as { value: string; question: string; order: number; }[])

    const debugName = `${parent}▷[${name}]`

    React.useEffect(() => { if (questions.length > 0) compileAnswers(setAnswers) }, [questions])

    useDebugInformation(debugName, { questions })
    return (
        <Box id="form-panel" >
            <Paper sx={(theme) => style(theme).paper} variant="elevation" elevation={4}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    {ui.TicketCategory && <TextInput readOnly uncontrolledName='Business Function> Category' inputName="Business Function> Category" parent={debugName} xs={6} sm={6} defaultValue={`${ui.TicketCategory.name !== "Other" ? `${ui.TicketCategory.name}  > ` : `Custom Other: ${ui.OtherCategory}`}${ui.TicketCategory.name !== "Other" ? ui?.TicketSubcategory?.name : ""}`} />}
                    {answers.length > 0 && (!(ui.TicketSubcategory?.ticketTypeId === 3))
                        ? <StandardRequest answers={answers} parent={debugName} />
                        : <SystemRequest answers={answers} parent={debugName} />
                    }
                    {ui.Files && ui.Files.length > 0 && (!(ui.TicketSubcategory?.ticketTypeId === 3)) && <>
                        <Grid item xs={12} sm={12}>
                            <Typography align="center" variant="subtitle2">
                                Attachments:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}><ul style={{ paddingLeft: 0 }}><RenderFilesTable files={ui.Files} /></ul></Grid>
                    </>}
                </Grid>
            </Paper>
        </Box>
    )
}
export default React.memo(SummaryPanel)