import Paper from '@mui/material/Paper';
import React from 'react';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone'
import useDebugInformation from '../../hooks/useDebugInformation';
import { ui } from '../../interfaces/interfaces';
import { getBase64, setMsg } from '../../assets/js/main';
import { FileProps } from '../../utilities/basicTypes';
import { logger } from '../../services/logger';
import { useAppSelector } from '../../store/hooks';
import { style } from '../../utilities/style';
import { cacheUtils } from '../..';
import { CacheTypes } from '../../utilities/cache/interfaces';

const name = "DropZone"
const namespace = name

type RenderFilesTableInput = {
    files: FileProps[] | undefined
}

export const RenderFilesTable = React.memo<RenderFilesTableInput>(({ files }) => (
    <div className='table-responsive-sm'>
        <table className='table table-sm table-striped' aria-labelledby="tableLabel" style={{ fontSize: "12px" }}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                {files?.map(file => {
                    const date = new Date(file.lastModified)
                    return (< tr key={file.name} >
                        <td>{file.name}</td>
                        <td>{file.type}</td>
                        <td>{file.size}</td>
                        <td>{`${date.toLocaleDateString("en-GB")} ${date.toLocaleTimeString("en-GB")}`}</td>
                    </tr>)
                })}
            </tbody>
        </table>
    </div >
));

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


type Props = {
    parent: string
    css?: React.CSSProperties
}

const onDrop = async (acceptedFiles: any) => {
    acceptedFiles = validateFiles(acceptedFiles)
    acceptedFiles = acceptedFiles.slice(0, 3)
    const cachedFiles = acceptedFiles.map(({ path, name, size, lastModified, type }: { path: string, name: string, size: number, lastModified: number, type: string }) => ({ path, name, size, lastModified, type }))
    cacheUtils.setCache("Files", cachedFiles)
    cacheUtils.setCache("Files", [])
    acceptedFiles.forEach(async (file: File, index: number) => {
        const reader = new FileReader()
        reader.onabort = () => logger.info(namespace, 'file reading was aborted')
        reader.onerror = () => logger.info(namespace, 'file reading has failed')
        reader.onload = (e) => {
            // Do whatever you want with the file contents
        }
        getBase64(file).then((base64: any) => {
            cachedFiles[index].data = base64.split(",")[1];

        })
        cacheUtils.setCache("Files", [...cacheUtils.getCache<CacheTypes.Files>("Files"), cachedFiles[index]])
        ui.Files = cachedFiles.map(({ path, name, size, lastModified, type }: { path: string, name: string, size: number, lastModified: number, type: string }) => ({ path, name, size, lastModified, type }))
    })
}

const endsWithIncludesBlackList = (fName: string) => {
    const blackList = ["exe"]
    return blackList.some((item: string) => fName.toLowerCase().endsWith(item))

}

const validateFiles = (files: any) => {
    return files.filter((element: { path: string, name: string, size: number, lastModified: number, type: string }) => {
        if (endsWithIncludesBlackList(element.name)) {
            setMsg(`${element.name.slice((element.name.length - 3)).toLocaleUpperCase()} files are not allowed!!! ${element.name} REMOVED!!!`, "error")
            return false
        }
        if (element.size > 5 * 1024 * 1024) {
            setMsg(`File size is: ${(element.size / 1024 / 1024).toFixed(2)}MB files over 5MB are not allowed!!! ${element.name} REMOVED!!!`, "error")
            return false
        }
        return true
    });
}

const DropZone: React.FC<Props> = ({ parent, css }) => {
    const debugName = `${parent}▷[${name}]`

    const files = useAppSelector(({ ui }) => ui.Files)

    const memoProps = React.useMemo(() => ({ files }), [files])

    const { getRootProps, getInputProps, isDragActive,
        isDragAccept,
        isDragReject } = useDropzone({ onDrop });

    const dropStyle: any = React.useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isDragActive, isDragAccept, isDragReject]);

    useDebugInformation(debugName, { style: dropStyle, onDrop, memoProps })
    return (
        <Box id="form-files" sx={(theme) => style(theme).dropZoneBox} style={css}>
            <Paper sx={(theme) => style(theme).paperInnerDropZone} variant="elevation" elevation={4}>
                <div {...getRootProps({ style: dropStyle })}>
                    <input {...getInputProps()} />
                    <p style={{ marginBottom: 0 }}>Drag 'n' drop files you wish to submit, or click to select files</p>
                </div>
                <ul style={{ paddingLeft: 0 }}><RenderFilesTable files={memoProps.files} /></ul>
            </Paper>
        </Box >
    )
}
export default React.memo(DropZone)