import { Stack } from "@mui/material"
import TextField from "@mui/material/TextField"
import React from "react"
import { Button } from "reactstrap"
import { Tables } from "../../../services/databaseModels/tables"
import useDebugInformation from "../../../hooks/useDebugInformation"
import { ui } from "../../../interfaces/interfaces"

type TextFiledQuestionsProps = {
    question: Tables.QuestionsOutput
    index: number
    id: number
    value: string
    maxRows?: number
    minRows?: number
}

const MemoizedTextFieldQuestions = React.memo<TextFiledQuestionsProps>(({ question, index, id, value, maxRows, minRows }) => {
    const debugName = "MemoizedTextFieldQuestion"
    useDebugInformation(debugName, { question, index, id, value, maxRows })
    if (question.question.includes("|")) {
        const label = question.question.split("|")[0]
        const howTo = Number(question.question.split("|")[1])
        return (<>
            <Stack gap={2}>
                <TextField
                    id="outlined-required"
                    label={label}
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        ui.Answer = { [id]: { ...ui.Answer[id], [`answer${index}`]: event.target.value } }
                    }}
                    sx={{ marginTop: 0 }}
                    multiline={(maxRows || minRows) ? true : false}
                    maxRows={maxRows}
                    minRows={minRows}
                    fullWidth
                    required
                    helperText={value === "" ? `Required. Type N/A if no answer.` : ' '}
                    error={value === ""}
                    type="text"
                    inputProps={{
                        autoComplete: "off",
                    }}
                />
                <Button color="primary" type="button" onClick={() => { ui.HowToDialogName = label; ui.HowToDialogId = howTo; ui.HowToDialogOpen = true; }} >How To</Button>
            </Stack>
        </>
        )
    }
    return (
        <>
            {/* <Typography >{question.question}</Typography> */}
            <TextField
                id="outlined-required"
                label={question.question}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    ui.Answer = { [id]: { ...ui.Answer[id], [`answer${index}`]: event.target.value } }
                }}
                sx={{ marginTop: 0 }}
                multiline={(maxRows || minRows) ? true : false}
                maxRows={maxRows}
                minRows={minRows}
                required
                helperText={value === "" ? `Required. Type N/A if no answer.` : ' '}
                error={value === ""}
                fullWidth
                type="text"
                inputProps={{
                    autoComplete: "off",
                }}
            /></>
    )
}
)

export default MemoizedTextFieldQuestions