import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { TicketLocation } from "../../../../../../../utilities/basicTypes";
import LightToolTip from "../../../../../../shared/LightToolTip";

type InitialToolTipProps = {
    option: any
}

const InitialsToolTip = React.memo<InitialToolTipProps>(({ option }) =>
(<LightToolTip key={option.id} title={`${option.firstName} ${option.lastName} -> ${option.eMail}`} placement="right-end">
    <Box display={'flex'} ml={3} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'row'} >
            <Typography color={'text.primary'}>
                {option.initials}
            </Typography>
        </Box>
    </Box>
</LightToolTip>)
)

type RenderInfoTableInput = {
    infos: {
        ticketId: number
        dateCreated: Date | string
        dateUpdated: Date | string
        ticketDate: Date | string
        ticketLocation?: TicketLocation;
        updatedBy: any
        assignedTo: any
        reportedBy: any
        ticketTypeId: number
    }
}

const transformDate = (date: Date | string) => {
    if (typeof date === 'string') {
        return new Date(date).toLocaleDateString("en-GB")
    }
    return date.toLocaleDateString("en-GB")
}

const HeaderTable: React.FC<RenderInfoTableInput> = (({ infos }) => (
    < div className='table-responsive-sm' >
        <table className='table table-sm table-striped' aria-labelledby="tabelLabel" style={{ fontSize: "12px" }}>
            <thead>
                <tr>
                    <th>Ticket #:</th>
                    <th>Creation Date:</th>
                    <th>Update Date:</th>
                    {infos.ticketTypeId === 1 && <th>Incident Date:</th>}
                    {infos.ticketTypeId === 1 && <th>Incident Location:</th>}
                    <th>Updated by:</th>
                    <th>Assigned to:</th>
                    <th>Reported by:</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{infos.ticketId}</td>
                    <td>{transformDate(infos.dateCreated)}</td>
                    <td>{transformDate(infos.dateUpdated)}</td>
                    {infos.ticketTypeId === 1 && <td>{transformDate(infos.ticketDate)}</td>}
                    {infos.ticketTypeId === 1 && infos?.ticketLocation && <td>{infos.ticketLocation.name}</td>}
                    <td><InitialsToolTip option={infos.updatedBy} /></td>
                    <td><InitialsToolTip option={infos.assignedTo} /></td>
                    <td><InitialsToolTip option={infos.reportedBy} /></td>
                </tr>
            </tbody >
        </table >
    </div >
));
export default React.memo(HeaderTable)