import { AxiosError, AxiosResponse } from "axios";
import AxiosService from "../axiosService";
import config from "../../utilities/env";
import { logger } from "../logger";
import subscriptions from "../subscriptions";
import { ExtUser } from "../../components/api-authorization/AuthorizeServiceInterface";

const name = "DatabaseAxiosService";

const axiosAPIService = new AxiosService(`${config.api_url}/api/`, "TOKEN_STORE");
axiosAPIService.initStore(["access_token_API"], ["expires_at"]);

(window as any).axiosTest = axiosAPIService;

subscriptions.subscribe("AuthService", name, (event: ExtUser) => {
  logger.info(name,`received event: ${event.access_token}`);
  axiosAPIService.setAuth(event.access_token);
});

const trimAll = (obj: any) => {
  // if array, loop through and trim each object
  // if object, loop through and trim each object
  // if string, trim
  // if number, return
  // if boolean, return
  // if null, return
  // if undefined, return

  if (typeof obj === "undefined" || obj === null || typeof obj === "number") return obj;
  else if (typeof obj === "string") {
    return obj.trim();
  } else if (Array.isArray(obj)) {
    obj = obj.map((item) => trimAll(item));
  } else if (typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      obj[key] = trimAll(obj[key]);
    });
  }
  return obj;
};

axiosAPIService.addInterceptor(
  async (response: AxiosResponse) => {
    if (response.status >= 200 && response.status < 300) {
      // logger.API(name, `${response.config.method} ${response.config.url}`, {
      //   status: response.status,
      //   config: response.config,
      // });

      trimAll(response.data);

      return response;
    }
  },
  async (error: AxiosError) => {
    // logger.API(name, `${error.config.method} ${error.config.url}`, {
    //   code: error.code,
    //   config: error.config,
    // });
    // axiosAPIService.getAuth().then((token: any) => {
    //   logger.API(name, `Access token expiry:`, { expiry: axiosAPIService.logAccessTokenExpiry(token) });
    // });
    if (error?.response?.status === 401) {
      // display alert saying to refresh the page
      alert("Please refresh the page, there was an error with your session. Please inform the system administrator.");
      return;
    }
    return Promise.reject(error);
  }
);

export default axiosAPIService;
