import Axios from "axios";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosAPIService from "..";
export declare namespace Tables {
  // AnswersOutput
  export interface AnswersOutput extends AnswersInput {
    id: number;
  }
  export interface AnswersInput {
    order: number;
    questionId: number;
    ticketId: number;
    value: string;
  }
  export interface Answers {
    (): Promise<AnswersOutput[]>;
  }
  export interface AnswersById {
    (id: number): Promise<AnswersOutput>;
  }
  export interface AnswersInsert {
    (data: AnswersInput): Promise<AnswersOutput>;
  }
  export interface AnswersUpdate {
    (id: number, data: AnswersInput): Promise<void>;
  }
  export interface AnswersDelete {
    (id: number): Promise<void>;
  } // AttachmentsOutput

  export interface AttachmentsInput {
    data: string | Date | string;
    dateAdded: string | Date | string;
    fileDate: string | Date | string;
    fileName: string;
    fileSize: number;
    fileType: string;
    ticketId: number;
  }
  export interface AttachmentsOutput extends AttachmentsInput {
    id: number;
  }
  export interface Attachments {
    (): Promise<AttachmentsOutput[]>;
  }
  export interface AttachmentsById {
    (id: number): Promise<AttachmentsOutput>;
  }
  export interface AttachmentsInsert {
    (data: AttachmentsInput): Promise<AttachmentsOutput>;
  }
  export interface AttachmentsUpdate {
    (id: number, data: AttachmentsInput): Promise<void>;
  }
  export interface AttachmentsDelete {
    (id: number): Promise<void>;
  } // CategoriesOutput

  export interface CategoriesInput {
    description: string;
    name: string;
  }
  export interface CategoriesOutput extends CategoriesInput {
    id: number;
  }
  export interface Categories {
    (): Promise<CategoriesOutput[]>;
  }
  export interface CategoriesById {
    (id: number): Promise<CategoriesOutput>;
  }
  export interface CategoriesInsert {
    (data: CategoriesInput): Promise<CategoriesOutput>;
  }
  export interface CategoriesUpdate {
    (id: number, data: CategoriesInput): Promise<void>;
  }
  export interface CategoriesDelete {
    (id: number): Promise<void>;
  } // ImmediateActionsOutput

  export interface ImmediateActionsInput {
    dateUpdated?: string | Date;
    value: string;
  }
  export interface ImmediateActionsOutput extends ImmediateActionsInput {
    id: number;
  }
  export interface ImmediateActions {
    (): Promise<ImmediateActionsOutput[]>;
  }
  export interface ImmediateActionsById {
    (id: number): Promise<ImmediateActionsOutput>;
  }
  export interface ImmediateActionsInsert {
    (data: ImmediateActionsInput): Promise<ImmediateActionsOutput>;
  }
  export interface ImmediateActionsUpdate {
    (id: number, data: ImmediateActionsInput): Promise<void>;
  }
  export interface ImmediateActionsDelete {
    (id: number): Promise<void>;
  } // LongTermActionsOutput

  export interface LongTermActionsInput {
    dateUpdated?: string | Date;
    value: string;
  }
  export interface LongTermActionsOutput extends LongTermActionsInput {
    id: number;
  }
  export interface LongTermActions {
    (): Promise<LongTermActionsOutput[]>;
  }
  export interface LongTermActionsById {
    (id: number): Promise<LongTermActionsOutput>;
  }
  export interface LongTermActionsInsert {
    (data: LongTermActionsInput): Promise<LongTermActionsOutput>;
  }
  export interface LongTermActionsUpdate {
    (id: number, data: LongTermActionsInput): Promise<void>;
  }
  export interface LongTermActionsDelete {
    (id: number): Promise<void>;
  } // TicketDetailsOutput

  export interface TicketDetailsInput {
    dateUpdated?: string | Date;
    value: string;
  }
  export interface TicketDetailsOutput extends TicketDetailsInput {
    id: number;
  }
  export interface TicketDetails {
    (): Promise<TicketDetailsOutput[]>;
  }
  export interface TicketDetailsById {
    (id: number): Promise<TicketDetailsOutput>;
  }
  export interface TicketDetailsInsert {
    (data: TicketDetailsInput): Promise<TicketDetailsOutput>;
  }
  export interface TicketDetailsUpdate {
    (id: number, data: TicketDetailsInput): Promise<void>;
  }
  export interface TicketDetailsDelete {
    (id: number): Promise<void>;
  } // ProgressLogsOutput

  export interface ProgressLogsInput {
    dateUpdated?: string | Date;
    value: string;
  }
  export interface ProgressLogsOutput extends ProgressLogsInput {
    id: number;
  }
  export interface ProgressLogs {
    (): Promise<ProgressLogsOutput[]>;
  }
  export interface ProgressLogsById {
    (id: number): Promise<ProgressLogsOutput>;
  }
  export interface ProgressLogsInsert {
    (data: ProgressLogsInput): Promise<ProgressLogsOutput>;
  }
  export interface ProgressLogsUpdate {
    (id: number, data: ProgressLogsInput): Promise<void>;
  }
  export interface ProgressLogsDelete {
    (id: number): Promise<void>;
  } // TicketsOutput

  export interface TicketsInput {
    assigneeId: number;
    categoryId: number;
    dateReported?: string | Date;
    dateUpdated?: string | Date;
    immediateId: number;
    locationId: number;
    longTermId: number;
    priorityId: number;
    progressId: number;
    reporteeId: number;
    statusId: number;
    subCategoryId: number;
    ticketDatetime: string | Date;
    ticketDetailsId: number;
    updatedById: number;
  }
  export interface TicketsPatchInput {
    assigneeId?: number;
    dateUpdated?: string | Date;
    priorityId?: number;
    statusId?: number;
    updatedById?: number;
  }
  export interface TicketsOutput extends TicketsInput {
    id: number;
  }
  export interface TicketsOverallOutput {
    categoryId: number;
    closed: number;
    criticalRisk: number;
    highRisk: number;
    mediumRisk: number;
    lowRisk: number;
    name: string;
    open: number;
    onHold: number;
    inProgress: number;
    total: number;
    unsetRisk: number;
  }
  export interface Tickets {
    (): Promise<TicketsOutput[]>;
  }
  export interface TicketsById {
    (id: number): Promise<TicketsOutput>;
  }
  export interface TicketsInsert {
    (data: TicketsInput): Promise<TicketsOutput>;
  }
  export interface TicketsUpdate {
    (id: number, data: TicketsInput): Promise<void>;
  }
  export interface TicketsPatch {
    (id: number, data: TicketsPatchInput): Promise<void>;
  }
  export interface TicketsDelete {
    (id: number): Promise<void>;
  }
  export interface TicketsWithFK {
    (id: number): Promise<any>;
  }
  export interface TicketsOverall {
    (): Promise<TicketsOverallOutput[]>;
  }
  export interface TicketsIncidents {
    (): Promise<TicketsOverallOutput[]>;
  }
  export interface TicketsRequests {
    (): Promise<TicketsOverallOutput[]>;
  }
  export interface TicketsOverallByCategory {
    (id: number): Promise<TicketsOverallOutput>;
  } // IncidentsOutput

  export interface IncidentsInput {
    assigneeId: number;
    categoryId: number;
    dateReported?: string | Date;
    dateUpdated?: string | Date;
    priorityId: number;
    progressId: number;
    reporteeId: number;
    statusId: number;
    subCategoryId: number;
    ticketDetailsId: number;
    updatedById: number;
  }
  export interface IncidentsOutput extends IncidentsInput {
    id: number;
  }
  export interface Incidents {
    (): Promise<IncidentsOutput[]>;
  } // RequestsOutput

  export interface RequestsInput {
    assigneeId: number;
    categoryId: number;
    dateReported?: string | Date;
    dateUpdated?: string | Date;
    priorityId: number;
    progressId: number;
    reporteeId: number;
    statusId: number;
    subCategoryId: number;
    ticketDetailsId: number;
    updatedById: number;
  }
  export interface RequestsOutput extends RequestsInput {
    id: number;
  }
  export interface Requests {
    (): Promise<RequestsOutput[]>;
  }
  export interface RequestsById {
    (id: number): Promise<RequestsOutput>;
  }
  export interface RequestsInsert {
    (data: RequestsInput): Promise<RequestsOutput>;
  }
  export interface RequestsUpdate {
    (id: number, data: RequestsInput): Promise<void>;
  }
  export interface RequestsDelete {
    (id: number): Promise<void>;
  }
  export interface RequestsWithFK {
    (id: number): Promise<any>;
  } // LocationsOutput

  export interface LocationsInput {
    description: string;
    name: string;
  }
  export interface LocationsOutput extends LocationsInput {
    id: number;
  }
  export interface Locations {
    (): Promise<LocationsOutput[]>;
  }
  export interface LocationsById {
    (id: number): Promise<LocationsOutput>;
  }
  export interface LocationsInsert {
    (data: LocationsInput): Promise<LocationsOutput>;
  }
  export interface LocationsUpdate {
    (id: number, data: LocationsInput): Promise<void>;
  }
  export interface LocationsDelete {
    (id: number): Promise<void>;
  } // PrioritiesOutput

  export interface PrioritiesInput {
    description: string;
    name: string;
  }
  export interface PrioritiesOutput extends PrioritiesInput {
    id: number;
  }
  export interface Priorities {
    (): Promise<PrioritiesOutput[]>;
  }
  export interface PrioritiesById {
    (id: number): Promise<PrioritiesOutput>;
  }
  export interface PrioritiesInsert {
    (data: PrioritiesInput): Promise<PrioritiesOutput>;
  }
  export interface PrioritiesUpdate {
    (id: number, data: PrioritiesInput): Promise<void>;
  }
  export interface PrioritiesDelete {
    (id: number): Promise<void>;
  } // QuestionsOutput

  export interface QuestionsInput {
    dateUpdated?: string | Date;
    dependsId: number | null;
    dependsValue: string | null;
    options?: string;
    order: number;
    question: string;
    questionTypeId: number;
    subCategoryId: number;
    version: number;
  }
  export interface QuestionsOutput extends QuestionsInput {
    id: number;
  }
  export interface Questions {
    (): Promise<QuestionsOutput[]>;
  }
  export interface QuestionsById {
    (id: number): Promise<QuestionsOutput>;
  }
  export interface QuestionsInsert {
    (data: QuestionsInput): Promise<QuestionsOutput>;
  }
  export interface QuestionsUpdate {
    (id: number, data: QuestionsInput): Promise<void>;
  }
  export interface QuestionsDelete {
    (id: number): Promise<void>;
  } // QuestionsTypesOutput

  export interface QuestionsTypesInput {
    description: string;
    name: string;
  }
  export interface QuestionsTypesOutput extends QuestionsTypesInput {
    id: number;
  }
  export interface QuestionsTypes {
    (): Promise<QuestionsTypesOutput[]>;
  }
  export interface QuestionsTypesById {
    (id: number): Promise<QuestionsTypesOutput>;
  }
  export interface QuestionsTypesInsert {
    (data: QuestionsTypesInput): Promise<QuestionsTypesOutput>;
  }
  export interface QuestionsTypesUpdate {
    (id: number, data: QuestionsTypesInput): Promise<void>;
  }
  export interface QuestionsTypesDelete {
    (id: number): Promise<void>;
  } // RolesOutput

  export interface RolesInput {
    description: string;
    name: string;
  }
  export interface RolesOutput extends RolesInput {
    id: number;
  }
  export interface Roles {
    (): Promise<RolesOutput[]>;
  }
  export interface RolesById {
    (id: number): Promise<RolesOutput>;
  }
  export interface RolesInsert {
    (data: RolesInput): Promise<RolesOutput>;
  }
  export interface RolesUpdate {
    (id: number, data: RolesInput): Promise<void>;
  }
  export interface RolesDelete {
    (id: number): Promise<void>;
  } // StatusesOutput

  export interface StatusesInput {
    description: string;
    name: string;
  }
  export interface StatusesOutput extends StatusesInput {
    id: number;
  }
  export interface Statuses {
    (): Promise<StatusesOutput[]>;
  }
  export interface StatusesById {
    (id: number): Promise<StatusesOutput>;
  }
  export interface StatusesInsert {
    (data: StatusesInput): Promise<StatusesOutput>;
  }
  export interface StatusesUpdate {
    (id: number, data: StatusesInput): Promise<void>;
  }
  export interface StatusesDelete {
    (id: number): Promise<void>;
  } // SubCategoriesOutput

  export interface SubCategoriesInput {
    categoryId: number;
    description: string;
    id?: number;
    name: string;
    ownerId: number;
    secOwnerId?: number;
    ticketTypeId: number;
    version: number;
    enabled: boolean;
  }
  export interface SubCategoriesOutput extends SubCategoriesInput {
    id: number;
    questions: QuestionsOutput[];
  }
  export interface SubCategories {
    (): Promise<SubCategoriesOutput[]>;
  }
  export interface SubCategoriesById {
    (id: number): Promise<SubCategoriesOutput>;
  }
  export interface SubCategoriesInsert {
    (data: SubCategoriesInput): Promise<SubCategoriesOutput>;
  }
  export interface SubCategoriesUpdate {
    (id: number, data: SubCategoriesInput): Promise<void>;
  }
  export interface SubCategoriesDelete {
    (id: number): Promise<void>;
  }
  export interface QuestionsBySubCategoryId {
    (id: number): Promise<void>;
  } // TicketTypesOutput

  export interface TicketTypesInput {
    description: string;
    name: string;
  }
  export interface TicketTypesOutput extends TicketTypesInput {
    id: number;
  }
  export interface TicketTypes {
    (): Promise<TicketTypesOutput[]>;
  }
  export interface TicketTypesById {
    (id: number): Promise<TicketTypesOutput>;
  }
  export interface TicketTypesInsert {
    (data: TicketTypesInput): Promise<TicketTypesOutput>;
  }
  export interface TicketTypesUpdate {
    (id: number, data: TicketTypesInput): Promise<void>;
  }
  export interface TicketTypesDelete {
    (id: number): Promise<void>;
  } // UsersOutput

  export interface UsersInput {
    eMail: string;
    firstName: string;
    identityId: string;
    lastName: string;
    roleId: number;
    initials?: string;
  }
  export interface UsersOutput extends UsersInput {
    id: number;
  }
  export interface Users {
    (): Promise<UsersOutput[]>;
  }
  export interface UsersById {
    (id: number): Promise<UsersOutput>;
  }
  export interface UsersInsert {
    (data: UsersInput): Promise<UsersOutput>;
  }
  export interface UsersUpdate {
    (id: number, data: UsersInput): Promise<void>;
  }
  export interface UsersDelete {
    (id: number): Promise<void>;
  }
}
export const AnswersById: Tables.AnswersById = async (b) => {
  return await axiosAPIService.get(`Answers/${b}`);
};
export const AttachmentsById: Tables.AttachmentsById = async (b) => {
  return await axiosAPIService.get(`Attachments/${b}`);
};
export const CategoriesById: Tables.CategoriesById = async (b) => {
  return await axiosAPIService.get(`Categories/${b}`);
};
export const ImmediateActionsById: Tables.ImmediateActionsById = async (b) => {
  return await axiosAPIService.get(`ImmediateActions/${b}`);
};
export const TicketsById: Tables.TicketsById = async (b) => {
  return await axiosAPIService.get(`Tickets/${b}`);
};
export const RequestsById: Tables.RequestsById = async (b) => {
  return await axiosAPIService.get(`Requests/${b}`);
};
export const LongTermActionsById: Tables.LongTermActionsById = async (b) => {
  return await axiosAPIService.get(`LongTermActions/${b}`);
};
export const PrioritiesById: Tables.PrioritiesById = async (b) => {
  return await axiosAPIService.get(`Priorities/${b}`);
};
export const ProgressLogsById: Tables.ProgressLogsById = async (b) => {
  return await axiosAPIService.get(`ProgressLogs/${b}`);
};
export const QuestionsById: Tables.QuestionsById = async (b) => {
  return await axiosAPIService.get(`Questions/${b}`);
};
export const QuestionsTypesById: Tables.QuestionsTypesById = async (b) => {
  return await axiosAPIService.get(`QuestionsTypes/${b}`);
};
export const RolesById: Tables.RolesById = async (b) => {
  return await axiosAPIService.get(`Roles/${b}`);
};
export const StatusesById: Tables.StatusesById = async (b) => {
  return await axiosAPIService.get(`Statuses/${b}`);
};
export const SubCategoriesById: Tables.SubCategoriesById = async (b) => {
  return await axiosAPIService.get(`SubCategories/${b}`);
};
export const TicketTypesById: Tables.TicketTypesById = async (b) => {
  return await axiosAPIService.get(`TicketTypes/${b}`);
};
export const UsersById: Tables.UsersById = async (b) => {
  return await axiosAPIService.get(`Users/${b}`);
};
export const LocationsById: Tables.LocationsById = async (b) => {
  return await axiosAPIService.get(`Locations/${b}`);
};
export const TicketDetailsById: Tables.TicketDetailsById = async (b) => {
  return await axiosAPIService.get(`TicketDetails/${b}`);
};
export const AnswersDelete: Tables.AnswersDelete = async (b) => {
  return await axiosAPIService.delete(`Answers/${b}`);
};
export const AttachmentsDelete: Tables.AttachmentsDelete = async (b) => {
  return await axiosAPIService.delete(`Attachments/${b}`);
};
export const CategoriesDelete: Tables.CategoriesDelete = async (b) => {
  return await axiosAPIService.delete(`Categories/${b}`);
};
export const QuestionsBySubCategoryId: Tables.QuestionsBySubCategoryId = async (b) => {
  return await axiosAPIService.get(`Questions/BySubCategoryId/${b}`);
};
export const ImmediateActionsDelete: Tables.ImmediateActionsDelete = async (b) => {
  return await axiosAPIService.delete(`ImmediateActions/${b}`);
};
export const TicketsDelete: Tables.TicketsDelete = async (b) => {
  return await axiosAPIService.delete(`Tickets/${b}`);
};
export const RequestsDelete: Tables.RequestsDelete = async (b) => {
  return await axiosAPIService.delete(`Requests/${b}`);
};
export const LongTermActionsDelete: Tables.LongTermActionsDelete = async (b) => {
  return await axiosAPIService.delete(`LongTermActions/${b}`);
};
export const PrioritiesDelete: Tables.PrioritiesDelete = async (b) => {
  return await axiosAPIService.delete(`Priorities/${b}`);
};
export const ProgressLogsDelete: Tables.ProgressLogsDelete = async (b) => {
  return await axiosAPIService.delete(`ProgressLogs/${b}`);
};
export const QuestionsDelete: Tables.QuestionsDelete = async (b) => {
  return await axiosAPIService.delete(`Questions/${b}`);
};
export const QuestionsTypesDelete: Tables.QuestionsTypesDelete = async (b) => {
  return await axiosAPIService.delete(`QuestionsTypes/${b}`);
};
export const RolesDelete: Tables.RolesDelete = async (b) => {
  return await axiosAPIService.delete(`Roles/${b}`);
};
export const StatusesDelete: Tables.StatusesDelete = async (b) => {
  return await axiosAPIService.delete(`Statuses/${b}`);
};
export const SubCategoriesDelete: Tables.SubCategoriesDelete = async (b) => {
  return await axiosAPIService.delete(`SubCategories/${b}`);
};
export const TicketTypesDelete: Tables.TicketTypesDelete = async (b) => {
  return await axiosAPIService.delete(`TicketTypes/${b}`);
};
export const UsersDelete: Tables.UsersDelete = async (b) => {
  return await axiosAPIService.delete(`Users/${b}`);
};
export const LocationsDelete: Tables.LocationsDelete = async (b) => {
  return await axiosAPIService.delete(`Locations/${b}`);
};
export const TicketDetailsDelete: Tables.TicketDetailsDelete = async (b) => {
  return await axiosAPIService.delete(`TicketDetails/${b}`);
};
export const Answers: Tables.Answers = async () => {
  return await axiosAPIService.get("Answers");
};
export const Attachments: Tables.Attachments = async () => {
  return await axiosAPIService.get("Attachments");
};
export const Categories: Tables.Categories = async () => {
  return await axiosAPIService.get("Categories");
};
export const ImmediateActions: Tables.ImmediateActions = async () => {
  return await axiosAPIService.get("ImmediateActions");
};
export const Tickets: Tables.Tickets = async () => {
  return await axiosAPIService.get("Tickets");
};
export const Requests: Tables.Requests = async () => {
  return await axiosAPIService.get("Requests");
};
export const Incidents: Tables.Incidents = async () => {
  return await axiosAPIService.get("Incidents");
};
export const LongTermActions: Tables.LongTermActions = async () => {
  return await axiosAPIService.get("LongTermActions");
};
export const Priorities: Tables.Priorities = async () => {
  return await axiosAPIService.get("Priorities");
};
export const ProgressLogs: Tables.ProgressLogs = async () => {
  return await axiosAPIService.get("ProgressLogs");
};
export const Questions: Tables.Questions = async () => {
  return await axiosAPIService.get("Questions");
};
export const QuestionsTypes: Tables.QuestionsTypes = async () => {
  return await axiosAPIService.get("QuestionsTypes");
};
export const Roles: Tables.Roles = async () => {
  return await axiosAPIService.get("Roles");
};
export const Statuses: Tables.Statuses = async () => {
  return await axiosAPIService.get("Statuses");
};
export const SubCategories: Tables.SubCategories = async () => {
  return await axiosAPIService.get("SubCategories");
};
export const TicketTypes: Tables.TicketTypes = async () => {
  return await axiosAPIService.get("TicketTypes");
};
export const Users: Tables.Users = async () => {
  return await axiosAPIService.get("Users");
};
export const Locations: Tables.Locations = async () => {
  return await axiosAPIService.get("Locations");
};
export const TicketDetails: Tables.TicketDetails = async () => {
  return await axiosAPIService.get("TicketDetails");
};
export const AnswersUpdate: Tables.AnswersUpdate = async (a, b) => {
  return await axiosAPIService.put(`Answers/${a}`, {
    id: a,
    ...b,
  });
};
export const AttachmentsUpdate: Tables.AttachmentsUpdate = async (a, b) => {
  return await axiosAPIService.put(`Attachments/${a}`, {
    id: a,
    ...b,
  });
};
export const CategoriesUpdate: Tables.CategoriesUpdate = async (a, b) => {
  return await axiosAPIService.put(`Categories/${a}`, {
    id: a,
    ...b,
  });
};
export const ImmediateActionsUpdate: Tables.ImmediateActionsUpdate = async (a, b) => {
  return await axiosAPIService.put(`ImmediateActions/${a}`, {
    id: a,
    ...b,
  });
};
export const ImmediateActionsPatch: Tables.ImmediateActionsUpdate = async (a, b) => {
  return await axiosAPIService.patch(`ImmediateActions/${a}`, b);
};
export const TicketsUpdate: Tables.TicketsUpdate = async (a, b) => {
  return await axiosAPIService.put(`Tickets/${a}`, {
    id: a,
    ...b,
  });
};
export const TicketsPatch: Tables.TicketsPatch = async (a, b) => {
  return await axiosAPIService.patch(`Tickets/${a}`, b);
};
export const RequestsUpdate: Tables.RequestsUpdate = async (a, b) => {
  return await axiosAPIService.put(`Requests/${a}`, {
    id: a,
    ...b,
  });
};
export const LongTermActionsUpdate: Tables.LongTermActionsUpdate = async (a, b) => {
  return await axiosAPIService.put(`LongTermActions/${a}`, {
    id: a,
    ...b,
  });
};
export const LongTermActionsPatch: Tables.LongTermActionsUpdate = async (a, b) => {
  return await axiosAPIService.patch(`LongTermActions/${a}`, b);
};
export const PrioritiesUpdate: Tables.PrioritiesUpdate = async (a, b) => {
  return await axiosAPIService.put(`Priorities/${a}`, {
    id: a,
    ...b,
  });
};
export const ProgressLogsUpdate: Tables.ProgressLogsUpdate = async (a, b) => {
  return await axiosAPIService.put(`ProgressLogs/${a}`, {
    id: a,
    ...b,
  });
};
export const ProgressLogsPatch: Tables.ProgressLogsUpdate = async (a, b) => {
  return await axiosAPIService.patch(`ProgressLogs/${a}`, b);
};
export const QuestionsUpdate: Tables.QuestionsUpdate = async (a, b) => {
  return await axiosAPIService.put(`Questions/${a}`, {
    id: a,
    ...b,
  });
};
export const QuestionsTypesUpdate: Tables.QuestionsTypesUpdate = async (a, b) => {
  return await axiosAPIService.put(`QuestionsTypes/${a}`, {
    id: a,
    ...b,
  });
};
export const RolesUpdate: Tables.RolesUpdate = async (a, b) => {
  return await axiosAPIService.put(`Roles/${a}`, {
    id: a,
    ...b,
  });
};
export const StatusesUpdate: Tables.StatusesUpdate = async (a, b) => {
  return await axiosAPIService.put(`Statuses/${a}`, {
    id: a,
    ...b,
  });
};
export const SubCategoriesUpdate: Tables.SubCategoriesUpdate = async (a, b) => {
  return await axiosAPIService.put(`SubCategories/${a}`, {
    id: a,
    ...b,
  });
};
export const TicketTypesUpdate: Tables.TicketTypesUpdate = async (a, b) => {
  return await axiosAPIService.put(`TicketTypes/${a}`, {
    id: a,
    ...b,
  });
};
export const LocationsUpdate: Tables.LocationsUpdate = async (a, b) => {
  return await axiosAPIService.put(`Locations/${a}`, {
    id: a,
    ...b,
  });
};
export const TicketDetailsUpdate: Tables.TicketDetailsUpdate = async (a, b) => {
  return await axiosAPIService.put(`TicketDetails/${a}`, {
    id: a,
    ...b,
  });
};
export const TicketDetailsPatch: Tables.TicketDetailsUpdate = async (a, b) => {
  return await axiosAPIService.patch(`TicketDetails/${a}`, b);
};
export const UsersUpdate: Tables.UsersUpdate = async (a, b) => {
  return await axiosAPIService.put(`pi/Users/${a}`, {
    id: a,
    ...b,
  });
};
export const AnswersInsert: Tables.AnswersInsert = async (a) => {
  return await axiosAPIService.post("Answers", a);
};
export const AttachmentsInsert: Tables.AttachmentsInsert = async (a) => {
  return await axiosAPIService.post("Attachments", a);
};
export const CategoriesInsert: Tables.CategoriesInsert = async (a) => {
  return await axiosAPIService.post("Categories", a);
};
export const ImmediateActionsInsert: Tables.ImmediateActionsInsert = async (a) => {
  return await axiosAPIService.post("ImmediateActions", a);
};
export const LocationsInsert: Tables.LocationsInsert = async (a) => {
  return await axiosAPIService.post("Locations", a);
};
export const TicketsInsert: Tables.TicketsInsert = async (a) => {
  return await axiosAPIService.post("Tickets", a);
};
export const RequestsInsert: Tables.RequestsInsert = async (a) => {
  return await axiosAPIService.post("Requests", a);
};
export const LongTermActionsInsert: Tables.LongTermActionsInsert = async (a) => {
  return await axiosAPIService.post("LongTermActions", a);
};
export const PrioritiesInsert: Tables.PrioritiesInsert = async (a) => {
  return await axiosAPIService.post("Priorities", a);
};
export const ProgressLogsInsert: Tables.ProgressLogsInsert = async (a) => {
  return await axiosAPIService.post("ProgressLogs", a);
};
export const QuestionsInsert: Tables.QuestionsInsert = async (a) => {
  return await axiosAPIService.post("Questions", a);
};
export const QuestionsTypesInsert: Tables.QuestionsTypesInsert = async (a) => {
  return await axiosAPIService.post("QuestionsTypes", a);
};
export const RolesInsert: Tables.RolesInsert = async (a) => {
  return await axiosAPIService.post("Roles", a);
};
export const StatusesInsert: Tables.StatusesInsert = async (a) => {
  return await axiosAPIService.post("Statuses", a);
};
export const SubCategoriesInsert: Tables.SubCategoriesInsert = async (a) => {
  return await axiosAPIService.post("SubCategories", a);
};
export const TicketTypesInsert: Tables.TicketTypesInsert = async (a) => {
  return await axiosAPIService.post("TicketTypes", a);
};
export const UsersInsert: Tables.UsersInsert = async (a) => {
  return await axiosAPIService.post("Users", a);
};
export const TicketDetailsInsert: Tables.TicketDetailsInsert = async (a) => {
  return await axiosAPIService.post("TicketDetails", a);
};
export const TicketsWithFK: Tables.TicketsWithFK = async (a) => {
  return await axiosAPIService.get(`Tickets/withFK/${a}`);
};
export const TicketsOverall: Tables.TicketsOverall = async () => {
  return await axiosAPIService.get(`Tickets/Overall`);
};
export const TicketsIncidents: Tables.TicketsIncidents = async () => {
  return await axiosAPIService.get(`Tickets/Incidents`);
};
export const TicketsRequests: Tables.TicketsRequests = async () => {
  return await axiosAPIService.get(`Tickets/Requests`);
};
export const TicketsOverallByCategory: Tables.TicketsOverallByCategory = async (a) => {
  return await axiosAPIService.get(`Tickets/OverallByCategory/${a}`);
};
export const RequestsWithFK: Tables.RequestsWithFK = async (a) => {
  return await axiosAPIService.get(`Requests/withFK/${a}`);
};
export const TablesEndpoints = {
  Answers,
  AnswersById,
  AnswersDelete,
  AnswersInsert,
  AnswersUpdate,
  Attachments,
  AttachmentsById,
  AttachmentsDelete,
  AttachmentsInsert,
  AttachmentsUpdate,
  Categories,
  CategoriesById,
  CategoriesDelete,
  CategoriesInsert,
  CategoriesUpdate,
  ImmediateActions,
  ImmediateActionsById,
  ImmediateActionsDelete,
  ImmediateActionsInsert,
  ImmediateActionsPatch,
  ImmediateActionsUpdate,
  Incidents,
  Locations,
  LocationsById,
  LocationsDelete,
  LocationsInsert,
  LocationsUpdate,
  LongTermActions,
  LongTermActionsById,
  LongTermActionsDelete,
  LongTermActionsInsert,
  LongTermActionsPatch,
  LongTermActionsUpdate,
  Priorities,
  PrioritiesById,
  PrioritiesDelete,
  PrioritiesInsert,
  PrioritiesUpdate,
  ProgressLogs,
  ProgressLogsById,
  ProgressLogsDelete,
  ProgressLogsInsert,
  ProgressLogsPatch,
  ProgressLogsUpdate,
  Questions,
  QuestionsById,
  QuestionsBySubCategoryId,
  QuestionsDelete,
  QuestionsInsert,
  QuestionsTypes,
  QuestionsTypesById,
  QuestionsTypesDelete,
  QuestionsTypesInsert,
  QuestionsTypesUpdate,
  QuestionsUpdate,
  Requests,
  RequestsById,
  RequestsDelete,
  RequestsInsert,
  RequestsUpdate,
  RequestsWithFK,
  Roles,
  RolesById,
  RolesDelete,
  RolesInsert,
  RolesUpdate,
  Statuses,
  StatusesById,
  StatusesDelete,
  StatusesInsert,
  StatusesUpdate,
  SubCategories,
  SubCategoriesById,
  SubCategoriesDelete,
  SubCategoriesInsert,
  SubCategoriesUpdate,
  TicketDetails,
  TicketDetailsById,
  TicketDetailsDelete,
  TicketDetailsInsert,
  TicketDetailsPatch,
  TicketDetailsUpdate,
  Tickets,
  TicketsById,
  TicketsDelete,
  TicketsIncidents,
  TicketsInsert,
  TicketsOverall,
  TicketsOverallByCategory,
  TicketsPatch,
  TicketsRequests,
  TicketsUpdate,
  TicketsWithFK,
  TicketTypes,
  TicketTypesById,
  TicketTypesDelete,
  TicketTypesInsert,
  TicketTypesUpdate,
  Users,
  UsersById,
  UsersDelete,
  UsersInsert,
  UsersUpdate,
};
