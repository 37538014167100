import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { ui, state } from '../../interfaces/interfaces';
import { useAppSelector } from '../../store/hooks';

interface ForwardEmailDialogProps {
}

const ForwardEmailDialog: React.FC<ForwardEmailDialogProps> = () => {
    const open = useAppSelector(({ ui }) => ui.ForwardEmailDialog)
    const usersList = useAppSelector(({ ui }) => ui.ItMembers)
    const handleClose = () => {
        ui.ForwardEmailDialog = false
    };

    const handleListItemClick = (value: any) => {
        window.location.href = `mailto:${value.eMail}?subject=RE: IT Support Ticket ID#: ${state.ActiveTicket?.id} was forwarded to you and requires your attention&body=Dear ${value.firstName},`;
        ui.ForwardEmailDialog = false
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Set forwarding email</DialogTitle>
            <List sx={{ pt: 0 }}>
                {usersList.map((user: any) => (
                    <ListItem button onClick={() => handleListItemClick(user)} key={user.id}>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.eMail} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

export default React.memo(ForwardEmailDialog)