import axios, { AxiosDefaults, AxiosInstance, AxiosRequestConfig } from "axios";
import { hydrateCacheReducer } from "../../reducers/cacheReducer/operations";
import IndexedDb from "../storage/indexedDB";

const namespace = "AxiosService";

const getTimeStamp = () => {
  const stamp = new Date();
  const time = stamp.toLocaleTimeString("en-GB");
  const date = stamp.toLocaleDateString("en-GB");
  return `${date} ${time}`;
};

const logger = (scope: string, message: string, obj?: any) => {
  console.group(`${namespace} [${scope.toUpperCase()}] [${getTimeStamp()}]`);
  if (scope === "info") console.log(`${message}`);
  else if (scope === "error") console.error(`${message}`);
  else if (scope === "warn") console.warn(`${message}`);
  else if (scope === "debug") console.debug(`${message}`);
  if (obj) {
    console.log(obj);
  }
  console.groupEnd();
};

interface Token {
  access_token: string;
  refresh_token: string;
  expires_at: number;
  expires_at_date: Date;
  issued_at: number;
  issued_at_date: Date;
  token_type: string;
}

// class axios service for axios
export class AxiosService {
  private instance: AxiosInstance;
  private tokenStore: IndexedDb;
  private interval: NodeJS.Timeout;

  constructor(baseUrl: string, storeName: string) {
    this.instance = axios.create();
    this.setBaseURL(baseUrl);
    this.tokenStore = new IndexedDb(storeName);
    this.interval = setInterval(() => this.getAuth(), 1000 * 30); // check every 30 seconds
    // this.setAuth();
  }

  public async initStore(tableNames: string[], keyPath?: string[]): Promise<void> {
    await this.tokenStore.createObjectStore(tableNames, keyPath);
  }

  // set instance defaults
  public setDefaults(defaults: AxiosDefaults): void {
    this.instance.defaults = defaults;
  }

  // set instance auth
  public setAuth(accessToken?: string): void {
    if (accessToken) {
      this.instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      hydrateCacheReducer();
    }
  }

  public getRedBoldString(text: string) {
    // get red string
    return "\x1b[31;1m" + text + "\x1b[0m";
  }

  public getGreenBoldString(text: string) {
    // get green string
    return "\x1b[32;1m" + text + "\x1b[0m";
  }

  public getUnderscoredBoldString(text: string) {
    // get underscored string
    return "\x1b[4;1m" + text + "\x1b[0m";
  }

  public getBlackBoldString(text: string) {
    // get black string
    return "\x1b[30;1m" + text + "\x1b[0m";
  }

  public isAccessTokenValid(token: Token): boolean {
    if (token) {
      const expiresAt = token.expires_at;
      const now = new Date().getTime();
      return expiresAt > now;
    }
    return false;
  }

  public isAccessTokenAboutToExpire(token: Token): boolean {
    if (token) {
      const expiresAt = token.expires_at;
      const now = new Date().getTime();
      const diff = expiresAt - now;
      return diff < 1000 * 60; // 1 minute
    }
    return false;
  }

  public getHMS = (diff: number) => {
    // get hours, minutes and seconds from a time difference
    // return an object with the hours, minutes and seconds
    // use the Date object to get the hours, minutes and seconds
    // In UTC time, the time difference is the time difference between the current time and the time the access token was requested

    const date = new Date(diff);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    return {
      H: hours,
      M: minutes,
      S: seconds,
      // H: Math.floor(diff / 1000 / 3600),
      // M: Math.floor(diff / 1000 / 60),
      // S: Math.floor(diff / 1000 - (diff / 1000 / 60) * 60),
    };
  };

  public logAccessTokenExpiry = (token: Token) => {
    const expiry = token.expires_at;
    const now = new Date().getTime();
    const diff = expiry - now;
    const hms = this.getHMS(diff);
    const message = `${hms.H}h ${hms.M}m ${hms.S}s`;
    return message;
  };

  public accessTokenWillExpireIn(token: Token): number {
    const expiresAt = token.expires_at;
    const now = new Date().getTime();
    return expiresAt - now;
  }

  public print(token: Token): void {
    // print
    logger(
      "info",
      `${this.getBlackBoldString("TOKEN INFORMATION:")}
Is access token valid: ${
        this.isAccessTokenValid(token) ? this.getGreenBoldString("true") : this.getRedBoldString("false")
      }
Is access token about to expire: ${
        this.isAccessTokenAboutToExpire(token) ? this.getRedBoldString("true") : this.getGreenBoldString("false")
      }      
Access token will expire at ${this.getUnderscoredBoldString(token.expires_at_date.toString())}
Access token will expire in ${this.getUnderscoredBoldString(this.logAccessTokenExpiry(token))}`
    );
  }

  // get instance auth
  public async getAuth(): Promise<Token | void> {
    const token = await this.tokenStore.getAllValues<Token>("access_token_API");

    // get last token from the store
    const lastToken = token?.[token.length - 1];

    // if last token
    if (lastToken) {
      // print token
      this.print(lastToken);
      return lastToken;
    }
  }

  // set base url
  public setBaseURL(url: string): void {
    this.instance.defaults.baseURL = url;
  }

  public setAxios(axios: AxiosInstance): void {
    this.instance = axios;
  }

  public getAxios(): AxiosInstance {
    return this.instance;
  }

  public getAxiosInstance(): AxiosInstance {
    return this.instance;
  }

  public async get<T>(url: string, params?: any): Promise<T> {
    const { data } = await this.instance.get<T>(url, { params });
    return data;
  }

  public async post<T>(url: string, body?: any): Promise<T> {
    const { data } = await this.instance.post<T>(url, body);
    return data;
  }

  public async put<T>(url: string, body?: any): Promise<T> {
    const { data } = await this.instance.put<T>(url, body);
    return data;
  }

  public async delete(url: string): Promise<void> {
    return await this.instance.delete(url);
  }

  public async patch<T>(url: string, body?: any): Promise<T> {
    const { data } = await this.instance.patch<T>(url, body);
    return data;
  }

  public head(url: string): Promise<any> {
    return this.instance.head(url);
  }

  public options(url: string): Promise<any> {
    return this.instance.options(url);
  }

  public request(config: AxiosRequestConfig): Promise<any> {
    return this.instance.request(config);
  }

  // get headers from the request
  public getHeaders(): any {
    return this.instance.defaults.headers;
  }

  // set headers to the request
  public setHeaders(config: AxiosRequestConfig, headers: any): AxiosRequestConfig {
    return {
      ...config,
      headers,
    };
  }

  public errorHandler(error: any): Promise<any> {
    return Promise.reject(error);
  }

  // add 401 interceptor
  public add401Interceptor(): void {
    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response.status === 401) {
          // eslint-disable-next-line no-restricted-globals
          self.postMessage({ task: "SignIn", origin: "AxiosAPIService" });
          // retry the request
          return this.instance(error.config);
        }
        return this.errorHandler(error);
      }
    );
  }
  // add interceptor
  public addInterceptor(interceptorResponseCallback: any, interceptorErrorCallback: any): void {
    this.instance.interceptors.response.use(interceptorResponseCallback, interceptorErrorCallback);
  }
}

// export const axiosTest = new AxiosService("http://localhost:5000", "TOKEN_STORE");
// axiosTest.initStore(["access_token_API", "refresh_token_API"], ["id", "id"]);

// declare axios test on window

export default AxiosService;
