import React from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../hooks/useDebugInformation';
import RequestFormPanel from './FormPanel';
import { state, ui } from '../../../../interfaces/interfaces';
import { clearAnswers } from '../../../../assets/js/main';
import HowTo from '../../../shared/HowTo';

const name = "RequestsForm"

type Props = {
    parent: string
}

const RequestForm: React.FC<Props> = ({ parent }) => {
    const debugName = `${parent}▷[${name}]`

    React.useEffect(() => {
        return () => {
            clearAnswers(ui, state);
        }
    }, [])

    useDebugInformation(debugName, { ui })
    return (
        <Container maxWidth="md">
            <HowTo />
            <Box sx={(theme) => ({
                // width: 'auto',
                margin: 'auto',
                //maxWidth: 1060
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    width: 1060,
                    margin: 'auto',
                    // minWidth: 310
                },
                minWidth: 365,
            })}>
                <Box id="main-container" style={{ display: "grid", gridTemplateColumns: '1fr 5fr 1fr', justifyContent: "center", columnGap: "10px", alignContent: "center", alignItems: "start" }} >
                    <RequestFormPanel parent={debugName} />
                </Box>
            </Box >
        </Container >
    );
}

export default React.memo(RequestForm)
