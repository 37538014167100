import { SxProps, Theme } from "@mui/material"
import Button from "@mui/material/Button"
import React from "react"

type MemoizedButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>
    label: string
    sx?: SxProps<Theme>
}

const MemoizedButton: React.FC<MemoizedButtonProps> = (({ onClick, label, sx }) => <Button
    variant="contained"
    // disabled={!requestChanged}
    color="primary"
    onClick={onClick}
    sx={sx}
>{label}
</Button>)

export default MemoizedButton