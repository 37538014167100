import {
  dispatchCached,
  dispatchCaching,
  dispatchHydrateCache,
  dispatchResetCache,
  dispatchResetCacheWithCache,
  dispatchSaveCache,
} from "..";
import { TemplateCACHEObject } from "../../../services/storage/storeTemplate/Cache";
import { store } from "../../../store";
import { logger } from "../../../services/logger";
import ObjectsOperations from "../../../utilities/objects/operations";
import { api } from "../../../interfaces/interfaces";

const namespace = "CacheReducer";

export const fetchCache = async () => {
  dispatchCaching();
  const fetchPromises = [
    api.fetchSubCategories(),
    api.fetchPriorities(),
    api.fetchStatuses(),
    api.fetchRoles(),
    api.fetchTicketTypes(),
    api.fetchQuestions(),
    api.fetchQuestionsTypes(),
    api.fetchUsers(),
    api.fetchLocations(),
    api.fetchCategories(),
  ];
  await Promise.all(fetchPromises);
  logger.info(namespace, "CACHING DONE");
};

export const hydrateCacheReducer = async () => {
  console.time("Hydrating CACHE");
  await fetchCache();
  console.timeEnd("Hydrating CACHE");
  dispatchCached();
};

// resetCache to initial state
export const resetCacheReducer = (exceptions?: string[]) => {
  const exceptionsObj: any = {};
  const cache = store.getState().cache;
  if (exceptions) {
    for (const key of exceptions) {
      exceptionsObj[key] = ObjectsOperations.deepCopyObjectsAll(cache[key as keyof typeof cache]);
    }
  }
  dispatchResetCache(exceptionsObj);
  logger.debug(namespace, "Resetting Cache state");
  dispatchSaveCache();
};

// resetCache to initial state
export const resetCacheReducerWithCache = (exceptions?: string[]) => {
  const exceptionsObj: any = {};
  const cache = store.getState().cache;
  if (exceptions) {
    for (const key of exceptions) {
      exceptionsObj[key] = ObjectsOperations.deepCopyObjectsAll(cache[key as keyof typeof cache]);
    }
  }
  dispatchResetCacheWithCache(exceptionsObj);
  logger.debug(namespace, "Resetting Cache state");
  dispatchSaveCache();
};

// importCache to state
export const importCacheReducer = (cache: TemplateCACHEObject) => {
  // preserve CallMeBacksQueue
  logger.debug(namespace, "Importing Cache state", cache);
  dispatchHydrateCache(cache);
  dispatchCached();
};
