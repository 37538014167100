import Paper from '@mui/material/Paper';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../hooks/useDebugInformation';

import "./index.css"
import DenseGridDataTable from '../../../../shared/DenseGridDataTable';
import { useLocation } from 'react-router-dom';

const name = "TicketsTable"

type Props = {
    parent: string
}

const TicketsTable: React.FC<Props> = ({ parent }) => {
    const location = useLocation();
    const { pathname } = location;
    const debugName = `${parent}▷[${name}]`

    useDebugInformation(debugName, {})
    return (
        <Box id="form-panel" sx={(theme) => (
            {
                margin: theme.spacing(0),
                width: "100%",
                [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
                    margin: theme.spacing(1),
                },
                paddingLeft: 1, paddingRight: 1
            }
        )}>
            <Paper variant="elevation" elevation={4} sx={(theme) => ({
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(0),
                padding: theme.spacing(2),
                [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(0),
                    padding: theme.spacing(2),
                },
            })}>
                {pathname.includes("my-tickets")
                    ? <DenseGridDataTable viewOnly={true} />
                    : <DenseGridDataTable viewOnly={false} />
                }

            </Paper>
        </Box >
    )
}

export default React.memo(TicketsTable)