import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React from "react"
import { InputNames } from "../../../../../../../utilities/basicTypes"
import { style } from "../../../../../../../utilities/style"
import TextInput from "../../../../../../shared/TextInput"

type StandardTicketProps = {
    answers: any[]
    parent: string
}

const StandardTicket: React.FC<StandardTicketProps> = ({ parent, answers }) => {
    return (
        <Grid container spacing={2} sx={{ alignItems: "center" }} gap={1} width={"100%"} margin={0}>
            {/* Screening Questions: */}
            {answers.map((answer, index) => {
                return (<Paper key={`answer-${index}`} variant="elevation" elevation={4} sx={(theme) => ({ ...style(theme).paperDetails, flexGrow: 1 })}>
                    <TextInput readOnly styledVariant='disabled' uncontrolledName={`${answer.question}`} inputName={`${answer.question}` as InputNames} parent={parent} xs={12} sm={12} defaultValue={answer.value} />
                </Paper>)
            })}
        </Grid>)
}

export default React.memo(StandardTicket)