import React from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { ReactComponent as ExcelLogo } from '../../../../assets/img/microsoft-excel.svg';
import RotateLeftSharpIcon from '@mui/icons-material/RotateLeftSharp';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import RadioButtonCheckedSharpIcon from '@mui/icons-material/RadioButtonCheckedSharp';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import { ui } from "../../../../interfaces/interfaces";
import useDebugInformation from "../../../../hooks/useDebugInformation";
import Stack from '@mui/material/Stack';
import { useAppSelector } from "../../../../store/hooks";
import { useGridApiContext } from "@mui/x-data-grid";
import Divider from '@mui/material/Divider';
import debouncingFunctions from "../../../../services/debouncing";

const name = "Toolbar"

const Toolbar: React.FC = () => {
    const apiRef = useGridApiContext();
    const globalFilter = useAppSelector(({ ui }) => ui["GlobalFilter"])
    const openOnly = useAppSelector(({ ui }) => ui["OpenOnly"])
    const closedOnly = useAppSelector(({ ui }) => ui["ClosedOnly"])
    const myOnly = useAppSelector(({ ui }) => ui["MyOnly"])
    const debugName = `${"Table"}▷[${name}]`

    useDebugInformation(debugName, { apiRef, globalFilter, openOnly, myOnly })
    return (<>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 1 }} >
            <Grid item >
                <Paper elevation={4} >
                    <ul className="pagination mb-0" >
                        <li className="page-item"><button type="button" style={{ color: "black" }} className="page-link" onClick={() => { ui.OpenOnly = !openOnly; ui.ClosedOnly = false }}>{openOnly ? <RadioButtonCheckedSharpIcon style={{ height: "1rem", marginBottom: 1, marginRight: 2, marginTop: -1, color: "black" }} /> : <RadioButtonUncheckedSharpIcon style={{ height: "1rem", marginBottom: 1, marginRight: 2, marginTop: -1, color: "black" }} />}Open only</button></li>
                        <li className="page-item"><button type="button" style={{ color: "black" }} className="page-link" onClick={() => { ui.ClosedOnly = !closedOnly; ui.OpenOnly = false }}>{closedOnly ? <RadioButtonCheckedSharpIcon style={{ height: "1rem", marginBottom: 1, marginRight: 2, marginTop: -1, color: "black" }} /> : <RadioButtonUncheckedSharpIcon style={{ height: "1rem", marginBottom: 1, marginRight: 2, marginTop: -1, color: "black" }} />}Closed only</button></li>
                        <li className="page-item"><button type="button" style={{ color: "black", backgroundColor: myOnly ? "#6e6e6e2e" : "#fff" }} className="page-link" onClick={() => ui.MyOnly = !myOnly}>{myOnly ? <AccountCircleSharpIcon style={{ height: "1rem", marginBottom: 1, marginRight: 2, marginTop: -1, color: "black", fill: "green" }} /> : <AccountCircleSharpIcon style={{ height: "1rem", marginBottom: 1, marginRight: 2, marginTop: -1, color: "black" }} />}My only</button></li>
                        <li className="page-item"><button type="button" style={{ color: "black" }} className="page-link" onClick={() => apiRef.current?.exportDataAsCsv({
                            fields: ["id",
                                "dateReported",
                                "categoryId",
                                "subCategoryId",
                                "statusId",
                                "priorityId",
                                "updatedById",
                                "assigneeId",
                                "reporteeId",
                                "locationId"],
                        })}><ExcelLogo style={{ height: "1rem", marginBottom: 2, marginRight: 2, marginTop: -2 }} />Export to Excel</button></li>
                        <li className="page-item"><button type="button" style={{ color: "black" }} className="page-link" onClick={() => {
                            ui.OpenOnly = false
                            ui.MyOnly = false
                            ui.ClosedOnly = false
                            ui.GlobalFilter = ("")
                            apiRef.current?.setQuickFilterValues([])
                            apiRef.current?.deleteFilterItem(apiRef.current?.state.filter.filterModel.items[0])
                        }}><RotateLeftSharpIcon style={{ height: "1rem", marginBottom: 1, marginRight: 2, marginTop: -1, color: "black" }} />Reset Filters</button></li>
                    </ul>
                </Paper>
            </Grid>
            <Grid item >
                <Paper elevation={4} >
                    <Stack direction="row" sx={{ maxWidth: "80%" }} alignItems="center">
                        <label htmlFor="search" style={{ paddingInline: 12, margin: 0 }}>Search:</label>
                        <input type="text" name="search" id="search" style={{ maxHeight: "2rem", lineHeight: "2rem", margin: 2, maxWidth: 140 }} value={globalFilter} onChange={(e) => { ui.GlobalFilter = e.target.value; debouncingFunctions.addOrExecute("GlobalFilter", 300, () => { apiRef.current?.setQuickFilterValues(ui.GlobalFilter.split(" ")) }) }} />
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
        <Divider />
    </>
    )
}

export default Toolbar;