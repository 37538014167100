import Paper from "@mui/material/Paper"
import React from "react"
import { Tables } from "../../services/databaseModels/tables"
import { api, ui } from "../../interfaces/interfaces"
import MemoizedDropDownQuestion from "./Inputs/DropDownQuestion"
import MemoizedRadioQuestion from "./Inputs/RadioQuestion"
import MemoizedTextFieldQuestion from "./Inputs/TextFieldQuestion"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Tooltip, Typography } from "@mui/material"
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import { cacheUtils } from "../.."
import { CacheTypes } from "../../utilities/cache/interfaces"
import { StorageUtils } from './../../services/storage/index';

type QuestionEditorProps = {
    question: Tables.QuestionsOutput
    open: boolean
    onClose: () => void
    insert?: boolean
}

const updateQuestionInDB = async (question: Tables.QuestionsOutput) => {
    const { id, ...rest } = question
    await api.fetchQuestionsUpdate(id, rest)
    const currentQuestions = cacheUtils.getCache<CacheTypes.Questions>("Questions");
    cacheUtils.setCache("Questions", [...currentQuestions, { id, ...rest }])
    await StorageUtils.replaceValuesInStoreInIndexedDB({ database: "CACHE", tableName: "Questions", values: [...currentQuestions, { id, ...rest }] })
}

const insertQuestionInDB = async (question: Tables.QuestionsOutput) => {
    let { id, ...rest } = question
    id = (await api.fetchQuestionsInsert(rest))?.id
    const currentQuestions = cacheUtils.getCache<CacheTypes.Questions>("Questions");
    cacheUtils.setCache("Questions", [...currentQuestions, { id, ...rest }])
    await StorageUtils.indexedDbs["CACHE"]?.putValue("Questions", { id, ...rest })
}

const QuestionEditor: React.FC<QuestionEditorProps> = ({ question, open, onClose, insert }) => {
    const [questionA, setQuestion] = React.useState<QuestionEditorProps["question"]>(question)

    return (
        <Dialog
            aria-labelledby="responsive-dialog-title"
            open={open}
            onClose={onClose}>
            <DialogTitle id="responsive-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    < TextField label="Question" value={questionA.question} onChange={(e) => setQuestion({ ...questionA, question: e.target.value })} />
                    < TextField label="Options" value={questionA.options} onChange={(e) => setQuestion({ ...questionA, options: e.target.value })} />
                    < TextField label="Depends on" value={questionA.dependsId} onChange={(e) => setQuestion({ ...questionA, dependsId: parseInt(e.target.value) || 0 })} />
                    < TextField label="Depends on value" value={questionA.dependsValue} onChange={(e) => setQuestion({ ...questionA, dependsValue: e.target.value })} />
                    < TextField label="Order" value={questionA.order} onChange={(e) => setQuestion({ ...questionA, order: parseInt(e.target.value) || 0 })} />
                    < TextField label="Version" value={questionA.version} onChange={(e) => setQuestion({ ...questionA, version: parseInt(e.target.value) || 0 })} />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={cacheUtils.getCache<CacheTypes.QuestionsTypes>("QuestionsTypes")}
                        sx={{ width: 250 }}
                        getOptionLabel={(option) => option.name}
                        value={cacheUtils.getCache<CacheTypes.QuestionsTypes>("QuestionsTypes").find(x => x.id === questionA.questionTypeId)}
                        onChange={(e, value) => value && setQuestion({ ...questionA, questionTypeId: value?.id })}
                        renderInput={(params) => <TextField {...params} label="Question type" variant="outlined" />}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories")}
                        sx={{ width: 250 }}
                        getOptionLabel={(option) => option.name}
                        value={cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories").find(x => x.id === questionA.subCategoryId)}
                        onChange={(e, value) => value && setQuestion({ ...questionA, subCategoryId: value?.id })}
                        renderInput={(params) => <TextField {...params} label="Sub category" variant="outlined" />}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => insert ? insertQuestionInDB(questionA) : updateQuestionInDB(questionA)}>
                    Update
                </Button>
                <Button onClick={onClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default QuestionEditor