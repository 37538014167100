import Grid from "@mui/material/Grid";
import { Layout } from "plotly.js";
import React from "react";
import Plot from "react-plotly.js";
import { cacheUtils } from "../../../../../../..";
import { api } from '../../../../../../../interfaces/interfaces';
import { CacheTypes } from "../../../../../../../utilities/cache/interfaces";
const dayInMilSec = 86400000.0;

const startDate = new Date("2021-12-30");
const endDate = new Date();

const generateOverallReportingRateData = async (setData: any) => {
    const tickets = await api.fetchIncidents();
    const categories = cacheUtils.getCache<CacheTypes.Categories>("Categories");
    const categoriesTickets: { [key: string]: any } = {}
    const reports: { [key: string]: any } = {}
    categories?.forEach((cat: { id: number, name: string }) => { if (tickets && tickets.length > 0) { reports[cat.name] = {}; categoriesTickets[cat.name] = tickets.filter(t => t.categoryId === cat.id) } })
    let traces: { [key: string]: any } = {}
    let dataRate: any = [];
    let tempRate: any = [];


    categories?.forEach((incidentCategory: { name: string }) => {
        const catName = incidentCategory.name
        dataRate[catName as any] = {};
        dataRate[catName].x = [];
        dataRate[catName].y = [];
        categoriesTickets[catName].forEach((data: { dateReported: string; }, count: number) => {
            const day = data.dateReported//.toLocaleDateString().replaceAll("/", "-");
            if (!(day in reports[catName]))
                reports[catName][day] = 1
            else {
                reports[catName][day]++
            }
        });
        for (const day in reports[catName]) {
            const count = reports[catName][day];
            dataRate[catName].x.push(new Date(day));
            dataRate[catName].y.push(count);
        }


        // reports[catName].forEach((data: any) => {
        //     // const day = (new Date(data.dateReported)).toLocaleDateString().replaceAll("/", "-");
        //     if (!(day in reports[catName]))
        //         reports[catName][day] = 1
        //     else {
        //         reports[catName][day]++
        //     }
        //     if (dataRate[catName].x.includes(day))
        //         dataRate[catName].y.push(count);
        //     dataRate[catName].x.push(new Date(data.dateReported));
        // });
        traces[catName] = {
            type: "histogram",
            x: dataRate[catName].x,
            y: dataRate[catName].y,
            text: catName,
            name: catName,
            meta: catName,
            // texttemplate: '%{text}',
            hovertemplate: '<i>Tickets Count</i>: %{y}' +
                '<br><b>Date</b>: %{x|%d/%m/%Y}<br>' +
                '<b>%{meta}</b>',
            // custom: dataRate[catName].custom,
            histfunc: "count",
            xbins: {
                size: dayInMilSec,
                start: startDate,
                end: endDate,
            }
        };
        if (dataRate[catName].x.length > 0) {
            tempRate.push(traces[catName]);
        }
    });
    setData(tempRate);
};

const layout: Partial<Layout> = {
    title: {
        text: "Reporting rate",
        font: {
            family: "inherit",
            size: 25
        },
        xref: "paper",
        x: 0.5
    },
    // autosize: true,
    width: 1600,
    height: 400,
    paper_bgcolor: "rgb(255,255,255)",
    plot_bgcolor: "rgb(255,255,255)",
    xaxis: {
        autorange: true,
        range: [startDate, endDate],
        title: "Dates",
        type: "date",
        // dtick: 86400000.0
        // category: "date"
    },
    barmode: "stack",
    yaxis: {
        autorange: true,
        title: "Tickets count",
        // category: "linear"
    },
    yaxis2: {
        title: "yaxis2 title",
        titlefont: { color: "rgb(148, 103, 189)" },
        tickfont: { color: "rgb(148, 103, 189)" },
        overlaying: "y",
        side: "right"
    },
    updatemenus: [
        {
            x: 0.1,
            y: 1.15,
            xref: "paper",
            yref: "paper",
            yanchor: "top",
            active: 0,
            showactive: true,
            buttons: [
                {
                    args: ["xbins.size", dayInMilSec],
                    label: "Day",
                    method: "restyle"
                },
                {
                    args: ["xbins.size", dayInMilSec * 7],
                    label: "Week",
                    method: "restyle"
                },
                {
                    args: ["xbins.size", "M1"],
                    label: "Month",
                    method: "restyle"
                },
                {
                    args: ["xbins.size", "M3"],
                    label: "Quater",
                    method: "restyle"
                },
                {
                    args: ["xbins.size", "M6"],
                    label: "Half Year",
                    method: "restyle"
                },
                {
                    args: ["xbins.size", "M12"],
                    label: "Year",
                    method: "restyle"
                }
            ]
        }
    ]
};

const Chart = React.memo(() => {
    const [data, setData] = React.useState([])

    React.useEffect(() => { generateOverallReportingRateData(setData) }, [])

    return (<>{data && data.length > 0 && <Plot data={data} layout={layout} config={{ responsive: true, showSendToCloud: false }} />}
    </>)
})

const ByRateChart = React.memo(() => {
    return (
        <Grid container>
            <Grid item xs={9} sm={9}>
                <Chart />
            </Grid>
        </Grid>
    )
})

export default ByRateChart