import Grid from "@mui/material/Grid";
import { Layout } from "plotly.js";
import React from "react";
import Plot from "react-plotly.js";
import { ui } from "../../../../../../../interfaces/interfaces";
import { useAppSelector } from "../../../../../../../store/hooks";

const processData = (data: any, setValues: any, setLabels: any) => {
    setLabels(data.map((a: any) => a[0]))
    setValues(data.map((a: any) => a[1]))

}

const layout: Partial<Layout> = {
    title: {
        text: "Incidents by Category",
        font: {
            family: "inherit",
            size: 24
        },
        xref: "paper",
        x: 0.015,
        y: 0.96
    },
    // height: "auto",
    width: 550,
    autosize: false,
    margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 50,
        pad: 0
    },
    showlegend: true,
    legend: {
        x: 0,
        xanchor: "right",
        y: 0.5
    }
}


const Chart = React.memo(() => {
    const data = useAppSelector(({ ui }) => ui.OverallTableData)
    const [values, setValues] = React.useState([])
    const [labels, setLabels] = React.useState([])

    React.useEffect(() => { data && data.length > 0 && processData(data, setValues, setLabels) }, [data])
    return (<Plot
        data={[
            {
                values,
                labels,
                type: "pie",
                // textinfo: "label+percent",
                automargin: true
            },
        ]}
        layout={layout}
        config={{
            responsive: true,
            showSendToCloud: false
        }}
    />)
}
)

const ByCategoryChart = React.memo(() => {
    return (
        <Grid container>
            <Grid item xs={9} sm={9}>
                <Chart />
            </Grid>
        </Grid>
    )
})

export default ByCategoryChart