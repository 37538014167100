import CancellationTokenStore from "../tokens";
import {
  ClearAllStorage,
  ClearSTATEStorage,
  ClearStorage,
  ClearUIStorage,
  ClearVALIDStorage,
  CreateIndexedDB,
  GetIndexedDB,
  GetFromStorage,
  IndexedDBs,
  RemoveFromStorage,
  SaveToStorage,
  RemoveIndexedDB,
  ClearIndexedDBStore,
  ClearAllIndexedDBStoresInDB,
  ClearAllIndexedDBs,
  ReturnAllIndexedDBsNames,
  ReturnAllIndexedDBs,
  CreateStoreInIndexedDB,
  GetAllValuesFromStoreInIndexedDB,
  GetValueFromStoreInIndexedDB,
  ClearCACHEStorage,
  SetValueInStoreInIndexedDB,
  SetValuesInStoreInIndexedDB,
  ReplaceValuesInStoreInIndexedDB,
  Databases,
  Tables,
  DeleteValuesFromStoreInIndexedDB,
  CheckIfStoreExistsInIndexedDB,
  ReturnIndexedDBTableLength,
} from "./model/index";
import { logger } from "../logger";
import { capitalizeFirstLetter } from "../../utilities/general";
import { DelayedWrite } from "./model";
import debouncingFunctions from "../debouncing";
import IndexedDb from "./indexedDB";
/** Provides a class for manipulating browser storage
 * @remarks This class provides various static utility methods for data manipulation.
 * To use simply import AddressUtils from this file. Because this class is a static class,
 * it is not required to instantiate it. Methods use interfaces to define the input and
 * output parameters. Interfaces are defined in model/index.ts Some of the methods are async,
 * and therefore return promises. Some of the async methods are using the API, and also return promises.
 * Methods which may compute a result, sometimes modify instances of the other objects passed to them.
 * These objects are passed by reference, and are therefore modified by the method.
 * This is to modify application state.
 * @author Adam Rzymski
 * @date 28/03/2022
 * @version 1.0.0
 * @public
 * @class StorageUtils
 * @property {string} _storageType - The type of storage to use.
 * @example
 * import { StorageUtils } from "./utilities/storage/index";
 */

export class StorageUtils {
  // Public Properties
  // --------------------------------------------------------------------------
  public static cancellationTokens: CancellationTokenStore = new CancellationTokenStore();
  public static nameSpace: string = "StorageUtils";
  public static delayedWritesStore = debouncingFunctions;
  public static indexedDbs: IndexedDBs = {
    ADDRESSES: undefined,
    CACHE: undefined,
    BOOKINGS: undefined,
    LOG: undefined,
  };
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // Public methods
  // --------------------------------------------------------------------------

  /**
   * Static method to delay a write to the browser storage by a given amount of time
   * provides debouncing for writes to the browser storage to reduce the number of writes
   * @remarks Delays a write to the browser storage by a given amount of time. This
   * provides a way to batch writes to the browser storage. It is a type of debouncing,
   * which means that it will only write to the browser storage once the given amount of
   * time has passed. This is useful for reducing the number of writes to the browser
   * storage.
   * @param {{ type: string, key: string, value: string, time: number }}
   * @static
   * @memberof StorageUtils
   * @method delayedWrite
   * @example
   * // delay a write to the browser storage
   * StorageUtils.delayedWrite({
   * type: 'local',
   * key: 'test',
   * value: 'test',
   * time: 1000
   * });
   * @returns {void}
   * returns nothing
   */

  public static delayedWrite: DelayedWrite = ({ key, type, store, value, delay }) => {
    // add or replace the debounced function
    this.delayedWritesStore.addOrExecute(type + store + key, delay, () => {
      this.saveToStorage({ key, type, store, value });
    });
  };

  /**
   * Static method to save a value to the browser storage
   * @param {{ type: string, store: string, key: string, value: any }}
   * @static
   * @memberof StorageUtils
   * @method saveToStorage
   * @example
   * // save a value to the browser storage
   * StorageUtils.saveToStorage({
   * type: 'local',
   * store: 'test',
   * key: 'test',
   * value: 'test'
   * });
   * @returns {void}
   * returns nothing
   */

  public static saveToStorage: SaveToStorage = ({ type, store, key, value }) => {
    // use switch to save to the correct storage
    switch (type) {
      // save to local storage
      case "local":
        localStorage.setItem(`${store}-${key}`, JSON.stringify(value));
        break;

      // save to session storage
      case "session":
        sessionStorage.setItem(`${store}-${key}`, JSON.stringify(value));
        break;

      // save to indexedDB
      // case "indexedDB":

      // default to local storage
      default:
        localStorage.setItem(`${store}-${key}`, JSON.stringify(value));
        break;
    }
  };

  /**
   * Static method to get a value from the browser storage
   * @param {{ type: string, store: string, key: string }}
   * @static
   * @memberof StorageUtils
   * @method getFromStorage
   * @example
   * // get a value from the browser storage
   * StorageUtils.getFromStorage({
   * type: 'local',
   * store: 'test',
   * key: 'test'
   * });
   * @returns {any}
   * returns the value from the browser storage
   */

  public static getFromStorage: GetFromStorage = ({ type, store, key }) => {
    switch (type) {
      case "local":
        {
          // get data from local storage
          const data = localStorage.getItem(store + "-" + key);
          if (data) {
            // if data type is of data is string, parse it to JSON
            if (typeof data === "string" && data !== "undefined") return JSON.parse(data);
          } else {
            return undefined;
          }
        }
        break;
      case "session":
        {
          // get data from session storage
          const data = sessionStorage.getItem(store + "-" + key);
          if (data) {
            // if data type is of data is string, parse it to JSON
            if (typeof data === "string" && data !== "undefined") return JSON.parse(data);
          } else {
            return undefined;
          }
        }
        break;
      default:
        {
          // get data from local storage
          const data = localStorage.getItem(store + "-" + key);
          if (data) {
            // if data type is of data is string, parse it to JSON
            if (typeof data === "string" && data !== "undefined") return JSON.parse(data);
          } else {
            return undefined;
          }
        }
        break;
    }
  };

  /**
   * Static method to remove a value from the browser storage
   * @param {{ type: string, store: string, key: string }}
   * @static
   * @memberof StorageUtils
   * @method removeFromStorage
   * @example
   * // remove a value from the browser storage
   * StorageUtils.removeFromStorage({ type, store, key });
   */

  public static removeFromStorage: RemoveFromStorage = ({ type, store, key }) => {
    // log the remove
    logger.debug(this.nameSpace, `Removing from ${capitalizeFirstLetter(type)} Storage ${store}-${key}`);
    // use switch to remove from the correct storage
    switch (type) {
      // remove from local storage
      case "local":
        localStorage.removeItem(store + "-" + key);
        break;
      // remove from session storage
      case "session":
        sessionStorage.removeItem(store + "-" + key);
        break;

      // default to local storage
      default:
        localStorage.removeItem(store + "-" + key);
        break;
    }
  };

  /**
   * Static method to clear the browser storage
   * @param {{ type: string, store: string }}
   * @static
   * @memberof StorageUtils
   * @method clearStorage
   * @example
   * // clear the browser storage
   * StorageUtils.clearStorage({ type, store });
   * @returns {void}
   * returns nothing
   */

  public static clearStorage: ClearStorage = ({ type, store }) => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing ${capitalizeFirstLetter(type)} Storage ${store}`);
    // use switch to clear the correct storage
    switch (type) {
      // clear local storage
      case "local":
        localStorage.clear();
        break;

      // clear session storage
      case "session":
        sessionStorage.clear();
        break;

      // default to local storage
      default:
        localStorage.clear();
        break;
    }
  };

  /**
   * Static method to clear all browser storage
   * @static
   * @memberof StorageUtils
   * @method clearAllStorage
   * @example
   * // clear all browser storage
   * StorageUtils.clearAllStorage();
   * @returns {void}
   * returns nothing
   */

  public static clearAllStorage: ClearAllStorage = () => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing all storage`);

    // clear all storage
    localStorage.clear();
    sessionStorage.clear();
    this.clearAllIndexedDBs();
  };

  /**
   * Static method to clear the browser storage
   * @static
   * @memberof StorageUtils
   * @method clearUIStorage
   * @example
   * // clear the browser storage
   * StorageUtils.clearUIStorage();
   * @returns {void}
   * returns nothing
   */

  public static clearUIStorage: ClearUIStorage = () => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing UI Storage`);

    // clear UI storage by removing all items that start with "ui-"
    for (const item in localStorage) {
      if (item.startsWith("ui-")) localStorage.removeItem(item);
    }
  };

  /**
   * Static method to clear the browser storage
   * @static
   * @memberof StorageUtils
   * @method clearSTATEStorage
   * @example
   * // clear the browser storage
   * StorageUtils.clearSTATEStorage();
   * @returns {void}
   * returns nothing
   */

  public static clearSTATEStorage: ClearSTATEStorage = () => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing STATE Storage`);

    // clear STATE storage by removing all items that start with "state-"
    for (const item in localStorage) {
      if (item.startsWith("state-")) localStorage.removeItem(item);
    }
  };

  /**
   * Static method to clear the browser storage
   * @static
   * @memberof StorageUtils
   * @method clearCACHEStorage
   * @example
   * // clear the browser storage
   * StorageUtils.clearCACHEStorage();
   * @returns {void}
   * returns nothing
   */

  public static clearCACHEStorage: ClearCACHEStorage = () => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing CACHE Storage`);

    // clear CACHE storage by removing all items that start with "cache-"
    for (const item in localStorage) {
      if (item.startsWith("cache-")) localStorage.removeItem(item);
    }
  };

  /**
   * Static method to clear the browser storage
   * @static
   * @memberof StorageUtils
   * @method clearVALIDStorage
   * @example
   * // clear the browser storage
   * StorageUtils.clearVALIDStorage();
   * @returns {void}
   * returns nothing
   */

  public static clearVALIDStorage: ClearVALIDStorage = () => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing VALID Storage`);

    // clear VALID storage by removing all items that start with "valid-"
    for (const item in localStorage) {
      if (item.startsWith("valid-")) localStorage.removeItem(item);
    }
  };

  /**
   * Static method to create an IndexedDB and return the database object
   * @param {string } name
   * @static
   * @memberof StorageUtils
   * @method createIndexedDB
   * @example
   * // create an indexedDB
   * StorageUtils.createIndexedDB({ name, version });
   * @returns {void}
   * returns nothing
   */

  public static createIndexedDB: CreateIndexedDB = (name) => {
    // log the create
    logger.debug(this.nameSpace, `Creating IndexedDB ${name}`);

    // create the database
    const db = new IndexedDb(name);

    // add the database to the store
    this.indexedDbs[name] = db;

    // return the database
    return db;
  };

  /**
   * Static method to create a store in the given IndexedDB
   * @param {{ name: string, store: string, keyPath: string }}
   * @static
   * @memberof StorageUtils
   * @async
   * @method createStoreInIndexedDB
   * @example
   * // create a store in an indexedDB
   * await StorageUtils.createStoreInIndexedDB({ name, store, keyPath });
   * @returns {Promise<IndexedDb>}
   * returns indexedDB
   */

  public static createStoreInIndexedDB: CreateStoreInIndexedDB = async ({ database, tableNames, keyPath }) => {
    // check if store does not exists
    if (!this.indexedDbs[database]) {
      // log the create
      logger.debug(this.nameSpace, `Creating IndexedDB ${database}`);
      // create the database
      const db = new IndexedDb(database);
      // add the database to the store
      this.indexedDbs[database] = db;
    }
    // if store is array, create multiple stores for the same name
    if (Array.isArray(tableNames)) {
      // if keyPath exists for
      if (keyPath) {
        // check if keyPath is array
        if (Array.isArray(keyPath)) {
          // create multiple stores with the keyPath
          await this.indexedDbs[database]!.createObjectStore(tableNames, keyPath);
        } else {
          // create multiple stores with the keyPath wrapped in an array
          await this.indexedDbs[database]!.createObjectStore(tableNames, [keyPath]);
        }
      } else {
        // create multiple stores with no keyPath
        await this.indexedDbs[database]!.createObjectStore(tableNames);
      }
    } else {
      // if store is not array, create a single store wrapped in an array
      await this.indexedDbs[database]!.createObjectStore([tableNames]);
    }

    // log the create
    logger.debug(this.nameSpace, `Created store ${tableNames} in IndexedDB ${database}`);

    // return the database
    return this.indexedDbs[database];
  };

  /**
   * Static method to get an IndexedDB from the store
   * @param {string} name
   * @static
   * @memberof StorageUtils
   * @async
   * @method getIndexedDB
   * @example
   * // get an indexedDB
   * StorageUtils.getIndexedDB({ name });
   * @returns {IndexedDb}
   * returns the indexedDB
   */

  public static getIndexedDB: GetIndexedDB = (database) => {
    // get the database from the store
    return this.indexedDbs[database];
  };

  /**
   * Static method to remove an IndexedDB from the store
   * @param {string} name
   * @static
   * @memberof StorageUtils
   * @async
   * @method removeIndexedDB
   * @example
   * // remove an indexedDB
   * await StorageUtils.removeIndexedDB({ name });
   * @returns {void}
   * returns nothing
   */
  public static removeIndexedDB: RemoveIndexedDB = async (database) => {
    // log the remove
    logger.debug(this.nameSpace, `Removing IndexedDB ${database}`);

    // delete the database store
    await this.indexedDbs[database]?.deleteDB();
    // remove the database from the store
    delete this.indexedDbs[database];
  };

  /**
   * Static method to clear an IndexedDB store
   * @param {{ name: string, store: string }}
   * @static
   * @memberof StorageUtils
   * @async
   * @method clearIndexedDBStore
   * @example
   * // clear an indexedDB store
   * await StorageUtils.clearIndexedDBStore({ name, store });
   * @returns {void}
   * returns nothing
   */

  public static clearIndexedDBStore: ClearIndexedDBStore = async ({ database, tableName }) => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing IndexedDB Store ${database}-${tableName}`);

    // clear the store
    await this.indexedDbs[database]?.deleteValues(tableName);
  };

  /**
   * Clears all IndexedDB stores in DB.
   * @param database The name of the IndexedDB to clear.
   * @example
   * // clear all stores
   * await Storage.clearAllIndexedDBStoresInDB("myIndexedDB");
   * @example
   */

  public static clearAllIndexedDBStoresInDB: ClearAllIndexedDBStoresInDB = async (database) => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing all IndexedDB Stores in DB ${database}`);

    // clear all stores
    await this.indexedDbs[database]?.deleteDB();
  };

  /**
   * Static method to clear all IndexedDBs from the store
   * @static
   * @memberof StorageUtils
   * @async
   * @method clearAllIndexedDBs
   * @example
   * // clear all indexedDBs
   * await StorageUtils.clearAllIndexedDBs();
   * @returns {Promise<void>}
   * returns nothing
   */

  public static clearAllIndexedDBs: ClearAllIndexedDBs = async () => {
    // log the clear
    logger.debug(this.nameSpace, `Clearing all IndexedDBs`);

    // clear all stores
    let db: Databases;
    for (db in this.indexedDbs) {
      await this.indexedDbs[db]?.deleteDB();
    }
    // remove all databases from the store
    this.indexedDbs = {
      ADDRESSES: undefined,
      CACHE: undefined,
      BOOKINGS: undefined,
      LOG: undefined,
    };
  };

  /**
   * Static method to return all IndexedDBs names from the store
   * @static
   * @memberof StorageUtils
   * @method returnAllIndexedDBsNames
   * @example
   * // return all indexedDBs names
   * StorageUtils.returnAllIndexedDBsNames();
   * @returns {string[]}
   * returns all indexedDBs names
   */

  public static returnAllIndexedDBsNames: ReturnAllIndexedDBsNames = () => {
    // log the get
    logger.debug(this.nameSpace, `Returning all IndexedDBs names`);

    // return all names
    return Object.keys(this.indexedDbs) as Tables[];
  };

  /**
   * Static method to return length of table in IndexedDBs store
   * @static
   * @memberof StorageUtils
   * @method returnIndexedDBTableLength
   * @example
   * // return length of table in indexedDBs store
   * StorageUtils.returnIndexedDBTableLength({ name, store });
   * @returns {number}
   * returns length of table in indexedDBs store
   * @param {{ name: string, store: string }}
   */

  public static returnIndexedDBTableLength: ReturnIndexedDBTableLength = async ({ database, tableName }) => {
    // log the get
    logger.debug(this.nameSpace, `Returning length of table ${tableName} in IndexedDB ${database}`);

    const results = await this.indexedDbs[database]?.getLength(tableName);

    // return length of table
    return results ?? 0;
  };

  /**
   * Static method to return all IndexedDBs from the store
   * @static
   * @memberof StorageUtils
   * @method returnAllIndexedDBs
   * @example
   * // return all indexedDBs
   * StorageUtils.returnAllIndexedDBs();
   * @returns {IndexedDb[]}
   * returns all indexedDBs
   */

  public static returnAllIndexedDBs: ReturnAllIndexedDBs = () => {
    // log the get
    logger.debug(this.nameSpace, `Returning all IndexedDBs`);

    // return all names
    return this.indexedDbs;
  };

  /**
   * Static method to get all values from a store in an IndexedDB
   * @param {{ name: string, store: string }}
   * @static
   * @memberof StorageUtils
   * @async
   * @method getAllValuesFromStoreInIndexedDB
   * @example
   * // get all values from a store in an indexedDB
   * await StorageUtils.getAllValuesFromStoreInIndexedDB({ name, store });
   * @returns {Promise<any[]>}
   * returns all values from a store in an indexedDB
   */

  public static getAllValuesFromStoreInIndexedDB: GetAllValuesFromStoreInIndexedDB = async <T>({
    database,
    tableName,
  }: {
    database: Databases;
    tableName: Tables;
  }) => {
    // log the get
    logger.debug(this.nameSpace, `Getting all values from store ${tableName} in IndexedDB ${database}`);

    // get all values from the store
    if (this.indexedDbs[database]) {
      const values = await this.indexedDbs[database]?.getAllValues<T>(tableName);
      // return the values
      return values;
    }
    // return nothing
    return [];
  };

  /**
   * Static method to get a value from a store in an IndexedDB
   * @param {{ name: string, store: string, key: string }}
   * @static
   * @memberof StorageUtils
   * @async
   * @method getValueFromStoreInIndexedDB
   * @example
   * // get a value from a store in an indexedDB
   * await StorageUtils.getValueFromStoreInIndexedDB({ name, store, key });
   * @returns {Promise<any>}
   * returns a value from a store in an indexedDB
   */

  public static getValueFromStoreInIndexedDB: GetValueFromStoreInIndexedDB = async <T>({
    database,
    tableName,
    key,
  }: {
    database: Databases;
    tableName: Tables;
    key: string | number;
  }) => {
    // log the get
    logger.debug(this.nameSpace, `Getting value from store ${tableName} in IndexedDB ${database}`);

    // get the value from the store
    const value = await this.indexedDbs[database]?.getValue<T>(tableName, key);

    // return the value
    return value;
  };

  /**
   * Static method to set a value in a store in an IndexedDB
   * @param {{ name: string, store: string, value: any }}
   * @static
   * @memberof StorageUtils
   * @async
   * @method setValueInStoreInIndexedDB
   * @example
   * // set a value in a store in an indexedDB
   * await StorageUtils.setValueInStoreInIndexedDB({ name, store, key, value });
   * @returns {Promise<void>}
   * returns nothing
   */

  public static setValueInStoreInIndexedDB: SetValueInStoreInIndexedDB = async ({ database, tableName, value }) => {
    // log the set
    logger.debug(this.nameSpace, `Setting value in store ${tableName} in IndexedDB ${database}`);

    // set the value in the store
    await this.indexedDbs[database]?.putValue(tableName, value);
  };

  /**
   * Static method to set values in a store in an IndexedDB
   * @param {{ name: string, store: string, values: any[] }}
   * @static
   * @memberof StorageUtils
   * @async
   * @method setValuesInStoreInIndexedDB
   * @example
   * // set values in a store in an indexedDB
   * await StorageUtils.setValuesInStoreInIndexedDB({ name, store, values });
   * @returns {Promise<void>}
   * returns nothing
   */

  public static setValuesInStoreInIndexedDB: SetValuesInStoreInIndexedDB = async ({ database, tableName, values }) => {
    // log the set
    logger.debug(this.nameSpace, `Setting values in store ${tableName} in IndexedDB ${database}`);

    // set the values in the store
    await this.indexedDbs[database]?.putBulkValues(tableName, values);
  };

  /**
   * Static method to replace all values in a store in an IndexedDB
   * @param {{ name: string, store: string, values: any[] }}
   * @static
   * @memberof StorageUtils
   * @async
   * @method replaceValuesInStoreInIndexedDB
   * @example
   * // replace all values in a store in an indexedDB
   * await StorageUtils.replaceValuesInStoreInIndexedDB({ name, store, values });
   * @returns {Promise<void>}
   * returns nothing
   */

  public static replaceValuesInStoreInIndexedDB: ReplaceValuesInStoreInIndexedDB = async ({
    database,
    tableName,
    values,
  }) => {
    // log the set
    logger.debug(this.nameSpace, `Replacing values in store ${tableName} in IndexedDB ${database}`);

    // replace the values in the store
    await this.indexedDbs[database]?.replaceBulkValues(tableName, values);
  };

  /**
   * Static method to delete values from a store in an IndexedDB
   * @param {{ name: string, store: string}}
   * @static
   * @memberof StorageUtils
   * @async
   * @method deleteValuesFromStoreInIndexedDB
   * @example
   * // delete values from a store in an indexedDB
   * await StorageUtils.deleteValuesFromStoreInIndexedDB({ name, store });
   * @returns {Promise<void>}
   * returns nothing
   */

  public static deleteValuesFromStoreInIndexedDB: DeleteValuesFromStoreInIndexedDB = async ({
    database,
    tableName,
  }) => {
    // log the delete
    logger.debug(this.nameSpace, `Deleting values from store ${tableName} in IndexedDB ${database}`);

    // delete the values from the store
    await this.indexedDbs[database]?.deleteValues(tableName);
  };

  /**
   * Static method to check if store with name exists in IndexedDB
   * @param {{ name: string, store: string}}
   * @static
   * @memberof StorageUtils
   * @async
   * @method checkIfStoreExistsInIndexedDB
   * @example
   * // check if store with name exists in IndexedDB
   * await StorageUtils.checkIfStoreExistsInIndexedDB({ name, store });
   * @returns {Promise<boolean>}
   * returns true if store exists, false if not
   * @throws {Error}
   * throws an error if the store does not exist
   */

  public static checkIfStoreExistsInIndexedDB: CheckIfStoreExistsInIndexedDB = async ({ database, tableName }) => {
    // log the check
    logger.debug(this.nameSpace, `Checking if store ${tableName} exists in IndexedDB ${database}`);

    // check if the store exists
    const exists = await this.indexedDbs[database]?.checkStoreExists(tableName);

    // return the result
    return exists ?? false;
  };
}

declare global {
  interface Window {
    StorageUtils: StorageUtils;
  }
}

window.StorageUtils = StorageUtils;

export default StorageUtils;
