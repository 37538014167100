import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React from "react"
import { hasTicketStateChanged } from "../../../../../../../assets/js/main"
import { state, ui } from "../../../../../../../interfaces/interfaces"
import { InputNames } from "../../../../../../../utilities/basicTypes"
import { style } from "../../../../../../../utilities/style"
import TextInput from "../../../../../../shared/TextInput"

type StandardRequestProps = {
    viewOnly: boolean
    parent: string
    answers: any[]
}

const StandardRequest: React.FC<StandardRequestProps> = ({ answers, parent, viewOnly }) => {
    return (<>
        <Grid container spacing={2} sx={{ alignItems: "center" }} gap={1} width={"100%"} margin={0}>
            {/* Screening Questions: */}
            <Paper variant="elevation" elevation={4} sx={(theme) => ({ ...style(theme).paperDetails, flexGrow: 1 })}>
                <TextInput readOnly styledVariant='disabled' uncontrolledName={`${answers[0].question}`} inputName={(`${answers[0].question}`) as InputNames} parent={parent} xs={12} sm={12} defaultValue={answers[0].value} />
            </Paper>
            <Paper variant="elevation" elevation={4} sx={(theme) => ({ ...style(theme).paperDetails, flexGrow: 1 })}>
                <TextInput readOnly styledVariant='disabled' uncontrolledName={`${answers[1].question}`} inputName={(`${answers[1].question}`) as InputNames} parent={parent} xs={12} sm={12} defaultValue={answers[1].value} />
            </Paper>
        </Grid>
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                <TextInput readOnly styledVariant='disabled' uncontrolledName={`BriefDescriptionOfChangeRequired`} inputName={`BriefDescriptionOfChangeRequired`} parent={parent} xs={12} sm={12} defaultValue={ui.TicketDetails} multiline={6} minRows={1} />
            </Grid>
        </Paper>
        <Grid container spacing={2} sx={{ alignItems: "center" }} gap={1} width={"100%"} margin={0}>
            {/* Screening Questions: */}
            <Paper variant="elevation" elevation={4} sx={(theme) => ({ ...style(theme).paperDetails, flexGrow: 1 })}>
                <TextInput readOnly styledVariant='disabled' uncontrolledName={`${answers[2].question}`} inputName={`${answers[2].question}` as InputNames} parent={parent} xs={12} sm={12} minRows={1} multiline={6} defaultValue={answers[2].value} />
            </Paper>
            <Paper variant="elevation" elevation={4} sx={(theme) => ({ ...style(theme).paperDetails, flexGrow: 1 })}>
                <TextInput readOnly styledVariant='disabled' uncontrolledName={`${answers[3].question}`} inputName={`${answers[3].question}` as InputNames} parent={parent} xs={12} sm={12} minRows={1} multiline={6} defaultValue={answers[3].value} />
            </Paper>
        </Grid >
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                {viewOnly
                    ? <TextInput readOnly styledVariant='disabled' uncontrolledName="TicketProgress" defaultValue={ui.TicketProgress} inputName="TicketProgress" parent={parent} xs={12} sm={12} type="text" multiline={3} minRows={3} />
                    : <TextInput styledVariant='required' color="success" onChange={(e: { target: { value: string; }; }) => { ui[`TicketProgress`] = e.target.value; hasTicketStateChanged() }} onDoubleClick={() => { ui.ScrollableDialogControlName = "TicketProgress"; ui.ScrollableDialogOpen = true }} inputName="TicketProgress" parent={parent} xs={12} sm={12} type="text" multiline={3} minRows={3} />}
            </Grid>
        </Paper>
    </>);
}
export default React.memo(StandardRequest)