import { state, ui } from "../../interfaces/interfaces";

export const stateProcessor = async (action: { payload: { name: string; value: any } }, next: any) => {
  switch (action.payload.name) {
    case "ActiveTicket":
      ui.Loading = false;
      return next(action);

    case "LoggedUser":
      if (action.payload.value) {
        const credentials = JSON.parse(action.payload.value.profile.ticketing_credentials);
        const role = JSON.parse(action.payload.value.profile.ticketing_role);
        const { create, manage, reopen, authorise, dashboard, seed } = credentials;

        const { id, name, description } = role;

        state.Credentials = {
          create,
          manage,
          reopen,
          authorise,
          dashboard,
          seed,
        };

        state.Role = {
          id,
          name,
          description,
        };

      } else {
        state.Credentials = undefined;
        state.Role = undefined;
      }

      return next(action);

    default:
      return next(action);
  }
};
