import { Middleware } from "@reduxjs/toolkit";
import uiReducer from "../reducers/uiReducer";
import stateReducer from "../reducers/stateReducer";
import apiReducer from "../reducers/apiReducer";
import { TemplateCACHEObject, TemplateSTATEObject, TemplateUIObject } from "../services/storage/storeTemplate";
import { stateProcessor } from "./stateProcessor";
import { uiProcessor } from "./uiProcessor";
import { apiProcessor } from "./apiProcessor";

export interface PostProcess {
  (action: any, next: any): any;
}

const { actions: uiActions } = uiReducer;
const { actions: stateActions } = stateReducer;
const { actions: apiActions } = apiReducer;

const postProcess: PostProcess = (action, next) => {
  switch (action.type) {
    case apiActions.set.type:
      apiProcessor(action, next);
      break;

    case uiActions.set.type:
      uiProcessor(action, next);
      break;

    case stateActions.set.type:
      stateProcessor(action, next);
      break;

    default:
      return next(action);
  }
};

export interface MiddlewarePostProcessor {
  ({
    getState,
  }: {
    getState: () => {
      ui: TemplateUIObject;
      cache: TemplateCACHEObject;
      state: TemplateSTATEObject;
    };
  }): (next: any) => (action: any) => Promise<void>;
}

const middlewarePostProcessor: Middleware = () => (next) => async (action) => {
  postProcess(action, next);
};

export default middlewarePostProcessor;
