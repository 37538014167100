import React from "react";
import { useAppSelector } from "../../store/hooks";
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants";
// import { Navigate } from 'react-router-dom';

const link = document.createElement("a");
const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;

interface AuthorizeRouteProps {
  path: string;
  component: any;
}

const AuthorizeRoute: React.FC<AuthorizeRouteProps> = ({ path, component }) => {
  const isAuthenticated = useAppSelector(({ state }) => state.IsAuthenticated);

  if (!isAuthenticated) {
    return <h1> You are not authorized to view this page. </h1>;
  }

  return component
};

export default React.memo(AuthorizeRoute);
