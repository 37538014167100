import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';

const name = "ScreeningDialog"

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    open: boolean
    onClose: () => void
}

const ScreeningDialog: React.FC<Props> = ({ open, onClose }) => {
    const [no, setNo] = React.useState(false);

    const navigate = useNavigate();

    const handleNo = () => {
        setNo(!no);
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Is this a critical issue affecting in-day business as usual?"}</DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-slide-description">
                        {no ? "Please raise as a Request" : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {no && <Button variant='contained' color='secondary' onClick={() => { onClose(); navigate("../request-form") }}>Raise Request</Button>}
                    {!no && <Button color='secondary' variant='contained' onClick={handleNo}>No</Button>}
                    {!no && <Button variant='contained' onClick={onClose}>Yes</Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}

ScreeningDialog.displayName = name

export default React.memo(ScreeningDialog)