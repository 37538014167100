import React from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../hooks/useDebugInformation';
import IncidentsFormPanel from './FormPanel';
import { ui } from '../../../../interfaces/interfaces';
import HowTo from '../../../shared/HowTo';
import ScreeningDialog from './ScreeningDialog';

const name = "IncidentsForm"

type Props = {
    parent: string
}

const IncidentForm: React.FC<Props> = ({ parent }) => {
    const debugName = `${parent}▷[${name}]`
    const [open, setOpen] = React.useState(true);

    const onClose = () => {
        setOpen(false);
    }

    useDebugInformation(debugName, { ui })
    return (
        <Container maxWidth="md">
            <HowTo />
            <ScreeningDialog open={open} onClose={onClose} />
            {/* display form only when all caching is done */}
            <Box id="main-container" style={{ display: "grid", gridTemplateColumns: '1fr 5fr 1fr', justifyContent: "center", columnGap: "10px", alignContent: "center", alignItems: "start" }} sx={(theme) => ({
                layout: {
                    // width: 'auto',
                    margin: 'auto',
                    //maxWidth: 1060
                    [theme.breakpoints.up(600 + Number(theme.spacing(2)) * 2)]: {
                        width: 1060,
                        margin: 'auto',
                        // minWidth: 310
                    },
                    minWidth: 365,
                },
            })}>
                <IncidentsFormPanel parent={debugName} />
            </Box>
        </Container >
    );
}

export default React.memo(IncidentForm)
