import Button from "@mui/material/Button"
import Grid, { GridSize } from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React from "react"
import { Link } from "react-router-dom"
import { style } from "../../../../../utilities/style"
import "./index.css"

type NavigationButtonProps = {
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined
    className?: string | undefined
    pathName?: string
    elevation?: number
    xs?: boolean | GridSize | undefined
    sm?: boolean | GridSize | undefined
    variant?: "text" | "contained" | "outlined" | undefined
    label: string
}

const NavigationButton = React.memo<NavigationButtonProps>(({ color, className, pathName, elevation, variant, xs, sm, label }) =>
    <Grid item xs={xs} sm={sm}>
        <Paper sx={(theme) => style(theme).btnHomeMenu} elevation={elevation}>
            <Link to={{ pathname: pathName, }} style={{ width: "100%", marginInline: "0.5rem" }}>
                <Button color={color} type="button" variant={variant} fullWidth style={{ whiteSpace: "nowrap" }}>{label}</Button>
            </Link>
        </Paper>
    </Grid>
)

export default NavigationButton