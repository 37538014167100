import React from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../hooks/useDebugInformation';
import TicketPanel from './TicketPanel';
import { ui, state } from '../../../../interfaces/interfaces';
import { clearAnswers } from '../../../../assets/js/main';
import { style } from '../../../../utilities/style';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';

const name = "TicketManagement"

const needReset = async (id: number, setGo: any) => {
    // if (ui.TicketId > 0 && ui.TicketId !== id) {
    await clearAnswers(ui, state)
    ui.TicketId = id
    setGo(true)
    // }
}

type Props = {
    parent: string
}

const viewOnly = (path: string) => ui.ViewOnly = path.includes("view-ticket")

const TicketManagement: React.FC<Props> = ({ parent }) => {
    const location = useLocation()
    const ticketId = useAppSelector(({ ui }) => ui.TicketId)
    const { pathname } = location
    // replace with regex to get the id
    const ticket = pathname.replace(/^.*\/(\d+)$/, '$1')

    if (ticket && parseInt(ticket) > 0) {
        ui.TicketId = parseInt(ticket)
    }

    const [go, setGo] = React.useState(false)

    React.useEffect(() => {
        viewOnly(pathname)
    }, [pathname])

    React.useEffect(() => {
        ticketId > 0 && !go && needReset(Number(ticketId), setGo)
    }, [go, ticketId])

    const debugName = `${parent}▷[${name}]`

    useDebugInformation(debugName, {})
    return (
        <Container maxWidth="lg">
            <Box sx={(theme) => style(theme).ticketLayout}>
                <Box id="main-container" style={{ display: "grid", justifyContent: "center", columnGap: "10px", alignContent: "center", alignItems: "start" }} >
                    {go && <TicketPanel parent={debugName} />}
                </Box>
            </Box >
        </Container >
    );
}

export default React.memo(TicketManagement)
