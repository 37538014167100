import Grid, { GridSize } from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import React from "react"
import { camelToWords } from "../../../assets/js/main"
import useDebugInformation from "../../../hooks/useDebugInformation"
import { ui } from "../../../interfaces/interfaces"
import { UIKeys } from "../../../services/storage/storeTemplate"
import { useAppSelector } from "../../../store/hooks"

type TextFiledQuestionsProps = {
    parent: string
    inputName: string
    xs: boolean | GridSize | undefined
    sm: boolean | GridSize | undefined
    type?: string
    multiline?: boolean
    step?: number
}

type A11yProps = {
    (name: string): {
        id: string
        name: string
        fullWidth: boolean
        autoComplete: string
        "aria-controls": string
    }
}

const a11yProps: A11yProps = (name) => ({
    id: `input-${name.toLowerCase()}`,
    name: `${name}`,
    fullWidth: true,
    autoComplete: 'off',
    "aria-controls": `action-input-${name.toLowerCase()}`,
});

const MemoizedTextFieldQuestionsMini = React.memo<TextFiledQuestionsProps>(({ parent, inputName, xs, sm, type, multiline, step }) => {
    const monitored = useAppSelector(({ ui }) => ui[`${inputName}` as UIKeys])
    const inputProps = {
        step
    }
    useDebugInformation(`${parent}▷[${inputName}]`, { parent, inputName, xs, sm, type, multiline, step, monitored })
    return (<Grid item xs={xs} sm={sm}>
        <TextField value={monitored}
            onChange={(e) => ui[`${inputName}` as UIKeys] = e.target.value as never}
            required {...a11yProps(inputName)}
            label={camelToWords(inputName).replace("Ticket", "Incident")}
            type={type}
            error={monitored === ""}
            helperText={monitored === "" ? "Required" : ""}
            InputLabelProps={(type === "date" || type === "number" || type === "time") ? { shrink: true, } : {}}
            multiline={multiline ? multiline : false}
            inputProps={inputProps}
        /> </Grid>)
}
)

export default MemoizedTextFieldQuestionsMini