import React from 'react';
import Paper from "@mui/material/Paper"
import Grid from '@mui/material/Grid';
import NavigationButtonsGroup from './NavigationButtonsGroup';
import useDebugInformation from '../../../hooks/useDebugInformation';
import { ReactComponent as RightioLogo } from "../../../assets/img/rightio-logo-(r).svg";
import "./index.css"
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { Card } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import { style } from '../../../utilities/style';

const namespace = "HomePage"

const AuthenticatedHomeView: React.FC = React.memo(() => {
    const loggedUser = useAppSelector(({ state }) => state.LoggedUser)
    const cached = useAppSelector(({ cache }) => cache.Cached)
    const sm = useAppSelector(({ ui }) => ui.sm)
    const md = useAppSelector(({ ui }) => ui.md)

    useDebugInformation("AuthenticatedHomeView", {})
    return (
        <Grid wrap="nowrap" container justifyContent="center" alignItems="flex-start" sx={(theme) => style(theme).gridHome} >
            {loggedUser && <>
                <Grid item xs={12} sm={3} order={{ xs: 2, sm: 1 }}>
                    <Paper sx={{ m: { xs: 1, md: 1 }, p: { xs: 1, md: 1 } }} elevation={4}>
                        {loggedUser && loggedUser.profile && cached
                            ? <NavigationButtonsGroup />
                            : <Skeleton height={285} variant='rectangular' />
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={9} order={{ xs: 1, sm: 2 }}>
                    <Card>
                        <Paper elevation={4} sx={{ whiteSpace: "nowrap", m: { xs: 1, md: 1 }, p: { xs: 1, md: 1 } }} >
                            <Typography sx={{ fontWeight: "bolder" }} variant={sm ? "h5" : 'subtitle1'}>Ticketing portal for incidents and support requests</Typography>
                        </Paper>
                        <Typography sx={{ m: 3 }} variant='subtitle1'>
                            Welcome to IT's Department support ticketing system. We are working hard to provide exemplary support to our colleagues at Rightio and resolve issues that can affect our customers.
                        </Typography>
                        <Typography sx={{ m: 3 }} variant='subtitle1'>
                            In return, we ask that you provide accurate and detailed information when submitting your tickets. Adhering to the above will ensure that we can provide higher quality of service and swift timely processing.
                        </Typography>
                        <Typography sx={{ m: 3 }} variant='subtitle1'>
                            Please use buttons located on the panel to the left to navigate to most commonly used options.
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3} order={{ xs: 3 }}>
                    {md && <Paper sx={{ m: { xs: 1, md: 1 }, p: { xs: 1, md: 1 } }} elevation={4}>
                        <RightioLogo />
                    </Paper>}
                </Grid>
            </>}
        </Grid >
    );
})

const AnonymousHomeView: React.FC<{}> = React.memo(() => {

    useDebugInformation("AnonymousHomeView", {})
    return (
        <Grid sx={(theme) => style(theme).gridHome} wrap="nowrap" container justifyContent="center" alignItems="center">
            <Grid container spacing={1} style={{ flexBasis: "100%" }}>
                <Grid item>
                    <Card sx={{ maxHeight: 250 }}>
                        <Paper sx={(theme) => style(theme).paperChart} elevation={4}>
                            <Typography sx={{ fontWeight: "bolder" }} variant={"h5"}>Ticketing portal for incidents and support requests</Typography>
                        </Paper>
                        <Typography sx={{ m: 3 }} variant='subtitle1'>
                            Please <span style={{ fontStyle: "oblique", fontWeight: "bolder" }}>log in</span> to access the services provided.
                        </Typography>
                        <Typography sx={{ m: 3 }} variant='subtitle1'>
                            This is a restricted area dedicated for internal use by Rightio® and its partners.
                        </Typography>
                        <Typography sx={{ m: 3 }} variant='subtitle1'>
                            Unauthorised access is strictly forbidden. If Rightio® does not authorise you to access this site, please leave immediately.
                        </Typography>
                        <Paper sx={(theme) => style(theme).homeLogo} elevation={4}>
                            <RightioLogo className='rightio-home-mini-logo' />
                        </Paper>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
})

const HomePage: React.FC<{}> = () => {
    const loggedUser = useAppSelector(({ state }) => state.LoggedUser)

    useDebugInformation(namespace, { loggedUser })

    return (!loggedUser
        ? <AnonymousHomeView />
        : <AuthenticatedHomeView />)
}

HomePage.displayName = namespace
AuthenticatedHomeView.displayName = "AuthenticatedHomeView"
AnonymousHomeView.displayName = "AnonymousHomeView"

export default React.memo(HomePage)