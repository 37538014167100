import React from "react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Skeleton from "@mui/material/Skeleton";
import { useGridApiContext, useGridSelector, gridPageSelector, gridPageCountSelector, gridPageSizeSelector, gridVisibleRowCountSelector } from "@mui/x-data-grid";

const name = "CustomPagination";

const CustomPagination: React.FC = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const totalRows = apiRef.current?.getRowsCount() || 0;
    const filteredRows = useGridSelector(apiRef, gridVisibleRowCountSelector);

    console.log(`${name} ▷ page: ${page} pageCount: ${pageCount} pageSize: ${pageSize} totalRows: ${totalRows} filteredRows: ${filteredRows}`);

    return (
        <Grid container justifyContent={"space-between"}>
            <Grid container justifyContent={"flex-start"} width={"fit-content"} alignItems={"center"} flexBasis={0} flexGrow={1}>
                {totalRows > 0 && <p className=" mb-0">Showing {pageSize * (page + 1) - (pageSize - 1)} - {(pageSize * (page + 1)) > (totalRows > filteredRows ? filteredRows : totalRows) ? (totalRows > filteredRows ? filteredRows : totalRows) : (pageSize * (page + 1))} Tickets (of {totalRows > filteredRows ? filteredRows : totalRows} {totalRows > filteredRows ? "Filtered" : "Total"} Ticket(s))</p>}
                {totalRows === 0 && <p className=" mb-0">No data found</p>}
                {totalRows === 0 && <Skeleton variant="rectangular" sx={{
                    width: {
                        xs: 440, // theme.breakpoints.up('xs')
                        sm: 460, // theme.breakpoints.up('sm')
                        md: 820, // theme.breakpoints.up('md')
                        lg: 1125, // theme.breakpoints.up('lg')
                        xl: 1450, // theme.breakpoints.up('xl')
                    },
                }} height={200} />}
            </Grid>
            <Grid container justifyContent={"flex-end"} width={"fit-content"} alignItems={"center"} flexBasis={0} flexGrow={1}>
                <Paper elevation={4} >
                    <Stack spacing={2}>
                        <Pagination
                            sx={{ p: 0.5 }}
                            color="primary"
                            count={pageCount}
                            page={page + 1}
                            variant="outlined"
                            shape="rounded"
                            onChange={(event: any, value: number) => apiRef.current.setPage(value - 1)}
                        />
                    </Stack>
                </Paper>
            </Grid>
        </Grid >
    );
}

CustomPagination.displayName = name;

export default CustomPagination;