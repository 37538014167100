import { IDBPDatabase, openDB, deleteDB } from "idb";
import { IndexedDbMethods } from "./interface";

const namespace = "IndexedDB API";

const getTimeStamp = () => new Date().toISOString();

const logger = (scope: string, namespace: string, message: string, obj?: any) => {
  if (scope === "info") console.log(`[${getTimeStamp()}] [INFO] [${namespace}] ${message}`);
  else if (scope === "error") console.error(`[${getTimeStamp()}] [ERROR] [${namespace}] ${message}`);
  else if (scope === "warn") console.warn(`[${getTimeStamp()}] [WARN] [${namespace}] ${message}`);
  else if (scope === "debug") console.debug(`[${getTimeStamp()}] [DEBUG] [${namespace}] ${message}`);
  if (obj) {
    console.log(obj);
  }
};

class IndexedDb {
  private _database: string;
  private _db: IDBPDatabase<unknown> | undefined;

  constructor(database: string) {
    this._database = database;
  }

  public get database(): string {
    return this._database;
  }

  public set database(value: string) {
    this._database = value;
  }

  public get db(): IDBPDatabase<unknown> | undefined {
    return this._db;
  }

  public set db(value: IDBPDatabase<unknown> | undefined) {
    this._db = value;
  }

  public getObjectStoreVersion(): number {
    return this.db?.version ?? 0;
  }

  public createObjectStore: IndexedDbMethods.createObjectStore = async (tableNames: string[], keyPath?: string[]) => {
    try {
      this.db = await openDB(this.database, undefined, {
        upgrade(db: IDBPDatabase) {
          let i = 0;
          for (const tableName of tableNames) {
            if (db.objectStoreNames.contains(tableName)) {
              continue;
            }
            db.createObjectStore(tableName, {
              autoIncrement: true,
              keyPath: keyPath?.[i] || keyPath?.[i] === null ? keyPath[i] : "id",
            });
            i++;
          }
        },
      });
      return true;
    } catch (error: any) {
      logger("error", namespace, `Error getting creating store for tableNames ${tableNames}:`, error);
      return false;
    }
  };

  public deleteDB: IndexedDbMethods.deleteDB = async () => {
    try {
      await deleteDB(this.database);

      // logger
      logger("debug", namespace, `Database ${this.database} deleted`);
      return true;
    } catch (error) {
      return false;
    }
  };

  public isReady: IndexedDbMethods.isReady = async () => {
    const some = new Promise<boolean>((resolve, reject) => {
      const db = this.db;
      const timer = setInterval(() => {
        if (db) {
          resolve(true);
          clearInterval(timer);
        }
      }, 10);
    });
    some.then((value) => {
      return value;
    });
    return some;
  };

  public getKeys: IndexedDbMethods.getKeys = async (tableName) => {
    try {
      const keys = await this.db?.getAllKeys(tableName);
      if (keys) {
        return keys;
      }
    } catch (error: any) {
      logger("error", namespace, `Error getting keys for tableName ${tableName}:`, error);
      return [];
    }
    return [];
  };

  public getValue: IndexedDbMethods.getValue = async (tableName, id) => {
    if (this.db) {
      try {
        const value = await this.db.get(tableName, id);
        return value;
      } catch (error: any) {
        logger("error", namespace, `Error getting value from table ${tableName}:`, error);
        return null;
      }
    }
    return null;
  };

  public getLastKey = async (tableName: string): Promise<IDBValidKey | null> => {
    if (this.db) {
      try {
        const value = await this.db.getAllKeys(tableName);
        return value[value.length - 1];
      } catch (error: any) {
        logger("error", namespace, `Error getting value from table ${tableName}:`, error);
        return null;
      }
    }
    return null;
  };

  public getAllValues: IndexedDbMethods.getAllValues = async (tableName) => {
    if (this.db) {
      try {
        const values = await this.db.getAll(tableName);
        return values;
      } catch (error: any) {
        logger("error", namespace, `Error getting all values from table ${tableName}:`, error);
        return [];
      }
    }
    return [];
  };

  public getLength: IndexedDbMethods.getLength = async (tableName) => {
    if (this.db) {
      try {
        const length = await this.db.count(tableName);
        return length;
      } catch (error: any) {
        logger("error", namespace, `Error getting length of table ${tableName}:`, error);
        return 0;
      }
    }
    return 0;
  };

  public putValue: IndexedDbMethods.putValue = async (tableName, value) => {
    if (this.db) {
      try {
        await this.db.put(tableName, value);
        return true;
      } catch (error: any) {
        logger("error", namespace, `Error putting value into table ${tableName}:`, error);
        return false;
      }
    }
    return false;
  };

  public putBulkValues: IndexedDbMethods.putBulkValues = async (tableName, values) => {
    if (this.db) {
      try {
        for (const value of values) {
          await this.db.put(tableName, value);
        }
        return true;
      } catch (error: any) {
        logger("error", namespace, `Error putting bulk values into table ${tableName}:`, error);
        return false;
      }
    }
    return false;
  };

  public replaceBulkValues: IndexedDbMethods.replaceBulkValues = async (tableName, values) => {
    await this.deleteValues(tableName);
    await this.putBulkValues(tableName, values);
  };

  public deleteValue: IndexedDbMethods.deleteValue = async (tableName, id) => {
    if (this.db) {
      try {
        await this.db.delete(tableName, id);
        return true;
      } catch (error: any) {
        logger("error", namespace, `Error deleting value from table ${tableName}:`, error);
        return false;
      }
    }
    return false;
  };

  public deleteValues: IndexedDbMethods.deleteValues = async (tableName) => {
    if (this.db) {
      try {
        await this.db.clear(tableName);
        return true;
      } catch (error: any) {
        logger("error", namespace, `Error deleting values from table ${tableName}:`, error);
        return false;
      }
    }
    return false;
  };

  public checkStoreExists: IndexedDbMethods.checkStoreExists = (tableName) => {
    if (this.db) {
      try {
        const exists = this.db.objectStoreNames.contains(tableName);
        return exists;
      } catch (error: any) {
        logger("error", namespace, `Error checking if store exists:`, error);
        return false;
      }
    }
    return false;
  };
}

export default IndexedDb;
