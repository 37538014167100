import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { state, ui } from '../../interfaces/interfaces';
import { useAppSelector } from '../../store/hooks';
import { sendEmails } from '../../utilities/general';

const namespace = "ReassignDialog"

interface ReassignDialogProps {
}

const handleListItemClick = async (value: any) => {
    ui.TicketProgress = ui.TicketProgress === ""
        ? `${state.User?.initials}: Reassigned to ${value.initials}`
        : `${ui.TicketProgress}
${state.User?.initials}@${new Date().toISOString().split("T")[0]}: Reassigned to ${value.initials}`
    await sendEmails(value)
    ui.ReassignDialog = false
};

const handleClose = () => {
    ui.ReassignDialog = false
};

const ReassignDialog: React.FC<ReassignDialogProps> = () => {
    const open = useAppSelector(({ ui }) => ui.ReassignDialog)
    const usersList = useAppSelector(({ ui }) => ui.ItMembers)

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Choose new assignee</DialogTitle>
            <List sx={{ pt: 0 }}>
                {usersList.map((user: any) => (
                    <ListItem button onClick={() => handleListItemClick(user)} key={user.id}>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.eMail} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

export default React.memo(ReassignDialog)