import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React from 'react';
import useDebugInformation from '../../../../../hooks/useDebugInformation';
import Box from '@mui/material/Box';
import CategoryPanel from './CategoryPanel';
import DropZone from './IncidentPanel';
import SummaryPanel from './SummaryPanel';
import { state, ui } from '../../../../../interfaces/interfaces';
import CompletionPanel from './CompletionPanel';
import { Link } from 'react-router-dom';
import { clearAnswers, compileFiles, generateImmediateActionId, generateTicketDetailId, generateLongTermActionId, generateProgressLogId, getLoggedUserId, insertAnswers, insertAttachments, insertTicket, sendEmail, setMsg } from '../../../../../assets/js/main';
import { StateKeys } from '../../../../../services/storage/storeTemplate';
import { logger } from '../../../../../services/logger';
import { useAppSelector } from '../../../../../store/hooks';
import { style } from '../../../../../utilities/style';
import { cacheUtils } from '../../../../..';
import { CacheTypes } from '../../../../../utilities/cache/interfaces';

const name = "IncidentsFormPanel"
const namespace = name

const steps = ['Ticket Category, Date, Location and Screening Questions', 'Ticket Details and Attachments', 'Ticket Summary', 'Report Completed'];

const getStepContent = (step: number, parent: string) => {
    switch (step) {
        case 0:
            return <CategoryPanel parent={parent} />;
        case 1:
            return <DropZone parent={parent} />;
        case 2:
            return <SummaryPanel parent={parent} />;
        case 3:
            return <CompletionPanel parent={parent} />;
        default:
            throw new Error('Unknown step');
    }
}

type Props = {
    parent: string
}

const compileAnswers = () => {
    const compiledAnswers = []
    const subCategoryId = ui.TicketSubcategory?.id
    if (ui.ActiveStep === 2 && subCategoryId) {
        if (ui.HasQuestion1) compiledAnswers.push({
            value: (ui.Answer[subCategoryId][`answer1`] === "Other" ? ui.Answer[subCategoryId][`answerOther1`] : ui.Answer[subCategoryId][`answer1`]),
            questionId: ui.OptionalQuestions[0].id,
            order: ui.OptionalQuestions[0].order,
        })
        if (ui.HasQuestion2) compiledAnswers.push({
            value: (ui.Answer[subCategoryId][`answer2`] === "Other" ? ui.Answer[subCategoryId][`answerOther2`] : ui.Answer[subCategoryId][`answer2`]),
            questionId: ui.OptionalQuestions[1].id,
            order: ui.OptionalQuestions[1].order,
        })
        if (ui.HasQuestion3) compiledAnswers.push({
            value: (ui.Answer[subCategoryId][`answer3`] === "Other" ? ui.Answer[subCategoryId][`answerOther3`] : ui.Answer[subCategoryId][`answer3`]),
            questionId: ui.OptionalQuestions[2].id,
            order: ui.OptionalQuestions[2].order,
        })
        if (ui.HasQuestion4) compiledAnswers.push({
            value: (ui.Answer[subCategoryId][`answer4`] === "Other" ? ui.Answer[subCategoryId][`answerOther4`] : ui.Answer[subCategoryId][`answer4`]),
            questionId: ui.OptionalQuestions[3].id,
            order: ui.OptionalQuestions[3].order,
        })
        if (ui.HasQuestion5) compiledAnswers.push({
            value: (ui.Answer[subCategoryId][`answer5`] === "Other" ? ui.Answer[subCategoryId][`answerOther5`] : ui.Answer[subCategoryId][`answer5`]),
            questionId: ui.OptionalQuestions[4].id,
            order: ui.OptionalQuestions[4].order,
        })
        if (ui.HasQuestion6) compiledAnswers.push({
            value: (ui.Answer[subCategoryId][`answer6`] === "Other" ? ui.Answer[subCategoryId][`answerOther6`] : ui.Answer[subCategoryId][`answer6`]),
            questionId: ui.OptionalQuestions[5].id,
            order: ui.OptionalQuestions[5].order,
        })
    }
    return compiledAnswers
}

const sendEmails = async (assigneeId: number) => {
    const assignee = cacheUtils.getCache<CacheTypes.Users>("Users").find(user => user.id === assigneeId)
    if (ui.TicketCategory && assignee) {
        const response = await Promise.all(
            [
                sendEmail(
                    `${state.LoggedUser?.profile.first_name} ${state.LoggedUser?.profile.last_name}`,
                    `${state.LoggedUser?.profile.email}`,
                    `Incident Ticket# ${ui.TicketId} Created Successfully.`,
                    `
Dear ${state.LoggedUser?.profile.first_name},<br><br>
Thank you for reporting your incident.<br>
Your ticket number is <b>${ui.TicketId}</b>.<br>
If there is any additional information required, you will be directly contacted by a member of IT Support.<br>
Thank you.`
                ),
                sendEmail(
                    `${assignee.firstName} ${assignee.lastName}`,
                    `${assignee.eMail}`,
                    `Incident Ticket# ${ui.TicketId} was submited by ${state.LoggedUser?.profile.first_name} ${state.LoggedUser?.profile.last_name}.`,
                    `
Dear ${assignee.firstName},<br><br>
A new ticket was created and assigned against "<b>${ui.TicketCategory.name}</b>" category.<br>
Please log in to "<b>Ticketing Portal</b>" and check ticket number <b>${ui.TicketId}</b> and comply with required actions.<br>
If there is anything else you would need from a person reporting an incident, please get in touch with them via e-Mail <a href="mailto:${state.LoggedUser?.profile.email}?subject=RE: Additional information required for IT Support Ticket ID#: ${ui.TicketId}&body=Dear ${state.LoggedUser?.profile.first_name}">${state.LoggedUser?.profile.email}</a><br>
Please always provide ticket ID <b>${ui.TicketId}</b> as a reference in the e-Mail subject field.<br>
Thank you.
`)
                // Please check ticket# <a href="https:itsupport.rightio.co.uk/manage-ticket/${ui.TicketId}">${ui.TicketId}</a> and comply with required actions.<br>
            ])
        if (response) {
            logger.info(`${namespace}::sendRequest`, `Emails sent successfully`)
            setMsg("Emails sent successfully", "success")
            return true
        }
        else {
            logger.error(`${namespace}::sendRequest`, `Error sending emails`)
            setMsg("Error sending emails", "error")
            return true
        }
    }
}

const reportIncident = async () => {
    ui.Loading = true
    const assigneeId = (ui.IsOtherCategory ? 2 : ui.AssigneeId)
    const locationId = ui.TicketLocation.id
    const categoryId = ui.TicketSubcategory?.categoryId
    const subCategoryId = (ui.IsOtherCategory ? -1 : ui.TicketSubcategory?.id)
    const ticketDate = ui.TicketDate
    const ticketTime = ui.TicketTime
    const longTermId = await generateLongTermActionId() || -1
    const progressId = await generateProgressLogId() || -1
    const ticketDetailsId = await generateTicketDetailId(ui.TicketDetails) || -1
    const immediateId = await generateImmediateActionId() || -1
    if (state.LoggedUser) {
        const reporteeId = await getLoggedUserId(state.LoggedUser) || -1
        const ticketId = await insertTicket(immediateId, longTermId, progressId, ticketDetailsId, reporteeId, locationId, categoryId || 0, assigneeId, subCategoryId || 0, reporteeId, ticketDate, ticketTime)
        const results: boolean[] = []
        ui.TicketId = ticketId || -1

        if (ui.TicketId > 0 && longTermId > 0 && progressId > 0 && ticketDetailsId > 0 && immediateId > 0 && reporteeId > 0) {
            results.push((await reportAnswers()))
            results.push((await reportAttachments()))
            results.push((await sendEmails(assigneeId)) || false)

            if (results.every(x => x === true)) {
                await clearAnswers(ui, state)
                ui.ActiveStep = 3
                ui.Loading = false
                logger.info(`${namespace}::reportIncident`, `Incident ${ui.TicketId} reported successfully`)
                setMsg("Incident Ticket# " + ui.TicketId + " Created Successfully.", "success")
            }
            else {
                logger.error(`${namespace}::reportIncident`, `Error reporting incident ${ui.TicketId}`)
                setMsg("Error reporting incident Ticket# " + ui.TicketId, "error")
                ui.Loading = false
            }
        }
        else {
            logger.error(`${namespace}::reportIncident`, `Error reporting incident ${ui.TicketId}`)
            setMsg("Error reporting incident Ticket# " + ui.TicketId, "error")
            ui.Loading = false
        }
        return ticketId
    }
    else {
        logger.error(`${namespace}::reportIncident`, `Error reporting incident ${ui.TicketId}`)
        setMsg("Error reporting incident Ticket# " + ui.TicketId, "error")
        ui.Loading = false
        return -1
    }
}
const reportAttachments = async () => {
    const files = await compileFiles()
    if (files.length > 0) {
        const response = await insertAttachments(ui.TicketId, files)
        if (response) {
            setMsg("Attachments Uploaded Successfully.", "success")
            logger.info(`${namespace}::reportAttachments`, `${files.length} Attachments Uploaded Successfully.`)
            return true
        }
        else { return false }
    }
    return true
}

const reportAnswers = async () => {
    const answers = compileAnswers()
    if (answers.length > 0) {
        const response = await insertAnswers(ui.TicketId, answers)
        if (response[0] && response[answers.length - 1]) {
            setMsg("Answers Submitted Successfully.", "success")
            logger.info(`${namespace}::reportAnswers`, `${answers.length} Answers Submitted Successfully.`)
        }
        else { return false }
    }
    return true
}

const isStepValid = (step: number) => {
    return state[`Step${step}Valid` as StateKeys]
}

const IncidentsFormPanel: React.FC<Props> = ({ parent }) => {
    const activeStep = useAppSelector(({ ui }) => ui.ActiveStep)
    const loading = useAppSelector(({ ui }) => ui.Loading)
    const step0Valid = useAppSelector(({ state }) => state.Step0Valid)
    const step1Valid = useAppSelector(({ state }) => state.Step1Valid)
    const step2Valid = useAppSelector(({ state }) => state.Step2Valid)
    const step3Valid = useAppSelector(({ state }) => state.Step3Valid)

    const debugName = `${parent}▷[${name}]`

    React.useEffect(() => {
        return () => {
            clearAnswers(ui, state)
            logger.debug(namespace, "Component unmounts cleaning")
        }
    }, [])

    useDebugInformation(debugName, { activeStep })
    return (
        <Box id="form-panel" sx={{ width: 1060 }}>
            {/* <FormBar /> */}
            <Paper sx={(theme) => style(theme).paper}>
                <Typography component="h6" variant="h6" align="center">
                    {steps[activeStep]}
                </Typography>
                <Stepper activeStep={activeStep} sx={(theme) => style(theme).stepper}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <>
                    {activeStep === steps.length - 1 ? (
                        <> <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperInner}>
                            {getStepContent(activeStep, debugName)}</Paper>
                            <Box sx={(theme) => style(theme).buttons}>
                                <Link to={{ pathname: "/", }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { ui.ActiveStep = 0; }}
                                        sx={(theme) => style(theme).button}
                                    >Finish</Button>
                                </Link>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperInner}>
                                {getStepContent(activeStep, debugName)}</Paper>
                            <Box sx={(theme) => style(theme).buttons}>
                                {activeStep !== 0 && (
                                    <Button onClick={() => ui.ActiveStep = Number(activeStep - 1)} sx={(theme) => style(theme).button}>
                                        Back
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isStepValid(activeStep) || loading}
                                    onClick={() => {
                                        if (activeStep === 1 && isStepValid(activeStep)) state.Step2Valid = true
                                        if (activeStep === 2) reportIncident()
                                        else
                                            ui.ActiveStep = Number(activeStep + 1);
                                    }}
                                    sx={(theme) => style(theme).button}
                                >
                                    {activeStep === 2 ? 'Submit Incident' : 'Next'}
                                </Button>
                            </Box>
                        </>
                    )}
                </>
            </Paper >
            {/* <Copyright /> */}
        </Box >
    )
}
export default React.memo(IncidentsFormPanel)