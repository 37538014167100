import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIKeys, uiTemplate } from "../../../services/storage/storeTemplate/UI";
import { RootState } from "../../../store";
import { logger } from "../../../services/logger";
import StorageUtils from "../../../services/storage";

const namespace = "UIReducer";

export const storeName = "ui";
export const templateState = uiTemplate();
export const initialState = uiTemplate();
export interface Action {
  name?: UIKeys;
  value: any;
}
export const uiSlice = createSlice({
  initialState,
  name: storeName,
  reducers: {
    hydrate: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Importing UI state");
      return { ...initialState, ...action.payload.value };
    },
    loaded: (state) => {
      logger.debug(namespace, "Setting UI state");
      state.Loading = false;
    },
    loading: (state) => {
      logger.debug(namespace, "Setting UI state");
      state.Loading = true;
    },
    reset: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Resetting UI state");
      return { ...initialState, ...action.payload.value, Done: true };
    },
    resetWithCache: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Resetting UI state");
      return { ...initialState, ...action.payload.value, Done: false };
    },
    save: (state) => {
      for (const key in state) {
        StorageUtils.saveToStorage({
          key,
          store: storeName,
          type: "local",
          value: state[key as keyof typeof state],
        });
      }
    },
    set: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Setting UI state");

      if (action.payload.name) {
        state[action.payload.name] = action.payload.value as never;
      }
    },
  },
});
export const { actions, reducer } = uiSlice;
export const { hydrate, loaded, loading, reset, resetWithCache, save, set } = actions;
export const uiSelector = (state: RootState) => state.ui;
