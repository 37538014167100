import { ReactComponent as RightioLogo } from "../../assets/img/rightio-logo-(r).svg";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import React from 'react'

const TemporaryMessage: React.FC<{ message: string }> = ({ message }) => (<Card >
    <CardActionArea sx={{ display: "flex", maxWidth: "50rem" }}>
        <RightioLogo style={{ width: 120, height: "auto" }} />
        <CardContent>
            <Typography gutterBottom variant="h3" component="div">
                {message}
            </Typography>
        </CardContent>
    </CardActionArea>
</Card>)

export default TemporaryMessage