import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { logger } from "../../../services/logger";
import StorageUtils from "../../../services/storage";
import { StateKeys, stateTemplate } from "../../../services/storage/storeTemplate/State";

const namespace = "StateReducer";

export const storeName = "state";
export const templateState = stateTemplate();
export const initialState = stateTemplate();
export interface Action {
  name?: StateKeys;
  value: any;
}
export const stateSlice = createSlice({
  initialState,
  name: storeName,
  reducers: {
    hydrate: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Importing State state");
      return { ...initialState, ...action.payload.value };
    },
    reset: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Resetting State state");
      return { ...initialState, ...action.payload.value, Done: true };
    },
    resetWithCache: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Resetting State state");
      return { ...initialState, ...action.payload.value, Done: false };
    },
    save: (state) => {
      for (const key in state) {
        StorageUtils.saveToStorage({
          key,
          store: storeName,
          type: "local",
          value: state[key as keyof typeof state],
        });
      }
    },
    set: (state, action: PayloadAction<Action>) => {
      logger.debug(namespace, "Setting State state");

      if (action.payload.name) {
        state[action.payload.name] = action.payload.value as never;
      }
    },
  },
});
export const { actions, reducer } = stateSlice;
export const { hydrate, reset, resetWithCache, save, set } = actions;
export const stateSelector = (state: RootState) => state.state;
