import Paper from '@mui/material/Paper';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../../hooks/useDebugInformation';
import Grid from '@mui/material/Grid';
import { state, ui } from '../../../../../../interfaces/interfaces';
import { Tables } from '../../../../../../services/databaseModels/tables';
import MemoizedDropDownMini from '../../../../../shared/Inputs/DropDownQuestionMini';
import Typography from '@mui/material/Typography';
import { questionsOptions } from '../../../../../../assets/js/main';
import TextInput from '../../../../../shared/TextInput';
import Question from '../../../../../shared/Questions';
import DropZone from '../../../../../shared/DropZone';
import ScrollableDialog from '../../../../../shared/ScrollableDialog';
import { SubOption, Option } from '../../../../../../utilities/basicTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { style } from '../../../../../../utilities/style';
import { CacheTypes } from '../../../../../../utilities/cache/interfaces';
import { cacheUtils } from '../../../../../..';

const NAME = "FormPanel"
const STEP = 0

type Props = {
    parent: string
}

const categoryGetOptionLabel = (option: Option) => option?.name || ""
const categoryIsOptionEqualToValue = (option: Option, value: Option) => option?.id === value?.id
const categoryOptions = (opts: CacheTypes.Categories[]) => opts && opts?.length > 0 ? opts : []

const subCategoryGetOptionLabel = (option: SubOption) => option?.name || ""
const subCategoryIsOptionEqualToValue = (option: SubOption, value: SubOption) => option?.id === value?.id
const subCategoryOptions = (opts: CacheTypes.SubCategories[], categoryId: number) => {
    return opts && opts?.length > 0 && categoryId > -2 ? (opts.filter((option) => option.categoryId === categoryId && (option.ticketTypeId === 2 || option.ticketTypeId === 3))) : []
}

const handleCategoryChange = (evt: { target: { value: any; name: any; }; }, value: any,) => {
    ui.TicketCategory = value
    ui.TicketSubcategory = {} as SubOption
}

const handleSubcategoryChange = (evt: { target: { value: any; name: any; }; }, value: any,) => {
    if (value) {
        ui.TicketSubcategory = value
        ui.AssigneeId = value?.ownerId
        if (value?.id > 0) {
            ui.Answer = {
                ...ui.Answer,
                [value?.id]: {
                    answer1: "",
                    answer2: "",
                    answer3: "",
                    answer4: "",
                    answer5: "",
                    answer6: "",
                    answerOther1: "",
                    answerOther2: "",
                    answerOther3: "",
                    answerOther4: "",
                    answerOther5: "",
                    answerOther6: "",
                }
            }
        }
    }
    else {
        ui.TicketSubcategory = {} as SubOption
    }
}

const shouldQuestionRender = (index: number, optionalQuestion: Tables.QuestionsOutput) => {
    const subCategoryId = ui.TicketSubcategory?.id
    const prevQuestionTypeId = ui.OptionalQuestions[index - 2].questionTypeId
    const dependsId = optionalQuestion?.dependsId ? optionalQuestion.dependsId : null
    const dependsValueArr = String(optionalQuestion.dependsValue).split("|")
    if (subCategoryId) {
        const answer = ui.Answer[subCategoryId][`answer${index - 1}`];
        const result = (dependsId === null || (Number(dependsId) > 0 && (dependsValueArr.includes(answer))) || ([2, 3].includes(prevQuestionTypeId)));
        return result
    }
}

const needResetAnswer = (index: number) => {
    const subCategoryId = ui.TicketSubcategory?.id
    if (subCategoryId) {
        if (!ui.Answer[subCategoryId]) ui.Answer[subCategoryId] = { answer1: "", answer2: "", answer3: "", answer4: "", answer5: "", answer6: "", answerOther1: "", answerOther2: "", answerOther3: "", answerOther4: "", answerOther5: "", answerOther6: "" }
        const answer = ui.Answer[subCategoryId][`answer${index - 1}`];
        const answerOther = ui.Answer[subCategoryId][`answerOther${index - 1}`];
        const resetAnswers: { [key: string]: string } = {}
        if (answer !== "" || answerOther !== "") {
            for (let i = index; i < 7; i++) {
                resetAnswers[`answer${i}`] = ""
                resetAnswers[`answerOther${i}`] = ""
            }
            ui.Answer = {
                ...ui.Answer, [subCategoryId]: { ...ui.Answer[subCategoryId], ...resetAnswers }
            }
        }
    }
}

type StandardRequetsProps = {
    optionalQuestions: Tables.QuestionsOutput[]
    ticketSubcategoryId: number
    parent: string
    hasQuestions?: boolean[]
}

const StandardRequest: React.FC<StandardRequetsProps> = ({ optionalQuestions, ticketSubcategoryId, parent }) => {
    return (
        <>
            <Typography variant='subtitle1' marginBottom={-2}>Screening Questions</Typography>
            <Grid container margin={0} gap={1} width={"100%"} >
                {/* Screening Questions: */}
                <Question question={optionalQuestions[0]} index={1} id={ticketSubcategoryId} />
                <Question question={optionalQuestions[1]} index={2} id={ticketSubcategoryId} />
            </Grid>
            <Grid container margin={0} gap={1} width={"100%"} >
                <Grid item xs={12} sm={12}>
                    <Paper sx={(theme) => style(theme).paper} variant="elevation" elevation={4}>
                        <TextInput inputName="BriefDescriptionOfChangeRequired" parent={parent} xs={12} sm={12} type="text" multiline={6} onDoubleClick={() => { ui.ScrollableDialogControlName = "BriefDescriptionOfChangeRequired"; ui.ScrollableDialogOpen = true }} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container margin={0} gap={1} width={"100%"} >
                {/* Screening Questions: */}
                <Question question={optionalQuestions[2]} index={3} id={ticketSubcategoryId} minRows={1} maxRows={6} />
                <Question question={optionalQuestions[3]} index={4} id={ticketSubcategoryId} minRows={1} maxRows={6} />
            </Grid>
        </>)
}

const SystemRequest: React.FC<StandardRequetsProps> = ({ optionalQuestions, ticketSubcategoryId, parent, hasQuestions }) => {
    console.log("SystemRequest", hasQuestions, optionalQuestions)
    if (hasQuestions && hasQuestions.length > 0) return (<>
        <Typography variant='subtitle1' marginBottom={-2}>Screening Questions</Typography>
        <Grid container margin={0} gap={1} width={"100%"} >
            {/* Screening Questions: */}
            {hasQuestions[0] && <Question question={optionalQuestions[0]} index={1} id={ticketSubcategoryId} />}
            {hasQuestions[1] && <Question question={optionalQuestions[1]} index={2} id={ticketSubcategoryId} />}
            {hasQuestions[2] && <Question question={optionalQuestions[2]} index={3} id={ticketSubcategoryId} />}
        </Grid>
        <Grid container margin={0} gap={1} width={"100%"} >
            {/* Screening Questions: */}
            {hasQuestions[3] && <Question question={optionalQuestions[3]} index={4} id={ticketSubcategoryId} />}
            {hasQuestions[4] && <Question question={optionalQuestions[4]} index={5} id={ticketSubcategoryId} />}
            {hasQuestions[5] && <Question question={optionalQuestions[5]} index={6} id={ticketSubcategoryId} />}
        </Grid>
        <Grid container margin={0} gap={1} width={"100%"} >
            <Grid item xs={12} sm={12}>
                <Paper sx={(theme) => style(theme).paper} variant="elevation" elevation={4}>
                    <TextInput inputName="RequestDetails" parent={parent} xs={12} sm={12} type="text" multiline={4} minRows={4} />
                </Paper>
            </Grid>
        </Grid>
    </>)
    return null
}

const areInputsValid = () => {
    if (ui.TicketSubcategory && ui.TicketSubcategory.ticketTypeId > 0) {
        const inputs = []
        inputs.push((ui.TicketSubcategory?.id > 0))
        if (ui.TicketSubcategory.ticketTypeId === 2) {
            if (ui.HasQuestion1) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer1 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther1 !== ""))
            if (ui.HasQuestion2) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer2 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther2 !== ""))
            if (ui.HasQuestion3) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer3 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther3 !== ""))
            if (ui.HasQuestion4) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer4 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther4 !== ""))
            if (ui.HasQuestion5) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer5 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther5 !== ""))
            if (ui.HasQuestion6) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer6 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther6 !== ""))
            inputs.push((ui.TicketDetails !== ""))
        }
        if (ui.TicketSubcategory.ticketTypeId === 3) {
            if (ui.HasQuestion1) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer1 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther1 !== ""))
            if (ui.HasQuestion2) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer2 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther2 !== ""))
            if (ui.HasQuestion3) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer3 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther3 !== ""))
            if (ui.HasQuestion4) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer4 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther4 !== ""))
            if (ui.HasQuestion5) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer5 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther5 !== ""))
            if (ui.HasQuestion6) inputs.push((ui.Answer[ui.TicketSubcategory.id].answer6 !== "" || ui.Answer[ui.TicketSubcategory.id].answerOther6 !== ""))
            inputs.push((ui.TicketDetails !== ""))
        }
        state[`Step${STEP}Valid`] = inputs.every(x => x === true)
        return
    }
    state[`Step${STEP}Valid`] = false
    return
}

const CategoryPanel: React.FC<Props> = ({ parent }) => {
    const debugName = `${parent}▷[${NAME}]`
    const ticketCategory = useAppSelector(({ ui }) => ui["TicketCategory"])
    const ticketSubcategory = useAppSelector(({ ui }) => ui["TicketSubcategory"])
    const isOtherCategory = useAppSelector(({ ui }) => ui["IsOtherCategory"])

    const hasQuestion1 = useAppSelector(({ ui }) => ui["HasQuestion1"])
    const hasQuestion2 = useAppSelector(({ ui }) => ui["HasQuestion2"])
    const hasQuestion3 = useAppSelector(({ ui }) => ui["HasQuestion3"])
    const hasQuestion4 = useAppSelector(({ ui }) => ui["HasQuestion4"])
    const hasQuestion5 = useAppSelector(({ ui }) => ui["HasQuestion5"])
    const hasQuestion6 = useAppSelector(({ ui }) => ui["HasQuestion6"])
    const ticketDetails = useAppSelector(({ ui }) => ui["TicketDetails"])

    const options = cacheUtils.getCache<CacheTypes.RequestCategories>("RequestCategories")
    const subOptions = cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories").filter(x => x.enabled)
    const questions = cacheUtils.getCache<CacheTypes.Questions>("Questions")

    const optionalQuestions = useAppSelector(({ ui }) => ui["OptionalQuestions"])
    const answer = useAppSelector(({ ui }) => ui["Answer"])

    React.useEffect(() => {
        if (questions.length > 0 && ticketSubcategory && ticketSubcategory?.id > 0) {
            ui.OptionalQuestions = questionsOptions(questions, ticketSubcategory.id);
        }
        else {
            if (ui.HasQuestion1) ui.HasQuestion1 = false
            if (ui.HasQuestion2) ui.HasQuestion2 = false
            if (ui.HasQuestion3) ui.HasQuestion3 = false
            if (ui.HasQuestion4) ui.HasQuestion4 = false
            if (ui.HasQuestion5) ui.HasQuestion5 = false
            if (ui.HasQuestion6) ui.HasQuestion6 = false
        }
        areInputsValid()
    }, [ticketSubcategory, questions])

    React.useEffect(() => { if (!hasQuestion2) { needResetAnswer(2) } }, [hasQuestion2])
    React.useEffect(() => { if (!hasQuestion3) { needResetAnswer(3) } }, [hasQuestion3])
    React.useEffect(() => { if (!hasQuestion4) { needResetAnswer(4) } }, [hasQuestion4])
    React.useEffect(() => { if (!hasQuestion5) { needResetAnswer(5) } }, [hasQuestion5])
    React.useEffect(() => { if (!hasQuestion6) { needResetAnswer(6) } }, [hasQuestion6])

    React.useEffect(() => {
        if (optionalQuestions && optionalQuestions.length > 0) {
            if (ui.HasQuestion2) ui.HasQuestion2 = false
            if (ui.HasQuestion3) ui.HasQuestion3 = false
            if (ui.HasQuestion4) ui.HasQuestion4 = false
            if (ui.HasQuestion5) ui.HasQuestion5 = false
            if (ui.HasQuestion6) ui.HasQuestion6 = false
            if (!ui.HasQuestion1) ui.HasQuestion1 = true
            if (optionalQuestions.length >= 2) ui.HasQuestion2 = shouldQuestionRender(2, optionalQuestions[1]) ? true : false
            if (optionalQuestions.length >= 3) ui.HasQuestion3 = shouldQuestionRender(3, optionalQuestions[2]) ? true : false
            if (optionalQuestions.length >= 4) ui.HasQuestion4 = shouldQuestionRender(4, optionalQuestions[3]) ? true : false
            if (optionalQuestions.length >= 5) ui.HasQuestion5 = shouldQuestionRender(5, optionalQuestions[4]) ? true : false
            if (optionalQuestions.length === 6) ui.HasQuestion6 = shouldQuestionRender(6, optionalQuestions[5]) ? true : false
        }
        else {
            if (ui.HasQuestion1) ui.HasQuestion1 = false
            if (ui.HasQuestion2) ui.HasQuestion2 = false
            if (ui.HasQuestion3) ui.HasQuestion3 = false
            if (ui.HasQuestion4) ui.HasQuestion4 = false
            if (ui.HasQuestion5) ui.HasQuestion5 = false
            if (ui.HasQuestion6) ui.HasQuestion6 = false
        }
        areInputsValid()
    }, [optionalQuestions])

    React.useEffect(() => {
        areInputsValid()
    }
        , [answer, ticketDetails])

    useDebugInformation(debugName, {})
    if (ticketSubcategory && ticketSubcategory?.id > 0)
        return (
            <Box id="form-panel" component="form"
                noValidate
                autoComplete="off" >
                <ScrollableDialog />
                <Paper sx={(theme) => style(theme).paperParentCol} variant="elevation" elevation={4}>
                    <Grid container spacing={2} margin={0} gap={1} width={"100%"}>
                        <Paper sx={(theme) => style(theme).paperItem()} variant="elevation" elevation={4}>
                            <MemoizedDropDownMini options={categoryOptions(options ? options : [])} name='Business Function' handleChange={handleCategoryChange} value={ticketCategory} isOptionEqualToValue={categoryIsOptionEqualToValue} getOptionLabel={categoryGetOptionLabel} xs={12} sm={12} />
                        </Paper>
                        <Paper sx={(theme) => style(theme).paperItem()} variant="elevation" elevation={4}>
                            <MemoizedDropDownMini options={subCategoryOptions(subOptions ? subOptions : [], ticketCategory?.id ? ticketCategory.id : -1)} xs={12} sm={12} isOptionEqualToValue={subCategoryIsOptionEqualToValue} getOptionLabel={subCategoryGetOptionLabel} disabled={!isOtherCategory && ticketCategory?.id ? false : true} name='Category' value={ticketSubcategory} handleChange={handleSubcategoryChange} />
                        </Paper>
                    </Grid>
                    {
                        (!(ticketSubcategory?.ticketTypeId === 3))
                            ? <StandardRequest optionalQuestions={optionalQuestions} parent={debugName} ticketSubcategoryId={ticketSubcategory.id} />
                            : <SystemRequest optionalQuestions={optionalQuestions} parent={debugName} ticketSubcategoryId={ticketSubcategory.id} hasQuestions={[hasQuestion1, hasQuestion2, hasQuestion3, hasQuestion4, hasQuestion5, hasQuestion6]} />
                    }
                    {
                        !(ticketSubcategory?.ticketTypeId === 3) && <>
                            <Grid container>
                                <Grid item xs={12} sm={12} justifyItems="center" >
                                    <Typography align="center" variant="subtitle2" sx={{ mb: -2 }}>
                                        Supporting files (max 3 files/ max size 5MB per file) - if more files are needed please zip it
                                    </Typography>
                                </Grid>
                            </Grid>
                            <DropZone parent={NAME} css={{ margin: 0 }} />
                        </>
                    }
                </Paper >
            </Box >
        )
    return (
        <Box id="form-panel" component="form"
            noValidate
            autoComplete="off" >
            <Paper sx={(theme) => style(theme).paperParentCol} variant="elevation" elevation={4}>
                <Grid container spacing={2} margin={0} gap={1} width={"100%"}>
                    <Paper sx={(theme) => style(theme).paperItem()} variant="elevation" elevation={4}>
                        <MemoizedDropDownMini options={categoryOptions(options ? options : [])} name='Business Function' handleChange={handleCategoryChange} value={ticketCategory} isOptionEqualToValue={categoryIsOptionEqualToValue} getOptionLabel={categoryGetOptionLabel} xs={12} sm={12} />
                    </Paper>
                    <Paper sx={(theme) => style(theme).paperItem()} variant="elevation" elevation={4}>
                        <MemoizedDropDownMini options={subCategoryOptions(subOptions ? subOptions : [], ticketCategory?.id ? ticketCategory.id : -1)} xs={12} sm={12} isOptionEqualToValue={subCategoryIsOptionEqualToValue} getOptionLabel={subCategoryGetOptionLabel} disabled={!isOtherCategory && ticketCategory?.id ? false : true} name='Category' value={ticketSubcategory} handleChange={handleSubcategoryChange} />
                    </Paper>
                </Grid>
            </Paper >
        </Box >
    )
}
export default React.memo(CategoryPanel)