import { cacheUtils } from "../..";
import { api, state, ui } from "../../interfaces/interfaces";
import { APIKeys } from "../../reducers/actions/apiActions/interface";
import { Tables } from "../../services/databaseModels/tables";
import StorageUtils from "../../services/storage";
// import { CacheKeys } from "../../services/storage/storeTemplate";
import { User } from "../../utilities/basicTypes";
import { CacheTypes } from "../../utilities/cache/interfaces";

const namespace = "API Middleware Post-Processor";

export const apiProcessor = async (
  action: {
    payload: {
      name: APIKeys;
      value: any;
      body: any;
    };
  },
  next: any
) => {
  switch (action.payload.name) {
    case "TicketsWithFK":
    case "RequestsWithFK":
      action.payload.value?.dateReported &&
        (action.payload.value.dateReported = new Date(action.payload.value.dateReported));
      action.payload.value?.dateUpdated &&
        (action.payload.value.dateUpdated = new Date(action.payload.value.dateUpdated));
      action.payload.value?.ticketDatetime &&
        (action.payload.value.ticketDatetime = new Date(action.payload.value.ticketDatetime));

      if (action.payload.value?.attachments && action.payload.value.attachments.length > 0) {
        await StorageUtils.replaceValuesInStoreInIndexedDB({
          database: "CACHE",
          tableName: "Files",
          values: action.payload.value.attachments,
        });
        cacheUtils.setCache("Files", action.payload.value.attachments);
      }
      return next(action);

    case "Categories":
      action.payload.value = action.payload.value.map((a: any) => ({
        id: a.id,
        name: a.name,
        description: a.description,
      }));

      cacheUtils.setCache("Categories", action.payload.value);

      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Categories",
        values: action.payload.value,
      });

      const subCategories = cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories");

      const ticketCategories = action.payload.value.filter((cat: { id: any }) =>
        subCategories?.some((subCat) => subCat.categoryId === cat.id && subCat.ticketTypeId === 1)
      );

      const requestCategories = action.payload.value.filter((cat: { id: any }) =>
        subCategories?.some(
          (subCat) => subCat.categoryId === cat.id && (subCat.ticketTypeId === 2 || subCat.ticketTypeId === 3)
        )
      );

      cacheUtils.setCache("TicketCategories", ticketCategories);
      cacheUtils.setCache("RequestCategories", requestCategories);

      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "TicketCategories",
        values: ticketCategories,
      });

      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "RequestCategories",
        values: requestCategories,
      });

      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Categories",
        values: action.payload.value,
      });

      break;

    case "Locations":
      cacheUtils.setCache("Locations", action.payload.value);
      ui.TicketLocations = action.payload.value;
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Locations",
        values: action.payload.value,
      });

      break;

    case "Questions":
      cacheUtils.setCache("Questions", action.payload.value);
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Questions",
        values: action.payload.value,
      });

      break;

    case "Roles":
      cacheUtils.setCache("Roles", action.payload.value);
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Roles",
        values: action.payload.value,
      });

      break;

    case "Statuses":
      cacheUtils.setCache("Statuses", action.payload.value);
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Statuses",
        values: action.payload.value,
      });

      break;

    case "Priorities":
      cacheUtils.setCache("Priorities", action.payload.value);
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Priorities",
        values: action.payload.value,
      });

      break;

    case "TicketTypes":
      cacheUtils.setCache("TicketTypes", action.payload.value);
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "TicketTypes",
        values: action.payload.value,
      });

      break;

    case "SubCategories":
      cacheUtils.setCache("SubCategories", action.payload.value);
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "SubCategories",
        values: action.payload.value,
      });

      break;

    case "QuestionsTypes":
      cacheUtils.setCache("QuestionsTypes", action.payload.value);
      // cache[action.payload.name] = action.payload.value;
      await StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "QuestionsTypes",
        values: action.payload.value,
      });

      break;

    case "Users":
      cacheUtils.setCache("Users", action.payload.value);
      const loggedUser = state.LoggedUser;
      const role = state.Role;
      const user = action.payload.value.find(
        (user: User) => user.eMail.toLowerCase() === loggedUser?.profile.email.toLowerCase()
      );

      if (user) {
        state.User = user;
      } else {
        // new user must be created

        const body: Tables.UsersInput = {
          eMail: loggedUser?.profile.email || "",
          firstName: loggedUser?.profile.first_name || "",
          lastName: loggedUser?.profile.last_name || "",
          roleId: role?.id || 0,
          identityId: loggedUser?.profile.sub || "",
          initials: loggedUser?.profile.initials || "",
        };

        const createdUser = await api.fetchUsersInsert(body);
        
        state.User = {
          id: createdUser.id,
          email: createdUser.eMail,
          first_name: createdUser.firstName,
          last_name: createdUser.lastName,
          initials: createdUser.initials || "",
        };

        action.payload.value.push(createdUser);
      }

      StorageUtils.replaceValuesInStoreInIndexedDB({
        database: "CACHE",
        tableName: "Users",
        values: action.payload.value,
      });

      // cache[action.payload.name] = action.payload.value;
      break;
    default:
      break;
  }
};
