import { AnyAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { dispatchSetState } from "../../reducers/stateReducer";
import { dispatchSetUI } from "../../reducers/uiReducer";
import { logger } from "../../services/logger";
import StorageUtils from "../../services/storage";
import { CacheKeys, TemplateCACHEObject } from "../../services/storage/storeTemplate/Cache";
import { StateKeys, stateTemplate, TemplateSTATEObject } from "../../services/storage/storeTemplate/State";
import { TemplateUIObject, UIKeys, uiTemplate } from "../../services/storage/storeTemplate/UI";
import { RootState, store } from "../../store";
import { deepCompare } from "../../utilities/general";

export type State = { [key: string]: any }; // your state type
export type AppDispatch = ThunkDispatch<State, any, AnyAction>;

export interface SetGeneric {
  (store: keyof RootState, name: keyof RootState[keyof RootState], body?: any): void;
}

export interface GetGeneric {
  (store: keyof RootState, name: keyof RootState[keyof RootState]): any;
}

export interface GenericAction {
  (store: string): TemplateUIObject | TemplateCACHEObject | TemplateSTATEObject | undefined;
}

export interface ActionUi {
  (): TemplateUIObject;
}
export interface ActionState {
  (): TemplateSTATEObject;
}
// export interface ActionCache {
//   (): TemplateCACHEObject;
// }

const namespace: string = "GenericActionStore";

const cachedStores: {
  ui: TemplateUIObject | null;
  state: TemplateSTATEObject | null;
  // cache: TemplateCACHEObject | null;
} = {
  ui: null,
  state: null,
  // cache: null,
};

// const cacheStore: ActionCache = () => {
//   if (cachedStores["cache"]) return cachedStores["cache"];
//   logger.debug(namespace, "Generating new CACHE Action Store");
//   const object = cacheTemplate();
//   for (const key of Object.keys(object)) {
//     Object.defineProperty(object, key, {
//       get() {
//         return getGeneric("cache", key as never);
//       },
//       set(value) {
//         setGeneric("cache", key as never, value);
//       },
//     });
//   }
//   Object.defineProperty(object, "name", {
//     get() {
//       return "cache";
//     },
//   });
//   cachedStores["cache"] = object;
//   return object;
// };

const uiStore: ActionUi = () => {
  if (cachedStores["ui"]) return cachedStores["ui"];
  logger.debug(namespace, "Generating new UI Action Store");
  const object = uiTemplate();
  for (const key of Object.keys(object)) {
    Object.defineProperty(object, key, {
      get() {
        return getGeneric("ui", key as never);
      },

      set(value) {
        setGeneric("ui", key as never, value);
      },
    });
  }
  Object.defineProperty(object, "name", {
    get() {
      return "ui";
    },
  });
  cachedStores.ui = object;
  return cachedStores.ui;
};

const stateStore: ActionState = () => {
  if (cachedStores["state"]) return cachedStores["state"];
  logger.debug(namespace, "Generating new STATE Action Store");
  const object = stateTemplate();
  for (const key of Object.keys(object)) {
    Object.defineProperty(object, key, {
      get() {
        return getGeneric("state", key as never);
      },

      set(value) {
        setGeneric("state", key as never, value);
      },
    });
  }
  Object.defineProperty(object, "name", {
    get() {
      return "state";
    },
  });
  cachedStores.state = object;
  return cachedStores.state;
};

export const getGeneric: GetGeneric = (storeName, name): any => {
  const value = store.getState()[storeName][name];
  return value;
};

export const setGeneric: SetGeneric = (storeName, name, payload) => {
  // save only if different
  if (!deepCompare(store.getState()[storeName][name], payload)) {
    setValueInStore(storeName, name, payload);
    StorageUtils.delayedWrite({ type: "local", store: storeName, key: name, value: payload, delay: 100 });
  }
};

export const setGenericNoDelay: SetGeneric = (storeName, name, payload) => {
  // save only if different
  if (!deepCompare(store.getState()[storeName][name], payload)) {
    setValueInStore(storeName, name, payload);
    StorageUtils.saveToStorage({ type: "local", store: storeName as any, key: name, value: payload });
  }
};

export const setGenericNoStorage: SetGeneric = (storeName, name, payload) => {
  // save only if different
  if (!deepCompare(store.getState()[storeName][name], payload)) {
    setValueInStore(storeName, name, payload);
  }
};

const setValueInStore = (storeName: string, name: StateKeys | CacheKeys | UIKeys, payload: any) => {
  if (storeName === "state") {
    dispatchSetState(name as StateKeys, payload);
  }
  // if (storeName === "cache") {
  //   dispatchSetCache(name as CacheKeys, payload);
  // }
  if (storeName === "ui") {
    dispatchSetUI(name as UIKeys, payload);
  }
};

export const genericAction: GenericAction = (store) => {
  // if (store === "cache") return cacheStore();
  if (store === "ui") return uiStore();
  if (store === "state") return stateStore();
};
