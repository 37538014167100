import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ui, state } from '../../interfaces/interfaces';
import TextInput from './TextInput';
import Grid from '@mui/material/Grid';
import { camelToWords, hasTicketStateChanged } from '../../assets/js/main';
import { UIKeys } from '../../services/storage/storeTemplate';
import { useAppSelector } from '../../store/hooks';

type ScrollableDialogProps = {
}

const ScrollDialog: React.FC<ScrollableDialogProps> = () => {
    const open = useAppSelector(({ ui }) => ui["ScrollableDialogOpen"])
    const controlName = useAppSelector(({ ui }) => ui["ScrollableDialogControlName"]) as "ImmediateAction" | "LongTermAction" | "RequestDetails" | "TicketCategory" | "TicketDetails" | "TicketProgress" | "TicketSubcategory" | "BriefDescriptionOfChangeRequired" | "Ticket Category > SubCategory" | "Ticket Location, Date and Time" | "Business Function> Category"
    const readOnly = useAppSelector(({ ui }) => ui["ScrollableDialogReadOnly"])
    const monitored = useAppSelector(({ ui }) => ui[controlName as UIKeys]) as string
    const [prevText, setPrevText] = React.useState("")

    React.useEffect(() => { open && prevText === "" && setPrevText(monitored) }, [monitored, open, prevText])

    const handleClose = () => {
        setPrevText("")
        ui.ScrollableDialogOpen = false
        ui.ScrollableDialogReadOnly = false
    };
    const handleCancel = () => {
        ui[controlName === "BriefDescriptionOfChangeRequired" ? "TicketDetails" : controlName as UIKeys] = prevText as never
        hasTicketStateChanged()
        setPrevText("")
        ui.ScrollableDialogOpen = false
        ui.ScrollableDialogReadOnly = false
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">{camelToWords(controlName)}</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container sx={{ width: 500 }}>
                    {readOnly ? <TextInput parent={''} inputName={controlName} xs={12} sm={12} multiline={20} minRows={20} uncontrolledName={controlName === "BriefDescriptionOfChangeRequired" ? "TicketDetails" : controlName} />
                        : <TextInput parent={''} inputName={controlName} xs={12} sm={12} multiline={20} minRows={20} onChange={(e: { target: { value: string; }; }) => { ui[controlName === "BriefDescriptionOfChangeRequired" ? "TicketDetails" : controlName as UIKeys] = e.target.value as never; hasTicketStateChanged() }} />}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(ScrollDialog)