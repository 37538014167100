import React from "react";
import useDebugInformation from "../../../../hooks/useDebugInformation";
import { useAppSelector } from "../../../../store/hooks";

const name = "Body"

interface DtProps {
    parent: "Incidents" | "Requests" | "Overall" | "Manage"
}

const tableInit = (tbodyRef: any, data: any[]) => {
    const clearTable = () => {
        const tbody = tbodyRef.current
        for (let index = 0; index < data.length; index++) {
            tbody.deleteRow(-1);
        }
    }

    const makeTable = () => {
        const tbody = tbodyRef.current
        for (const row of data) {
            const tr = tbody.insertRow(-1)
            for (const cell of row) {
                const td = tr.insertCell(-1)
                td.innerText = cell
            }
        }
    }

    clearTable()
    makeTable()
}

const TableBSBody: React.FC<DtProps> = ({ parent }) => {
    const tbodyRef = React.useRef(null)
    const tableData = useAppSelector(({ ui }) => ui[`${parent}TableData`])
    React.useEffect(() => {
        if (tbodyRef && tbodyRef.current) tableInit(tbodyRef, tableData)
    }, [tableData])

    const debugName = `${parent}▷[${name}]`
    useDebugInformation(debugName, { parent, tbodyRef, tableData })

    return (
        <tbody ref={tbodyRef} >
        </tbody>
    )
}

export default React.memo(TableBSBody);