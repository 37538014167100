import * as StateInterface from "./interface";
export * from "./interface";
export const stateTemplate: StateInterface.TemplateSTATE = () => ({
  ActiveTicket: null,
  Assignee: {
    eMail: "",
    firstName: "",
    id: 0,
    identityId: "",
    initials: "",
    lastName: "",
    roleId: 0,
  },
  CacheLoaded: false,
  CachingStarted: false,
  ColumnVisibilityModel: undefined,
  Credentials: undefined,
  EditCategory: false,
  EditQuestions: false,
  EditSubcategory: false,
  FilterModel: undefined,
  IsAuthenticated: false,
  LoadDate: null,
  LoggedUser: undefined,
  Page: undefined,
  Questions: [],
  Reportee: {
    eMail: "",
    firstName: "",
    id: 0,
    identityId: "",
    initials: "",
    lastName: "",
    roleId: 0,
  },
  RequestUpdateActive: false,
  Role: undefined,
  SortModel: undefined,
  Step0Valid: false,
  Step1Valid: false,
  Step2Valid: false,
  Step3Valid: false,
  TicketsPerPage: 0,
  User: undefined,
});
