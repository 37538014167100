import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { logger } from "../../../services/logger"
import { RootState } from "../../../store"

const storeName = 'api'
const nameSpace = 'Api Reducer'

export interface Action {
    value: any
    name?: string
    body?: any
    //  {
    //     settingName: keyof TemplateTEMPObject;
    //     tradeCode: TradeCode;
    //     accountCode: AccountCode;
    //     jobTypeCode: JobTypeCode;
    //     postcode: string;
    //     callTypeId: number;
    // };
}

export interface IState {
    loading: boolean
    error: any
    [key: string]: any
}

export const initialState: IState = {
    loading: false,
    error: false
}

export const apiSlice = createSlice({
    name: storeName,
    initialState,
    reducers: {
        start: (state) => {
            state.loading = true
            state.error = false
        },
        error: (state) => {
            state.loading = false
            state.error = true
        },
        set: (state, action: PayloadAction<Action>) => {
            state.loading = false
            state.error = false
            if (action.payload.name) state[action.payload.name] = action.payload.value
        },
        reset: (state, action: PayloadAction<Action>) => {
            logger.debug(nameSpace, 'API reset')
            return { ...initialState, ...action.payload.value };
        }
    }
})


export const { actions, reducer } = apiSlice

export const { start, error, set, reset } = actions

export const apiSelector = (state: RootState) => state.api

export const apiStateSelector = (state: RootState) => state.api.loading

export const apiErrorSelector = (state: RootState) => state.api.error
