import Grid, { GridSize } from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import { camelToWords } from "../../assets/js/main";
import useDebugInformation from "../../hooks/useDebugInformation";

import { ui } from "../../interfaces/interfaces";
import Typography from '@mui/material/Typography';
// import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../../store/hooks";
import { UIKeys } from "../../services/storage/storeTemplate";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         margin: theme.spacing(0),
//         // marginBottom: theme.spacing(0),
//         padding: theme.spacing(2),
//         [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
//             margin: theme.spacing(1),
//             padding: theme.spacing(2),
//         },
//     },
// }));


type A11yProps = {
    (name: string): {
        id: string
        name: string
        fullWidth: boolean
        autoComplete: string
        "aria-controls": string
    }
}

const a11yProps: A11yProps = (name) => ({
    id: `input-${name.toLowerCase()}`,
    name,
    fullWidth: true,
    autoComplete: 'off',
    "aria-controls": `action-input-${name.toLowerCase()}`,
});

type MemoizedInputProps = {
    parent: string
    inputName: "TicketDetails" | "Ticket Category > SubCategory" | "Ticket Location, Date and Time" | "BriefDescriptionOfChangeRequired" | "RequestDetails" | "Business Function> Category" | "TicketSubcategory" | "TicketCategory" | "TicketProgress" | "ImmediateAction" | "LongTermAction"
    xs: boolean | GridSize | undefined
    sm: boolean | GridSize | undefined
    type?: string
    multiline?: number
    minRows?: number
    description?: string
    uncontrolledName?: string
    onChange?: any
    readOnly?: boolean
    defaultValue?: any
    onDoubleClick?: any
    inputProps?: any
    color?: any
    styledVariant?: string
}

const CssTextFieldDisabled = styled(TextField)({
    '& label.Mui-focused': {
        color: '#d8790d78',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#d8790d78',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#d8790d78',
        },
        '&:hover fieldset': {
            borderColor: '#d8790d78',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#d8790d78',
            borderWidth: "1px"
        },
    },
});
const CssTextFieldRequired = styled(TextField)({
    '& label.Mui-focused': {
        color: 'green',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'green',
            borderWidth: "2px"
        },
        '&:hover fieldset': {
            borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'green',
            borderWidth: "2px"
        },
    },
});

const TextInput = React.memo<MemoizedInputProps>(({ parent, styledVariant, inputProps, uncontrolledName, onDoubleClick, inputName, xs, color, sm, type, multiline, description, minRows, defaultValue, readOnly, onChange }) => {
    const monitored = useAppSelector(({ ui }) => ui[(inputName === "BriefDescriptionOfChangeRequired" || inputName === "RequestDetails") ? "TicketDetails" : `${inputName}` as UIKeys])
    useDebugInformation(`${parent}▷[${inputName}]`, { parent, uncontrolledName, inputProps, inputName, xs, sm, type, color, multiline, description, monitored })
    if (uncontrolledName && styledVariant === "disabled") return (<Grid item xs={xs} sm={sm}> <CssTextFieldDisabled color={color} inputProps={{
        ...inputProps,
        readOnly,
    }} {...a11yProps(uncontrolledName)} defaultValue={defaultValue} label={camelToWords(inputName)} type={type} InputLabelProps={(type === "date" || type === "number" || type === "time" || readOnly) ? { shrink: true, } : {}} multiline={multiline ? true : false} maxRows={multiline} minRows={minRows} /> </Grid>)
    if (uncontrolledName) return (<Grid item xs={xs} sm={sm}> <TextField color={color} inputProps={{
        ...inputProps,
        readOnly,
    }} {...a11yProps(uncontrolledName)} defaultValue={defaultValue} label={camelToWords(inputName)} type={type} InputLabelProps={(type === "date" || type === "number" || type === "time" || readOnly) ? { shrink: true, } : {}} multiline={multiline ? true : false} maxRows={multiline} minRows={minRows} /> </Grid>)

    if (description) return (
        <Grid container spacing={2} sx={
            (theme) => ({
                margin: theme.spacing(0),
                // marginBottom: theme.spacing(0),
                padding: theme.spacing(2),
                [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
                    margin: theme.spacing(1),
                    padding: theme.spacing(2),
                },
            })
        }>
            <Grid item xs={xs} sm={sm}> <Typography>{description}</Typography> </Grid>
            <Grid item xs={xs} sm={sm}> <TextField color={color} value={monitored} onChange={(e) => ui[(inputName === "BriefDescriptionOfChangeRequired" || inputName === "RequestDetails") ? "TicketDetails" : `${inputName}` as UIKeys] = e.target.value as never} required {...a11yProps(inputName)} label={camelToWords(inputName)} type={type} InputLabelProps={(type === "date" || type === "number" || type === "time") ? { shrink: true, } : {}} multiline={multiline ? true : false} maxRows={multiline} minRows={minRows} error={monitored === ""} /> </Grid>
        </Grid >)
    if (styledVariant === "required")
        return (<Grid item xs={xs} sm={sm} > <CssTextFieldRequired color={color} onDoubleClick={onDoubleClick} value={monitored} onChange={onChange ? onChange : (e) => ui[(inputName === "BriefDescriptionOfChangeRequired" || inputName === "RequestDetails") ? "TicketDetails" : `${inputName}` as UIKeys] = e.target.value as never} required {...a11yProps(inputName)} label={camelToWords(inputName)} type={type} InputLabelProps={(type === "date" || type === "number" || type === "time") ? { shrink: true, } : {}} multiline={multiline ? true : false} maxRows={multiline} minRows={minRows} error={monitored === ""} /> </Grid>)
    return (<Grid item xs={xs} sm={sm} > <TextField color={color} onDoubleClick={onDoubleClick} value={monitored} onChange={onChange ? onChange : (e) => ui[(inputName === "BriefDescriptionOfChangeRequired" || inputName === "RequestDetails") ? "TicketDetails" : `${inputName}` as UIKeys] = e.target.value as never} required {...a11yProps(inputName)} label={camelToWords(inputName)} type={type} InputLabelProps={(type === "date" || type === "number" || type === "time") ? { shrink: true, } : {}} multiline={multiline ? true : false} maxRows={multiline} minRows={minRows} error={monitored === ""} /> </Grid>)

})

export default TextInput

