import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ui } from '../../interfaces/interfaces';
import ComputerName from '../HowTo/ComputerName';
import { useAppSelector } from '../../store/hooks';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HowToDialog: React.FC = () => {
    const open = useAppSelector(({ ui }) => ui.HowToDialogOpen);
    const name = useAppSelector(({ ui }) => ui.HowToDialogName);
    const howToId = useAppSelector(({ ui }) => ui.HowToDialogId);
    const handleClose = () => {
        ui.HowToDialogOpen = false;
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'sticky' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        How to find: {name}
                    </Typography>
                </Toolbar>
            </AppBar>
            {howToId === 1 && < ComputerName />}
        </Dialog>
    );
}

export default React.memo(HowToDialog)