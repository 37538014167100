// import IndexedDb from "../storage/indexedDB";

// initializing new indexedDB
// const logIndexedDB = new IndexedDb("LOG");

// function initializing the database log store
// const createLogDB = async () => {
//   await logIndexedDB.createObjectStore(["Events", "API"]);
// };

// initializing the database log store
// createLogDB();

export interface GetTimeStamp {
  (): string;
}

export interface Log {
  (type: string, namespace: string, message: string | string[], object?: object): void;
}

export interface Logger {
  info: (namespace: string, message: string, object?: object) => void;
  warn: (namespace: string, message: string, object?: object) => void;
  debug: (namespace: string, message: string, object?: object) => void;
  error: (namespace: string, message: string, object?: object) => void;
  // API: (namespace: string, message: string, object?: object, url?: any) => void;
  group: (namespace: string, messages: string[], object?: object) => void;
}

// get the current time stamp
const getTimeStamp: GetTimeStamp = () => new Date().toISOString();

// logging to database
// const logToDB = async (type: any, namespace: any, message: any, object: any, url?: any) => {
//   // if type is API then we need to log to the API store
//   if (type === "API") {
//     // check if store not exists
//     const checkStoreExists = await logIndexedDB.checkStoreExists("API");
//     if (!checkStoreExists) {
//       // create the store
//       await logIndexedDB.createObjectStore(["API"]);
//     }
//     await logIndexedDB.putValue("API", {
//       timeDate: getTimeStamp(),
//       endPoint: namespace,
//       status: message,
//       url,
//       response: JSON.parse(JSON.stringify(object)),
//     });
//     return;
//   }
//   // if type is not API and not DEBUG then we need to log to the Events store
//   if (namespace !== "IndexedDB API" && type !== "DEBUG") {
//     // check if store not exists
//     const checkStoreExists = logIndexedDB.checkStoreExists("Events");
//     if (!checkStoreExists) {
//       // create the store
//       await logIndexedDB.createObjectStore(["Events"]);
//     }
//     await logIndexedDB.putValue("Events", {
//       timeDate: getTimeStamp(),
//       endPoint: namespace,
//       status: message,
//       url,
//       response: object ? JSON.parse(JSON.stringify(object)) : null,
//     });
//     return;
//   }
// };

// internal logger
const log: Log = (type, namespace, message, object) => {
  // create variable to hold the log message and timestamp
  let msg = [];
  if (typeof message === "string") {
    msg = [`[${getTimeStamp()}] [${type}] [${namespace}] ${message}`];
  } else {
    msg = [`[${getTimeStamp()}] [${type}] [${namespace}]`];
  }
  // check type and use appropriate console method

  //temporary filter for render only
  // if (namespace === "RENDER") {
  if (type === "INFO") console.info(...msg);
  if (type === "WARN") console.warn(...msg);
  if (type === "DEBUG") console.debug(...msg);
  if (type === "ERROR") console.error(...msg);
  if (type === "GROUP") {
    console.log(...msg);
    console.group(namespace);
    for (const mess of message) {
      console.log(mess);
    }
    if (object) {
      // log object to console as table
      console.log(object);
    }
    console.groupEnd();
  }
  // }
  // log to database
  // logToDB(type, namespace, message, object);

  // check if object exists and log it
  if (typeof message === "string" && object) {
    // log object to console as table
    console.log(object);
  }
  // }
};

// Logger
export const logger: Logger = {
  info: (namespace, message, object) => log("INFO", namespace, message, object),
  warn: (namespace, message, object) => log("WARN", namespace, message, object),
  debug: (namespace, message, object) => log("DEBUG", namespace, message, object),
  error: (namespace, message, object) => log("ERROR", namespace, message, object),
  group: (namespace, messages, object) => log("GROUP", namespace, messages, object),
  // API: (namespace, message, object, url) => logToDB("API", namespace, message, object, url),
};
