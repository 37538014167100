// static class for operations on objects
// it will offer deep copy of objects
// it will offer deep copy of arrays

class ObjectsOperations {
  static deepCopyNestedObjectExpensive(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static deepCopyFlatObject(obj: any) {
    return { ...obj };
  }

  static deepCopyArray(arr: any[], ignoreList: string[] = []) {
    // loop through array
    // use recursion to deep copy objects
    // if object is an array, call deepCopyArray
    // if object is an object, call deepCopyObject
    // if object is a instance of Date, return new Date(obj)
    // if object is a primitive, return object
    // if object is null, return null
    // if object is undefined, return undefined
    const newArr: any[] = [];
    for (const item of arr) {
      newArr.push(this.deepCopyObjects(item, ignoreList));
    }
    return newArr;
  }

  static deepCopyObjects(obj: any, ignoreList: string[] = [], endingIgnoreList: string[] = []) {
    // loop through object properties
    // use recursion to deep copy objects
    // if object is an array, call deepCopyArray
    // if object is an object, call deepCopyObject
    // if object is a instance of Date, return new Date(obj)
    // if object is a primitive, return object
    // if object is null, return null
    // if object is undefined, return undefined
    // if object is one of the ignoreList, return
    if (obj === null) return null;
    if (typeof obj === "undefined") {
      return undefined;
    } else if (obj instanceof Date) {
      return new Date(obj.getTime());
    } else if (obj instanceof Array) {
      return this.deepCopyArray(obj);
    } else if (typeof obj !== "object") {
      return obj;
    }
    const newObj: { [key: string]: any } = {};
    for (const prop in obj) {
      if (
        ignoreList.includes(prop) ||
        endingIgnoreList.some((i) => prop.endsWith(i)) ||
        obj[prop] === null ||
        obj[prop] === undefined ||
        obj[prop] === "" ||
        obj[prop] === false ||
        obj[prop] < 1 ||
        (Array.isArray(obj[prop]) && obj[prop].length === 0) || // is empty array
        (typeof obj[prop] === "object" && !(obj[prop] instanceof Date) && Object.keys(obj[prop]).length === 0) // is empty object
      ) {
        continue;
      }
      if (obj.hasOwnProperty(prop)) {
        if (obj[prop] instanceof Date) {
          newObj[prop] = new Date(obj[prop].getTime());
        } else if (typeof obj[prop] === "object") {
          newObj[prop] = this.deepCopyObjects(obj[prop]);
        } else {
          newObj[prop] = obj[prop];
        }
      }
    }
    return newObj;
  }

  static deepCopyObjectsAll(obj: any) {
    // loop through object properties
    // use recursion to deep copy objects
    // if object is an array, call deepCopyArray
    // if object is an object, call deepCopyObject
    // if object is a instance of Date, return new Date(obj)
    // if object is a primitive, return object
    // if object is null, return null
    // if object is undefined, return undefined
    // if object is one of the ignoreList, return
    if (obj === null) return null;
    if (typeof obj === "undefined") {
      return undefined;
    } else if (obj instanceof Date) {
      return new Date(obj.getTime());
    } else if (obj instanceof Array) {
      return this.deepCopyArray(obj);
    } else if (typeof obj !== "object") {
      return obj;
    }
    const newObj: { [key: string]: any } = {};
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (typeof obj[prop] === "object") {
          newObj[prop] = this.deepCopyObjectsAll(obj[prop]);
        } else {
          newObj[prop] = obj[prop];
        }
      }
    }
    return newObj;
  }
}

// decalre new window global object
declare global {
  interface Window {
    Operations: ObjectsOperations;
  }
}

export default ObjectsOperations;
