import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import React from "react"
import { cacheUtils } from "../../.."
import useDebugInformation from "../../../hooks/useDebugInformation"
import { ui } from "../../../interfaces/interfaces"
import { UIKeys } from "../../../services/storage/storeTemplate"
import { useAppSelector } from "../../../store/hooks"
import { CacheTypes } from "../../../utilities/cache/interfaces"
import ActiveIncidentTicket from './../../Layout/TicketsManagement/TicketManagement/TicketPanel/TicketForm/type';

type RadioInput = {
    inputName: "TicketLocation"
    parent: string
    xs: number
    sm: number
}

const getLocations = async () => {
    const locations = cacheUtils.getCache<CacheTypes.Locations>("Locations")
    if (locations && locations.length > 0) {
        ui.TicketLocation = locations[0];
        ui.TicketLocations = locations
    }
}

const MemoizedRadioInputMini = React.memo<RadioInput>(({ parent, inputName, xs, sm }) => {
    const value = useAppSelector(({ ui }) => ui[`${inputName}`]) as ActiveIncidentTicket.Location;
    const options = useAppSelector(({ ui }) => ui[`${inputName}s`]) as ActiveIncidentTicket.Location[];

    React.useEffect(() => { getLocations() }, [])
    useDebugInformation(`${parent}▷[${inputName}]`, { parent, inputName, xs, sm, options, value })
    return (
        <Grid item xs={xs} sm={sm} sx={{ marginTop: "5px" }}>
            <FormControl component="fieldset">
                {/* <FormLabel component="legend">Incident Location</FormLabel> */}
                {options && options.length > 0 && <RadioGroup row aria-label="ticket-location" sx={{ alignItems: "center" }} name={inputName} value={value.id} onChange={(event: React.ChangeEvent<HTMLInputElement>) => ui[`${inputName}` as UIKeys] = options[Number((event.target as HTMLInputElement).value) - 1] as never} >
                    Incident location:
                    {options.map((element: any, idx: number) => <FormControlLabel key={idx} value={element.id} control={<Radio />} label={element.name} disabled={element?.disabled} labelPlacement="start" sx={{ marginBottom: 0 }} />)}
                </RadioGroup>}
            </FormControl >
        </Grid >
    )
})

export default MemoizedRadioInputMini