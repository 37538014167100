import { StateKeys, TemplateSTATEObject } from "../../services/storage/storeTemplate/State";
import { store } from "../../store";
import * as reducer from "./slice";

export const dispatchHydrateState = (state: TemplateSTATEObject) => {
  store.dispatch(
    reducer.hydrate({
      value: state,
    })
  );
};

export const dispatchSetState = (name: StateKeys, payload: any) => {
  store.dispatch(
    reducer.set({
      name,
      value: payload,
    })
  );
};

export const dispatchResetState = (payload?: Array<string>) => {
  store.dispatch(
    reducer.reset({
      value: payload,
    })
  );
};

export const dispatchResetStateWithCache = (payload?: Array<string>) => {
  store.dispatch(
    reducer.resetWithCache({
      value: payload,
    })
  );
};

export const dispatchSaveState = () => {
  store.dispatch(reducer.save());
};

export default reducer;
