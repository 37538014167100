import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import useDebugInformation from "../../hooks/useDebugInformation";
// 

const a11yCombosProps = (name: string, onChange: any, options: any, getOptionLabel: any, isOptionEqualToValue: any, value: any) => {
    return {
        id: `combo-box-${name.toLowerCase()}`,
        name: `ProblemCombo${name}`,
        sx: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor:
                    value?.id
                        ? "green"
                        : "red",
            }
        },
        // error: ui["ProblemCombo" + name] ? false : true,
        options,
        // onBlur: onChange,
        getOptionLabel,
        isOptionEqualToValue,
        value: value ? Object.keys(value).length === 0 ? null : value : null,
        onChange,
        "aria-controls": `action-combo-${name.toLowerCase()}`,
    };
}

type ComboProps = {
    name: string
    onChange: any
    options: any
    getOptionLabel?: any
    isOptionEqualToValue?: any
    sm: number
    value: any
    xs?: number
    renderOptions?: ((props: React.HTMLAttributes<HTMLLIElement>, option: any, state: AutocompleteRenderOptionState) => React.ReactNode)
    helperText?: string
    error?: boolean
    disabled?: boolean
}

const ComboDropDown: React.FC<ComboProps> = ({ name, onChange, options, getOptionLabel, isOptionEqualToValue, sm, value, xs, renderOptions, disabled, error, helperText }) => {

    useDebugInformation("ComboDropDown", { name, onChange, options, getOptionLabel, isOptionEqualToValue, sm, value, xs, disabled })
    return (
        <Grid item xs={xs} sm={sm}>
            <Autocomplete  {...a11yCombosProps(name, onChange, options, getOptionLabel, isOptionEqualToValue, value)} disabled={disabled}
                renderInput={(params) => <TextField {...params} label={name} variant="outlined" autoComplete="off" error={error} helperText={helperText} />} renderOption={renderOptions}
            />
        </Grid>
    )
}

export default React.memo(ComboDropDown)