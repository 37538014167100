import { Tables } from "../../../services/databaseModels/tables";
import { someQuestions, someSubCategories } from "./schema";
import { convertData } from "./../main/index";
import { api } from "./../../../interfaces/interfaces";

class QuestionsManager {
  private _Questions: Questions;
  private _QuestionTypes: QuestionTypes;
  private _SubCategories: SubCategories;
  private _First: number;
  private _Path: number[];
  private _Current: number;

  public get Questions(): Questions {
    return this._Questions;
  }

  public set Questions(Questions: Questions) {
    this._Questions = Questions;
  }
  public get SubCategories(): SubCategories {
    return this._SubCategories;
  }

  public set SubCategories(SubCategories: SubCategories) {
    this._SubCategories = SubCategories;
  }

  public get QuestionTypes(): QuestionTypes {
    return this._QuestionTypes;
  }

  public set QuestionTypes(QuestionTypes: QuestionTypes) {
    this._QuestionTypes = QuestionTypes;
  }

  public get First(): number {
    return this._First;
  }

  public set First(First: number) {
    this._First = First;
  }

  public get Path(): number[] {
    return this._Path;
  }

  public set Path(Path: number[]) {
    this._Path = Path;
  }

  public get Current(): number {
    return this._Current;
  }

  public set Current(Current: number) {
    this._Current = Current;
  }

  constructor() {
    this._QuestionTypes = [
      { id: 1, name: "Boolean", description: "Choose single answer from two options." },
      { id: 2, name: "FreeType", description: "Type single line answer." },
      { id: 3, name: "MultiLineFreeType", description: "Type multi line answer." },
      { id: 4, name: "Radio", description: "Choose single answer from multiple options." },
      { id: 5, name: "DropDown", description: "Select single option/answer from dropdown menu." },
      {
        id: 6,
        name: "DropDownOther",
        description: "Select single option/answer from dropdown menu or choose other to type option/answer.",
      },
      { id: 7, name: "MultiSelect", description: "Select multiple option(s)/answer(s)." },
    ];
    this._Questions = [];
    this._SubCategories = [];
    this._First = -1;
    this._Path = [];
    this._Current = -1;
    someSubCategories.forEach((subcategory: SubCategory) => {
      this.addSubCategory(subcategory);
    });
    someQuestions.forEach((question: Question) => {
      let hasDep = false;
      if ("dependsId" in question || "dependsValue" in question) hasDep = true;
      this.addQuestion(question, hasDep);
    });
    // this.addPrevious()
  }
  addQuestion(question: Question, depends: boolean) {
    let lastId = null;
    if (depends) lastId = this.Questions[this.Questions.length - 1]?.id || null;

    this.Questions.push({
      ...question,
      dependsId: lastId ? lastId : null,
    });
    const index = this.Questions.length;
    this.Questions[index - 1].id = index;
    return index;
  }
  addSubCategory(subcategory: SubCategory) {
    this.SubCategories.push({
      ...subcategory,
    });
    const index = this.SubCategories.length;
    this.SubCategories[index - 1].id = index;
    return index;
  }

  async insertQuestions() {
    let prevId = 0;
    for (let index = 0; index < this.Questions.length; index++) {
      const question = this.Questions[index];
      prevId = await this.insertQuestion(question, question?.dependsId ? prevId : null);
    }
    return true;
  }
  async insertSubCategories() {
    for (let index = 0; index < this.SubCategories.length; index++) {
      const subcategory = this.SubCategories[index];
      await this.insertSubCategory(subcategory);
    }
    return true;
  }

  async insertQuestion(question: Question, depId: number | null) {
    const data: Tables.QuestionsInput = {
      questionTypeId: question.questionTypeId,
      order: question.order,
      question: question.question,
      options: question.options,
      version: question.version,
      subCategoryId: question.subCategoryId,
      dependsId: depId ? depId : null,
      dependsValue: question?.dependsValue ? question.dependsValue : null,
    };
    const response = await api.fetchQuestionsInsert(data);
    const converted = convertData(response);
    return converted.id;
  }

  async insertSubCategory(subcategory: SubCategory) {
    const data: Tables.SubCategoriesInput = {
      name: subcategory.name,
      description: subcategory.description,
      categoryId: subcategory.categoryId,
      ticketTypeId: subcategory.ticketTypeId,
      version: subcategory.version,
      ownerId: subcategory.ownerId,
      secOwnerId: subcategory.secOwnerId,
      enabled: subcategory.enabled,
    };
    const response = await api.fetchSubCategoriesInsert(data);
    const converted = convertData(response);
    return converted.id;
  }
}

export interface SubCategory {
  id?: number;
  name: string;
  categoryId: number;
  description: string;
  ticketTypeId: number;
  ownerId: number;
  secOwnerId?: number;
  version: number;
  enabled: boolean;
}
export interface SubCategories extends Array<SubCategory> {}

export interface Question {
  id?: number;
  questionTypeId: number;
  order: number;
  question: string;
  version: number;
  options?: string;
  dependsId?: number | null;
  dependsValue?: string;
  subCategoryId: number;
}
export interface Questions extends Array<Question> {}

interface QuestionType {
  id: number;
  name: string;
  description: string;
}

interface QuestionTypes extends Array<QuestionType> {}

const questionsManager = new QuestionsManager();

declare global {
  interface Window {
    QuestionsManager: any;
  }
}

window.QuestionsManager = questionsManager;
export default questionsManager;
