import { logger } from "../../services/logger";
import { Tables } from "../../services/storage/model";
import { CacheTypes } from "./interfaces";
// CacheUtils class definition
// it will hold object with cache data, indexed by key
// and will provide methods to get, set and remove data
// It will implement StorageUtils interface
// It will implement IndexedDB interface
// It will implement Cache class

const nameSpace = "CacheUtils";

export type CacheTables =
  | "Categories"
  | "Files"
  | "Locations"
  | "Priorities"
  | "Questions"
  | "QuestionsTypes"
  | "RequestCategories"
  | "Roles"
  | "Statuses"
  | "SubCategories"
  | "TicketCategories"
  | "TicketTypes"
  | "Users";

export default class CacheUtils {
  private cacheStore: { [key in CacheTables]: Cache<CacheTypes.CacheUtilsTypes> } = {
    Categories: new Cache<CacheTypes.Categories>("Categories"),
    Files: new Cache<CacheTypes.Files>("Files"),
    Locations: new Cache<CacheTypes.Locations>("Locations"),
    Priorities: new Cache<CacheTypes.Priorities>("Priorities"),
    Questions: new Cache<CacheTypes.Questions>("Questions"),
    QuestionsTypes: new Cache<CacheTypes.QuestionsTypes>("QuestionsTypes"),
    RequestCategories: new Cache<CacheTypes.RequestCategories>("RequestCategories"),
    Roles: new Cache<CacheTypes.Roles>("Roles"),
    Statuses: new Cache<CacheTypes.Statuses>("Statuses"),
    SubCategories: new Cache<CacheTypes.SubCategories>("SubCategories"),
    TicketCategories: new Cache<CacheTypes.TicketCategories>("TicketCategories"),
    TicketTypes: new Cache<CacheTypes.TicketTypes>("TicketTypes"),
    Users: new Cache<CacheTypes.Users>("Users"),
  };

  public getEmptyIndexes = (): Cache<CacheTypes.CacheUtilsTypes>[] => {
    const emptyIndexes: Cache<CacheTypes.CacheUtilsTypes>[] = [];
    Object.values(this.cacheStore).forEach((cache) => {
      if (cache.getCacheSize() === 0) {
        emptyIndexes.push(cache);
      }
    });
    return emptyIndexes;
  };

  public areEmptyStoresPresent(): boolean {
    return this.getAllLengths().some((size) => size === 0);
  }

  public getAllLengths = () => {
    return Object.values(this.cacheStore).map((cache) => cache.getCacheSize());
  };

  public getLength(table: CacheTables): Number {
    return this.cacheStore[table].getCacheSize();
  }

  public isInCacheStore(key: CacheTables): boolean {
    return this.cacheStore[key] !== undefined;
  }

  public getValue(key: CacheTables): Cache<CacheTypes.CacheUtilsTypes> {
    return this.cacheStore[key];
  }

  public setValue(key: CacheTables, value: Cache<CacheTypes.CacheUtilsTypes>): void {
    this.cacheStore[key] = value;
  }

  public setCache(key: CacheTables, value: CacheTypes.CacheUtilsTypes[]): void {
    this.cacheStore[key].setCache(value);
  }

  public getCache<T>(key: CacheTables): Array<T> {
    return this.cacheStore[key].getCache() as unknown as Array<T>;
  }
}

// Cache class definition
// it will hold data obtained from api method calls
// it will refresh on set interval

class Cache<T> {
  private cache: Array<T> = [];
  private cacheSize: number = 0;
  private tableName: Tables;

  constructor(tableName: Tables) {
    // log with logger
    logger.debug(nameSpace, `Cache constructor called for ${tableName}`);
    this.tableName = tableName;
  }

  public getCacheSize(): number {
    return this.cacheSize;
  }

  public getCache() {
    return this.cache;
  }

  public setCache(cache: Array<T>) {
    // log with logger
    this.cache = cache;
  }
}

// cache helpers class
export class CacheHelpers {
  public static dateDifference(current: number, future: number): string {
    let diffTime = Math.abs(future - current);
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];

    return `${days} days, ${hours} hours, ${minutes} minutes, ${secs} seconds`;
  }
}
