import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../../hooks/useDebugInformation';
import Grid from '@mui/material/Grid';
import { api, state, ui } from '../../../../../../interfaces/interfaces';
import TextInput from '../../../../../shared/TextInput';
import ActiveIncidentTicket from './type';
import { clearAnswers, compileAnswers, hasTicketStateChanged, questionsOptions, setMsg, updateState } from '../../../../../../assets/js/main';
import MemoizedDropDownMini from '../../../../../shared/Inputs/DropDownQuestionMini';
import ScrollableDialog from '../../../../../shared/ScrollableDialog';
import Skeleton from '@mui/material/Skeleton';
import StandardTicket from './StandardTicket';
import StandardTicketMini from './StandardTicketMini';
import StandardRequest from './StandardRequest';
import SystemRequest from './SystemRequest';
import HeaderTable from './HeaderTable';
import AttachmentsTable from './AttachmentsTable';
import { InputNames } from '../../../../../../utilities/basicTypes';
import { TemplateUIObject, UIKeys } from '../../../../../../services/storage/storeTemplate';
import { useAppSelector } from '../../../../../../store/hooks';
import { logger } from '../../../../../../services/logger';
import { style } from '../../../../../../utilities/style';
import { CacheTypes } from '../../../../../../utilities/cache/interfaces';
import { cacheUtils } from '../../../../../..';

const name = "TicketIncidentForm"

const statusGetOptionLabel = (option: any) => option?.name || ""
const statusIsOptionEqualToValue = (option: any, value: any) => option?.id === value?.id
const statusOptions = (opts: CacheTypes.Categories[]) => opts && opts?.length > 0 ? opts : []
const priorityGetOptionLabel = (option: any) => option?.name || ""
const priorityIsOptionEqualToValue = (option: any, value: any) => option?.id === value?.id
const priorityOptions = (opts: CacheTypes.Categories[]) => opts && opts?.length > 0 ? opts : []

const handleStatusChange = (evt: { target: { value: any; name: any; }; }, value: any,) => {
    ui.TicketStatus = value
    hasTicketStateChanged()
}
const handlePriorityChange = (evt: { target: { value: any; name: any; }; }, value: any,) => {
    ui.TicketPriority = value
    hasTicketStateChanged()
}

type Props = {
    parent: string
}

const getPriorities = () => ui.TicketPriorities = cacheUtils.getCache<CacheTypes.Priorities>("Priorities")
const getStatuses = () => ui.TicketStatuses = cacheUtils.getCache<CacheTypes.Statuses>("Statuses")
const getLocations = () => ui.TicketLocations = cacheUtils.getCache<CacheTypes.Locations>("Locations")
const getQuestions = (setQuestions: any) => {
    const questions = cacheUtils.getCache<CacheTypes.Questions>("Questions")
    if (questions) {
        ui.TicketSubcategory?.id && (ui.OptionalQuestions = questionsOptions(questions, ui.TicketSubcategory.id))
        setQuestions(questions);
    }
}

const getAnswers = (a: ActiveIncidentTicket.Answer[]) => {
    const response = {} as { [key: string]: string }
    a.forEach((ans, idx) => {
        response[`answer${idx + 1}`] = ans.value
        ui[`HasQuestion${idx + 1}` as UIKeys] = true as never
    })
    return response
}

const getTicketById = async (id: number) => {
    const ticket = (ui.TicketTypeId === 1 ? (await api.fetchTicketsWithFK(id)) : (await api.fetchRequestsWithFK(id)))
    ticket && (state.ActiveTicket = { ...ticket })
}

type File = {
    data: string;
    dateAdded: string;
    fileDate: string;
    fileName: string;
    fileSize: number;
    fileType: string;
    id: number
}

const TicketIncidentForm: React.FC = () => {
    const debugName = `${"LINK"}▷[${name}]`
    const activeTicket = useAppSelector(({ state }) => state.ActiveTicket)
    const ticketPriority = useAppSelector(({ ui }) => ui.TicketPriority)
    const ticketStatus = useAppSelector(({ ui }) => ui.TicketStatus)
    const ticketDetails = useAppSelector(({ ui }) => ui.TicketDetails)
    const ticketSubcategory = useAppSelector(({ ui }) => ui.TicketSubcategory)
    const ticketCategory = useAppSelector(({ ui }) => ui.TicketCategory)
    const requestChanged = useAppSelector(({ ui }) => ui.RequestChanged)
    const answer = useAppSelector(({ ui }) => ui.Answer)
    const ticketId = useAppSelector(({ ui }) => ui.TicketId)
    const ticketDate = useAppSelector(({ ui }) => ui.TicketDate)
    const ticketLocation = useAppSelector(({ ui }) => ui.TicketLocation)
    const dateCreated = useAppSelector(({ ui }) => ui.DateCreated)
    const dateUpdated = useAppSelector(({ ui }) => ui.DateUpdated)
    const updatedBy = useAppSelector(({ ui }) => ui.UpdatedBy)
    const assignedTo = useAppSelector(({ ui }) => ui.AssignedTo)
    const requestUpdateActive = useAppSelector(({ state }) => state.RequestUpdateActive)
    const optionalQuestions = useAppSelector(({ ui }) => ui.OptionalQuestions)
    const viewOnly = useAppSelector(({ ui }) => ui.ViewOnly)

    const [files, setFiles] = React.useState([]) as [File[], any]
    const [updated, setUpdated] = React.useState(false)
    const [questions, setQuestions] = React.useState([] as { question: string }[])
    const [answers, setAnswers] = React.useState([] as { value: string; question: string; order: number; }[])

    // FIRST RENDER AND CLEANUP
    React.useEffect(() => {
        ui.Loading = true
        getPriorities()
        getStatuses()
        getLocations()
        return () => {
            clearAnswers(ui, state)
            logger.debug("Ticket Form", "CLEAN UP")
        }
    }, [])

    // UPDATE DB ON BUTTON CLICK
    React.useEffect(() => {
        if (!ui.Loading && requestUpdateActive && requestChanged) updateState()
    }, [requestUpdateActive, requestChanged])

    // FETCH TICKET
    React.useEffect(() => {
        if ((ticketId && ticketId > 0) && !activeTicket) {
            setUpdated(false)
            if (ticketId > 0) getTicketById(ticketId)
        }
    }, [activeTicket, ticketId])

    React.useEffect(() => {
        if (activeTicket && activeTicket?.updatedById && !updated) {
            ui.TicketSubcategory = activeTicket.subCategory
            ui.TicketCategory = activeTicket.category
            ui.Answer = { [activeTicket.subCategoryId]: getAnswers(activeTicket.answers) } as TemplateUIObject["Answer"]
            ui.TicketDetails = activeTicket.ticketDetails?.value || ""
            ui.ImmediateAction = activeTicket.immediate?.value || ""
            ui.LongTermAction = activeTicket.longTerm?.value || ""
            ui.TicketProgress = activeTicket.progress?.value || ""
            ui.TicketId = activeTicket.id || -1
            ui.DateCreated = activeTicket.dateReported || ""
            ui.DateUpdated = activeTicket.dateUpdated || ""
            ui.TicketTime = activeTicket.ticketDatetime?.toTimeString().split(" ")[0] || ""
            ui.TicketDate = activeTicket.ticketDatetime || ""

            activeTicket?.assignee && (ui.AssignedTo = activeTicket.assignee)
            activeTicket?.updatedBy && (ui.UpdatedBy = activeTicket.updatedBy)
            activeTicket?.location && (ui.TicketLocation = activeTicket.location)
            activeTicket?.priority && (ui.TicketPriority = activeTicket.priority)
            activeTicket?.status && (ui.TicketStatus = activeTicket.status)

            getQuestions(setQuestions)
            setFiles(activeTicket.attachments || [])
            logger.info("TICKET", "ActiveTicket", activeTicket ?? undefined)
            setUpdated(true)
        }
    }, [activeTicket, updated])

    // GENERATE ANSWERS TO OPTIONAL QUESTIONS
    React.useEffect(() => { if (questions.length > 0 && ui.TicketSubcategory?.id && optionalQuestions.length > 0) compileAnswers(setAnswers) }, [questions, answer, optionalQuestions])

    useDebugInformation(debugName, {
        activeTicket,
        ticketPriority,
        ticketStatus,
        ticketDetails,
        requestChanged,
        answer,
        ticketId,
        ticketDate,
        ticketLocation,
        dateCreated,
        dateUpdated,
        requestUpdateActive,
        files,
        questions,
        answers,
    })
    return (
        <Box id="form-panel" component="form"
            noValidate
            autoComplete="off">
            <ScrollableDialog />
            {!updated && <Skeleton />}
            {updated &&
                <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperInnerTicketForm}>
                    <Paper sx={(theme) => style(theme).paperStatus} variant="elevation" elevation={4}>
                        <HeaderTable infos={{ ticketId, dateCreated, dateUpdated, ticketDate, ticketLocation: ticketLocation ? ticketLocation : undefined, updatedBy: updatedBy ? updatedBy : "", assignedTo: assignedTo ? assignedTo : "", reportedBy: state.Reportee, ticketTypeId: ui.TicketTypeId }} />
                    </Paper>
                    <Grid container spacing={2} margin={0} marginTop={1} gap={1} width={"100%"}>
                        {ticketPriority && <Paper sx={(theme) => style(theme).paperItem({ maxWidth: "50%" })} variant="elevation" elevation={4}>
                            {!viewOnly ? <MemoizedDropDownMini helperText={ticketPriority && ticketPriority.id === 1 ? "Please set Priority!" : ""} error={ticketPriority && ticketPriority.id === 1} options={priorityOptions(ui.TicketPriorities)} xs={12} sm={12} isOptionEqualToValue={priorityIsOptionEqualToValue} getOptionLabel={priorityGetOptionLabel} name='Request Priority' value={ticketPriority} handleChange={handlePriorityChange} />
                                : <TextInput readOnly styledVariant='disabled' uncontrolledName={`TicketPriority`} inputName={`TicketPriority` as InputNames} parent={debugName} xs={12} sm={12} defaultValue={ticketPriority.name} />}
                        </Paper>}
                        {ticketStatus && <Paper sx={(theme) => style(theme).paperItem()} variant="elevation" elevation={4}>
                            {!viewOnly ? <MemoizedDropDownMini options={statusOptions(ui.TicketStatuses)} name='Request Status' handleChange={handleStatusChange} value={ticketStatus} isOptionEqualToValue={statusIsOptionEqualToValue} getOptionLabel={statusGetOptionLabel} xs={12} sm={12} />
                                : <TextInput readOnly styledVariant='disabled' uncontrolledName={`TicketStatus`} inputName={`TicketStatus` as InputNames} parent={debugName} xs={12} sm={12} defaultValue={ticketStatus.name} />}
                        </Paper>}
                    </Grid>
                    <Grid container spacing={2} margin={0} marginTop={1} gap={1} width={"100%"}>
                        {ticketCategory && <Paper sx={(theme) => style(theme).paperItem({ maxWidth: "50%" })} variant="elevation" elevation={4}>
                            <TextInput readOnly styledVariant='disabled' uncontrolledName={`TicketCategory`} inputName={`TicketCategory` as InputNames} parent={debugName} xs={12} sm={12} defaultValue={ticketCategory?.name} />
                        </Paper>}
                        {ticketSubcategory && <Paper sx={(theme) => style(theme).paperItem()} variant="elevation" elevation={4}>
                            <TextInput readOnly styledVariant='disabled' uncontrolledName={`TicketSubcategory`} inputName={`TicketSubcategory` as InputNames} parent={debugName} xs={12} sm={12} defaultValue={ticketSubcategory?.name} />
                        </Paper>}
                    </Grid>
                    {answers.length > 0 && activeTicket?.subCategory.ticketTypeId === 3 && <SystemRequest answers={answers} parent={debugName} viewOnly={viewOnly} />}
                    {answers.length > 0 && activeTicket?.subCategory.ticketTypeId === 2 && <StandardRequest answers={answers} parent={debugName} viewOnly={viewOnly} />}
                    {answers.length > 0 && activeTicket?.subCategory.ticketTypeId === 1 && <StandardTicket answers={answers} parent={debugName} />}
                    {answers.length >= 0 && activeTicket?.subCategory.ticketTypeId === 1 && <StandardTicketMini parent={debugName} viewOnly={viewOnly} />}
                    {ui.TicketTypeId !== 3 && <Paper variant="elevation" elevation={4} sx={(theme) => ({ ...style(theme).paperAttachments, padding: 1 })}>
                        <Typography align="center" variant="subtitle2">
                            Attachments:
                        </Typography>
                        {files.length > 0 ? <AttachmentsTable files={files} setMsg={setMsg} /> : <Typography align="center" variant="subtitle2">
                            NO FILE ATTACHMENTS FOUND:
                        </Typography>}
                    </Paper>}
                </Paper>}
        </Box >
    )
}

export default React.memo(TicketIncidentForm)
