/**
 * Cancellation token class for cancelling async operations.
 * @class CancellationToken
 * @remarks This class implements the CancellationToken interface.
 * @property {boolean} isCancelled - Indicates whether the token has been cancelled.
 * @memberof utils.tokens
 * @author Adam Rzymski
 * @date 2022-03-29
 * @version 1.0.0
 * @public
 * @instance
 * @example
 * const token = new CancellationToken();
 * token.cancel();
 * token.isCancelled; // true
 * @returns {CancellationToken}
 * CancellationToken
 */
export class CancellationToken {
  private _isCancelled: boolean = false;
  private _name: string = "";

  constructor(name: string) {
    this._isCancelled = false;
    this._name = name;
  }

  get isCancelled(): boolean {
    return this._isCancelled;
  }

  get name(): string {
    return this._name;
  }

  /**
   * Cancels the token.
   * @method cancel
   * @memberof utils.tokens.CancellationToken
   * @public
   * @instance
   * @example
   * const token = new CancellationToken();
   * token.cancel();
   * token.isCancelled; // true
   * @returns {void}
   */
  cancel(): void {
    this._isCancelled = true;
  }

  /**
   * Returns the token.
   * @method getToken
   * @memberof utils.tokens.CancellationToken
   * @public
   * @instance
   * @example
   * const token = new CancellationToken();
   * token.getToken();
   * @returns {CancellationToken}
   * CancellationToken
   */
  getToken(): CancellationToken {
    return this;
  }
}

/**
 * Cancellation token store class for storing and managing CancellationTokens
 * @class CancellationTokenStore
 * @remarks This class implements the CancellationTokenStore interface.
 * @property {tokens} tokens - Object of CancellationTokens.
 * @memberof utils.tokens
 * @author Adam Rzymski
 * @date 2022-03-29
 * @version 1.0.0
 * @public
 * @instance
 * @example
 * const tokenStore = new CancellationTokenStore();
 * @returns {CancellationTokenStore}
 * CancellationTokenStore
 */
export class CancellationTokenStore {
  private _tokens: { [key: string]: CancellationToken } = {};

  constructor() {
    this._tokens = {};
  }

  /**
   * Adds a token to the store.
   * @method addToken
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * @returns {void}
   * void
   * @param {string} name - Name of the token.
   * @param {CancellationToken} token - CancellationToken to add.
   */
  addToken(name: string, token: CancellationToken): void {
    this._tokens[name] = token;
  }

  /**
   * Returns a token from the store.
   * @method getToken
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.getToken('tokenName');
   * @returns {CancellationToken}
   * CancellationToken
   * @param {string} name - Name of the token.
   */
  getToken(name: string): CancellationToken {
    return this._tokens[name];
  }

  /**
   * Returns all tokens from the store.
   * @method getTokens
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.getTokens();
   * @returns {CancellationToken[]}
   * CancellationToken[]
   */
  getTokens(): CancellationToken[] {
    return Object.values(this._tokens);
  }

  /**
   * Removes a token from the store.
   * @method removeToken
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.removeToken('tokenName');
   * @returns {void}
   * void
   * @param {string} name - Name of the token.
   */
  removeToken(name: string): void {
    delete this._tokens[name];
  }

  /**
   * Removes all tokens from the store.
   * @method removeTokens
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.removeTokens();
   * @returns {void}
   * void
   */

  removeTokens(): void {
    this._tokens = {};
  }

  /**
   * Returns the number of tokens in the store.
   * @method getTokenCount
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.getTokenCount();
   * @returns {number}
   * number
   */
  getTokenCount(): number {
    return Object.keys(this._tokens).length;
  }

  /**
   * Returns true if the store contains a token with the given name.
   * @method hasToken
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.hasToken('tokenName');
   * @returns {boolean}
   * boolean
   * @param {string} name - Name of the token.
   */

  hasToken(name: string): boolean {
    return this._tokens[name] !== undefined;
  }

  /**
   * Returns true if the store contains no tokens.
   * @method isEmpty
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.isEmpty();
   * @returns {boolean}
   * boolean
   * @param {string} name - Name of the token.
   */

  isEmpty(): boolean {
    return Object.keys(this._tokens).length === 0;
  }

  /**
   * Generate a name for a token.
   * @method generateTokenName
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.generateTokenName();
   * @returns {string}
   * string
   */

  generateTokenName(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  /**
   * Returns a list of all token names in the store.
   * @method getTokenNames
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.getTokenNames();
   * @returns {string[]}
   * string[]
   */

  getTokenNames(): string[] {
    return Object.keys(this._tokens);
  }

  /**
   * Generates a new token and adds it to the store.
   * @method generateToken
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.generateToken();
   * @returns {CancellationToken}
   * CancellationToken
   */

  generateToken(): CancellationToken {
    const name = this.generateTokenName();
    const token = new CancellationToken(name);
    this.addToken(name, token);
    return token;
  }

  /**
   * Creates and adds a token to the store with the given name.
   * @method createToken
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.createToken('tokenName');
   * @returns {CancellationToken}
   * CancellationToken
   * @param {string} name - Name of the token.
   */

  createToken(name: string): CancellationToken {
    const token = new CancellationToken(name);
    this.addToken(name, token);
    return token;
  }

  /**
   * Check if token is cancelled.
   * @method isCancelled
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.isCancelled('tokenName');
   * @returns {boolean}
   * boolean
   * @param {string} name - Name of the token.
   */

  isCancelled(name: string): boolean {
    return this._tokens[name].isCancelled;
  }

  /**
   * Cancels the token with the given name.
   * @method cancelToken
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.cancelToken('tokenName');
   * @returns {void}
   * void
   * @param {string} name - Name of the token.
   */

  cancelToken(name: string): void {
    this._tokens[name].cancel();
  }

  /**
   * Cancels all tokens in the store.
   * @method cancelAllTokens
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.cancelAllTokens();
   * @returns {void}
   * void
   */

  cancelAllTokens(): void {
    Object.keys(this._tokens).forEach((name) => {
      this._tokens[name].cancel();
    });
  }

  /**
   * Removes all cancelled tokens from the store.
   * @method removeCancelledTokens
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.cancelToken('tokenName');
   * tokenStore.removeCancelledTokens();
   * @returns {void}
   * void
   */

  removeCancelledTokens(): void {
    Object.keys(this._tokens).forEach((name) => {
      if (this._tokens[name].isCancelled) {
        delete this._tokens[name];
      }
    });
  }

  /**
   * Gets all cancelled tokens from the store.
   * @method getCancelledTokens
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.cancelToken('tokenName');
   * tokenStore.getCancelledTokens();
   * @returns {CancellationToken[]}
   * CancellationToken[]
   */

  getCancelledTokens(): CancellationToken[] {
    const cancelledTokens: CancellationToken[] = [];
    Object.keys(this._tokens).forEach((name) => {
      if (this._tokens[name].isCancelled) {
        cancelledTokens.push(this._tokens[name]);
      }
    });
    return cancelledTokens;
  }

  /**
   * Gets all active tokens from the store.
   * @method getActiveTokens
   * @memberof utils.tokens.CancellationTokenStore
   * @public
   * @instance
   * @example
   * const tokenStore = new CancellationTokenStore();
   * tokenStore.addToken('tokenName', new CancellationToken());
   * tokenStore.cancelToken('tokenName');
   * tokenStore.getActiveTokens();
   * @returns {CancellationToken[]}
   * CancellationToken[]
   */

  getActiveTokens(): CancellationToken[] {
    const activeTokens: CancellationToken[] = [];
    Object.keys(this._tokens).forEach((name) => {
      if (!this._tokens[name].isCancelled) {
        activeTokens.push(this._tokens[name]);
      }
    });
    return activeTokens;
  }
}

export default CancellationTokenStore;
