import { configureStore } from "@reduxjs/toolkit";
// import { createLogger } from "redux-logger";
import apiReducer from "../reducers/apiReducer";
import stateReducer from "../reducers/stateReducer";
import cacheReducer from "../reducers/cacheReducer";
import uiReducer from "../reducers/uiReducer";
import middlewarePostProcessor from "../middleware";
// import { validationListenerMiddleware } from "./listener";

export const store = configureStore(
  {
    reducer: { api: apiReducer.reducer, state: stateReducer.reducer, ui: uiReducer.reducer, cache: cacheReducer.reducer },
    middleware: [middlewarePostProcessor],
    //  (getDefaultMiddleware) =>
    //   getDefaultMiddleware({ serializableCheck: false })
    //     .prepend(validationListenerMiddleware.middleware)
    //     .concat(middlewarePostProcessor),
  }
  // middleware: [middlewarePostProcessor,createLogger()],
);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

//declare window global variables
declare global {
  interface Window {
    ReduxStore: typeof store;
  }
}

window.ReduxStore = store;
