// class subscriptions for subscriptions, it will be used to subscribe to events
// store subscriptions in a object with namespace as key
// subscriptions can be asynchronous and will be called when the event is triggered
// subscriptions are called with the event object as the first argument, and the namespace as the second argument
// each subscription will be represented by subscription class

export class Subscription {
    public namespace: string;
    public client: string
    public callback: any;
    constructor(namespace: string, client: string, callback: any) {
        this.namespace = namespace;
        this.client = client;
        this.callback = callback;
    }

    getNamespace() {
        return this.namespace;
    }

    getCallback() {
        return this.callback;
    }

    getClient() {
        return this.client;
    }

    setNamespace(namespace: string) {
        this.namespace = namespace;
    }

    setClient(client: string) {
        this.client = client;
    }

    setCallback(callback: any) {
        this.callback = callback;
    }
}

export class Subscriptions {
    private subscriptions: { [key: string]: Subscription[] } = {};
    constructor() {
        this.subscriptions = {};
    }
    // subscribe to an event
    subscribe(namespace: string, client: string, callback: any) {
        if (!this.subscriptions[namespace]) {
            this.subscriptions[namespace] = [];
        }
        this.subscriptions[namespace].push(new Subscription(namespace, client, callback));
    }
    // unsubscribe from an event
    unsubscribe(namespace: string, client: string) {
        if (!this.subscriptions[namespace]) {
            return;
        }
        this.subscriptions[namespace] = this.subscriptions[namespace].filter(
            (subscription) => {
                return subscription.client !== client;
            }
        );
    }
    // unsubscribe from all events
    unsubscribeAll(client: any) {
        Object.keys(this.subscriptions).forEach((namespace) => {
            this.subscriptions[namespace] = this.subscriptions[namespace].filter(
                (subscription) => {
                    return subscription.client !== client;
                }
            );
        });
    }
    // publish an event
    publish(namespace: string, event: any) {
        if (!this.subscriptions[namespace]) {
            return;
        }
        this.subscriptions[namespace].forEach((subscription) => {
            subscription.callback(event, namespace);
        });
    }
    // get subscriptions
    getSubscriptions(namespace: string) {
        return this.subscriptions[namespace];
    }
    // get all subscriptions
    getAllSubscriptions() {
        return this.subscriptions;
    }
    // event namespaces
    getNamespaces() {
        return Object.keys(this.subscriptions);
    }
    // delete subscriptions
    deleteSubscriptions(namespace: string) {
        delete this.subscriptions[namespace];
    }
    // delete all subscriptions
    deleteAllSubscriptions() {
        this.subscriptions = {};
    }
    // rename subscriptions
    renameSubscriptions(oldNamespace: string, newNamespace: string) {
        if (!this.subscriptions[oldNamespace]) {
            return;
        }
        this.subscriptions[newNamespace] = this.subscriptions[oldNamespace];
        this.subscriptions[newNamespace].forEach((subscription) => {
            subscription.setNamespace(newNamespace);
        });
        delete this.subscriptions[oldNamespace];
    }
    //replace callback
    replaceCallback(namespace: string, client: string, newCallback: any) {
        if (!this.subscriptions[namespace]) {
            return;
        }
        this.subscriptions[namespace] = this.subscriptions[namespace].map(
            (subscription) => {
                if (subscription.client === client) {
                    subscription.setCallback(newCallback);
                }
                return subscription;
            }
        );
    }
}

const subscriptions = new Subscriptions();

// declare subscriptions on the window object
declare global {
    interface Window {
        subscriptions: Subscriptions;
    }
}

window.subscriptions = subscriptions;

export default subscriptions;