import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import Box from '@mui/material/Box';
import useDebugInformation from '../../../../../../hooks/useDebugInformation';
import Grid from '@mui/material/Grid';
import DropZone from '../../../../../shared/DropZone';
import TextInput from '../../../../../shared/TextInput';
import { state, ui } from '../../../../../../interfaces/interfaces';
import { useAppSelector } from '../../../../../../store/hooks';
import { style } from '../../../../../../utilities/style';

const NAME = "IncidentPanel"
const STEP = 1

const areInputsValid = () => {
    const inputs = []

    inputs.push((ui.TicketDetails !== "" && ui.TicketDetails !== null))
    state[`Step${STEP}Valid`] = inputs.every(x => x === true)
    return
}

type Props = {
    parent: string
}

const IncidentPanel: React.FC<Props> = ({ parent }) => {
    const debugName = `${parent}▷[${NAME}]`
    const ticketDetails = useAppSelector(({ ui }) => ui.TicketDetails)

    React.useEffect(() => areInputsValid(), [ticketDetails])

    useDebugInformation(debugName, {})
    return (
        <Box id="form-panel" >
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={12}>
                    <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paper} >
                        <TextInput inputName="TicketDetails" parent={debugName} xs={12} sm={12} type="text" multiline={9} minRows={9} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography align="center" variant="subtitle2">
                        Supporting files (max 3 files/ max size 5MB per file) - if more files are needed please zip it
                    </Typography></Grid>
                <DropZone parent={NAME} />
            </Grid>
        </Box >
    )
}
export default React.memo(IncidentPanel)