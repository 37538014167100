import { CacheKeys, TemplateCACHEObject } from "../../services/storage/storeTemplate/Cache";
import { store } from "../../store";
import * as reducer from "./slice";

export const dispatchHydrateCache = (state: TemplateCACHEObject) => {
  store.dispatch(
    reducer.hydrate({
      value: state,
    })
  );
};

export const dispatchSetCache = (name: CacheKeys, payload: any) => {
  store.dispatch(
    reducer.set({
      name,
      value: payload,
    })
  );
};

export const dispatchResetCache = (payload?: Array<string>) => {
  store.dispatch(
    reducer.reset({
      value: payload,
    })
  );
};

export const dispatchCaching = () => {
  store.dispatch(reducer.caching());
};

export const dispatchCached = () => {
  store.dispatch(reducer.cached());
};

export const dispatchResetCacheWithCache = (payload?: Array<string>) => {
  store.dispatch(
    reducer.resetWithCache({
      value: payload,
    })
  );
};

export const dispatchSaveCache = () => {
  store.dispatch(reducer.save());
};

// export const dispatchCacheLoading = () => {
//   store.dispatch(reducer.loading());
// };

// export const dispatchCacheLoaded = () => {
//   store.dispatch(reducer.loaded());
// };

export default reducer;
