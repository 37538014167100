import axiosAPIService from "..";

export declare namespace Email {
  export interface EmailSendInput {
    fromUserName: string;
    fromEmail: string;
    toUserName: string;
    toEmail: string;
    subject: string;
    message: string;
  }

  export interface EmailSend {
    (data: EmailSendInput): Promise<void>;
  }
}

export const EmailSend: Email.EmailSend = async (a) => {
  await axiosAPIService.post("Email", a);
};

export const EmailEndpoints = {
  EmailSend,
};
