import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react"
import useDebugInformation from "../../../hooks/useDebugInformation"
import ComboDropDown from "../ComboDropDown"
import LightToolTip from "../LightToolTip";

const renderOptions = (props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement> & React.LiHTMLAttributes<HTMLLIElement>, option: { description: string; name: string; id: number }, { selected }: any) => {
    return (
        <LightToolTip key={option.id} title={option.description} placement="right-end">
            <li {...props}>
                <Box display={'flex'} ml={3} flexDirection={'column'}>
                    <Box display={'flex'} flexDirection={'row'} >
                        <Typography color={'text.primary'}>
                            {option.name}
                        </Typography>
                    </Box>
                </Box>
            </li >
        </LightToolTip>
    );
}

type MemoizedDropDownInput = {
    value: any
    options: any
    name: string
    sm: number
    xs: number
    disabled?: boolean
    getOptionLabel?: any
    isOptionEqualToValue?: any
    helperText?: string
    error?: boolean
    handleChange: (e: { target: { value: any; name: any; }; }, value: any) => void
}

const MemoizedDropDownMini = React.memo<MemoizedDropDownInput>(({ helperText, error, sm, xs, disabled, name, options, value, getOptionLabel, isOptionEqualToValue, handleChange }) => {
    const debugName = "MemoizedDropDownMini"
    useDebugInformation(debugName, { sm, xs, disabled, name, options, value, getOptionLabel, isOptionEqualToValue, helperText, error, handleChange })
    return (<ComboDropDown
        sm={sm}
        xs={xs}
        disabled={disabled}
        name={name}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(e: { target: { value: any; name: any; }; }, value: any) => handleChange(e, value,)}
        value={value}
        renderOptions={renderOptions}
        error={error}
        helperText={helperText}
    />)
})
export default MemoizedDropDownMini