import * as UIInterface from "./interface";
export * from "./interface";
export const uiTemplate: UIInterface.TemplateUI = () => ({
  ActiveStep: 0,
  Answer: {
    0: {
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
      answer5: "",
      answer6: "",
      answerOther1: "",
      answerOther2: "",
      answerOther3: "",
      answerOther4: "",
      answerOther5: "",
      answerOther6: "",
    },
  },
  Answer1: "",
  Answer2: "",
  Answer3: "",
  Answer4: "",
  Answer5: "",
  Answer6: "",
  AnswerOther1: "",
  AnswerOther2: "",
  AnswerOther3: "",
  AnswerOther4: "",
  AnswerOther5: "",
  AnswerOther6: "",
  AssignedTo: {
    eMail: "",
    firstName: "",
    id: 0,
    initials: "",
    lastName: "",
    roleId: 0,
  },
  AssigneeId: -1,
  DateCreated: "",
  DateUpdated: "",
  DateReported: "",
  Done: false,
  Files: undefined,
  FilterAssignedTo: "",
  FilterDateCreated: "",
  FilterDateUpdated: "",
  FilteredData: [],
  FilteredDataLength: 0,
  FilterReportedBy: "",
  FilterTicket: -1,
  FilterUpdatedBy: "",
  ForwardEmailDialog: false,
  GlobalFilter: "",
  HasQuestion1: false,
  HasQuestion2: false,
  HasQuestion3: false,
  HasQuestion4: false,
  HasQuestion5: false,
  HasQuestion6: false,
  HowToDialogId: 0,
  HowToDialogName: "",
  HowToDialogOpen: false,
  ImmediateAction: "",
  IncidentsFooters: [],
  IncidentsTableData: [],
  IsFiltered: false,
  IsOtherCategory: false,
  IsOtherSubcategory: false,
  ItMembers: [],
  lg: false,
  Loading: false,
  LongTermAction: "",
  ManageFooters: [],
  ManageTableData: [],
  md: false,
  MyOnly: false,
  OpenOnly: false,
  ClosedOnly: false,
  OptionalQuestions: [],
  OtherCategory: "",
  OtherSubcategory: "",
  OverallFooters: [],
  OverallTableData: [],
  Page: 0,
  ProblemMsg: "",
  ProblemMsgVariant: "success",
  ProblemShowMsg: false,
  ReassignDialog: false,
  RequestChanged: false,
  RequestDetails: "",
  RequestPriorityChanged: false,
  RequestProgressChanged: false,
  RequestsFooters: [],
  RequestsTableData: [],
  RequestStatusChanged: false,
  Role: "",
  ScrollableDialogControlName: "",
  ScrollableDialogOpen: false,
  ScrollableDialogReadOnly: false,
  sm: false,
  SubmitTicket: false,
  TicketCategory: undefined,
  TicketDate: "",
  TicketDetails: "",
  TicketId: 0,
  TicketLocation: {
    description: "",
    id: 0,
    name: "",
  },
  TicketLocations: [],
  TicketPriorities: [],
  TicketPriority: {
    description: "",
    id: 1,
    name: "Unset",
  },
  TicketProgress: "",
  TicketsPerPage: 20,
  TicketStatus: {
    description: "",
    id: 1,
    name: "Open",
  },
  TicketStatuses: [],
  TicketSubcategory: undefined,
  TicketTime: "",
  TicketTypeId: 0,
  TotalPages: 0,
  UpdatedBy: {
    eMail: "",
    firstName: "",
    id: 0,
    initials: "",
    lastName: "",
    roleId: 0,
  },
  ViewOnly: false,
  xl: false,
  xs: false,
});
