import React from "react";
import useDebugInformation from "../../../../hooks/useDebugInformation";
import { useAppSelector } from "../../../../store/hooks";

const name = "Footer"

const TableBSMiniFooter: React.FC<{ parent: "Overall" | "Manage" | "Requests" | "Incidents" }> = ({ parent }) => {
    const footer = useAppSelector(({ ui }) => ui[`${parent}Footers`])
    const debugName = `${parent}▷[${name}]`

    useDebugInformation(debugName, { parent, footer })

    return (
        <tfoot>
            <tr>
                {footer && footer.map((col: any, idx: number) => <td key={idx}>{col}</td>)}
            </tr>
        </tfoot>
    )
}

export default React.memo(TableBSMiniFooter)