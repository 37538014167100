import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React from "react"
import { hasTicketStateChanged } from "../../../../../../../assets/js/main"
import { ui } from "../../../../../../../interfaces/interfaces"
import TextInput from "../../../../../../shared/TextInput"
import { style } from './../../../../../../../utilities/style';
type StandardTicketMiniProps = {
    viewOnly: boolean
    parent: string
}

const StandardTicketMini: React.FC<StandardTicketMiniProps> = ({ parent, viewOnly }) => {
    return (<>
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                <TextInput readOnly styledVariant='disabled' uncontrolledName={`TicketDetails`} inputName={`TicketDetails`} parent={parent} xs={12} sm={12} defaultValue={ui.TicketDetails} multiline={6} minRows={1} />
            </Grid>
        </Paper>
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                {viewOnly
                    ? <TextInput readOnly styledVariant='disabled' uncontrolledName="TicketProgress" defaultValue={ui.TicketProgress} inputName="TicketProgress" parent={parent} xs={12} sm={12} type="text" multiline={6} minRows={1} />
                    : <TextInput styledVariant='required' color="success" onChange={(e: { target: { value: string; }; }) => { ui[`TicketProgress`] = e.target.value; hasTicketStateChanged() }} onDoubleClick={() => { ui.ScrollableDialogControlName = "TicketProgress"; ui.ScrollableDialogOpen = true }} inputName="TicketProgress" parent={parent} xs={12} sm={12} type="text" multiline={6} minRows={1} />}
            </Grid>
        </Paper>
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                {viewOnly
                    ? <TextInput readOnly styledVariant='disabled' uncontrolledName="ImmediateAction" defaultValue={ui.ImmediateAction} inputName="ImmediateAction" parent={parent} xs={12} sm={12} type="text" multiline={6} minRows={1} />
                    : <TextInput styledVariant='required' color="success" onChange={(e: { target: { value: string; }; }) => { ui[`ImmediateAction`] = e.target.value; hasTicketStateChanged() }} onDoubleClick={() => { ui.ScrollableDialogControlName = "ImmediateAction"; ui.ScrollableDialogOpen = true }} inputName="ImmediateAction" parent={parent} xs={12} sm={12} type="text" multiline={6} minRows={1} />}
            </Grid>
        </Paper>
        <Paper variant="elevation" elevation={4} sx={(theme) => style(theme).paperDetails}>
            <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                {viewOnly
                    ? <TextInput readOnly styledVariant='disabled' uncontrolledName="LongTermAction" defaultValue={ui.LongTermAction} inputName="LongTermAction" parent={parent} xs={12} sm={12} type="text" multiline={6} minRows={1} />
                    : <TextInput styledVariant='required' color="success" onChange={(e: { target: { value: string; }; }) => { ui[`LongTermAction`] = e.target.value; hasTicketStateChanged() }} onDoubleClick={() => { ui.ScrollableDialogControlName = "LongTermAction"; ui.ScrollableDialogOpen = true }} inputName="LongTermAction" parent={parent} xs={12} sm={12} type="text" multiline={6} minRows={1} />}
            </Grid>
        </Paper>
    </>)
}

export default React.memo(StandardTicketMini)