import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { cacheUtils } from '../../../..';
import { CacheTypes } from '../../../../utilities/cache/interfaces';
import Grid from '@mui/material/Grid';
import { api, ui } from '../../../../interfaces/interfaces';
import { StorageUtils } from './../../../../services/storage/index';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
import { questionsOptions } from '../../../../assets/js/main';
import { Tables } from '../../../../services/databaseModels/tables';
import QuestionTemplate from '../../../shared/QuestionsTemplate';
import QuestionsDialog from './QuestionsDialog';

const updateCatInDB = (current: CacheTypes.Categories[], newCategories: CacheTypes.Categories[]) => {
    // find all rows that are different
    const diff = newCategories.filter((nc) => {
        const found = current.find((c) => c.id === nc.id);
        console.log(found, nc);
        return found?.name !== nc.name || found?.description !== nc.description;
    });

    console.log(diff);

    // update the database
    if (diff.length > 0) {
        diff.forEach(async (c) => {
            await api.fetchCategoriesUpdate(c.id, { name: c.name, description: c.description });
            cacheUtils.setCache("Categories", newCategories);
            await StorageUtils.replaceValuesInStoreInIndexedDB({ database: "CACHE", tableName: "Categories", values: newCategories });
        }
        );
    }
}

const insertNewCatInDB = async (newCategory: CacheTypes.Categories) => {
    const current = cacheUtils.getCache<CacheTypes.Categories>("Categories");
    const { id } = await api.fetchCategoriesInsert({ name: newCategory.name, description: newCategory.description });
    // update id of last inserted category
    newCategory.id = id;
    const newCategories = [...current, newCategory];
    cacheUtils.setCache("Categories", newCategories);
    await StorageUtils.replaceValuesInStoreInIndexedDB({ database: "CACHE", tableName: "Categories", values: newCategories });
}

const updateSubCatInDB = (current: CacheTypes.SubCategories[], newSubCategories: CacheTypes.SubCategories[]) => {
    // find all rows that are different
    const diff = newSubCategories.filter((nc) => {
        const found = current.find((c) => c.id === nc.id);
        console.log(found, nc);
        return found?.name !== nc.name || found?.description !== nc.description || found?.categoryId !== nc.categoryId || found?.enabled !== nc.enabled || found?.ownerId !== nc.ownerId || found?.ticketTypeId !== nc.ticketTypeId || found?.version !== nc.version;
    });

    console.log(diff);

    // update the database
    if (diff.length > 0) {
        diff.forEach(async (c) => {
            await api.fetchSubCategoriesUpdate(c.id, { name: c.name, description: c.description, categoryId: c.categoryId, ownerId: c.ownerId, ticketTypeId: c.ticketTypeId, version: c.version, secOwnerId: c.secOwnerId, enabled: c.enabled });
            cacheUtils.setCache("SubCategories", newSubCategories);
            await StorageUtils.replaceValuesInStoreInIndexedDB({ database: "CACHE", tableName: "SubCategories", values: newSubCategories });
        }
        );
    }
}

const insertNewSubCatInDB = async (newSubCategory: CacheTypes.SubCategories) => {
    const { id } = await api.fetchSubCategoriesInsert({ ...newSubCategory, id: undefined });
    // add to cache
    const current = cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories");
    const newSubCategories = [...current, newSubCategory];
    cacheUtils.setCache("SubCategories", newSubCategories);
    // add to indexedDB
    await StorageUtils.setValueInStoreInIndexedDB({ database: "CACHE", tableName: "SubCategories", value: newSubCategory });
}

const deleteSubCatInDB = async (subCategory: CacheTypes.SubCategories) => {
    await api.fetchSubCategoriesDelete(subCategory.id);
    // remove from cache
    const current = cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories");
    const newSubCategories = current.filter((c) => c.id !== subCategory.id);
    cacheUtils.setCache("SubCategories", newSubCategories);
    // remove from indexedDB
    await StorageUtils?.indexedDbs?.["CACHE"]?.deleteValue("SubCategories", subCategory.id);
}

const Categories: React.FC = () => {
    const categories = cacheUtils.getCache<CacheTypes.Categories>("Categories");
    const subCategories = cacheUtils.getCache<CacheTypes.SubCategories>("SubCategories");
    const questions = cacheUtils.getCache<CacheTypes.Questions>("Questions")

    const [open, setOpen] = React.useState(false);

    const optionalQuestions = useAppSelector(({ ui }) => ui["OptionalQuestions"])

    const [subCategoriesEnabledOnly, setSubCategoriesEnabledOnly] = React.useState(true);
    const [loadSubCategories, setLoadSubCategories] = React.useState(false);
    const [tempCategories, setTempCategories] = React.useState<CacheTypes.Categories[]>([...categories])
    const [tempSubCategories, setTempSubCategories] = React.useState<CacheTypes.SubCategories[]>([...subCategories])
    const [newCategory, setNewCategory] = React.useState<CacheTypes.Categories>({ id: tempCategories.length + 1, name: "", description: "" });
    const [newSubCategory, setNewSubCategory] = React.useState<CacheTypes.SubCategories>({ id: newCategory.id || tempSubCategories.length + 1, name: "", description: "", categoryId: categories.length, ownerId: 0, questions: [], ticketTypeId: 0, version: 0, secOwnerId: undefined, enabled: false });

    React.useEffect(() => {
        if (questions.length > 0 && newSubCategory && newSubCategory?.id > 0 && newSubCategory?.name && newSubCategory?.name.length > 0) {
            ui.OptionalQuestions = questionsOptions(questions, newSubCategory.id);
        }
    }, [questions, newSubCategory])

    return (
        <Box sx={{ width: 1024, bgcolor: 'background.paper' }}>
            <QuestionsDialog hasQuestions={Array(questions.filter(q => q.subCategoryId === newSubCategory?.id).length).fill(true)} onClose={() => { setOpen(false) }} open={open} optionalQuestions={optionalQuestions} parent="asaa" ticketSubcategoryId={newSubCategory?.id} />
            <Grid container gap={1}>
                <Paper elevation={4}>
                    <nav aria-label="main categories">
                        <List>
                            {categories.map((category, idx) =>
                            (
                                <ListItem key={idx} sx={{ p: 0 }}>
                                    <ListItemButton onClick={() => setNewCategory(category)}>
                                        <ListItemIcon>
                                            <EditTwoToneIcon id={`${category.id}`} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={category?.name ? category.name : null}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                            )}
                        </List>
                    </nav>
                    <Divider />
                    <nav aria-label="secondary categories">
                        <List>
                            <ListItem disablePadding sx={{ justifyContent: "space-between" }}>
                                {/* <Button variant='contained' type='button' sx={{ ml: 1 }} onClick={() => setCategories((prevValue) => ([...prevValue, { name: newCategory, description: newCategory }]))}>Add</Button> */}
                            </ListItem>
                        </List>
                    </nav>
                </Paper>
                <Paper elevation={4}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-center', flexDirection: "column" }}>
                        <TextField id="outlined-basic" label="Category name" variant="outlined" value={newCategory.name} onChange={(e) => setNewCategory((prevValue) => ({ ...prevValue, name: e.target.value }))} sx={{ m: 1 }} />
                        <TextField id="outlined-basic" label="Category description" variant="outlined" value={newCategory.description} onChange={(e) => setNewCategory((prevValue) => ({ ...prevValue, description: e.target.value }))} sx={{ m: 1 }} />
                        <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => setTempCategories((prevValue) => ([...prevValue, newCategory]))}>Add</Button>
                        <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => insertNewCatInDB(newCategory)}>Insert</Button>
                        <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => setTempCategories((prevValue) => (prevValue.filter(c => c.id !== newCategory.id)))}>Delete</Button>
                        <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => {
                            setTempCategories((prevValue) => {
                                const temp = [...prevValue.filter(a => a.id !== newCategory.id), newCategory].sort((a, b) => {
                                    if (a.id < b.id) return -1;
                                    if (a.id > b.id) return 1;
                                    return 0;
                                })
                                updateCatInDB(categories, temp);
                                return temp;
                            })
                        }}>Update</Button>
                        <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => setLoadSubCategories(true)}>SubCategories</Button>
                    </Box>
                </Paper>
                {loadSubCategories && <><Paper elevation={4}>
                    <nav aria-label="main subcategories">
                        <List>
                            {subCategories.filter(s => s.categoryId === newCategory.id && s.enabled === subCategoriesEnabledOnly).map((subCategory, idx) =>
                            (
                                <ListItem key={idx} sx={{ p: 0 }}>
                                    <ListItemButton onClick={() => setNewSubCategory(subCategory)}>
                                        <ListItemIcon>
                                            <EditTwoToneIcon id={`${subCategory.id}`} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={subCategory?.name ? subCategory.name : null}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                            )}
                        </List>
                    </nav>
                    <Divider />
                    <nav aria-label="secondary subcategories">
                        <List>
                            <ListItem disablePadding sx={{ justifyContent: "space-between" }}>
                                {/* <Button variant='contained' type='button' sx={{ ml: 1 }} onClick={() => setCategories((prevValue) => ([...prevValue, { name: newCategory, description: newCategory }]))}>Add</Button> */}
                            </ListItem>
                        </List>
                    </nav>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={subCategoriesEnabledOnly} onChange={(e, checked) => setSubCategoriesEnabledOnly(checked)} sx={{ m: 1 }} />} label="Enabled Only" />
                    </FormGroup>
                </Paper>
                    <Paper elevation={4}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-center', flexDirection: "column" }}>
                            <TextField id="outlined-basic-sub" label="Id" variant="outlined" value={newSubCategory.id} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, id: Number(e.target.value) }))} sx={{ m: 1 }} />
                            <TextField id="outlined-basic-sub" label="CategoryId" variant="outlined" value={newSubCategory.categoryId} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, categoryId: Number(e.target.value) }))} sx={{ m: 1 }} />
                            <TextField id="outlined-basic-sub" label="Version" variant="outlined" value={newSubCategory.version} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, version: Number(e.target.value) }))} sx={{ m: 1 }} />
                            <TextField id="outlined-basic-sub" label="Owner" variant="outlined" value={newSubCategory.ownerId} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, ownerId: Number(e.target.value) }))} sx={{ m: 1 }} />
                            <TextField id="outlined-basic-sub" label="SecOwner" variant="outlined" value={newSubCategory.secOwnerId} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, secOwnerId: Number(e.target.value) }))} sx={{ m: 1 }} />
                            <TextField id="outlined-basic-sub" label="Ticket Type" variant="outlined" value={newSubCategory.ticketTypeId} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, ticketTypeId: Number(e.target.value) }))} sx={{ m: 1 }} />
                            <TextField id="outlined-basic-sub" label="SubCategory name" variant="outlined" value={newSubCategory.name} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, name: e.target.value }))} sx={{ m: 1 }} />
                            <TextField id="outlined-basic-sub" label="SubCategory description" variant="outlined" value={newSubCategory.description} onChange={(e) => setNewSubCategory((prevValue) => ({ ...prevValue, description: e.target.value }))} sx={{ m: 1 }} />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={newSubCategory.enabled} onChange={(e, checked) => setNewSubCategory((prevValue) => ({ ...prevValue, enabled: checked }))} sx={{ m: 1 }} />} label="Enabled" />
                            </FormGroup>
                            <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => setTempSubCategories((prevValue) => ([...prevValue, newSubCategory]))}>Add</Button>
                            <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => insertNewSubCatInDB(newSubCategory)}>Insert</Button>
                            <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => { setTempSubCategories((prevValue) => (prevValue.filter(c => c.id !== newSubCategory.id))); deleteSubCatInDB(newSubCategory) }}>Delete</Button>
                            <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => {
                                setTempSubCategories((prevValue) => {
                                    const temp = [...prevValue.filter(a => a.id !== newSubCategory.id), newSubCategory].sort((a, b) => {
                                        if (a.id < b.id) return -1;
                                        if (a.id > b.id) return 1;
                                        return 0;
                                    })
                                    updateSubCatInDB(subCategories, temp);
                                    return temp;
                                })
                            }}>Update</Button>
                            <Button variant='contained' type='button' sx={{ m: 1, p: 1 }} onClick={() => setOpen(true)}>Questions</Button>
                        </Box>
                    </Paper></>}
            </Grid>
        </Box >
    );
}

export default Categories