import Typography from "@mui/material/Typography";
import React from "react";

type TextProps = {
    children: any
}

const Text: React.FC<TextProps> = ({ children }) => <Typography variant="subtitle1" sx={{ fontSize: "1.25rem" }}>{children}</Typography>


export default React.memo(Text);