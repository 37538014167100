import * as APIInterface from "./interface";
import authService from "../../../components/api-authorization/AuthorizeService";
import { AxiosRequestConfig } from "axios";
import { TablesEndpoints } from "../../../services/databaseModels/tables";
import { convertData } from "../../../assets/js/main";
import { EmailEndpoints } from "../../../services/databaseModels/email/index";
import { dispatchUILoaded, dispatchUILoading } from "../../uiReducer";
import { dispatchErrorApi, dispatchSetApi, dispatchStartApi } from "../../apiReducer";
import { logger } from "../../../services/logger";
const namespace: string = "API Actions";
const apiMap: APIInterface.MapOfAPIEndpoints = {
  Answers: {
    method: TablesEndpoints.Answers,
    name: "Answers",
  },
  AnswersById: {
    method: TablesEndpoints.AnswersById,
    name: "AnswersById",
  },
  AnswersDelete: {
    method: TablesEndpoints.AnswersDelete,
    name: "AnswersDelete",
  },
  AnswersInsert: {
    method: TablesEndpoints.AnswersInsert,
    name: "AnswersInsert",
  },
  AnswersUpdate: {
    method: TablesEndpoints.AnswersUpdate,
    name: "AnswersUpdate",
  },
  Attachments: {
    method: TablesEndpoints.Attachments,
    name: "Attachments",
  },
  AttachmentsById: {
    method: TablesEndpoints.AttachmentsById,
    name: "AttachmentsById",
  },
  AttachmentsDelete: {
    method: TablesEndpoints.AttachmentsDelete,
    name: "AttachmentsDelete",
  },
  AttachmentsInsert: {
    method: TablesEndpoints.AttachmentsInsert,
    name: "AttachmentsInsert",
  },
  AttachmentsUpdate: {
    method: TablesEndpoints.AttachmentsUpdate,
    name: "AttachmentsUpdate",
  },
  Categories: {
    method: TablesEndpoints.Categories,
    name: "Categories",
  },
  CategoriesById: {
    method: TablesEndpoints.CategoriesById,
    name: "CategoriesById",
  },
  CategoriesDelete: {
    method: TablesEndpoints.CategoriesDelete,
    name: "CategoriesDelete",
  },
  CategoriesInsert: {
    method: TablesEndpoints.CategoriesInsert,
    name: "CategoriesInsert",
  },
  CategoriesUpdate: {
    method: TablesEndpoints.CategoriesUpdate,
    name: "CategoriesUpdate",
  },
  EmailSend: {
    method: EmailEndpoints.EmailSend,
    name: "EmailSend",
  },
  ImmediateActions: {
    method: TablesEndpoints.ImmediateActions,
    name: "ImmediateActions",
  },
  ImmediateActionsById: {
    method: TablesEndpoints.ImmediateActionsById,
    name: "ImmediateActionsById",
  },
  ImmediateActionsDelete: {
    method: TablesEndpoints.ImmediateActionsDelete,
    name: "ImmediateActionsDelete",
  },
  ImmediateActionsInsert: {
    method: TablesEndpoints.ImmediateActionsInsert,
    name: "ImmediateActionsInsert",
  },
  ImmediateActionsPatch: {
    method: TablesEndpoints.ImmediateActionsPatch,
    name: "ImmediateActionsPatch",
  },
  ImmediateActionsUpdate: {
    method: TablesEndpoints.ImmediateActionsUpdate,
    name: "ImmediateActionsUpdate",
  },
  Incidents: {
    method: TablesEndpoints.Incidents,
    name: "Incidents",
  },
  Locations: {
    method: TablesEndpoints.Locations,
    name: "Locations",
  },
  LocationsById: {
    method: TablesEndpoints.LocationsById,
    name: "LocationsById",
  },
  LocationsDelete: {
    method: TablesEndpoints.LocationsDelete,
    name: "LocationsDelete",
  },
  LocationsInsert: {
    method: TablesEndpoints.LocationsInsert,
    name: "LocationsInsert",
  },
  LocationsUpdate: {
    method: TablesEndpoints.LocationsUpdate,
    name: "LocationsUpdate",
  },
  LongTermActions: {
    method: TablesEndpoints.LongTermActions,
    name: "LongTermActions",
  },
  LongTermActionsById: {
    method: TablesEndpoints.LongTermActionsById,
    name: "LongTermActionsById",
  },
  LongTermActionsDelete: {
    method: TablesEndpoints.LongTermActionsDelete,
    name: "LongTermActionsDelete",
  },
  LongTermActionsInsert: {
    method: TablesEndpoints.LongTermActionsInsert,
    name: "LongTermActionsInsert",
  },
  LongTermActionsPatch: {
    method: TablesEndpoints.LongTermActionsPatch,
    name: "LongTermActionsPatch",
  },
  LongTermActionsUpdate: {
    method: TablesEndpoints.LongTermActionsUpdate,
    name: "LongTermActionsUpdate",
  },
  Priorities: {
    method: TablesEndpoints.Priorities,
    name: "Priorities",
  },
  PrioritiesById: {
    method: TablesEndpoints.PrioritiesById,
    name: "PrioritiesById",
  },
  PrioritiesDelete: {
    method: TablesEndpoints.PrioritiesDelete,
    name: "PrioritiesDelete",
  },
  PrioritiesInsert: {
    method: TablesEndpoints.PrioritiesInsert,
    name: "PrioritiesInsert",
  },
  PrioritiesUpdate: {
    method: TablesEndpoints.PrioritiesUpdate,
    name: "PrioritiesUpdate",
  },
  ProgressLogs: {
    method: TablesEndpoints.ProgressLogs,
    name: "ProgressLogs",
  },
  ProgressLogsById: {
    method: TablesEndpoints.ProgressLogsById,
    name: "ProgressLogsById",
  },
  ProgressLogsDelete: {
    method: TablesEndpoints.ProgressLogsDelete,
    name: "ProgressLogsDelete",
  },
  ProgressLogsInsert: {
    method: TablesEndpoints.ProgressLogsInsert,
    name: "ProgressLogsInsert",
  },
  ProgressLogsPatch: {
    method: TablesEndpoints.ProgressLogsPatch,
    name: "ProgressLogsPatch",
  },
  ProgressLogsUpdate: {
    method: TablesEndpoints.ProgressLogsUpdate,
    name: "ProgressLogsUpdate",
  },
  Questions: {
    method: TablesEndpoints.Questions,
    name: "Questions",
  },
  QuestionsById: {
    method: TablesEndpoints.QuestionsById,
    name: "QuestionsById",
  },
  QuestionsBySubCategoryId: {
    method: TablesEndpoints.QuestionsBySubCategoryId,
    name: "QuestionsBySubCategoryId",
  },
  QuestionsDelete: {
    method: TablesEndpoints.QuestionsDelete,
    name: "QuestionsDelete",
  },
  QuestionsInsert: {
    method: TablesEndpoints.QuestionsInsert,
    name: "QuestionsInsert",
  },
  QuestionsTypes: {
    method: TablesEndpoints.QuestionsTypes,
    name: "QuestionsTypes",
  },
  QuestionsTypesById: {
    method: TablesEndpoints.QuestionsTypesById,
    name: "QuestionsTypesById",
  },
  QuestionsTypesDelete: {
    method: TablesEndpoints.QuestionsTypesDelete,
    name: "QuestionsTypesDelete",
  },
  QuestionsTypesInsert: {
    method: TablesEndpoints.QuestionsTypesInsert,
    name: "QuestionsTypesInsert",
  },
  QuestionsTypesUpdate: {
    method: TablesEndpoints.QuestionsTypesUpdate,
    name: "QuestionsTypesUpdate",
  },
  QuestionsUpdate: {
    method: TablesEndpoints.QuestionsUpdate,
    name: "QuestionsUpdate",
  },
  Requests: {
    method: TablesEndpoints.Requests,
    name: "Requests",
  },
  RequestsById: {
    method: TablesEndpoints.RequestsById,
    name: "RequestsById",
  },
  RequestsDelete: {
    method: TablesEndpoints.RequestsDelete,
    name: "RequestsDelete",
  },
  RequestsInsert: {
    method: TablesEndpoints.RequestsInsert,
    name: "RequestsInsert",
  },
  RequestsUpdate: {
    method: TablesEndpoints.RequestsUpdate,
    name: "RequestsUpdate",
  },
  RequestsWithFK: {
    method: TablesEndpoints.RequestsWithFK,
    name: "RequestsWithFK",
  },
  Roles: {
    method: TablesEndpoints.Roles,
    name: "Roles",
  },
  RolesById: {
    method: TablesEndpoints.RolesById,
    name: "RolesById",
  },
  RolesDelete: {
    method: TablesEndpoints.RolesDelete,
    name: "RolesDelete",
  },
  RolesInsert: {
    method: TablesEndpoints.RolesInsert,
    name: "RolesInsert",
  },
  RolesUpdate: {
    method: TablesEndpoints.RolesUpdate,
    name: "RolesUpdate",
  },
  Statuses: {
    method: TablesEndpoints.Statuses,
    name: "Statuses",
  },
  StatusesById: {
    method: TablesEndpoints.StatusesById,
    name: "StatusesById",
  },
  StatusesDelete: {
    method: TablesEndpoints.StatusesDelete,
    name: "StatusesDelete",
  },
  StatusesInsert: {
    method: TablesEndpoints.StatusesInsert,
    name: "StatusesInsert",
  },
  StatusesUpdate: {
    method: TablesEndpoints.StatusesUpdate,
    name: "StatusesUpdate",
  },
  SubCategories: {
    method: TablesEndpoints.SubCategories,
    name: "SubCategories",
  },
  SubCategoriesById: {
    method: TablesEndpoints.SubCategoriesById,
    name: "SubCategoriesById",
  },
  SubCategoriesDelete: {
    method: TablesEndpoints.SubCategoriesDelete,
    name: "SubCategoriesDelete",
  },
  SubCategoriesInsert: {
    method: TablesEndpoints.SubCategoriesInsert,
    name: "SubCategoriesInsert",
  },
  SubCategoriesUpdate: {
    method: TablesEndpoints.SubCategoriesUpdate,
    name: "SubCategoriesUpdate",
  },
  TicketDetails: {
    method: TablesEndpoints.TicketDetails,
    name: "TicketDetails",
  },
  TicketDetailsById: {
    method: TablesEndpoints.TicketDetailsById,
    name: "TicketDetailsById",
  },
  TicketDetailsDelete: {
    method: TablesEndpoints.TicketDetailsDelete,
    name: "TicketDetailsDelete",
  },
  TicketDetailsInsert: {
    method: TablesEndpoints.TicketDetailsInsert,
    name: "TicketDetailsInsert",
  },
  TicketDetailsPatch: {
    method: TablesEndpoints.TicketDetailsPatch,
    name: "TicketDetailsPatch",
  },
  TicketDetailsUpdate: {
    method: TablesEndpoints.TicketDetailsUpdate,
    name: "TicketDetailsUpdate",
  },
  Tickets: {
    method: TablesEndpoints.Tickets,
    name: "Tickets",
  },
  TicketsById: {
    method: TablesEndpoints.TicketsById,
    name: "TicketsById",
  },
  TicketsDelete: {
    method: TablesEndpoints.TicketsDelete,
    name: "TicketsDelete",
  },
  TicketsIncidents: {
    method: TablesEndpoints.TicketsIncidents,
    name: "TicketsIncidents",
  },
  TicketsInsert: {
    method: TablesEndpoints.TicketsInsert,
    name: "TicketsInsert",
  },
  TicketsOverall: {
    method: TablesEndpoints.TicketsOverall,
    name: "TicketsOverall",
  },
  TicketsOverallByCategory: {
    method: TablesEndpoints.TicketsOverallByCategory,
    name: "TicketsOverallByCategory",
  },
  TicketsPatch: {
    method: TablesEndpoints.TicketsPatch,
    name: "TicketsPatch",
  },
  TicketsRequests: {
    method: TablesEndpoints.TicketsRequests,
    name: "TicketsRequests",
  },
  TicketsUpdate: {
    method: TablesEndpoints.TicketsUpdate,
    name: "TicketsUpdate",
  },
  TicketsWithFK: {
    method: TablesEndpoints.TicketsWithFK,
    name: "TicketsWithFK",
  },
  TicketTypes: {
    method: TablesEndpoints.TicketTypes,
    name: "TicketTypes",
  },
  TicketTypesById: {
    method: TablesEndpoints.TicketTypesById,
    name: "TicketTypesById",
  },
  TicketTypesDelete: {
    method: TablesEndpoints.TicketTypesDelete,
    name: "TicketTypesDelete",
  },
  TicketTypesInsert: {
    method: TablesEndpoints.TicketTypesInsert,
    name: "TicketTypesInsert",
  },
  TicketTypesUpdate: {
    method: TablesEndpoints.TicketTypesUpdate,
    name: "TicketTypesUpdate",
  },
  Users: {
    method: TablesEndpoints.Users,
    name: "Users",
  },
  UsersById: {
    method: TablesEndpoints.UsersById,
    name: "UsersById",
  },
  UsersDelete: {
    method: TablesEndpoints.UsersDelete,
    name: "UsersDelete",
  },
  UsersInsert: {
    method: TablesEndpoints.UsersInsert,
    name: "UsersInsert",
  },
  UsersUpdate: {
    method: TablesEndpoints.UsersUpdate,
    name: "UsersUpdate",
  },
};

const fetchAPI: APIInterface.FetchAPI = async ({ body, id, name }) => {
  dispatchUILoading();
  dispatchStartApi();
  const configuration: AxiosRequestConfig = {};

  logger.debug(namespace, "Started fetching " + name);
  const token = await authService.getAccessToken();
  const axiosParams = [];
  id && axiosParams.push(id);
  body && axiosParams.push(body);
  axiosParams.push(configuration);

  try {
    let response = await apiMap[name].method(...axiosParams);
    response = convertData(response);
    dispatchSetApi(apiMap[name].name, response, body);

    dispatchUILoaded();
    logger.debug(namespace, "Finished fetching " + name);
    return response;
  } catch (error) {
    logger.error(namespace, "Failed fetching " + name, error as Error);
    dispatchErrorApi();
    dispatchUILoaded();
    return error;
  }
};

export const api: APIInterface.Api = () => {
  const fetch_API: APIInterface.Fetch_API = async ({ body, id, name }) =>
    fetchAPI({
      body,
      id,
      name,
    });

  return {
    async fetchAnswers() {
      return fetch_API({
        name: "Answers",
      });
    },

    async fetchAnswersById(id) {
      return fetch_API({
        id,
        name: "AnswersById",
      });
    },

    async fetchAnswersDelete(id) {
      return fetch_API({
        id,
        name: "AnswersDelete",
      });
    },

    async fetchAnswersInsert(body) {
      return fetch_API({
        body,
        name: "AnswersInsert",
      });
    },

    async fetchAnswersUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "AnswersUpdate",
      });
    },

    async fetchAttachments() {
      return fetch_API({
        name: "Attachments",
      });
    },

    async fetchAttachmentsById(id) {
      return fetch_API({
        id,
        name: "AttachmentsById",
      });
    },

    async fetchAttachmentsDelete(id) {
      return fetch_API({
        id,
        name: "AttachmentsDelete",
      });
    },

    async fetchAttachmentsInsert(body) {
      return fetch_API({
        body,
        name: "AttachmentsInsert",
      });
    },

    async fetchAttachmentsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "AttachmentsUpdate",
      });
    },

    async fetchCategories() {
      return fetch_API({
        name: "Categories",
      });
    },

    async fetchCategoriesById(id) {
      return fetch_API({
        id,
        name: "CategoriesById",
      });
    },

    async fetchCategoriesDelete(id) {
      return fetch_API({
        id,
        name: "CategoriesDelete",
      });
    },

    async fetchCategoriesInsert(body) {
      return fetch_API({
        body,
        name: "CategoriesInsert",
      });
    },

    async fetchCategoriesUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "CategoriesUpdate",
      });
    },

    async fetchEmailSend(body) {
      return fetch_API({
        body,
        name: "EmailSend",
      });
    },

    async fetchImmediateActions() {
      return fetch_API({
        name: "ImmediateActions",
      });
    },

    async fetchImmediateActionsById(id) {
      return fetch_API({
        id,
        name: "ImmediateActionsById",
      });
    },

    async fetchImmediateActionsDelete(id) {
      return fetch_API({
        id,
        name: "ImmediateActionsDelete",
      });
    },

    async fetchImmediateActionsInsert(body) {
      return fetch_API({
        body,
        name: "ImmediateActionsInsert",
      });
    },

    async fetchImmediateActionsPatch(id, body) {
      return fetch_API({
        body,
        id,
        name: "ImmediateActionsPatch",
      });
    },

    async fetchImmediateActionsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "ImmediateActionsUpdate",
      });
    },

    async fetchIncidents() {
      return fetch_API({
        name: "Incidents",
      });
    },

    async fetchLocations() {
      return fetch_API({
        name: "Locations",
      });
    },

    async fetchLocationsById(id) {
      return fetch_API({
        id,
        name: "LocationsById",
      });
    },

    async fetchLocationsDelete(id) {
      return fetch_API({
        id,
        name: "LocationsDelete",
      });
    },

    async fetchLocationsInsert(body) {
      return fetch_API({
        body,
        name: "LocationsInsert",
      });
    },

    async fetchLocationsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "LocationsUpdate",
      });
    },

    async fetchLongTermActions() {
      return fetch_API({
        name: "LongTermActions",
      });
    },

    async fetchLongTermActionsById(id) {
      return fetch_API({
        id,
        name: "LongTermActionsById",
      });
    },

    async fetchLongTermActionsDelete(id) {
      return fetch_API({
        id,
        name: "LongTermActionsDelete",
      });
    },

    async fetchLongTermActionsInsert(body) {
      return fetch_API({
        body,
        name: "LongTermActionsInsert",
      });
    },

    async fetchLongTermActionsPatch(id, body) {
      return fetch_API({
        body,
        id,
        name: "LongTermActionsPatch",
      });
    },

    async fetchLongTermActionsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "LongTermActionsUpdate",
      });
    },

    async fetchPriorities() {
      return fetch_API({
        name: "Priorities",
      });
    },

    async fetchPrioritiesById(id) {
      return fetch_API({
        id,
        name: "PrioritiesById",
      });
    },

    async fetchPrioritiesDelete(id) {
      return fetch_API({
        id,
        name: "PrioritiesDelete",
      });
    },

    async fetchPrioritiesInsert(body) {
      return fetch_API({
        body,
        name: "PrioritiesInsert",
      });
    },

    async fetchPrioritiesUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "PrioritiesUpdate",
      });
    },

    async fetchProgressLogs() {
      return fetch_API({
        name: "ProgressLogs",
      });
    },

    async fetchProgressLogsById(id) {
      return fetch_API({
        id,
        name: "ProgressLogsById",
      });
    },

    async fetchProgressLogsDelete(id) {
      return fetch_API({
        id,
        name: "ProgressLogsDelete",
      });
    },

    async fetchProgressLogsInsert(body) {
      return fetch_API({
        body,
        name: "ProgressLogsInsert",
      });
    },

    async fetchProgressLogsPatch(id, body) {
      return fetch_API({
        body,
        id,
        name: "ProgressLogsPatch",
      });
    },

    async fetchProgressLogsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "ProgressLogsUpdate",
      });
    },

    async fetchQuestions() {
      return fetch_API({
        name: "Questions",
      });
    },

    async fetchQuestionsById(id) {
      return fetch_API({
        id,
        name: "QuestionsById",
      });
    },

    async fetchQuestionsBySubCategoryId(id) {
      return fetch_API({
        id,
        name: "QuestionsBySubCategoryId",
      });
    },

    async fetchQuestionsDelete(id) {
      return fetch_API({
        id,
        name: "QuestionsDelete",
      });
    },

    async fetchQuestionsInsert(body) {
      return fetch_API({
        body,
        name: "QuestionsInsert",
      });
    },

    async fetchQuestionsTypes() {
      return fetch_API({
        name: "QuestionsTypes",
      });
    },

    async fetchQuestionsTypesById(id) {
      return fetch_API({
        id,
        name: "QuestionsTypesById",
      });
    },

    async fetchQuestionsTypesDelete(id) {
      return fetch_API({
        id,
        name: "QuestionsTypesDelete",
      });
    },

    async fetchQuestionsTypesInsert(body) {
      return fetch_API({
        body,
        name: "QuestionsTypesInsert",
      });
    },

    async fetchQuestionsTypesUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "QuestionsTypesUpdate",
      });
    },

    async fetchQuestionsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "QuestionsUpdate",
      });
    },

    async fetchRequests() {
      return fetch_API({
        name: "Requests",
      });
    },

    async fetchRequestsById(id) {
      return fetch_API({
        id,
        name: "RequestsById",
      });
    },

    async fetchRequestsDelete(id) {
      return fetch_API({
        id,
        name: "RequestsDelete",
      });
    },

    async fetchRequestsInsert(body) {
      return fetch_API({
        body,
        name: "RequestsInsert",
      });
    },

    async fetchRequestsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "RequestsUpdate",
      });
    },

    async fetchRequestsWithFK(id) {
      return fetch_API({
        id,
        name: "RequestsWithFK",
      });
    },

    async fetchRoles() {
      return fetch_API({
        name: "Roles",
      });
    },

    async fetchRolesById(id) {
      return fetch_API({
        id,
        name: "RolesById",
      });
    },

    async fetchRolesDelete(id) {
      return fetch_API({
        id,
        name: "RolesDelete",
      });
    },

    async fetchRolesInsert(body) {
      return fetch_API({
        body,
        name: "RolesInsert",
      });
    },

    async fetchRolesUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "RolesUpdate",
      });
    },

    async fetchStatuses() {
      return fetch_API({
        name: "Statuses",
      });
    },

    async fetchStatusesById(id) {
      return fetch_API({
        id,
        name: "StatusesById",
      });
    },

    async fetchStatusesDelete(id) {
      return fetch_API({
        id,
        name: "StatusesDelete",
      });
    },

    async fetchStatusesInsert(body) {
      return fetch_API({
        body,
        name: "StatusesInsert",
      });
    },

    async fetchStatusesUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "StatusesUpdate",
      });
    },

    async fetchSubCategories() {
      return fetch_API({
        name: "SubCategories",
      });
    },

    async fetchSubCategoriesById(id) {
      return fetch_API({
        id,
        name: "SubCategoriesById",
      });
    },

    async fetchSubCategoriesDelete(id) {
      return fetch_API({
        id,
        name: "SubCategoriesDelete",
      });
    },

    async fetchSubCategoriesInsert(body) {
      return fetch_API({
        body,
        name: "SubCategoriesInsert",
      });
    },

    async fetchSubCategoriesUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "SubCategoriesUpdate",
      });
    },

    async fetchTicketDetails() {
      return fetch_API({
        name: "TicketDetails",
      });
    },

    async fetchTicketDetailsById(id) {
      return fetch_API({
        id,
        name: "TicketDetailsById",
      });
    },

    async fetchTicketDetailsDelete(id) {
      return fetch_API({
        id,
        name: "TicketDetailsDelete",
      });
    },

    async fetchTicketDetailsInsert(body) {
      return fetch_API({
        body,
        name: "TicketDetailsInsert",
      });
    },

    async fetchTicketDetailsPatch(id, body) {
      return fetch_API({
        body,
        id,
        name: "TicketDetailsPatch",
      });
    },

    async fetchTicketDetailsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "TicketDetailsUpdate",
      });
    },

    async fetchTickets() {
      return fetch_API({
        name: "Tickets",
      });
    },

    async fetchTicketsById(id) {
      return fetch_API({
        id,
        name: "TicketsById",
      });
    },

    async fetchTicketsDelete(id) {
      return fetch_API({
        id,
        name: "TicketsDelete",
      });
    },

    async fetchTicketsIncidents() {
      return fetch_API({
        name: "TicketsIncidents",
      });
    },

    async fetchTicketsInsert(body) {
      return fetch_API({
        body,
        name: "TicketsInsert",
      });
    },

    async fetchTicketsOverall() {
      return fetch_API({
        name: "TicketsOverall",
      });
    },

    async fetchTicketsOverallByCategory(id) {
      return fetch_API({
        id,
        name: "TicketsOverallByCategory",
      });
    },

    async fetchTicketsPatch(id, body) {
      return fetch_API({
        body,
        id,
        name: "TicketsPatch",
      });
    },

    async fetchTicketsRequests() {
      return fetch_API({
        name: "TicketsRequests",
      });
    },

    async fetchTicketsUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "TicketsUpdate",
      });
    },

    async fetchTicketsWithFK(id) {
      return fetch_API({
        id,
        name: "TicketsWithFK",
      });
    },

    async fetchTicketTypes() {
      return fetch_API({
        name: "TicketTypes",
      });
    },

    async fetchTicketTypesById(id) {
      return fetch_API({
        id,
        name: "TicketTypesById",
      });
    },

    async fetchTicketTypesDelete(id) {
      return fetch_API({
        id,
        name: "TicketTypesDelete",
      });
    },

    async fetchTicketTypesInsert(body) {
      return fetch_API({
        body,
        name: "TicketTypesInsert",
      });
    },

    async fetchTicketTypesUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "TicketTypesUpdate",
      });
    },

    async fetchUsers() {
      return fetch_API({
        name: "Users",
      });
    },

    async fetchUsersById(id) {
      return fetch_API({
        id,
        name: "UsersById",
      });
    },

    async fetchUsersDelete(id) {
      return fetch_API({
        id,
        name: "UsersDelete",
      });
    },

    async fetchUsersInsert(body) {
      return fetch_API({
        body,
        name: "UsersInsert",
      });
    },

    async fetchUsersUpdate(id, body) {
      return fetch_API({
        body,
        id,
        name: "UsersUpdate",
      });
    },
  };
};
