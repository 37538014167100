import { ApplicationPaths, ApplicationName } from "./ApiAuthorizationConstants";
import { WebStorageStateStore, UserManager, UserManagerSettings } from "oidc-client-ts";
import IAuthorizeService, { ExtUser } from "./AuthorizeServiceInterface";

export class AuthorizeService implements IAuthorizeService {
  _callbacks: Array<{ callback: Function; subscription: number }> = [];
  _nextSubscriptionId: number = 0;
  _user: ExtUser | null | undefined | void = null;
  _isAuthenticated: boolean = false;
  _managerInitializing: boolean = false;
  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled: boolean = true;
  userManager: UserManager | undefined = undefined;

  async isAuthenticated() {
    const user = await this.getUser();
    return !!user;
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user;
    }

    await this.ensureUserManagerInitialized();
    const user = (await this.userManager?.getUser()) as ExtUser;
    user && this.userLoaded(user);
    return user;
  }

  async getAccessToken() {
    await this.ensureUserManagerInitialized();
    const user = await this.getUser();
    return user && user.access_token;
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state?: { returnUrl: any } | undefined) {
    console.log("signIn", state);
    await this.ensureUserManagerInitialized();
    try {
      //   await this.userManager?.signinSilent(this.createArguments());
      //   return this.success(state);
      // } catch (silentError) {
      //   // User might not be authenticated, fallback to popup authentication
      //   //console.log("Silent authentication error: ", silentError);

      //   try {
      //     if (this._popUpDisabled) {
      //       throw new Error(
      //         "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
      //       );
      //     }

      //     await this.userManager?.signinPopup(this.createArguments());
      //     return this.success(state);
      //   } catch (popUpError: any) {
      //     if (popUpError.message === "Popup window closed") {
      //       // The user explicitly cancelled the login action by closing an opened popup.
      //       return this.error("The user closed the window.");
      //     } else if (!this._popUpDisabled) {
      //       //console.log("Popup authentication error: ", popUpError);
      //     }

      // PopUps might be blocked by the user, fallback to redirect
      // try {
      await this.userManager?.signinRedirect({ extraQueryParams: { prompt: "login" } });
      return this.redirect();
    } catch (redirectError) {
      //console.log("Redirect authentication error: ", redirectError);
      return this.error(redirectError);
    }
    //   }
    // }
  }

  async completeSignIn(url: string) {
    try {
      console.log("completeSignIn", url);
      await this.ensureUserManagerInitialized();
      const user = await this.userManager?.signinCallback(url);
      return this.success(user && user.state);
    } catch (error) {
      //console.log('There was an error signing in: ', error);
      return this.error("There was an error signing in.");
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state: any) {
    await this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
        );
      }

      await this.userManager?.signoutPopup(this.createArguments());
      this.updateState(undefined);
      return this.success(state);
    } catch (popupSignOutError) {
      console.log("Popup signout error: ", popupSignOutError);
      try {
        await this.userManager?.signoutRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectSignOutError) {
        console.log("Redirect signout error: ", redirectSignOutError);
        return this.error(redirectSignOutError);
      }
    }
  }

  async completeSignOut(url: string) {
    await this.ensureUserManagerInitialized();
    try {
      await this.userManager?.signoutCallback(url);
      this.updateState(null);
      return this.success({ status: "success" });
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  updateState(user: ExtUser | null | undefined | void) {
    if (
      this._user?.session_state !== user?.session_state ||
      this._user?.access_token !== user?.access_token ||
      this._user?.id_token !== user?.id_token ||
      this._user?.refresh_token !== user?.refresh_token ||
      this._user?.expires_at !== user?.expires_at ||
      this._user?.scope !== user?.scope ||
      JSON.stringify(this._user?.profile) !== JSON.stringify(user?.profile)
    ) {
      this._user = user;
      this._isAuthenticated = !!this._user;
      this.notifySubscribers();
    }
  }

  subscribe(callback: (_isAuthenticated: boolean, user: ExtUser) => void) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => (element.subscription === subscriptionId ? { found: true, index } : { found: false }))
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    // splice out the found subscription
    if (subscriptionIndex[0].index) {
      this._callbacks.splice(subscriptionIndex[0].index, 1);
    }
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback(this._isAuthenticated, this._user);
    }
  }

  createArguments(state?: any): any {
    return { useReplaceToNavigate: true, data: state };
  }

  error(message: any) {
    return { status: AuthenticationResultStatus.Fail, state: message };
  }

  success(state: any) {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }

  userLoaded = (user: ExtUser) => {
    console.log("user loaded", user);
    this.userManager?.getUser().then(
      (user) => {
        console.log("getUser loaded user after userLoaded event fired");
        this.updateState(user as ExtUser);
      },
      () => {}
    );
  };

  async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return;
    }

    this._managerInitializing = true;

    let response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
    if (!response.ok) {
      throw new Error(`Could not load settings for '${ApplicationName}'`);
    }

    let settings: UserManagerSettings = await response.json();

    settings.userStore = new WebStorageStateStore({
      prefix: ApplicationName,
      store: window.localStorage,
    });

    console.log("Initializing user manager.", settings);

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager?.removeUser();
      this.updateState(null);
    });

    this.userManager.events.addUserLoaded(this.userLoaded as any);

    this.userManager.events.addUserUnloaded(() => {
      this.updateState(undefined);
      console.log("user unloaded");
    });

    this._managerInitializing = false;
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

//declare on window
(window as any).authService = authService;

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
