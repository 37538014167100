import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import { Tables } from '../../../../../services/databaseModels/tables';
import QuestionTemplate from '../../../../shared/QuestionsTemplate';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import QuestionEditor from '../../../../shared/QuestionsEditor';

const name = "QuestionsDialog"


type StandardTicketProps = {
  optionalQuestions: Tables.QuestionsOutput[]
  ticketSubcategoryId: number
  parent: string
  hasQuestions?: boolean[]
}

export const StandardTicket: React.FC<StandardTicketProps> = ({ optionalQuestions, ticketSubcategoryId, parent, hasQuestions }) => {
  const [open, setOpen] = React.useState(false)
  const [question, setQuestion] = React.useState<Tables.QuestionsOutput>({ dependsId: null, dependsValue: null, id: 0, order: optionalQuestions.length + 1, question: "", questionTypeId: 1, subCategoryId: ticketSubcategoryId, version: 1, })

  const handleClose = () => {
    setOpen(false)
  }

  if (hasQuestions && hasQuestions.length > 0) return (<>
    <QuestionEditor question={question} open={open} onClose={handleClose} insert={true} />
    <IconButton onClick={() => {
      setOpen(true)
    }}>
      <AddIcon />
    </IconButton>
    {hasQuestions[0] && <Typography variant='subtitle1' marginBottom={2}>Screening Questions</Typography>}
    <Grid container margin={0} gap={1} width={"100%"} >
      {/* Screening Questions: */}
      {hasQuestions[0] && <QuestionTemplate question={optionalQuestions[0]} index={1} id={ticketSubcategoryId} />}
      {hasQuestions[1] && <QuestionTemplate question={optionalQuestions[1]} index={2} id={ticketSubcategoryId} />}
      {hasQuestions[2] && <QuestionTemplate question={optionalQuestions[2]} index={3} id={ticketSubcategoryId} />}
    </Grid>
    <Grid container margin={0} gap={1} width={"100%"} >
      {/* Screening Questions: */}
      {hasQuestions[3] && <QuestionTemplate question={optionalQuestions[3]} index={4} id={ticketSubcategoryId} />}
      {hasQuestions[4] && <QuestionTemplate question={optionalQuestions[4]} index={5} id={ticketSubcategoryId} />}
      {hasQuestions[5] && <QuestionTemplate question={optionalQuestions[5]} index={6} id={ticketSubcategoryId} />}
    </Grid>
  </>)
  return (<><QuestionEditor question={question} open={open} onClose={handleClose} insert={true} />
    <IconButton onClick={() => {
      setOpen(true)
    }}>
      <AddIcon />
    </IconButton>
  </>)
}

interface QuestionsDialogProps {
  open: boolean
  onClose: () => void
  ticketSubcategoryId: number
  parent: string
  hasQuestions: boolean[]
  optionalQuestions: Tables.QuestionsOutput[]
}

const QuestionsDialog: React.FC<QuestionsDialogProps> = ({ open, onClose, parent, ticketSubcategoryId, hasQuestions, optionalQuestions }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Questions"}
      </DialogTitle>
      <DialogContent>
        <StandardTicket optionalQuestions={optionalQuestions} parent={parent} ticketSubcategoryId={ticketSubcategoryId} hasQuestions={hasQuestions} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Disagree
        </Button>
        <Button onClick={handleClose} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}

QuestionsDialog.displayName = name

export default React.memo(QuestionsDialog)