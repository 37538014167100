import React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './index.css';
import { ReactComponent as RightioLogo } from "../../../assets/img/rightio-logo.svg";
import LoginMenu from './LoginMenu';
import useDebugInformation from '../../../hooks/useDebugInformation';

const NavMenu: React.FC = () => {
  const [collapsed, setCollapsed] = React.useState(true)
  const toggleNavBar = React.useCallback(() => setCollapsed((prevState) => !prevState), [])

  useDebugInformation("NavMenu", { collapsed, toggleNavBar })

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <Container className='pl-4 pr-4 d-inline-flex' style={{ maxWidth: 1140 }} >
          <NavbarBrand className='d-sm-inline-flex' tag={Link} to="/">
            <RightioLogo className="rightio-logo d-inline-block align-top" />
            <h3 className='d-sm-inline-flex align-items-end'>IT Department</h3>
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavBar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row justify-content-end" isOpen={collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              </NavItem>
              <LoginMenu />
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header >
  )
}

export default React.memo(NavMenu);
