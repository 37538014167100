import React from 'react';
import Paper from "@mui/material/Paper"
import Grid from '@mui/material/Grid';
// import RecentTickets from './RecentTickets';
import useDebugInformation from '../../../hooks/useDebugInformation';
import "./index.css"
import { Theme } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import NavigationButtonsGroup from './NavigationButtonsGroup';

const namespace = "HomePage"

const style = (theme: Theme) => ({
    root: ({
        width: '100%',
        maxWidth: 1600,
        backgroundColor: theme.palette.background.paper,
    }),
    btn: ({
        display: "flex",
        justifyContent: "center",
        padding: 5,
        paddingTop: 15,
        paddingBottom: 15,
        marginBottom: 8
    }),
    paper: ({
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
    }),
    // paperFirst: {
    //     marginTop: theme.spacing(0),
    //     marginBottom: theme.spacing(1),
    //     padding: theme.spacing(2),     
    // },
    paperChart: ({
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up(600 + Number(theme.spacing(3)) * 2)]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
            padding: theme.spacing(2),
            maxWidth: 800
        },
    }),
});


const EditorView: React.FC = React.memo(() => {
    const loggedUser = useAppSelector(({ state }) => state.LoggedUser)

    useDebugInformation("AuthenticatedHomeView", {})
    return (
        <Grid wrap="nowrap" container justifyContent="center" alignItems="flex-start" sx={(theme) => style(theme).root}>
            <Grid item xs={12} sm={2.2} order={{ xs: 2, sm: 1 }}>
                <Paper sx={(theme) => style(theme).paperChart} elevation={4}>
                    {loggedUser && <NavigationButtonsGroup />}
                </Paper>
            </Grid>
        </Grid >
    );
})

const EditorPage: React.FC<{}> = () => {
    useDebugInformation(namespace, {})

    return (<EditorView />)
}

EditorPage.displayName = namespace

export default React.memo(EditorPage)