import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import React from "react"
import { cacheUtils } from "../../../.."
import { seedData } from "../../../../assets/js/main"
import { ui } from "../../../../interfaces/interfaces"
import { useAppSelector } from "../../../../store/hooks"
import { CacheTypes } from "../../../../utilities/cache/interfaces"
import NavigationButton from "./NavigationButton"

const NavigationButtonsGroup = React.memo(() => {
    const credentials = useAppSelector(({ state }) => state.Credentials)

    return (<Grid container>
        <NavigationButton xs={12} sm={12} color='primary' variant='contained' pathName='/my-tickets' elevation={4} label="My Tickets" />
        {credentials && credentials.manage &&
            <NavigationButton xs={12} sm={12} color='primary' variant='contained' pathName='/manage-tickets' elevation={4} label="Manage tickets" />}
        <NavigationButton xs={12} sm={12} color='primary' variant='contained' pathName='/report-form' elevation={4} label="Submit Incident" />
        <NavigationButton xs={12} sm={12} color='primary' variant='contained' pathName='/request-form' elevation={4} label="Submit Request" />
        {credentials && credentials.dashboard && <NavigationButton xs={12} sm={12} color='primary' variant='contained' pathName='/dashboard' elevation={4} label="Dashboard" />}
        {(credentials && credentials.seed && cacheUtils.getCache<CacheTypes.Questions>("Questions").length === 0) && (<Grid item xs={12} sm={12}>
            <Paper elevation={4}>
                <Button color="primary" type="button" variant="contained" fullWidth style={{ whiteSpace: "nowrap" }} onClick={() => seedData(ui)}>Seed</Button>
            </Paper>
        </Grid>)}
    </Grid>)
}
)

export default NavigationButtonsGroup